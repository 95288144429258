import React, { PureComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import DayPicker, { DateUtils, DayPickerProps } from 'react-day-picker';
import moment from 'moment';
import { debounce } from 'underscore';

import Button from '../Button';
import Container from '../Container';
import Footer from '../Footer';
import { IconArrowDown } from '../Icon';
import ModalHeader from '../ModalHeader';
import Panel from '../Panel';
import ScrollArea from '../ScrollArea';
import Spacer from '../Spacer';
import TextInput from '../TextInput';
import { Layout } from '../TextInput/TextInput';
import { RadioButtonItemType } from '../RadioButton/RadioButton';
import Popup from '../Popup';
import { Mobile, TabletAndDesktop } from '../../utils/responsive';

import {
  DateInputContainer,
  PresetRangeContainer,
  PresetRenderer,
  StyledDatePicker,
  StyledDash,
} from './DateRangeComponents';

import {
  dateRangePresets,
  getAutoPlacementLeft,
  getPresetRange,
  getUnixTimestampFromDay,
  getUnixTimestampToDay,
  formatDate,
  parseDate,
  findMatchedPreset,
  reconstructDatesFromRange,
  DateRangePresetType,
} from './dateRangeUtils';
import DateNavbar from '../DatePicker/DateNavbar';

import { PlacementType } from '../DatePickerInput/DatePickerInput';

export type PropsType = {
  allowEmpty?: boolean;
  dayPickerProps?: DayPickerProps;
  disabled?: boolean;
  from?: string;
  fromPlaceholder?: string;
  onSubmit?: (obj: DateRangeInputSubmitType) => void;
  /**
   * The css position to render the date picker
   * @type {string} left | right | auto
   */
  placement?: PlacementType;
  presets?: DateRangePresetType;
  primaryText?: string;
  /**
   * Sets the PresetRange Input's width
   */
  rangeInputWidth?: string;
  /**
   * Current selectedPreset
   */
  selectedPreset?: PresetType;
  showPopup?: boolean;
  showPresetAsRange?: boolean;
  to?: string;
  toPlaceholder?: string;
};

type CurrentSelectionType = 'From' | 'To';

const RANGE: { [key: string]: CurrentSelectionType } = {
  From: 'From',
  To: 'To',
};

export type StateType = {
  /**
   * Auto calculated left value for positioning the component
   */
  autoLeft: number | null;
  /**
   * Currently selected input to change ( from / to ) for manually tapping on inputs and
   * changing dates
   */
  currentSelection: CurrentSelectionType | null;
  /**
   * ErrorFrom/to highlights incorrect ones
   */
  errorFrom: boolean;
  errorTo: boolean;
  /**
   * String dates used for display purposes ( and while flipping dates )
   */
  formattedFrom: string;
  formattedTo: string;
  from: Date | null;
  /**
   * Shows a formatted preset range
   */
  presetRange: string;
  selectedPreset: PresetType | null;
  showPopup: boolean;
  to: Date | null;
};

export type DateRangeInputSubmitType = {
  from: string;
  selectedPreset: PresetType | null;
  to: string;
  unixFrom: number;
  unixTo: number;
};

export type PresetType = 'today' | 'yesterday' | 'past_7' | 'past_30' | string;

/**
 * **Deprecated** Use DateRangeSelect, DatePickerInput instead
 * @deprecated
 */
export default class DateRangeInput extends PureComponent<PropsType, StateType> {
  static defaultProps = {
    allowEmpty: false,
    onSubmit: () => {},
    presets: dateRangePresets,
    primaryText: 'Apply',
    fromPlaceholder: 'Select from',
    toPlaceholder: 'Select to',
    rangeInputWidth: '240px',
    placement: 'left',
    disabled: false,
  };

  constructor(props: PropsType) {
    super(props);
    this.state = {
      currentSelection: undefined,
      selectedPreset: undefined,
      errorFrom: false,
      errorTo: false,
      from: undefined,
      to: undefined,
      presetRange: '',
      showPopup: this.props.showPopup,
      autoLeft: null,
      formattedFrom: null,
      formattedTo: null,
    };
  }

  componentDidMount() {
    let overflow;
    const { current } = this.presetContainerRef;
    if (current != null) {
      const rect = current.getClientRects();
      // if the component is not rendered in a hidden state, e.g., inside accordion
      if (rect.length > 0) {
        overflow = getAutoPlacementLeft(rect[0]?.x, 626);
      }
    }

    /**
     * If from/To props are set, use that over selectedPreset.
     */
    const { presets, selectedPreset, from, to, showPresetAsRange } = this.props;
    const { fromDate = undefined, toDate = undefined } = presets[selectedPreset]
      ? presets[selectedPreset].value
      : {};

    /**
     * Parse DD/MM/YYYY formatted strings.
     */
    const parsedFrom = from
      ? parseDate(from)
      : fromDate
      ? moment.unix(fromDate).toDate()
      : undefined;
    const parsedTo = to ? parseDate(to) : toDate ? moment.unix(toDate).toDate() : undefined;

    const selectedPresetFromDates = findMatchedPreset(parsedFrom, parsedTo, presets);

    const presetRange = getPresetRange(
      presets,
      showPresetAsRange,
      selectedPresetFromDates,
      parsedFrom,
      parsedTo,
    );
    this.processDates(parsedFrom, parsedTo, { from, to });

    this.setState({
      selectedPreset: selectedPresetFromDates,
      presetRange,
      autoLeft: overflow,
      currentSelection: RANGE.From,
    });
  }

  /**
   * A Ref for preset range container, to get the x axis from bound area
   * @type {{current}|{current: (null|*)}|*|{current}}
   */

  presetContainerRef = React.createRef<HTMLDivElement>();

  selectPreset = (e: { id: string } | RadioButtonItemType) => {
    const { presets } = this.props;
    const presetKeys = Object.keys(presets);

    /**
     * Use e.id for Segment type or inputValue for radioButtons type.
     */
    // @ts-ignore
    const selectedPreset = e.id != null ? presetKeys[e.id] : e.inputValue;
    const { fromDate, toDate } =
      // @ts-ignore
      e.id != null ? presets[selectedPreset].value : presets[e.inputValue].value;
    const presetRange = {
      fromDate: moment.unix(fromDate).toDate(),
      toDate: moment.unix(toDate).toDate(),
    };
    this.setState({
      selectedPreset,
      from: presetRange.fromDate,
      to: presetRange.toDate,
      formattedFrom: formatDate(presetRange.fromDate),
      formattedTo: formatDate(presetRange.toDate),
      errorFrom: false,
      errorTo: false,
      currentSelection: undefined,
    });
  };

  handleDayClick = (day: Date, modifiers) => {
    if (modifiers.disabled) {
      return;
    }

    const { from: f, to: t, currentSelection } = this.state;
    const { from, to } = DateUtils.addDayToRange(day, { from: f, to: t });
    /**
     * If user taps on From/To inputs, handle their respective actions.
     */
    if (currentSelection === RANGE.From) {
      this.changeFrom(day);
      this.setState({ currentSelection: undefined, to: undefined });
    } else if (currentSelection === RANGE.To) {
      this.changeTo(day);
      this.setState({ currentSelection: undefined });
    } else {
      this.processDates(from, to);
      this.setState({ selectedPreset: undefined, errorFrom: false, errorTo: false });
    }
  };

  /**
   * While toggling popup (more like when closing it, do the same as onSubmit)
   * If one of the dates are undefined, replace it with the other one.
   */
  togglePopup = () => {
    const { from, to, showPopup, presetRange } = this.state;
    const { from: fromProp, to: toProp, presets } = this.props;
    if (from == null && to) {
      this.setState({ from: to, formattedFrom: formatDate(to), errorFrom: false });
    }
    if (to == null && from) {
      this.setState({ to: from, formattedTo: formatDate(from), errorTo: false });
    }

    /**
     * If selectedPreset is undefined or different than 'presetRange', reset
     * back to whatever presetRange is.
     * Both cases rely on current presetRange,
     * if it matches our lookup object, get date values from there and reset our control.
     * For any other case reconstruct dates from presetRange.
     */
    if (presetRange) {
      /**
       * Always results to an array with first value as presetName (aka object.entries key) and
       * second value as an object ({ name: string, value: number})
       * @type {void|[string, *]|{}|Array}
       */
      const result = Object.entries(presets).find(
        // @ts-ignore
        ([, { name }]: DateRangePresetType): DateRangePresetType => name === presetRange,
      );
      /**
       * This case relies on a matched preset
       */
      if (result && result[1] && result[1].value) {
        // fromDate and toDate will always exist if the above condition passes as a
        // result's value will be of DateRangePresetType

        const { fromDate, toDate } = result[1].value;
        const f = moment.unix(fromDate).toDate();
        const t = moment.unix(toDate).toDate();

        this.processDates(f, t, { fromProp, toProp });

        this.setState({
          selectedPreset: result[0],
        });
      } else {
        /**
         * For any other case reconstruct dates from current presetRange.
         */
        const { from: reconstructedFrom, to: reconstructedTo } = reconstructDatesFromRange(
          presetRange,
          '-',
        );
        this.processDates(reconstructedFrom, reconstructedTo);
        this.setState({ selectedPreset: undefined });
      }
    }
    this.setState({ showPopup: !showPopup });
  };

  isValidDate = (
    date: string | null | Record<string, any>,
    parsedDate: any,
    allowEmpty: boolean,
  ): boolean => {
    let res = false;
    /**
     * parseddate would be undefined for empty strings.
     */
    if (
      allowEmpty &&
      typeof date === 'string' &&
      !date.trim() &&
      typeof parsedDate === 'undefined'
    ) {
      res = true;
    }

    /**
     * a valid string date would also result to a parsedDate object
     */
    if (typeof date === 'string' && typeof parsedDate === 'object') {
      res = true;
    }

    /**
     * A chosen date will always be an object
     */
    if (typeof date === 'object' && typeof parsedDate === 'object') {
      res = true;
    }
    return res;
  };

  changeFrom = (from: Date | null) => {
    const rawFrom = from;
    const { allowEmpty } = this.props;
    const { to } = this.state;
    // @ts-ignore this shouldn't be required as from and to should already be Dates
    const parsedFrom = parseDate(from);
    // @ts-ignore
    const parsedTo = parseDate(to);

    const isValid = this.isValidDate(from, parsedFrom, allowEmpty);
    /**
     * If undefined, show an error.
     */
    if (!isValid) {
      this.setState({ errorFrom: true });
    } else {
      this.setState({ errorFrom: false });
    }

    /**
     * A selected from date would always be an object, and on selection, reset to date.
     */
    if (typeof from === 'object') {
      this.setState({
        from: parsedFrom,
        formattedFrom: formatDate(parsedFrom),
        // @ts-ignore
        to: '',
        formattedTo: '',
      });
    } else {
      this.processDates(parsedFrom, parsedTo, { from: rawFrom, to });
    }
    this.setState({ selectedPreset: undefined });
  };

  /**
   * Change toDate and if it's before from, update from.
   * @param {Date} to
   * @return {void|*}
   */
  changeTo = (to: string | Date) => {
    const { allowEmpty } = this.props;
    const { from } = this.state;
    /**
     * An entered valid date would always parse, else result being undefined.
     * @type {Date}
     */
    // @ts-ignore this shouldn't be required as from and to should already be Dates
    const parsedTo = parseDate(to);
    // @ts-ignore
    const parsedFrom = parseDate(from);

    /**
     * Show an error if parsedTo returns undefined
     */
    const validTo = this.isValidDate(to, parsedTo, allowEmpty);
    if (!validTo) {
      this.setState({ errorTo: true });
    } else {
      this.setState({ errorTo: false });
    }

    /**
     * If a past to date and a future from date range has been selected or entered, flip it.
     * Also checking for from values before parsing it.
     */
    if (typeof to === 'object' && from && !DateUtils.isDayBefore(parsedTo, from)) {
      this.setState({ to: parsedTo, formattedTo: formatDate(parsedTo) });
    } else {
      this.processDates(parsedFrom, parsedTo, { from, to });
    }
    this.setState({ selectedPreset: undefined });
  };

  processDates = (from: Date | null, to: Date | null, rawValues?: any) => {
    /**
     * If from/to exists and is a past date, flip it.
     */
    if (from != null && to != null && DateUtils.isDayBefore(to, from)) {
      const formattedFrom = formatDate(to);
      const formattedTo = formatDate(from);
      this.setState(
        (prevState: StateType): StateType => ({
          ...prevState,
          formattedFrom,
          formattedTo,
          from: to,
          to: from,
          errorFrom: false,
          errorTo: false,
          currentSelection: prevState.currentSelection === RANGE.From ? RANGE.To : RANGE.From,
        }),
      );
    } else {
      /**
       * If from/to value is null use the rawValues to update the textInput.
       */
      const formattedFrom = from ? formatDate(from) : rawValues ? rawValues.from : '';
      const formattedTo = to ? formatDate(to) : rawValues ? rawValues.to : '';
      this.setState({ from, to, formattedFrom, formattedTo });
    }
  };

  onFromClick = (): void => this.setState({ currentSelection: RANGE.From });

  onToClick = (): void => this.setState({ currentSelection: RANGE.To });

  onSubmit = (): void => {
    const { presets, showPresetAsRange } = this.props;
    let { from, to } = this.state;
    const { selectedPreset } = this.state;

    /**
     * Update local and state variables if either one of them end up being null or undefined,
     * maintaining local version makes sure that setstate's asynchronous behavior doesn't effect
     * the callback function.
     */
    if (from == null && to) {
      from = to;
      this.setState({ from: to, formattedFrom: formatDate(to), errorFrom: false });
    }
    if (to == null && from) {
      to = from;
      this.setState({ to: from, formattedTo: formatDate(from), errorTo: false });
    }
    if (from == null && to == null) {
      from = new Date();
      to = new Date();
      this.setState({
        from,
        to,
        formattedFrom: formatDate(from),
        formattedTo: formatDate(to),
        errorFrom: false,
        errorTo: false,
      });
    }

    const result = {
      from: formatDate(from),
      selectedPreset,
      to: formatDate(to),
      unixFrom: getUnixTimestampFromDay(from),
      unixTo: getUnixTimestampToDay(to),
    };
    /**
     * selectedPreset should precede showPresetAsRange to handle currently selected range.
     */

    const matchedPreset = findMatchedPreset(from, to, presets);
    const presetRange = getPresetRange(presets, showPresetAsRange, matchedPreset, from, to);

    this.setState({ showPopup: false, presetRange });

    return this.props.onSubmit(result);
  };

  render() {
    const {
      placement,
      primaryText,
      rangeInputWidth,
      fromPlaceholder,
      toPlaceholder,
      presets,
      dayPickerProps,
      disabled,
    } = this.props;

    const {
      autoLeft,
      showPopup,
      presetRange = '',
      selectedPreset,
      errorFrom,
      errorTo,
      from,
      to,
      formattedFrom,
      formattedTo,
      currentSelection,
    } = this.state;

    return (
      <>
        <PresetRangeContainer rangeInputWidth={rangeInputWidth} ref={this.presetContainerRef}>
          <Layout
            padding
            maxWidth={rangeInputWidth}
            onClick={disabled ? undefined : this.togglePopup}
            disabled={disabled}
          >
            <StyledPresetRangeInput
              value={presetRange}
              readOnly
              data-chamid="DateRangeInput"
              data-chaminputid="dateRangeInput"
              disabled={disabled}
            />
            <StyledIconArrow disabled={disabled}>
              <IconArrowDown />
            </StyledIconArrow>
          </Layout>
          {showPopup && (
            <DateRangePopup
              togglePopup={this.togglePopup}
              onSubmit={this.onSubmit}
              renderDateRangeSelect={
                <RenderDateRangeSelect
                  fromPlaceholder={fromPlaceholder}
                  presets={presets}
                  toPlaceholder={toPlaceholder}
                  handleDayClick={this.handleDayClick}
                  selectPreset={this.selectPreset}
                  changeFrom={this.changeFrom}
                  onFromClick={this.onFromClick}
                  changeTo={this.changeTo}
                  onToClick={this.onToClick}
                  errorFrom={errorFrom}
                  errorTo={errorTo}
                  from={from}
                  formattedFrom={formattedFrom}
                  to={to}
                  formattedTo={formattedTo}
                  selectedPreset={selectedPreset}
                  currentSelection={currentSelection}
                  dayPickerProps={dayPickerProps}
                />
              }
              primaryText={primaryText}
              placement={placement}
              autoLeft={autoLeft}
            />
          )}
        </PresetRangeContainer>
      </>
    );
  }
}

type DateRangePopupType = {
  autoLeft: number | null;
  onSubmit: () => void;
  placement: string;
  primaryText: string;
  renderDateRangeSelect: JSX.Element;
  togglePopup: () => void;
};

const DateRangePopup = ({
  togglePopup,
  onSubmit,
  renderDateRangeSelect,
  primaryText,
  placement,
  autoLeft,
}: DateRangePopupType): JSX.Element => (
  <div>
    <Mobile>
      <MobilePanel
        onClose={togglePopup}
        footerRightContent={
          <Button primary fullWidth onClick={onSubmit}>
            {primaryText}
          </Button>
        }
      >
        {renderDateRangeSelect}
      </MobilePanel>
    </Mobile>
    <TabletAndDesktop>
      <PopupContainer placement={placement} autoLeft={autoLeft}>
        <Popup onClickOutside={togglePopup}>
          {renderDateRangeSelect}
          <Footer
            style={footerStyle}
            rightContent={
              <Button primary fullWidth onClick={onSubmit}>
                {primaryText}
              </Button>
            }
          />
        </Popup>
      </PopupContainer>
    </TabletAndDesktop>
  </div>
);

const footerStyle: React.CSSProperties = { position: 'absolute' };

const MobilePanel = ({ children, footerRightContent, onClose }): JSX.Element => (
  <Panel onClosed={onClose}>
    <Container>
      <ModalHeader title="Select range" onClose={onClose} />
      <ScrollArea showBackground={false}>
        <Spacer />
        {children}
        <Footer style={footerStyle} rightContent={footerRightContent} />
      </ScrollArea>
    </Container>
  </Panel>
);

type RenderPickerType = {
  currentSelection: CurrentSelectionType;
  dayPickerProps?: DayPickerProps;
  from: Date;
  handleDayClick: (day: Date, modifiers) => void;
  months: number;
  to: Date;
};

/**
 * Used to determine the calendar to display on screen
 * @param currentSelection
 * @param from
 * @param to
 * @return {*|undefined}
 */
export const getDisplayMonth = (
  currentSelection: CurrentSelectionType | null,
  from: Date,
  to: Date,
): Date | null => {
  const setCurrentMonth = (month: Date, add: number): Date =>
    moment(month).add(add, 'month').toDate();
  /**
   * CurrentSelection exists and from/to does/not exist, return appropriate months.
   */
  if (currentSelection) {
    if (
      currentSelection === RANGE.From ||
      (from && !to) ||
      (from && to && DateUtils.isSameMonth(from, to))
    ) {
      return setCurrentMonth(from, 0);
    }
    if (currentSelection === RANGE.To || (to && !from)) {
      return setCurrentMonth(to, -1);
    }
  }

  /**
   * a case where current selection doesn't exist and from/to are/are't the same month.
   */
  if (from && to && DateUtils.isSameMonth(from, to)) {
    return setCurrentMonth(to, 0);
  }
  if (from && to && !DateUtils.isSameMonth(from, to)) {
    return setCurrentMonth(to, -1);
  }
  return undefined;
};

const RenderPicker = (props: RenderPickerType): JSX.Element => {
  const { months, from, to, handleDayClick, currentSelection, dayPickerProps } = props;
  const selectedDays = [from, { from, to }];
  const modifiers = { start: from, end: to };
  const singleSelect = from == null || to == null;

  const monthToDisplay = months >= 2 ? getDisplayMonth(currentSelection, from, to) : undefined;

  const dayPickerRef = React.useRef();
  return (
    <StyledDatePicker columns={months} singleSelect={singleSelect}>
      <DayPicker
        ref={dayPickerRef}
        initialMonth={from || undefined}
        month={monthToDisplay}
        pagedNavigation
        numberOfMonths={months}
        selectedDays={selectedDays}
        modifiers={modifiers}
        onDayClick={handleDayClick}
        navbarElement={<DateNavbar spacing="4px" top="12px" />}
        {...(dayPickerProps || {})}
      />
    </StyledDatePicker>
  );
};

type RenderRangeSelectType = {
  changeFrom: (from: Date) => void;
  changeTo: (to: Date) => void;
  currentSelection: CurrentSelectionType;
  dayPickerProps?: DayPickerProps;
  errorFrom: boolean;
  errorTo: boolean;
  formattedFrom: string;
  formattedTo: string;
  from: Date;
  fromPlaceholder: string;
  handleDayClick: (day: Date, modifiers) => void;
  onFromClick: () => void;
  onToClick: () => void;
  presets: DateRangePresetType;
  selectPreset: (e: { id: string } | RadioButtonItemType) => void;
  selectedPreset: string;
  to: Date;
  toPlaceholder: string;
};

const RenderDateRangeSelect = (props: RenderRangeSelectType): JSX.Element => {
  const {
    fromPlaceholder,
    presets,
    toPlaceholder,
    handleDayClick,
    selectPreset,
    changeFrom,
    onFromClick,
    changeTo,
    onToClick,
    errorFrom,
    errorTo,
    from,
    to,
    formattedFrom,
    formattedTo,
    selectedPreset,
    currentSelection,
    dayPickerProps,
  } = props;

  const [draftFrom, updateFrom] = useState(formattedFrom);
  const [draftTo, updateTo] = useState(formattedTo);

  /**
   * Need a debouncer to change from/to dates as it's being subscribed to in useEffect and that
   * causes it to parse a D MM YYYY format and update the textinputs straight away.
   * @type {Function}
   */
  const debouncedChangeFrom = debounce(changeFrom, 600);
  const debouncedChangeTo = debounce(changeTo, 600);

  useEffect(() => {
    updateFrom(formattedFrom);
    updateTo(formattedTo);
  }, [from, to, currentSelection]);

  const fromInputRef = React.useRef();

  const toInputRef = React.useRef();

  /**
   * Need a key for the `To textInput` as flipping dates in the HOC doesn't cause a re
   render on this one.
   */
  return (
    <ContainerLayout>
      <PresetRenderer
        presets={presets}
        selectedPreset={selectedPreset}
        onPresetSelect={selectPreset}
      />
      <DateInputContainer>
        <TextInput
          value={draftFrom}
          onChange={debouncedChangeFrom}
          placeholder={fromPlaceholder}
          onClick={onFromClick}
          error={errorFrom}
          ref={fromInputRef}
        />
        <StyledDash>—</StyledDash>
        <TextInput
          value={draftTo}
          onChange={debouncedChangeTo}
          placeholder={toPlaceholder}
          onClick={onToClick}
          error={errorTo}
          ref={toInputRef}
          key={String(from)}
        />
      </DateInputContainer>
      <>
        <Mobile>
          <RenderPicker
            months={1}
            handleDayClick={handleDayClick}
            from={from}
            to={to}
            currentSelection={currentSelection}
            dayPickerProps={dayPickerProps}
          />
        </Mobile>
        <TabletAndDesktop>
          <RenderPicker
            months={2}
            handleDayClick={handleDayClick}
            from={from}
            to={to}
            currentSelection={currentSelection}
            dayPickerProps={dayPickerProps}
          />
        </TabletAndDesktop>
      </>
      <Spacer />
    </ContainerLayout>
  );
};

const ContainerLayout = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: ${({ theme }) => theme.space.s16};
  }
`;

const PopupContainer = styled.div<{ autoLeft?: number; placement?: string }>`
  position: absolute;
  right: ${({ placement }): string => (placement === 'right' ? '0' : 'unset')};
  left: ${({ placement, autoLeft }): string =>
    placement === 'auto' && autoLeft <= 0 ? `${autoLeft}px` : 'unset'};
  z-index: ${({ theme }): number => theme.zIndex.popup};
  padding: 24px;
  margin-top: 12px;
  overflow: auto;
  background: white;
  border-radius: ${({ theme }): string => theme.border.radius};
  border: ${({ theme }): string => theme.form.border};
  box-shadow: ${({ theme }): string => theme.shadows.default};
`;

const StyledPresetRangeInput = styled.input<{ disabled?: boolean }>`
  &&&& {
    width: 100%;
    float: none;
    -webkit-font-smoothing: inherit; // Override existing Kounta css
    border: none;
    font: ${({ theme }): string => theme.text.body};
    color: ${({ theme }): string => theme.colors.text};
    cursor: ${({ disabled }): string => (disabled ? 'not-allowed' : 'pointer')};
    background-color: ${({ theme, disabled }): string =>
      disabled ? theme.form.inactiveBackgroundColor : theme.form.backgroundColor};

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
`;

const StyledIconArrow = styled.div<{ disabled?: boolean }>`
  cursor: ${({ disabled }): string => (disabled ? 'not-allowed' : 'pointer')};
`;
