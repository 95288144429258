import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { theme as lightTheme, darkTheme } from '../../themes';

type PropsType = {
  theme?: 'dark' | 'light';
};

const themeMap = {
  light: lightTheme,
  dark: darkTheme,
};

/**
 * A ChameleonRoot holds all of the essential context for your application to use Chameleon components
 * It should always sit at the root of your application as a parent to all user interface
 */
export const ChameleonRoot: React.FunctionComponent<React.PropsWithChildren<PropsType>> = ({
  theme,
  children,
}) => (
  <ThemeProvider theme={themeMap[theme] || themeMap.light}>
    <GlobalStyles>{children}</GlobalStyles>
  </ThemeProvider>
);

const GlobalStyles = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fontSizes.base};
  font-family: ${({ theme }) => theme.fonts.sans};
  line-height: ${({ theme }) => theme.lineHeights.base};
  width: 100%;
  height: 100%;

  box-sizing: border-box;
  *,
  *:before,
  *:after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }
`;

ChameleonRoot.defaultProps = {
  theme: 'light',
};

export default ChameleonRoot;
