import React from 'react';
import IconStyle from '../IconStyle';
import IconPropsType from '../types';
import IconSvg from './Ascending.svg';

export default function IconAscending(props: IconPropsType): JSX.Element {
  return (
    <IconStyle {...props}>
      <IconSvg {...props} aria-label="ascending-order" />
    </IconStyle>
  );
}
