import React, {
  memo,
  FC,
  useRef,
  useState,
  KeyboardEvent,
  useEffect,
  useMemo,
  ChangeEvent,
} from 'react';
import styled from 'styled-components';

import TextInput from '../TextInput';
import type { TextInputOnBlurCallBackReturnType, TextInputPropsType } from '../TextInput';

export type PropsType = TextInputPropsType & {
  allowNegative?: boolean;
  decimalSeparator?: string;
  maxDecimalPart?: number;
  maxWholePart?: number;
  minDecimalPart?: number;
  minWholePart?: number;
  name?: string;
  onBlur?: (value: string) => void;
  onChange?: (value: string) => void;
  value?: string;
  variant?: 'normal' | 'short';
};

const systemDecimalSeparator = '.';

const DecimalInput: FC<React.PropsWithChildren<PropsType>> = ({
  name,
  value: initialValue = '',
  maxWholePart = 12,
  maxDecimalPart = 4,
  decimalSeparator = '.',
  allowNegative = true,
  variant = 'normal',
  onChange,
  onBlur,
  onKeyDown,
  ...restProps
}) => {
  const [value, setValue] = useState(initialValue);
  const [caretPosition, setCaretPosition] = useState(-1);
  const [caretTime, setCaretTime] = useState(-1);
  const inputRef = useRef<HTMLInputElement>();

  const variantProps: Pick<TextInputPropsType, 'maxWidth' | 'align'> = useMemo(() => {
    switch (variant) {
      case 'short':
        return {
          maxWidth: '56px',
          align: 'center',
        };
      default:
        return {};
    }
  }, [variant]);

  const inputNumericRegex = useMemo(
    () =>
      new RegExp(
        `(^${
          allowNegative ? '[\\-]?' : ''
        }[\\d]{0,${maxWholePart}})(\\${systemDecimalSeparator}+([\\d]{0,${maxDecimalPart}}))?$`,
      ),
    [allowNegative, maxWholePart, maxDecimalPart],
  );

  const formatDisplayValue = useMemo(() => {
    // We don't need to convert separators when `decimalSeparator` = `systemDecimalSeparator`.
    if (decimalSeparator === systemDecimalSeparator) {
      return (inputValue: string) => inputValue;
    }

    return (inputValue: string) =>
      inputNumericRegex.test(inputValue)
        ? inputValue.replace(new RegExp(`\\${systemDecimalSeparator}`, 'g'), decimalSeparator)
        : inputValue;
  }, [decimalSeparator]);

  const formatInputValue = useMemo(() => {
    // We don't need to convert separators when `decimalSeparator` != `systemDecimalSeparator`.
    if (decimalSeparator === systemDecimalSeparator) {
      return (displayValue: string) => displayValue;
    }

    // Remove any `systemDecimalSeparator`s that have been entered (e.g. pasted) before replacing
    // the localised `decimalSeparator`. This avoids creating output with multiple decimals when
    // the string contains both characters, e.g. to avoid turning '1.234,56' into '1.234.56'.
    return (displayValue: string) =>
      displayValue
        .replace(new RegExp(`\\${systemDecimalSeparator}`, 'g'), '')
        .replace(new RegExp(`\\${decimalSeparator}`, 'g'), systemDecimalSeparator);
  }, [decimalSeparator]);

  function getInputRef(): React.RefObject<HTMLInputElement> {
    return restProps.inputRef || inputRef;
  }

  useEffect(() => {
    if (!inputNumericRegex.test(initialValue)) {
      // eslint-disable-next-line
      console.warn(`Invalid decimal value: ${initialValue}`);
    }
    setValue(initialValue);
  }, [initialValue, inputNumericRegex]);

  // Ensures caret stays in the last position when there is a rejected character input.
  // This fixes an issue where the caret jumps to the end if a user input is invalid.
  // https://kounta.atlassian.net/browse/CHAM-664
  useEffect(() => {
    const ref = getInputRef();
    ref.current.selectionStart = caretPosition;
    ref.current.selectionEnd = caretPosition;
  }, [caretTime]);

  function handleChange(rawValue: string, _: string, event: ChangeEvent<HTMLInputElement>) {
    const newValue = formatInputValue(rawValue);
    const updatedValue = newValue.startsWith(systemDecimalSeparator) ? `0${newValue}` : newValue;
    if (inputNumericRegex.test(updatedValue)) {
      setValue(updatedValue);
      if (onChange) {
        onChange(updatedValue);
      }
    } else {
      setCaretPosition(event.target.selectionStart - 1);
      setValue(value);
      setCaretTime(Date.now());
      if (onChange) {
        onChange(value);
      }
    }
  }

  function handleBlur():
    | Promise<TextInputOnBlurCallBackReturnType | void>
    | TextInputOnBlurCallBackReturnType
    | void {
    if (value.endsWith(systemDecimalSeparator)) {
      const updatedValue = value.substring(0, value.length - 1);
      setValue(updatedValue);
      if (onBlur) {
        onBlur(updatedValue);
      }
    } else if (onBlur) {
      onBlur(value);
    }
  }

  function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (
      event.key === decimalSeparator &&
      (maxDecimalPart <= 0 || event.currentTarget.value.includes(decimalSeparator))
    ) {
      event.preventDefault();
    } else if (event.key === '-' && (!allowNegative || event.currentTarget.value.includes('-'))) {
      event.preventDefault();
    }
    if (onKeyDown) {
      onKeyDown(event);
    }
  }

  return (
    <DecimalInputContainer>
      <TextInput
        {...variantProps}
        {...restProps}
        controlled
        inputRef={getInputRef()}
        inputFieldName={name}
        value={formatDisplayValue(value)}
        inputMode="decimal"
        title="Please enter numbers only."
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      />
    </DecimalInputContainer>
  );
};

export default memo(DecimalInput);

const DecimalInputContainer = styled.div``;
