import React from 'react';
import styled from 'styled-components';

export type ClampModeType = 'ellipsis' | 'fade';

type PropsType = {
  /**
   * The line-height used in conjunction with maxLines for calculations for `fade` mode
   */
  baseLineHeight?: number;
  /**
   * Allow language-aware hyphenation
   */
  hyphenate?: boolean;
  maxLines?: number;
  mode?: ClampModeType;
};

const TextClamp: React.FunctionComponent<React.PropsWithChildren<PropsType>> = ({
  children = null,
  maxLines,
  mode = 'ellipsis',
  baseLineHeight = 24,
  hyphenate = false,
}) => {
  const textComponent =
    mode === 'fade' ? (
      <FadeSpan maxLines={maxLines} baseLineHeight={baseLineHeight}>
        {children}
      </FadeSpan>
    ) : (
      <InnerSpan maxLines={maxLines} useEllipsis={mode === 'ellipsis'}>
        {children}
      </InnerSpan>
    );

  return <ParentSpan hyphenate={hyphenate}>{textComponent}</ParentSpan>;
};

const ParentSpan = styled.span<{ hyphenate?: boolean }>`
  hyphens: ${(props): string => (props.hyphenate ? 'auto' : 'none')};
  display: table;
  table-layout: fixed;
  width: 100%;
`;

const InnerSpan = styled.span<{ maxLines?: number; useEllipsis?: boolean }>`
  display: table-cell;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${(props): number => props.maxLines};
  -webkit-box-orient: vertical;
`;

const FadeSpan = styled.span<{ baseLineHeight?: number; maxLines?: number }>`
  position: relative;
  max-height: ${(props): number => props.maxLines * props.baseLineHeight}px;
  overflow: hidden;
  display: block;

  &:after {
    content: '';
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20%;
    height: ${(props): number => props.baseLineHeight}px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
  }
`;

export default TextClamp;
