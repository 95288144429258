import React, { Fragment } from 'react';
import styled from 'styled-components';

import classNames from 'classnames';
import Overlay from '../Overlay';
import Button from '../Button';
import ModalHeader from '../ModalHeader';
import Paragraph from '../Paragraph';
import { SpacingTypes } from '../../theme';
import { themeGet } from '../../utils';
import Escaper from '../../utils/escaper';
import FocusElement from '../../utils/focusElement';
import { DataPropsType, getDataAttributes } from '../../utils/dataAttributes';
import { Spacer } from '..';

export const ModalContainer = styled.div<{
  height?: string;
  width?: string;
}>`
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: ${({ theme }): string => theme.dialog.backgroundColor};
  border-radius: 5px;
  backface-visibility: hidden;
  display: block;

  height: ${({ height }): string => height};
  width: ${({ width }): string => width};

  @media screen and (max-width: 767px) {
    &.mobileFullScreen {
      height: max-content;
      width: 100%;
    }
  }
`;

const ModalHeaderContainer = styled.div<{
  padding?: SpacingTypes;
}>`
  padding: ${({ padding }) => themeGet(`spacing.${padding}`)};
`;

export const ModalBody = styled.div<{
  padding?: SpacingTypes;
}>`
  width: 100%;
  padding: 0 ${({ padding }) => themeGet(`spacing.${padding}`)};
  min-height: 32px;
  box-sizing: border-box;
`;

export const ModalFooter = styled.div<{
  padding?: SpacingTypes;
  shadow?: boolean;
}>`
  position: relative;
  display: flex;
  padding: ${({ padding }) => themeGet(`spacing.${padding}`)};
  box-shadow: ${({ shadow, theme }) => (shadow ? theme.dialog.shadow : 'unset')};
`;

type PropsType = DataPropsType & {
  actionClose?: (e?: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>) => void;
  actionPrimary?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  actionPrimaryDisabled?: boolean;
  autoFocusFooter?: boolean;
  buttonText?: string;
  containerPadding?: SpacingTypes;
  content?: string;
  footer?: JSX.Element | null;
  footerShadow?: boolean;
  forceRenderToDOM?: boolean;
  hasButton?: boolean;
  hasCloseBtn?: boolean;
  headerContent?: JSX.Element | null;
  height?: string;
  mobileFullScreen?: boolean;
  overlay?: boolean;
  preventBackgroundScroll?: boolean;
  removeFooter?: boolean;
  title?: string;
  width?: string;
};

export const CLASS_NAME_BTN_PRIMARY = 'btnPrimary';

const Modal: React.FC<React.PropsWithChildren<PropsType>> = ({
  overlay = true,
  title = 'Title',
  containerPadding = 's24',
  content = 'Are you sure?',
  hasCloseBtn = true,
  hasButton = true,
  buttonText = 'Ok',
  height = 'auto',
  width = '400px',
  headerContent = null,
  mobileFullScreen = false,
  autoFocusFooter = true,
  children = null,
  actionPrimary = () => {},
  actionPrimaryDisabled = false,
  actionClose = () => {},
  forceRenderToDOM = false,
  footerShadow = false,
  preventBackgroundScroll = false,
  data,
  footer,
  removeFooter,
}) => {
  const Wrapper = overlay ? Overlay : Fragment;

  return (
    <Wrapper
      forceRenderToDOM={forceRenderToDOM}
      mobileFullScreen={mobileFullScreen}
      preventBackgroundScroll={preventBackgroundScroll}
    >
      <ModalContainer
        // would ideally remove classname pollution, but some code in core depends on it
        className={classNames(mobileFullScreen ? 'mobileFullScreen' : '')}
        {...{ width, height }}
        {...getDataAttributes(data)}
      >
        <ModalHeaderContainer padding={containerPadding}>
          <ModalHeader
            data={data}
            title={title}
            showCloseButton={hasCloseBtn}
            onClose={actionClose}
          />
          {headerContent}
        </ModalHeaderContainer>
        <ModalBody padding={containerPadding}>
          {children || <Paragraph>{content}</Paragraph>}
        </ModalBody>
        {removeFooter ? (
          <Spacer spacing="s24" />
        ) : (
          <Escaper onEscape={actionClose}>
            <FocusElement focus={autoFocusFooter}>
              <ModalFooter padding={containerPadding} shadow={footerShadow}>
                {footer ||
                  (hasButton && (
                    <Button
                      primary
                      fullWidth
                      disabled={actionPrimaryDisabled}
                      className={CLASS_NAME_BTN_PRIMARY}
                      onClick={actionPrimary}
                    >
                      {buttonText}
                    </Button>
                  ))}
              </ModalFooter>
            </FocusElement>
          </Escaper>
        )}
      </ModalContainer>
    </Wrapper>
  );
};

export default Modal;
