import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import Alignment from '../Alignment';
import { TableHeaderType } from './types';

const Td = styled.td<{
  expand?: boolean;
  showIndent?: boolean;
  spacingBetweenCells?: string;
  spacingBetweenRows?: string;
}>`
  display: table-cell;
  vertical-align: inherit;
  white-space: ${({ expand }) => (expand ? 'nowrap' : '')};

  font: ${({ theme }): string => theme.text.body};
  color: ${({ theme }): string => theme.colors.text};

  &.numeric {
    text-align: right;
  }
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }

  padding: ${({ spacingBetweenRows, spacingBetweenCells, theme }): string =>
    `${theme.space[spacingBetweenRows]} ${theme.space[spacingBetweenCells]}`};

  &:first-child {
    padding-left: ${(props): string => (props.showIndent ? '8px' : '0px')};
  }
  &:last-child {
    padding-right: ${(props): string => (props.showIndent ? '8px' : '0px')};
  }
`;

export type ColumnContentType = TableHeaderType & {
  spacingBetweenCells?: string;
  spacingBetweenRows?: string;
};

export const TableCell: React.FunctionComponent<React.PropsWithChildren<ColumnContentType>> = ({
  id,
  children,
  numeric,
  align,
  ...tableHeaderProps
}) => {
  const classes = classNames('cell', numeric ? 'numeric' : '');
  return (
    <Td className={classes} {...tableHeaderProps} data-chamid="Table__TableCell">
      <Alignment vertical="center" horizontal={align}>
        {children}
      </Alignment>
    </Td>
  );
};

export default TableCell;
