/**
 * Convert a number to a px value.
 */

export function toPx(n: number): string {
  return `${n}px`;
}

export function maybeReverse<T>(arr: T[], reverse: boolean): T[] {
  return reverse ? arr.reverse() : arr;
}
