import React, { Component } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { IconTick, IconIndeterminate } from '../Icon';
import { DataPropsType, getDataAttributes } from '../../utils/dataAttributes';

const CheckboxContainer = styled.label<{ height: number; padding: string; width: number }>`
  display: inline-flex;
  position: relative;
  padding-left: ${({ padding }) => padding};
  cursor: pointer;
  min-width: ${({ width }) => width}px;
  min-height: ${({ height }) => height}px;

  .text {
    font: ${({ theme }): string => theme.text.body};
    margin-left: 16px;
    display: inline-block;

    &.truncate {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      min-width: 0;
    }
  }

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 0px;
    height: 0px;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: ${({ height }) => height}px;
    width: ${({ width }) => width}px;
    border-radius: 2px;
    padding: 2px;
    border: ${({ theme }): string => theme.checkbox.border};
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: ${({ theme }): string => theme.form.hoverBackgroundColor};
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    border: ${({ theme }): string => theme.form.selectedBorder};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark .icon {
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark .icon {
    display: block;
  }

  &.inactive {
    background: transparent;
    opacity: 0.5;
    .checkmark {
      background-color: ${({ theme }): string => theme.checkbox.inactiveBackgroundColor};
    }
    cursor: not-allowed;
  }

  &.hideCheckbox {
    padding-left: 0;
    justify-content: center;
    min-width: unset;
    min-height: unset;
    .text {
      margin-left: 0;
    }
    /* This is to override the opacity which defined in Kounta frontend.css */
    .checker {
      opacity: 0;
    }
    .checkmark {
      display: none;
    }
  }
`;

export type PropsType = DataPropsType & {
  /**
   * Deprecated. Checked acts more like defaultChecked, as the componentWillReceiveProps doesn't work as expected.
   * Prefer forceChecked to be fully controlled.
   * @deprecated */
  checked?: boolean;
  /** Fully control the checked state of the component. */
  forceChecked?: boolean;
  hideCheckbox?: boolean;
  /** Required to make the checkbox interactive. */
  htmlFor?: string;
  inactive?: boolean;
  indeterminate?: boolean;
  inputClassName?: string;
  label?: string;
  onChange?: (checked: boolean) => void;
  /**
   * Sets Checkbox container's padding-left
   */
  padding?: string;
  truncate?: boolean;
};

type StateType = {
  checked: boolean;
};

export enum InputState {
  CHECKED = 'checked',
  INDETERMINATE = 'indeterminate',
  UNCHECKED = 'unchecked',
}

export default class Checkbox extends Component<PropsType, StateType> {
  static defaultProps = {
    inactive: false,
    indeterminate: false,
    checked: false,
    forceChecked: undefined,
    label: '',
    htmlFor: undefined,
    truncate: false,
    hideCheckbox: false,
    onChange: () => {},
    padding: '16px',
  };

  constructor(props: PropsType) {
    super(props);
    this.state = { checked: this.props.checked };
  }

  UNSAFE_componentWillReceiveProps(nextProps: PropsType) {
    if (nextProps.checked !== this.state.checked) {
      this.setState({ checked: nextProps.checked });
    }
  }

  toggleCheck = () => {
    if (this.props.inactive) return;

    if (this.props.onChange) {
      this.props.onChange(!this.isChecked());
    }
    this.setState(({ checked }) => ({ checked: !checked }));
  };

  isChecked() {
    if (typeof this.props.forceChecked !== 'undefined') {
      return this.props.forceChecked;
    }
    return this.state.checked;
  }

  render(): JSX.Element {
    const {
      padding,
      hideCheckbox,
      indeterminate,
      inactive,
      truncate,
      htmlFor,
      label,
      inputClassName,
      data,
    } = this.props;

    const checked = this.isChecked() || indeterminate;
    const icon = indeterminate ? <IconIndeterminate /> : <IconTick />;
    const checkboxClasses = classnames(
      'checkbox',
      checked ? 'checked' : '',
      inactive ? 'inactive' : '',
      hideCheckbox ? 'hideCheckbox' : '',
    );
    let inputState = checked ? InputState.CHECKED : InputState.UNCHECKED;
    if (indeterminate) {
      inputState = InputState.INDETERMINATE;
    }

    const textClasses = classnames('text', truncate ? 'truncate' : '');

    return (
      <CheckboxContainer
        width={24}
        height={24}
        htmlFor={htmlFor}
        className={checkboxClasses}
        padding={hideCheckbox ? '0' : padding}
      >
        {!!label && <span className={textClasses}>{label}</span>}
        <input
          id={htmlFor}
          type="checkbox"
          onChange={this.toggleCheck}
          checked={checked}
          disabled={inactive}
          className={inputClassName}
          data-chaminputid="checkboxInput"
          data-chaminputstate={inputState}
          {...getDataAttributes(data)}
        />
        <span className="checkmark">
          <div className="icon">{icon}</div>
        </span>
      </CheckboxContainer>
    );
  }
}
