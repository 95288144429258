import React from 'react';

interface StateType {
  currentIndex?: number;
}

// This component is an antipattern and therefore is accruing tech debt until it is refactored away from inheritence
export default class PageController<P, S extends StateType> extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    // @ts-ignore
    this.state = { currentIndex: 0 };
  }

  nextPage = () => this.setState({ currentIndex: this.state.currentIndex + 1 });

  prevPage = () => this.setState({ currentIndex: this.state.currentIndex - 1 });

  goToPage = (currentIndex: number) => this.setState({ currentIndex });

  render() {
    return null;
  }
}
