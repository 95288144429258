import styled from 'styled-components';
import { themeGet } from '../../utils/themeGet';

type IconStyleTypes = {
  color?: string;
  height?: string | number;
  usingPath?: boolean;
  width?: string | number;
};
const IconStyle = styled.span<IconStyleTypes>`
  display: inline-flex;
  width: ${(props): string => `${props.width}px`};
  height: ${(props): string => `${props.height}px`};
  svg {
    * {
      fill: ${({ usingPath, color, theme }) => {
        if (usingPath) return 'none';
        if (color) return themeGet(`colors.${color}`);
        return theme.icon.color;
      }};
      stroke: ${({ usingPath, color, theme }) => {
        if (!usingPath) return 'none';
        if (color) return themeGet(`colors.${color}`);
        return theme.icon.color;
      }};
    }
  }
`;
IconStyle.defaultProps = {
  color: '',
  usingPath: false,
  width: 24,
  height: 24,
};

/** @component */
export default IconStyle;
