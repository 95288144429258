import styled, { css } from 'styled-components';
import React from 'react';
import { SpacingTypes, StatusTypes } from '../../theme';
import { themeGet } from '../../utils/themeGet';

type PropsType = {
  [any: string]: unknown;
  callout?: boolean;
  /**
   * **Deprecated** never pass className to a Chameleon component
   * @deprecated
   */
  className?: string;
  /** Show disabled style */
  disabled?: boolean;
  height?: string;
  margin?: SpacingTypes;
  padding?: SpacingTypes;
  selected?: boolean;
  showBorder?: boolean;
  /** Show hover style when hovered. */
  showHover?: boolean;
  /**
   * **Deprecated** Use padding instead
   * @deprecated
   */
  spacing?: SpacingTypes;
  status?: StatusTypes;
  width?: string;
};

export const Box: React.FunctionComponent<PropsType & React.HTMLAttributes<HTMLDivElement>> = ({
  callout = false,
  className = 'box',
  disabled = false,
  selected = false,
  spacing = 'large',
  showHover = false,
  showBorder = true,
  status = 'info',
  margin = 's0',
  onClick,
  padding,
  ...restProps
}) => {
  const hasInteractionEffects = onClick && showHover && showBorder && !disabled;
  const givenStatus = callout ? status : null;

  return (
    <StyledBox
      className={className}
      disabled={disabled}
      selected={selected}
      showBorder={showBorder}
      hasInteractionEffects={hasInteractionEffects}
      status={givenStatus}
      margin={margin}
      padding={padding || spacing}
      onClick={onClick}
      {...restProps}
    />
  );
};

type StyledBoxProps = {
  disabled: boolean;
  hasInteractionEffects: boolean;
  height?: string;
  margin: SpacingTypes;
  padding?: SpacingTypes;
  selected: boolean;
  showBorder: boolean;
  status: StatusTypes;
  width?: string;
};

const StyledBox = styled.div<StyledBoxProps>`
  padding: ${({ padding }) => themeGet(`space.${padding}`)};
  margin: ${({ margin }) => themeGet(`space.${margin}`)};
  border-radius: ${({ theme }): string => theme.border.radius};
  height: ${({ height }) => height};
  width: ${({ width }) => width};

  cursor: ${({ onClick, disabled }) => {
    if (disabled) {
      return 'not-allowed';
    }
    if (onClick) {
      return 'pointer';
    }
    return 'default';
  }};

  ${({ hasInteractionEffects, theme }) =>
    hasInteractionEffects &&
    css`
      &:hover {
        border: ${theme.box.hoverBorder};
      }
    `}
  border: ${({ selected, disabled, theme }): string =>
    selected && !disabled ? theme.box.selectedBorder : theme.box.defaultBorder};
  border-width: ${({ showBorder }): string => (showBorder ? '2px' : '0px')};

  background-color: ${({ theme, status }): string =>
    status ? theme.status[status].surfaceSubdued : theme.box.backgroundColor};

  color: ${({ theme, status }): string => status && theme.status[status].textOnSurface};
`;

export default Box;
