import React, { LabelHTMLAttributes } from 'react';
import styled from 'styled-components';
import { typographyStyleFn, TypographyProps } from '../../utils/styleFns';

const StyledLabel = styled.div<PropsType>`
  font-family: ${({ theme }) => theme.fonts.sans};
  font-size: ${({ theme }) => theme.fontSizes.base};
  line-height: ${({ theme }) => theme.lineHeights.base};
  font-weight: ${({ theme }) => theme.fontWeights.bold};

  color: ${({ primary, theme }): string =>
    primary ? theme.link.primary.color : theme.text.labelColor};

  ${typographyStyleFn}
`;

type PropsType = TypographyProps & {
  /**
   * **Deprecated** Use a Link instead
   * @deprecated
   */
  primary?: boolean;
};

const Label: React.FunctionComponent<
  React.PropsWithChildren<PropsType & LabelHTMLAttributes<HTMLDivElement>>
> = ({ align = 'left', children, primary = false, ...restProps }) => {
  return (
    <StyledLabel align={align} primary={primary} {...restProps}>
      {children}
    </StyledLabel>
  );
};

export default Label;
