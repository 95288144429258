import React from 'react';
import styled from 'styled-components';
import { StyledAccordionItem } from './AccordionItem';

export type PropsType = {
  /**
   * Toggles the left/right border
   */
  showBorder?: boolean;
};

const Accordion: React.FunctionComponent<React.PropsWithChildren<PropsType>> = ({
  showBorder = false,
  children = [],
}) => {
  return (
    <StyledAccordion showBorder={showBorder}>
      <ChildContainer showBorder={showBorder}>{children}</ChildContainer>
    </StyledAccordion>
  );
};

const ChildContainer = styled.div<{ showBorder?: boolean }>`
  clip-path: ${({ showBorder }): string => (showBorder ? 'inset(0 0 0 0 round 4px)' : 'unset')};
`;

const StyledAccordion = styled.div<{ showBorder?: boolean }>`
  ${({ showBorder, theme }) =>
    !showBorder
      ? {
          borderTop: theme.border.defaultBorder,
          borderBottom: theme.border.defaultBorder,
        }
      : {
          border: theme.border.defaultBorder,
          borderRadius: '4px',
        }}
  ${StyledAccordionItem} + ${StyledAccordionItem} {
    border-top: ${({ theme }): string => theme.border.defaultBorder};
  }

  ${StyledAccordionItem}.isopen + ${StyledAccordionItem}.isOpen {
    padding-top: 0;
  }

  ${StyledAccordionItem}:first-child {
    padding-top: 0;
  }
  ${StyledAccordionItem}:last-child {
    padding-bottom: 0;
    > div {
      box-shadow: unset;
    }
  }
`;

export default Accordion;
