import React, { Component, KeyboardEvent } from 'react';
import styled from 'styled-components';
import { debounce } from 'underscore';
import { IconClear, IconSearch } from '../Icon';
import { getDataAttributes, DataPropsType } from '../../utils/dataAttributes';
import Input from '../Input';

type PropsType = DataPropsType & {
  autoFocus: boolean;
  /**
   * Automatically sets focus on input element after clear button is clicked.
   */
  autoFocusOnClear: boolean;
  disabled?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  onBlur: () => void;
  /**
   * Triggered when input value is changed regardless of searchLength.
   */
  onChange: (value: string) => void;
  onClear: () => void;
  /** You can use the second argument to prevent parent forms from being submitted. */
  onEnterKeyPress: (value: string, event: KeyboardEvent<HTMLInputElement>) => void;
  /**
   * A debounced callback when input value is changed and its length is greater than searchLength.
   * @param {string} value at the end of a search.
   * */
  onSearchChanged: (value: string) => void;
  placeholder: string;
  /**
   * The number of characters required to be entered before onChange is called. Default is 0
   */
  searchLength: number;
  searchText: string;
};

type StateType = {
  value: string;
};

export default class Search extends Component<PropsType, StateType> {
  static defaultProps = {
    placeholder: 'Search',
    searchLength: 0,
    searchText: '',
    autoFocus: false,
    autoFocusOnClear: false,
    inputRef: null,
    disabled: false,
    onClear: () => {},
    onChange: () => {},
    onBlur: () => {},
    onEnterKeyPress: () => {},
    onSearchChanged: () => {},
  };

  constructor(props: PropsType) {
    super(props);
    const { searchText } = props;
    this.state = { value: searchText };
  }

  static getDerivedStateFromProps(props: PropsType): Partial<StateType> {
    return {
      value: props.searchText,
    };
  }

  componentDidMount() {
    const inputRef = this.getInputRef();
    if (this.props.autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }

  componentDidUpdate(prevProps: Readonly<PropsType>): void {
    // Update debounced `onSearchChanged` handler when `onSearchChanged` prop is changed.
    if (this.props.onSearchChanged !== prevProps.onSearchChanged) {
      this.onSearchChanged = this.props.onSearchChanged
        ? debounce(this.props.onSearchChanged)
        : undefined;
    }
  }

  inputRef = React.createRef<HTMLInputElement>();

  getInputRef(): React.RefObject<HTMLInputElement> {
    return this.props.inputRef || this.inputRef;
  }

  handleChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    // In React SyntheticEvents are pooled by default and for debounce to work they have to be
    // retained.
    // https://reactjs.org/docs/events.html#event-pooling
    e.persist();
    const text = e.target.value;
    if (this.props.onChange) {
      this.props.onChange(text);
    }
    const characterCount = this.props.searchLength;
    if (text.length >= characterCount && this.onSearchChanged) {
      this.onSearchChanged(text);
    }
    this.updateValue(text);
  };

  handleKeyDownEvent = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13 && this.props.onEnterKeyPress) {
      const { value } = e.target as HTMLInputElement;
      this.props.onEnterKeyPress(value, e);
    }
  };

  handleClearEvent = () => {
    if (this.state.value !== '') {
      this.updateValue('');
      this.props.onClear();
    }
    if (this.props.autoFocusOnClear) {
      const inputRef = this.getInputRef();
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  // NOTE: This is updated within `componentDidUpdate` if the `onSearchChanged` prop is changed.
  onSearchChanged = this.props.onSearchChanged && debounce(this.props.onSearchChanged, 250);

  updateValue = (newValue: string) => {
    this.setState({ value: newValue });
  };

  getClearClass(): string {
    const text = this.state.value;
    return typeof text !== 'undefined' && text.length > 0 ? 'clearIcon' : 'hide';
  }

  render(): JSX.Element {
    const { disabled, data, placeholder, onBlur } = this.props;
    const clearClassName = this.getClearClass();
    return (
      <SearchComponent role="search">
        <div className="searchIcon" {...getDataAttributes(data, 'searchIcon')}>
          <IconSearch />
        </div>
        <div
          role="button"
          tabIndex={-1}
          className={clearClassName}
          onClick={this.handleClearEvent}
          {...getDataAttributes(data, 'clearButton')}
        >
          <IconClear />
        </div>
        <Input
          ref={this.getInputRef()}
          placeholder={placeholder}
          type="text"
          data-chaminputid="searchInput"
          onBlur={onBlur}
          value={this.state.value}
          onChange={this.handleChangeEvent}
          onKeyDown={this.handleKeyDownEvent}
          disabled={disabled}
          {...getDataAttributes(data)}
        />
      </SearchComponent>
    );
  }
}

export const SearchComponent = styled.div`
  &&&& {
    position: relative;
    margin: 0;
    width: 100%;

    .searchIcon {
      position: absolute;
      left: 8px;
      top: 0;
      display: flex;
      align-items: center;
      height: 100%;
    }

    .hide {
      display: none;
    }
    .clearIcon {
      position: absolute;
      right: 12px;
      top: 0;
      display: flex;
      align-items: center;
      height: 100%;

      &:focus {
        outline: none;
      }
    }
  }
`;
