import { NumberPadActionGroup } from './NumberPadActionGroup';
import { NumberPadActionItem } from './NumberPadActionItem';
import { NumberPadActionBar } from './NumberPadActionBar';
import { ActionHandlerType, FormatterValueType, NumberPadType } from './types';

export const ACTION_PLUS_NAME = '+';
export const ACTION_MINUS_NAME = '-';
export const GROUP_PLUS_MINUS_NAME = 'plus_minus_group';
export const BAR_NAME = 'plus_minus_bar';
export type PlusMinusAmountPercentageActionBarType = {
  isNegative?: boolean;
};
const defaultParam: PlusMinusAmountPercentageActionBarType = {
  isNegative: false,
};
export class NumberPadPlusMinusActionBar extends NumberPadActionBar {
  /**
   * This function is to generate the state according to action bar state
   * @param actionBar
   */
  actionHandler = (actionHandlerInputState: ActionHandlerType): ActionHandlerType => {
    const ret: ActionHandlerType = { ...actionHandlerInputState };
    const action = this.groups[0].actions.find((a: NumberPadActionItem): boolean => a.selected);
    if (action) {
      ret.isNegative = action.name === ACTION_MINUS_NAME;
    }
    return ret;
  };

  formatter = (formatterInputState: FormatterValueType): string =>
    formatterInputState.isNegative ? `-${formatterInputState.value}` : formatterInputState.value;

  keyboardHandler: (event: KeyboardEvent | React.KeyboardEvent) => boolean = (event) => {
    let ret = false;
    if (this.actions) {
      // find the action button according to the name
      let foundGroup = null;
      const foundAction = this.actions.find(
        (action: NumberPadActionItem): boolean => action.name === event.key,
      );
      if (foundAction) {
        foundGroup = this.groups.find(
          (group: NumberPadActionGroup): boolean => group.groupName === foundAction.groupName,
        );
      }
      if (foundGroup && foundAction) {
        // Toggle the selected action item; and save the previous selected action for rollback.
        this.toggleGroupActionItem(foundAction, foundGroup);
        ret = true;
      }
    }
    return ret;
  };

  syncFromState = (state: NumberPadType) => {
    // reset the action bar according to the state;
    const syncActionItem = state.isNegative
      ? this.getActionItem(GROUP_PLUS_MINUS_NAME, ACTION_MINUS_NAME)
      : this.getActionItem(GROUP_PLUS_MINUS_NAME, ACTION_PLUS_NAME);

    if (syncActionItem) {
      this.toggleGroupActionItem(syncActionItem);
    }
  };

  // @ts-ignore
  constructor(param?: PlusMinusAmountPercentageActionBarType = defaultParam) {
    const actionGroups = [];
    param = { ...defaultParam, ...param };
    const NumberPadPlusMinusGroup = [
      new NumberPadActionItem(ACTION_PLUS_NAME, ACTION_PLUS_NAME),
      new NumberPadActionItem(ACTION_MINUS_NAME, ACTION_MINUS_NAME),
    ];

    const defaultAction = param.isNegative
      ? NumberPadPlusMinusGroup[1]
      : NumberPadPlusMinusGroup[0];

    actionGroups.push(
      new NumberPadActionGroup(GROUP_PLUS_MINUS_NAME, defaultAction, NumberPadPlusMinusGroup),
    );
    super(BAR_NAME, actionGroups);
  }
}
