import React from 'react';
import { IconClear } from '../Icon';
import { Alignment } from '../index';

type PropsType = {
  handleClearEvent: () => void;
  inputValue: string;
};

export const displayToggleByInputValue = (inputValue: string): string => {
  return inputValue ? 'block' : 'none';
};

export const ClearButton = React.memo(({ inputValue, handleClearEvent }: PropsType) => {
  const buttonStyle = { display: displayToggleByInputValue(inputValue) };
  return (
    <div role="button" tabIndex={-1} onClick={handleClearEvent} style={buttonStyle}>
      <Alignment vertical="center" horizontal="center">
        <IconClear />
      </Alignment>
    </div>
  );
});
