import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils, DayPickerProps } from 'react-day-picker';
// @ts-ignore
import { formatDate, parseDate } from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import Label from '../Label';
import DateContainer from '../DatePicker/DateContainer';
import DateNavbar from '../DatePicker/DateNavbar';
import { getAutoPlacementLeft } from '../DateRangeInput/dateRangeUtils';
import { onEnterKeyPress, isEmptyString } from './utils';
import randomGenerator from '../../utils/randomGenerator';
import { borderFocused, borderDefault, borderError } from '../../mixins';

const DateOverlay = styled.div<{ autoLeft?: number; placement?: string }>`
  position: absolute;
  width: calc(100vw - 64px);
  max-width: 368px;
  border-radius: ${({ theme }): string => theme.border.radius};
  border: ${({ theme }): string => theme.form.border};
  background: ${({ theme }): string => theme.calendar.background};
  z-index: ${({ theme }): number => theme.zIndex.overlay};
  right: ${({ placement }): string => (placement === 'right' ? '0' : 'unset')};
  left: ${({ placement, autoLeft }): string =>
    placement === 'auto' && autoLeft <= 0 ? `${autoLeft}px` : 'unset'};
`;

const DayPickerInputWrapper = styled.div`
  height: 48px;
  position: relative;
  min-width: 120px;
  .DayPickerInput {
    position: absolute;
    width: -webkit-fill-available;
    input {
      position: relative;
      padding: 12px;
      border-radius: ${({ theme }): string => theme.border.radius};
      ${borderDefault};
      font: ${({ theme }): string => theme.text.body};
      color: ${({ theme }): string => theme.colors.text};
      width: 120px;
      float: none;

      &::placeholder {
        color: ${({ theme }): string => theme.text.hint};
      }

      &:focus {
        ${borderFocused};
      }
    }
  }
  &.error .DayPickerInput input {
    ${borderError};
  }
`;

type PropsType = {
  /**
   * Determines the error state considering empty checks being part of the rule,
   * if error is being used.
   */
  allowEmpty?: boolean;
  date?: Date;
  dateFormat?: string;
  disabled?: boolean;
  error?: boolean;
  label?: string;
  onBlur?: (value: string) => void;
  /**
   * Callback when date changes
   * @param {Date} date
   */
  onDateChange?: (date: Date) => void;
  /**
   * An empty resolver callback
   */
  onEmpty?: () => void;
  /**
   * Callback to execute if an invalid date has been entered.
   */
  onError?: (value: string) => void;
  placeholder?: string;
  /**
   * The css position to render the date picker
   * @type {string} left | right
   */
  placement?: PlacementType;
  readOnly?: boolean;
  showPopup?: boolean;
};

type StateType = {
  key: string | null;
};

export type PlacementType = 'left' | 'right' | 'auto';

export default class DatePickerInput extends Component<PropsType & DayPickerProps, StateType> {
  static defaultProps = {
    label: null,
    placeholder: null,
    date: new Date(),
    dateFormat: [
      'DD MMM YYYY',
      'DD MMMM YYYY',
      'D MMM YYYY',
      'D MMMM YYYY',
      'DD/MM/YYYY',
      'DD/MM/YY',
    ],
    readOnly: false,
    disabled: false,
    error: false,
    placement: 'left',
    onDateChange: () => {},
    onBlur: () => {},
    onError: () => {},
    onEmpty: () => {},
    allowEmpty: false,
  };

  constructor(props) {
    super(props);
    this.state = { key: undefined };
  }

  datePicker = createRef<DayPickerInput>();

  dateContainer = createRef<HTMLDivElement>();

  onDateChange = (
    date: Date,
    { disabled }: Record<string, any>,
    dayPickerInput: Record<string, any>,
  ) => {
    if (disabled) return;
    const { allowEmpty, date: curDate, onDateChange, onError, onEmpty } = this.props;

    const { value } = dayPickerInput.getInput();
    /**
     * If props.allowEmpty is true we wanna allow empty strings
     * @type {boolean}
     */
    const isEmpty = allowEmpty ? isEmptyString(value) : false;
    const isValidDay = typeof date !== 'undefined' || isEmpty;
    const isSameDay = DateUtils.isSameDay(date, parseDate(curDate));

    if (!isValidDay) onError(value);
    /**
     * A callback for when a user empties out the input value
     */ else onEmpty();
    /**
     * call props.onDateChange only if it's valid, not empty, and a different date.
     * The only reason we check for not empty here is because if not, it returns an undefined
     * date to the caller.
     */
    if (isValidDay && !isEmpty && !isSameDay) onDateChange(date);
  };

  onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void =>
    onEnterKeyPress(e, this.onDayPickerHide);

  handleDateBlur = () => {
    // Handle outside click as per this suggestion
    // https://github.com/gpbl/react-day-picker/issues/723
    setTimeout(() => {
      const clickedElement = document.activeElement;
      const container = this.dateContainer;
      if (container.current && !container.current.contains(clickedElement)) {
        this.datePicker.current.hideDayPicker();
      }
    }, 1);
  };

  onDayPickerHide = () => {
    // @ts-ignore
    const key = this.datePicker.current ? this.datePicker.current.input.value : '';
    this.setState({ key: randomGenerator() }, () => {
      this.props.onDateChange(this.props.date);
      this.props.onBlur(key);
    });
  };

  getDateContainerClientRect = (): Record<string, any> =>
    this.dateContainer.current && this.dateContainer.current.getClientRects()[0];

  getDateContainerAxis = (axis: 'x' | 'y'): number => {
    const clientRect = this.getDateContainerClientRect();
    return clientRect ? clientRect[axis] : 0;
  };

  getAutoLeft = (): number => getAutoPlacementLeft(this.getDateContainerAxis('x'), 366);

  render(): JSX.Element {
    return (
      <DateContainer ref={this.dateContainer}>
        <Label>{this.props.label}</Label>
        <DayPickerInputWrapper className={this.props.error ? 'error' : ''}>
          <DayPickerInput
            key={this.state.key}
            ref={this.datePicker}
            value={this.props.date}
            placeholder={this.props.placeholder}
            formatDate={formatDate}
            parseDate={parseDate}
            format={this.props.dateFormat}
            showOverlay={this.props.showPopup}
            onDayChange={this.onDateChange}
            onDayPickerHide={this.onDayPickerHide}
            overlayComponent={(overlayProps: Record<string, any>): JSX.Element => (
              <DateOverlay
                {...overlayProps}
                placement={this.props.placement}
                autoLeft={this.getAutoLeft()}
              >
                {overlayProps.children}
              </DateOverlay>
            )}
            keepFocus={false}
            // @ts-ignore
            dayPickerProps={{
              ...this.props,
              navbarElement: <DateNavbar />,
              onBlur: this.handleDateBlur,
            }}
            inputProps={{
              readOnly: this.props.readOnly,
              disabled: this.props.disabled,
              onKeyDown: this.onKeyDown,
            }}
          />
        </DayPickerInputWrapper>
      </DateContainer>
    );
  }
}
