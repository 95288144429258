import React from 'react';
import styled, { css } from 'styled-components';
import { useWindowSize } from '../../hooks';
import { declareVariants } from '../../utils/declareVariants';
import Label from '../Label';

const TOGGLE_TRANSITION_DURATION = '0.45s';

const ToggleCase = styled.label<{
  disabled: boolean;
  large?: boolean;
  variant: 'off' | 'on';
}>`
  display: block;
  position: relative;
  width: ${({ theme, large }) => (large ? '82px' : theme.space.s48)};
  height: ${({ theme, large }) => (large ? '32px' : theme.space.s24)};
  margin: ${({ theme }) => theme.space.s4} 0;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  border-width: 2px;
  border-style: solid;
  border-radius: ${({ theme }) => theme.space.s24};

  transition-property: background-color, border-color;
  transition-duration: ${TOGGLE_TRANSITION_DURATION};
  transition-timing-function: ease-in-out;

  ${({ theme, disabled }) =>
    declareVariants({
      off: css`
        background-color: ${theme.colors.white};
        border-color: ${disabled ? theme.form.inactiveColor : theme.colors.interactive};
      `,
      on: css`
        background-color: ${disabled ? theme.form.inactiveColor : theme.colors.interactive};
        border-color: ${disabled ? theme.form.inactiveColor : theme.colors.interactive};
      `,
    })}
`;

const HiddenInput = styled.input`
  opacity: 0;
  position: absolute;
  pointer-events: none;
  height: 0;
  width: 0;
`;

const CirclePosition = styled.div<{ variant: 'off' | 'on' }>`
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  transition-property: left, transform;
  transition-duration: ${TOGGLE_TRANSITION_DURATION};
  transition-timing-function: ease-in-out;

  ${declareVariants({
    off: css`
      left: 0;
      transform: translateX(0);
    `,
    on: css`
      left: 100%;
      transform: translateX(-100%);
    `,
  })}
`;

const Circle = styled.div<{
  disabled: boolean;
  large: boolean;
  variant: 'off' | 'on';
}>`
  position: relative;

  height: ${({ large }) => (large ? '24px' : '16px')};
  width: ${({ large }) => (large ? '24px' : '16px')};
  margin: 2px;

  border-radius: 50%;
  transition-property: background-color;
  transition-duration: ${TOGGLE_TRANSITION_DURATION};
  transition-timing-function: ease-in-out;

  ${({ theme, disabled }) =>
    declareVariants({
      off: css`
        background-color: ${disabled ? theme.form.inactiveColor : theme.colors.interactive};
      `,
      on: css`
        background-color: ${theme.colors.white};
      `,
    })}
`;

const LabelPostiton = styled.div<{ variant: 'off' | 'on' }>`
  margin: 0 ${({ theme }) => theme.space.s12};
  height: 100%;
  display: flex;
  align-items: center;

  ${declareVariants({
    off: css`
      justify-content: flex-end;
    `,
    on: css`
      justify-content: flex-start;
    `,
  })}
`;

type PropsType = {
  activeLabelText?: string;
  checked?: boolean;
  disabled?: boolean;
  fixedSize?: Sizes;
  inactiveLabelText?: string;
  minimiseOnDesktop?: boolean;
  onToggle?: (checked: boolean) => void;
};

type Sizes = 'large' | 'small';

const Toggle: React.FC<PropsType> = ({
  checked = false,
  disabled = false,
  minimiseOnDesktop = true,
  fixedSize,
  activeLabelText = 'On',
  inactiveLabelText = 'Off',
  onToggle = () => {},
}) => {
  const handleChange = () => {
    if (disabled || !onToggle) {
      return;
    }
    onToggle(!checked);
  };

  const onLabelClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    handleChange();
  };

  const variant = checked ? 'on' : 'off';
  const { width } = useWindowSize();
  const large = isSizeLarge(minimiseOnDesktop, width, fixedSize);
  return (
    <ToggleCase onClick={onLabelClick} variant={variant} disabled={disabled} large={large}>
      <HiddenInput type="checkbox" tabIndex={-1} checked={checked} readOnly />
      <CirclePosition variant={variant}>
        <Circle variant={variant} disabled={disabled} large={large} />
      </CirclePosition>
      {large && (
        <LabelPostiton variant={variant}>
          <Label align={checked ? 'left' : 'right'} color={getTextColor(checked, disabled)}>
            {checked ? activeLabelText : inactiveLabelText}
          </Label>
        </LabelPostiton>
      )}
    </ToggleCase>
  );
};

export function getTextColor(checked: boolean, disabled: boolean): string {
  if (checked) {
    return 'white';
  }
  if (disabled) {
    return 'form.inactiveColor';
  }
  return 'interactive';
}

export function isSizeLarge(
  minimiseOnDesktop: boolean,
  width: number,
  fixedSize?: string,
): boolean {
  if (fixedSize === 'large') {
    return true;
  }
  if (fixedSize === 'small') {
    return false;
  }
  if (minimiseOnDesktop && width >= 1024) {
    return false;
  }
  return true;
}

export default Toggle;
