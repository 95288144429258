/* eslint no-underscore-dangle: [2, { "allowAfterThis": true }] */

// In each action bar, it contains one or more action groups
import { NumberPadActionGroup } from './NumberPadActionGroup';
import { NumberPadActionItem } from './NumberPadActionItem';
import {
  ActionHandlerFuncType,
  FormatterFuncType,
  KeyboardHandlerFuncType,
  SyncFromStateFuncType,
} from './types';

export class NumberPadActionBar {
  actions: NumberPadActionItem[];

  actionHandler: ActionHandlerFuncType | null;

  barName: string;

  formatter: FormatterFuncType | null;

  keyboardHandler: KeyboardHandlerFuncType | null;

  groups: NumberPadActionGroup[];

  syncFromState: SyncFromStateFuncType | null;

  constructor(barName: string, groups: NumberPadActionGroup[]) {
    this.actions = [];
    this.barName = barName;
    this.formatter = null;
    this.groups = groups;
    this.keyboardHandler = null;
    this.actionHandler = null;
    this.syncFromState = null;

    this.groups.forEach((group: NumberPadActionGroup) => {
      this.actions = [...this.actions, ...group.actions];
    });
    this.actions[this.actions.length - 1].isLast = true;
    if (this.actions.length === 2) {
      this.actions.forEach((action: NumberPadActionItem) => {
        action.doubleWidth = true;
      });
    }
  }

  getActionGroup(groupName: string | null): NumberPadActionGroup | null {
    const foundGroup = this.groups.find(
      (group: NumberPadActionGroup): boolean => group.groupName === groupName,
    );
    return foundGroup;
  }

  getActionItem(groupName: string, actionItemName: string): NumberPadActionItem | null {
    let foundActionItem = null;
    const foundGroup = this.getActionGroup(groupName);
    if (foundGroup) {
      foundActionItem = foundGroup.actions.find(
        (actionItem: NumberPadActionItem): boolean => actionItem.name === actionItemName,
      );
    }
    return foundActionItem;
  }

  /**
   * Toggle the selected item;
   * @param selectedActionItem
   * @returns {NumberPadActionItem}
   */
  toggleGroupActionItem(
    selectedActionItem: NumberPadActionItem,
    actionGroup: NumberPadActionGroup | null = null,
  ): NumberPadActionItem | null {
    if (!selectedActionItem) {
      return null;
    }
    let group = actionGroup;
    if (!group) {
      group = this.getActionGroup(selectedActionItem.groupName);
    }
    if (!group) {
      return null;
    }
    const previousSelctedAction: NumberPadActionItem | null = group.actions.find(
      (a: NumberPadActionItem): boolean => a.selected,
    );
    if (previousSelctedAction) {
      previousSelctedAction.selected = false;
      const selectedAction = group.actions.find(
        (action: NumberPadActionItem): boolean =>
          action.groupName === selectedActionItem.groupName &&
          action.name === selectedActionItem.name,
      );
      if (selectedAction) {
        selectedAction.selected = true;
      }
    }
    return previousSelctedAction;
  }

  setActions(actions: NumberPadActionItem[]) {
    this.actions = actions;
  }

  getActions(): NumberPadActionItem[] {
    return this.actions;
  }

  getActionHandler(): ActionHandlerFuncType | null {
    return this.actionHandler;
  }

  setActionHandler(value: ActionHandlerFuncType | null) {
    this.actionHandler = value;
  }

  setBarName(name: string) {
    this.barName = name;
  }

  getBarName(): string {
    return this.barName;
  }

  setFormatter(formatter: FormatterFuncType | null) {
    this.formatter = formatter;
  }

  getFormatter(): FormatterFuncType | null {
    return this.formatter;
  }

  setKeyboardHandler(handler: KeyboardHandlerFuncType | null) {
    this.keyboardHandler = handler;
  }

  getKeyboardHandler(): KeyboardHandlerFuncType | null {
    return this.keyboardHandler;
  }

  setGroups(groups: NumberPadActionGroup[]) {
    this.groups = groups;
  }

  getGroups(): NumberPadActionGroup[] {
    return this.groups;
  }

  setSyncFromState(value: SyncFromStateFuncType | null) {
    this.syncFromState = value;
  }

  getSyncFromState(): SyncFromStateFuncType | null {
    return this.syncFromState;
  }
}
