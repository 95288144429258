import React from 'react';
import styled from 'styled-components';
import { SpacingTypes } from '../../theme';

const ScrollAreaStyle = styled.div`
  overflow-x: ${(props: Record<string, any>): string => (props.horizontal ? 'auto' : 'hidden')};
  overflow-y: ${(props: Record<string, any>): string => (props.horizontal ? 'hidden' : 'auto')};
  background-color: ${(props: Record<string, any>): string =>
    props.showBackground ? props.theme.background.scrollArea : 'transparent'};
  height: ${(props: Record<string, any>): string =>
    props.horizontal ? 'fit-content' : `calc(${props.height} - ${props.mobileOffset})`};

  margin-right: ${(props: Record<string, any>): string =>
    props.overflowSpacing ? `-${props.theme.space[props.overflowSpacing]}` : 'unset'};

  @media screen and (min-width: 768px){
    height: calc(${(props: Record<string, any>): string => props.height} - ${(
  props: Record<string, any>,
): string => props.offset}));
  }
`;

type PropsType = {
  height?: string;
  horizontal?: boolean;
  mobileOffset?: string;
  offset?: string;
  /**
   * The spacing unit that goes stretches beyond the container (negative right margin)
   */
  overflowSpacing?: SpacingTypes;
  showBackground?: boolean;
};

const ScrollArea: React.FunctionComponent<React.PropsWithChildren<PropsType>> = ({
  children,
  ...restProps
}) => {
  return <ScrollAreaStyle {...restProps}>{children}</ScrollAreaStyle>;
};

ScrollArea.defaultProps = {
  showBackground: true,
  height: '100vh',
  offset: '0px',
  mobileOffset: '0px',
  overflowSpacing: 's0',
  horizontal: false,
};

export default ScrollArea;
