import React from 'react';
import styled from 'styled-components';
import IconButton from '../IconButton';
import IconClose from '../Icon/IconClose';
import { StatusTypes } from '../../theme';
import Box from '../Box';
import Paragraph from '../Paragraph';

type PropsType = {
  alertMessage?: React.ReactNode;
  alertTime?: number;
  alertType?: StatusTypes | string;
  autoClose?: boolean;
  onClose?: () => void;
};

type StateType = {
  showAlert: boolean;
};

const AlertContainer = styled.div`
  position: relative;
`;

const CloseArea = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

  display: flex;
  align-items: center;
`;
export default class Alert extends React.Component<PropsType, StateType> {
  timer: number;

  static defaultProps = {
    alertTime: 5000,
    autoClose: false,
  };

  constructor(props: PropsType) {
    super(props);
    this.state = {
      showAlert: true,
    };
  }

  componentDidMount() {
    if (this.props.autoClose) {
      this.timer = window.setTimeout(this.handleCloseAlert, this.props.alertTime);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handleCloseAlert = () => {
    if (this.state.showAlert) {
      this.setState({
        showAlert: false,
      });
      if (this.props.onClose) {
        this.props.onClose();
      }
    }
  };

  render(): JSX.Element {
    const { alertType, alertMessage } = this.props;
    const status = alertType === 'Error' ? 'alert' : alertType.toLowerCase(); // backwards compatibility with old api

    return (
      <Box callout status={status as StatusTypes} showBorder={false} padding="s16">
        <AlertContainer>
          <Paragraph color="inherit" align="center">
            {alertMessage}
          </Paragraph>
          <CloseArea>
            <IconButton onClick={this.handleCloseAlert}>
              <IconClose width={16} height={16} color="text" />
            </IconButton>
          </CloseArea>
        </AlertContainer>
      </Box>
    );
  }
}
