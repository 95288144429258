export type ModeType = 'view' | 'select' | 'delete';

export const modes = { view: 'view', select: 'select', delete: 'delete' };

export type TileType = {
  color?: string;
  contain?: boolean;
  id?: number;
  label?: string;
  selected?: boolean;
  url?: string;
};
