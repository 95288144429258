import { createContext } from 'react';

import { NewFeatureItemType } from './types';

export type HelpContextType = {
  allProductFeatures: NewFeatureItemType[] | [];
  featureId: string;
  history: string[];
  newFeaturesAvaliable: NewFeatureItemType[] | [];
  onClose: () => void;
  onConfirmModal: (id: string) => void;
  setFeatureId: (id: string) => void;
  setHistory: (id: string[]) => void;
  setShowModal: (value: boolean) => void;
  setShowOldFeatures: (value: boolean) => void;
  setShowSidePanel: (value: boolean) => void;
  showModal: boolean;
  showOldFeatures: boolean;
  showSidePanel: boolean;
};

export const HelpContext = createContext<HelpContextType>(null);
