import React, { Component } from 'react';
import TextInput, { PropsType as TextInputProps } from '../TextInput/TextInput';
import { IconSearch } from '../Icon';
import { ClearButton } from './ClearButton';
import { Alignment } from '../index';
import AdvancedSelect, { AdvancedSelectOptionType } from '../AdvancedSelect';

export type PropsType = Omit<TextInputProps, 'prefix' | 'suffix'> & {
  /**
   * Drop down props:
   */
  defaultDropDownOption: AdvancedSelectOptionType;
  dropDownOptions: AdvancedSelectOptionType[];

  onChange?: (value: string) => void;
  onClear?: () => void;
  onDropDownChange: (selectedOption: AdvancedSelectOptionType) => void;
  placeholderPrefix?: string;
  value?: string;
};

interface StateType {
  placeholder: string;
}

export default class DropdownSearch extends Component<PropsType, StateType> {
  static defaultProps = {
    connectedElementWidth: '200px',
    inputRef: null,
    value: '',
    helperText: '',
    label: '',
    onChange: () => {},
    onKeyPress: () => {},
    onKeyDown: () => {},
    onClear: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      placeholder: `${this.props.placeholderPrefix} ${this.props.defaultDropDownOption.value}`,
    };
  }

  inputRef = React.createRef<HTMLInputElement>();

  handleClearEvent = () => {
    this.props.onClear();
    if (this.props.autoFocus && this.props.inputRef.current) {
      this.inputRef.current.focus();
    }
  };

  handleDropDownChange = (option: AdvancedSelectOptionType): void => {
    this.setState({
      placeholder: `${this.props.placeholderPrefix} ${option.value}`,
    });
    this.props.onDropDownChange(option);
  };

  render() {
    return (
      <TextInput
        {...this.props}
        prefix={
          <Alignment vertical="center" horizontal="center">
            <IconSearch />
          </Alignment>
        }
        suffix={
          <ClearButton handleClearEvent={this.handleClearEvent} inputValue={this.props.value} />
        }
        value={this.props.value}
        onChange={this.props.onChange}
        placeholder={this.props.placeholder ? this.props.placeholder : this.state.placeholder}
        inputHtmlType="text"
        onKeyDown={this.props.onKeyDown}
        connectedElementWidth={this.props.connectedElementWidth}
        connectedLeft={
          <AdvancedSelect
            onChange={this.handleDropDownChange}
            options={this.props.dropDownOptions}
            isConnectedLeft
            isTickLeft={false}
            defaultValue={this.props.defaultDropDownOption}
          />
        }
      />
    );
  }
}
