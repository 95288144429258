/* eslint-disable react/require-default-props,react/no-unused-prop-types */

import React from 'react';
import ReactAutoSizer from 'react-virtualized-auto-sizer';
import styled from 'styled-components';

export type SizeType = {
  height?: number;
  width?: number;
};

type PropsType = {
  children?: any;
  /** Optional custom CSS class name to attach to root AutoSizer element.  */
  className?: string;
  /** Default height to use for initial render; useful for SSR */
  defaultHeight?: number;
  /** Default width to use for initial render; useful for SSR */
  defaultWidth?: number;
};

type SizeApplierType = {
  component?: JSX.Element;
  size?: SizeType;
};

/**
 * Detects the height and width of it's parent container
 * and passes that value to the child component
 * Note: When running as a test AutoSizer returns the children without the sizing rules
 */
export default function AutoSizer(props: PropsType): JSX.Element {
  const sizes = { height: props.defaultHeight, width: props.defaultWidth };
  /**
   * AutoSizer depends on the DOM to work. When there is no DOM the component may not
   * render the children. There is no DOM when jest is running, you can mock it using jsDOM
   * but for some reason in the Produce codebase it was not working.
   * Even with setting default width & height which should resolve this issue did not work
   * in Produce. Running in Chameleon tests, we could see the component working as expected.
   *
   * https://github.com/bvaughn/react-virtualized/issues/493
   */
  return (
    <Wrapper>
      {
        // eslint-disable-next-line no-nested-ternary
        process.env.NODE_ENV === 'test'
          ? typeof props.children === 'function'
            ? props.children(sizes)
            : props.children
          : useAutoSizer(props)
      }
    </Wrapper>
  );
}

AutoSizer.defaultProps = {
  defaultHeight: 1024,
  defaultWidth: 768,
};

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const useAutoSizer = (props: PropsType): JSX.Element => (
  <ReactAutoSizer
    className={props.className}
    defaultHeight={props.defaultHeight}
    defaultWidth={props.defaultWidth}
  >
    {(size: SizeType): JSX.Element | undefined => {
      if (typeof props.children === 'function') {
        return props.children(size);
      }
      return <ApplySizeToComponent component={props.children} size={size} />;
    }}
  </ReactAutoSizer>
);

const ApplySizeToComponent = ({ component, size }: SizeApplierType): JSX.Element =>
  React.cloneElement(component, {
    style: {
      height: size.height,
      width: size.width,
    },
  });
