import { extend } from 'underscore';

const instances: { [key: string]: Currency } = {};

export class Currency {
  static SIGN_LOCAL = 'sign';

  static SIGN_PORTABLE = 'portable';

  static SIGN_GLOBAL = 'global';

  code: string;

  decimal: string;

  thousands: string;

  global: string;

  // These properties are loaded on from the `defs` below through the get()
  // method.
  name: string;

  sign: string;

  portable: string;

  space: boolean;

  prepend: boolean;

  precision: number;

  static get(code: string): Currency {
    if (!instances[code]) {
      instances[code] = new Currency(code);
    }
    return instances[code];
  }

  constructor(code1: string) {
    let def;
    this.code = code1;
    extend(this, (def = defs[this.code]), {
      decimal: '.',
      thousands: ',',
      global: this.code === def.sign ? this.code : `${this.code} ${def.sign}`,
    });
  }
}

type CountryDefinitionType = {
  name: string;
  portable: string;
  precision: number;
  prepend: boolean;
  sign: string;
  space: boolean;
};

const defs: { [key: string]: CountryDefinitionType } = {
  AED: {
    name: 'UAE Dirham',
    sign: 'dh',
    portable: 'د.إ.',
    space: false,
    prepend: true,
    precision: 2,
  },
  AUD: {
    name: 'Australian Dollar',
    sign: '$',
    portable: 'AU$',
    space: false,
    prepend: true,
    precision: 2,
  },
  BDT: {
    name: 'Taka',
    sign: '৳',
    portable: 'Tk',
    space: false,
    prepend: true,
    precision: 2,
  },
  BRL: {
    name: 'Brazilian Real',
    sign: 'R$',
    portable: 'R$',
    space: false,
    prepend: true,
    precision: 2,
  },
  CAD: {
    name: 'Canadian Dollar',
    sign: '$',
    portable: 'C$',
    space: false,
    prepend: true,
    precision: 2,
  },
  CHF: {
    name: 'Swiss Franc',
    sign: 'CHF',
    portable: 'CHF',
    space: false,
    prepend: true,
    precision: 2,
  },
  CLP: {
    name: 'Chilean Peso',
    sign: '$',
    portable: 'CL$',
    space: false,
    prepend: true,
    precision: 0,
  },
  CNY: {
    name: 'Yuan Renminbi',
    sign: '¥',
    portable: 'RMB¥',
    space: false,
    prepend: true,
    precision: 2,
  },
  COP: {
    name: 'Colombian Peso',
    sign: '$',
    portable: 'COL$',
    space: false,
    prepend: true,
    precision: 0,
  },
  CRC: {
    name: 'Costa Rican Colon',
    sign: '₡',
    portable: 'CR₡',
    space: false,
    prepend: true,
    precision: 0,
  },
  CZK: {
    name: 'Czech Koruna',
    sign: 'Kč',
    portable: 'Kč',
    space: false,
    prepend: true,
    precision: 2,
  },
  DKK: {
    name: 'Danish Krone',
    sign: 'kr',
    portable: 'kr',
    space: false,
    prepend: true,
    precision: 2,
  },
  DOP: {
    name: 'Dominican Peso',
    sign: '$',
    portable: 'RD$',
    space: false,
    prepend: true,
    precision: 2,
  },
  EGP: {
    name: 'Egyptian Pound',
    sign: '£',
    portable: 'LE',
    space: false,
    prepend: true,
    precision: 2,
  },
  EUR: {
    name: 'Euro',
    sign: '€',
    portable: '€',
    space: false,
    prepend: true,
    precision: 2,
  },
  GBP: {
    name: 'Pound Sterling',
    sign: '£',
    portable: 'GB£',
    space: false,
    prepend: true,
    precision: 2,
  },
  HKD: {
    name: 'Hong Kong Dollar',
    sign: '$',
    portable: 'HK$',
    space: false,
    prepend: true,
    precision: 2,
  },
  ILS: {
    name: 'New Israeli Sheqel',
    sign: '₪',
    portable: 'IL₪',
    space: false,
    prepend: true,
    precision: 2,
  },
  INR: {
    name: 'Indian Rupee',
    sign: '₹',
    portable: 'Rs',
    space: false,
    prepend: true,
    precision: 2,
  },
  ISK: {
    name: 'Iceland Krona',
    sign: 'kr',
    portable: 'kr',
    space: false,
    prepend: true,
    precision: 0,
  },
  JMD: {
    name: 'Jamaican Dollar',
    sign: '$',
    portable: 'JA$',
    space: false,
    prepend: true,
    precision: 2,
  },
  JPY: {
    name: 'Yen',
    sign: '¥',
    portable: 'JP¥',
    space: false,
    prepend: true,
    precision: 0,
  },
  KRW: {
    name: 'Won',
    sign: '₩',
    portable: 'KR₩',
    space: false,
    prepend: true,
    precision: 0,
  },
  LKR: {
    name: 'Sri Lanka Rupee',
    sign: 'Rs',
    portable: 'SLRs',
    space: false,
    prepend: true,
    precision: 2,
  },
  MNT: {
    name: 'Tugrik',
    sign: '₮',
    portable: 'MN₮',
    space: false,
    prepend: true,
    precision: 0,
  },
  MXN: {
    name: 'Mexican Peso',
    sign: '$',
    portable: 'Mex$',
    space: false,
    prepend: true,
    precision: 2,
  },
  MYR: {
    name: 'Malaysian Ringgit',
    sign: 'RM',
    portable: 'RM',
    space: false,
    prepend: true,
    precision: 2,
  },
  NOK: {
    name: 'Norwegian Krone',
    sign: 'kr',
    portable: 'NOkr',
    space: false,
    prepend: true,
    precision: 2,
  },
  PAB: {
    name: 'Balboa',
    sign: 'B/.',
    portable: 'B/.',
    space: false,
    prepend: true,
    precision: 2,
  },
  PEN: {
    name: 'Nuevo Sol',
    sign: 'S/.',
    portable: 'S/.',
    space: false,
    prepend: true,
    precision: 2,
  },
  PHP: {
    name: 'Philippine Peso',
    sign: '₱',
    portable: 'Php',
    space: false,
    prepend: true,
    precision: 2,
  },
  PKR: {
    name: 'Pakistan Rupee',
    sign: 'Rs',
    portable: 'PKRs.',
    space: false,
    prepend: true,
    precision: 0,
  },
  RUB: {
    name: 'Russian Ruble',
    sign: 'руб.',
    portable: 'руб.',
    space: true,
    prepend: false,
    precision: 2,
  },
  SAR: {
    name: 'Saudi Riyal',
    sign: 'Rial',
    portable: 'Rial',
    space: false,
    prepend: true,
    precision: 2,
  },
  SEK: {
    name: 'Swedish Krona',
    sign: 'kr',
    portable: 'kr',
    space: false,
    prepend: true,
    precision: 2,
  },
  SGD: {
    name: 'Singapore Dollar',
    sign: '$',
    portable: 'S$',
    space: false,
    prepend: true,
    precision: 2,
  },
  THB: {
    name: 'Baht',
    sign: '฿',
    portable: 'THB',
    space: false,
    prepend: true,
    precision: 2,
  },
  TRY: {
    name: 'Turkish Lira',
    sign: 'TL',
    portable: 'YTL',
    space: false,
    prepend: true,
    precision: 2,
  },
  TWD: {
    name: 'New Taiwan Dollar',
    sign: 'NT$',
    portable: 'NT$',
    space: false,
    prepend: true,
    precision: 2,
  },
  USD: {
    name: 'US Dollar',
    sign: '$',
    portable: 'US$',
    space: false,
    prepend: true,
    precision: 2,
  },
  UYU: {
    name: 'Peso Uruguayo',
    sign: '$',
    portable: '$U',
    space: false,
    prepend: true,
    precision: 1,
  },
  VND: {
    name: 'Dong',
    sign: '₫',
    portable: 'VN₫',
    space: false,
    prepend: true,
    precision: 0,
  },
  YER: {
    name: 'Yemeni Rial',
    sign: 'Rial',
    portable: 'Rial',
    space: false,
    prepend: true,
    precision: 0,
  },
  ZAR: {
    name: 'Rand',
    sign: 'R',
    portable: 'ZAR',
    space: false,
    prepend: true,
    precision: 2,
  },
  AFN: {
    name: 'Afghani',
    sign: 'Af.',
    portable: 'AFN',
    space: false,
    prepend: true,
    precision: 0,
  },
  ALL: {
    name: 'Lek',
    sign: 'Lek',
    portable: 'Lek',
    space: false,
    prepend: true,
    precision: 0,
  },
  AMD: {
    name: 'Armenian Dram',
    sign: 'Dram',
    portable: 'dram',
    space: false,
    prepend: true,
    precision: 0,
  },
  AOA: {
    name: 'Kwanza',
    sign: 'Kz',
    portable: 'Kz',
    space: false,
    prepend: true,
    precision: 2,
  },
  ARS: {
    name: 'Argentine Peso',
    sign: '$',
    portable: 'AR$',
    space: false,
    prepend: true,
    precision: 2,
  },
  AWG: {
    name: 'Aruban Florin',
    sign: 'Afl.',
    portable: 'Afl.',
    space: false,
    prepend: true,
    precision: 2,
  },
  AZN: {
    name: 'Azerbaijanian Manat',
    sign: 'man.',
    portable: 'man.',
    space: false,
    prepend: true,
    precision: 2,
  },
  BAM: {
    name: 'Convertible Mark',
    sign: 'KM',
    portable: 'KM',
    space: false,
    prepend: true,
    precision: 2,
  },
  BBD: {
    name: 'Barbados Dollar',
    sign: '$',
    portable: 'Bds$',
    space: false,
    prepend: true,
    precision: 2,
  },
  BGN: {
    name: 'Bulgarian Lev',
    sign: 'lev',
    portable: 'lev',
    space: false,
    prepend: true,
    precision: 2,
  },
  BHD: {
    name: 'Bahraini Dinar',
    sign: 'din',
    portable: 'din',
    space: false,
    prepend: true,
    precision: 3,
  },
  BIF: {
    name: 'Burundi Franc',
    sign: 'FBu',
    portable: 'FBu',
    space: false,
    prepend: true,
    precision: 0,
  },
  BMD: {
    name: 'Bermudian Dollar',
    sign: '$',
    portable: 'BD$',
    space: false,
    prepend: true,
    precision: 2,
  },
  BND: {
    name: 'Brunei Dollar',
    sign: '$',
    portable: 'B$',
    space: false,
    prepend: true,
    precision: 2,
  },
  BOB: {
    name: 'Boliviano',
    sign: 'Bs',
    portable: 'Bs',
    space: false,
    prepend: true,
    precision: 2,
  },
  BSD: {
    name: 'Bahamian Dollar',
    sign: '$',
    portable: 'BS$',
    space: false,
    prepend: true,
    precision: 2,
  },
  BTN: {
    name: 'Ngultrum',
    sign: 'Nu.',
    portable: 'Nu.',
    space: false,
    prepend: true,
    precision: 2,
  },
  BWP: {
    name: 'Pula',
    sign: 'P',
    portable: 'pula',
    space: false,
    prepend: true,
    precision: 2,
  },
  BYR: {
    name: 'Belarussian Ruble',
    sign: 'BYR',
    portable: 'BYR',
    space: false,
    prepend: true,
    precision: 0,
  },
  BZD: {
    name: 'Belize Dollar',
    sign: '$',
    portable: 'BZ$',
    space: false,
    prepend: true,
    precision: 2,
  },
  CDF: {
    name: 'Congolese Franc',
    sign: 'FrCD',
    portable: 'CDF',
    space: false,
    prepend: true,
    precision: 2,
  },
  CUC: {
    name: 'Peso Convertible',
    sign: '$',
    portable: 'CUC$',
    space: false,
    prepend: true,
    precision: 1,
  },
  CUP: {
    name: 'Cuban Peso',
    sign: '$',
    portable: 'CU$',
    space: false,
    prepend: true,
    precision: 2,
  },
  CVE: {
    name: 'Cape Verde Escudo',
    sign: 'CVE',
    portable: 'Esc',
    space: false,
    prepend: true,
    precision: 2,
  },
  DJF: {
    name: 'Djibouti Franc',
    sign: 'Fdj',
    portable: 'Fdj',
    space: false,
    prepend: true,
    precision: 0,
  },
  DZD: {
    name: 'Algerian Dinar',
    sign: 'din',
    portable: 'din',
    space: false,
    prepend: true,
    precision: 2,
  },
  ERN: {
    name: 'Nakfa',
    sign: 'Nfk',
    portable: 'Nfk',
    space: false,
    prepend: true,
    precision: 2,
  },
  ETB: {
    name: 'Ethiopian Birr',
    sign: 'Birr',
    portable: 'Birr',
    space: false,
    prepend: true,
    precision: 2,
  },
  FJD: {
    name: 'Fiji Dollar',
    sign: '$',
    portable: 'FJ$',
    space: false,
    prepend: true,
    precision: 2,
  },
  FKP: {
    name: 'Falkland Islands Pound',
    sign: '£',
    portable: 'FK£',
    space: false,
    prepend: true,
    precision: 2,
  },
  GEL: {
    name: 'Lari',
    sign: 'GEL',
    portable: 'GEL',
    space: false,
    prepend: true,
    precision: 2,
  },
  GHS: {
    name: 'Ghana Cedi',
    sign: 'GHS',
    portable: 'GHS',
    space: false,
    prepend: true,
    precision: 2,
  },
  GIP: {
    name: 'Gibraltar Pound',
    sign: '£',
    portable: 'GI£',
    space: false,
    prepend: true,
    precision: 2,
  },
  GMD: {
    name: 'Dalasi',
    sign: 'GMD',
    portable: 'GMD',
    space: false,
    prepend: true,
    precision: 2,
  },
  GNF: {
    name: 'Guinea Franc',
    sign: 'FG',
    portable: 'FG',
    space: false,
    prepend: true,
    precision: 0,
  },
  GTQ: {
    name: 'Quetzal',
    sign: 'Q',
    portable: 'GTQ',
    space: false,
    prepend: true,
    precision: 2,
  },
  GYD: {
    name: 'Guyana Dollar',
    sign: '$',
    portable: 'GY$',
    space: false,
    prepend: true,
    precision: 0,
  },
  HNL: {
    name: 'Lempira',
    sign: 'L',
    portable: 'HNL',
    space: false,
    prepend: true,
    precision: 2,
  },
  HRK: {
    name: 'Croatian Kuna',
    sign: 'kn',
    portable: 'kn',
    space: false,
    prepend: true,
    precision: 2,
  },
  HTG: {
    name: 'Gourde',
    sign: 'HTG',
    portable: 'HTG',
    space: false,
    prepend: true,
    precision: 2,
  },
  HUF: {
    name: 'Forint',
    sign: 'Ft',
    portable: 'Ft',
    space: false,
    prepend: true,
    precision: 0,
  },
  IDR: {
    name: 'Rupiah',
    sign: 'Rp',
    portable: 'Rp',
    space: false,
    prepend: true,
    precision: 0,
  },
  IQD: {
    name: 'Iraqi Dinar',
    sign: 'din',
    portable: 'IQD',
    space: false,
    prepend: true,
    precision: 0,
  },
  IRR: {
    name: 'Iranian Rial',
    sign: 'Rial',
    portable: 'IRR',
    space: false,
    prepend: true,
    precision: 0,
  },
  JOD: {
    name: 'Jordanian Dinar',
    sign: 'din',
    portable: 'JOD',
    space: false,
    prepend: true,
    precision: 3,
  },
  KES: {
    name: 'Kenyan Shilling',
    sign: 'Ksh',
    portable: 'Ksh',
    space: false,
    prepend: true,
    precision: 2,
  },
  KGS: {
    name: 'Som',
    sign: 'KGS',
    portable: 'KGS',
    space: false,
    prepend: true,
    precision: 2,
  },
  KHR: {
    name: 'Riel',
    sign: 'Riel',
    portable: 'KHR',
    space: false,
    prepend: true,
    precision: 2,
  },
  KMF: {
    name: 'Comoro Franc',
    sign: 'CF',
    portable: 'KMF',
    space: false,
    prepend: true,
    precision: 0,
  },
  KPW: {
    name: 'North Korean Won',
    sign: '₩KP',
    portable: 'KPW',
    space: false,
    prepend: true,
    precision: 0,
  },
  KWD: {
    name: 'Kuwaiti Dinar',
    sign: 'KWD',
    portable: 'KWD',
    space: false,
    prepend: true,
    precision: 3,
  },
  KYD: {
    name: 'Cayman Islands Dollar',
    sign: '$',
    portable: 'KY$',
    space: false,
    prepend: true,
    precision: 2,
  },
  KZT: {
    name: 'Tenge',
    sign: '₸',
    portable: 'KZT',
    space: false,
    prepend: true,
    precision: 2,
  },
  LAK: {
    name: 'Kip',
    sign: '₭',
    portable: '₭',
    space: false,
    prepend: true,
    precision: 0,
  },
  LBP: {
    name: 'Lebanese Pound',
    sign: 'L£',
    portable: 'LBP',
    space: false,
    prepend: true,
    precision: 0,
  },
  LRD: {
    name: 'Liberian Dollar',
    sign: '$',
    portable: 'L$',
    space: false,
    prepend: true,
    precision: 2,
  },
  LSL: {
    name: 'Loti',
    sign: 'LSL',
    portable: 'LSL',
    space: false,
    prepend: true,
    precision: 2,
  },
  LTL: {
    name: 'Lithuanian Litas',
    sign: 'Lt',
    portable: 'Lt',
    space: false,
    prepend: true,
    precision: 2,
  },
  LVL: {
    name: 'Latvian Lats',
    sign: 'Ls',
    portable: 'Ls',
    space: false,
    prepend: true,
    precision: 2,
  },
  LYD: {
    name: 'Libyan Dinar',
    sign: 'din',
    portable: 'LD',
    space: false,
    prepend: true,
    precision: 3,
  },
  MAD: {
    name: 'Moroccan Dirham',
    sign: 'dh',
    portable: 'MAD',
    space: false,
    prepend: true,
    precision: 2,
  },
  MDL: {
    name: 'Moldovan Leu',
    sign: 'MDL',
    portable: 'MDL',
    space: false,
    prepend: true,
    precision: 2,
  },
  MGA: {
    name: 'Malagasy Ariary',
    sign: 'Ar',
    portable: 'MGA',
    space: false,
    prepend: true,
    precision: 0,
  },
  MKD: {
    name: 'Denar',
    sign: 'din',
    portable: 'MKD',
    space: false,
    prepend: true,
    precision: 2,
  },
  MMK: {
    name: 'Kyat',
    sign: 'K',
    portable: 'MMK',
    space: false,
    prepend: true,
    precision: 0,
  },
  MOP: {
    name: 'Pataca',
    sign: 'MOP',
    portable: 'MOP$',
    space: false,
    prepend: true,
    precision: 2,
  },
  MRO: {
    name: 'Ouguiya',
    sign: 'MRO',
    portable: 'MRO',
    space: false,
    prepend: true,
    precision: 0,
  },
  MUR: {
    name: 'Mauritius Rupee',
    sign: 'MURs',
    portable: 'MURs',
    space: false,
    prepend: true,
    precision: 0,
  },
  MWK: {
    name: 'Kwacha',
    sign: 'MWK',
    portable: 'MWK',
    space: false,
    prepend: true,
    precision: 2,
  },
  MZN: {
    name: 'Mozambique Metical',
    sign: 'MTn',
    portable: 'MTn',
    space: false,
    prepend: true,
    precision: 2,
  },
  NAD: {
    name: 'Namibia Dollar',
    sign: '$',
    portable: 'N$',
    space: false,
    prepend: true,
    precision: 2,
  },
  NGN: {
    name: 'Naira',
    sign: '₦',
    portable: 'NG₦',
    space: false,
    prepend: true,
    precision: 2,
  },
  NIO: {
    name: 'Cordoba Oro',
    sign: 'C$',
    portable: 'C$',
    space: false,
    prepend: true,
    precision: 2,
  },
  NPR: {
    name: 'Nepalese Rupee',
    sign: 'Rs',
    portable: 'NPRs',
    space: false,
    prepend: true,
    precision: 2,
  },
  NZD: {
    name: 'New Zealand Dollar',
    sign: '$',
    portable: 'NZ$',
    space: false,
    prepend: true,
    precision: 2,
  },
  OMR: {
    name: 'Rial Omani',
    sign: 'Rial',
    portable: 'OMR',
    space: false,
    prepend: true,
    precision: 3,
  },
  PGK: {
    name: 'Kina',
    sign: 'PGK',
    portable: 'PGK',
    space: false,
    prepend: true,
    precision: 2,
  },
  PLN: {
    name: 'Zloty',
    sign: 'zł',
    portable: 'zł',
    space: false,
    prepend: true,
    precision: 2,
  },
  PYG: {
    name: 'Guarani',
    sign: 'Gs',
    portable: 'PYG',
    space: false,
    prepend: true,
    precision: 0,
  },
  QAR: {
    name: 'Qatari Rial',
    sign: 'Rial',
    portable: 'QR',
    space: false,
    prepend: true,
    precision: 2,
  },
  RON: {
    name: 'New Romanian Leu',
    sign: 'RON',
    portable: 'RON',
    space: false,
    prepend: true,
    precision: 2,
  },
  RSD: {
    name: 'Serbian Dinar',
    sign: 'din',
    portable: 'RSD',
    space: false,
    prepend: true,
    precision: 0,
  },
  RWF: {
    name: 'Rwanda Franc',
    sign: 'RF',
    portable: 'RF',
    space: false,
    prepend: true,
    precision: 0,
  },
  SBD: {
    name: 'Solomon Islands Dollar',
    sign: '$',
    portable: 'SI$',
    space: false,
    prepend: true,
    precision: 2,
  },
  SCR: {
    name: 'Seychelles Rupee',
    sign: 'SCR',
    portable: 'SCR',
    space: false,
    prepend: true,
    precision: 2,
  },
  SDG: {
    name: 'Sudanese Pound',
    sign: 'SDG',
    portable: 'SDG',
    space: false,
    prepend: true,
    precision: 2,
  },
  SHP: {
    name: 'Saint Helena Pound',
    sign: '£',
    portable: 'SH£',
    space: false,
    prepend: true,
    precision: 2,
  },
  SLL: {
    name: 'Leone',
    sign: 'SLL',
    portable: 'SLL',
    space: false,
    prepend: true,
    precision: 0,
  },
  SOS: {
    name: 'Somali Shilling',
    sign: 'SOS',
    portable: 'SOS',
    space: false,
    prepend: true,
    precision: 0,
  },
  SRD: {
    name: 'Surinam Dollar',
    sign: '$',
    portable: 'SR$',
    space: false,
    prepend: true,
    precision: 2,
  },
  STD: {
    name: 'Dobra',
    sign: 'Db',
    portable: 'Db',
    space: false,
    prepend: true,
    precision: 0,
  },
  SYP: {
    name: 'Syrian Pound',
    sign: '£',
    portable: 'SY£',
    space: false,
    prepend: true,
    precision: 0,
  },
  SZL: {
    name: 'Lilangeni',
    sign: 'SZL',
    portable: 'SZL',
    space: false,
    prepend: true,
    precision: 2,
  },
  TJS: {
    name: 'Somoni',
    sign: 'Som',
    portable: 'TJS',
    space: false,
    prepend: true,
    precision: 2,
  },
  TND: {
    name: 'Tunisian Dinar',
    sign: 'din',
    portable: 'DT',
    space: false,
    prepend: true,
    precision: 3,
  },
  TOP: {
    name: 'Pa’anga',
    sign: 'T$',
    portable: 'T$',
    space: false,
    prepend: true,
    precision: 2,
  },
  TTD: {
    name: 'Trinidad and Tobago Dollar',
    sign: '$',
    portable: 'TT$',
    space: false,
    prepend: true,
    precision: 2,
  },
  TZS: {
    name: 'Tanzanian Shilling',
    sign: 'TSh',
    portable: 'TSh',
    space: false,
    prepend: true,
    precision: 0,
  },
  UAH: {
    name: 'Hryvnia',
    sign: '₴',
    portable: 'UAH',
    space: false,
    prepend: true,
    precision: 2,
  },
  UGX: {
    name: 'Uganda Shilling',
    sign: 'UGX',
    portable: 'UGX',
    space: false,
    prepend: true,
    precision: 0,
  },
  UZS: {
    name: 'Uzbekistan Sum',
    sign: 'soʼm',
    portable: 'UZS',
    space: false,
    prepend: true,
    precision: 0,
  },
  VEF: {
    name: 'Bolivar',
    sign: 'Bs',
    portable: 'Bs',
    space: false,
    prepend: true,
    precision: 2,
  },
  VUV: {
    name: 'Vatu',
    sign: 'VUV',
    portable: 'VUV',
    space: false,
    prepend: true,
    precision: 0,
  },
  WST: {
    name: 'Tala',
    sign: 'WST',
    portable: 'WST',
    space: false,
    prepend: true,
    precision: 2,
  },
  XAF: {
    name: 'CFA Franc BEAC',
    sign: 'FCFA',
    portable: 'FCFA',
    space: false,
    prepend: true,
    precision: 0,
  },
  XCD: {
    name: 'East Caribbean Dollar',
    sign: '$',
    portable: 'EC$',
    space: false,
    prepend: true,
    precision: 2,
  },
  XOF: {
    name: 'CFA Franc BCEAO',
    sign: 'CFA',
    portable: 'CFA',
    space: false,
    prepend: true,
    precision: 0,
  },
  XPF: {
    name: 'CFP Franc',
    sign: 'FCFP',
    portable: 'FCFP',
    space: false,
    prepend: true,
    precision: 0,
  },
  ZMK: {
    name: '',
    sign: 'ZMK',
    portable: 'ZMK',
    space: false,
    prepend: true,
    precision: 0,
  },
};
