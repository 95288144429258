const supportsProxy = () => 'Proxy' in window;

const isNotProd = (): boolean => {
  try {
    return ['development', 'test'].includes(process?.env?.NODE_ENV);
  } catch (e) {
    return false;
  }
};

const throwNonProdException = (e: Error): void => {
  if (isNotProd()) {
    throw e;
  }
  console.error(e.message);
};

function deepStrictObject<T extends Record<PropertyKey, any>>(input: T): T {
  const o = {} as T;
  Object.entries(input).forEach(([key, value]) => {
    o[key as keyof T] =
      value !== null && typeof value === 'object' ? deepStrictObject(value) : value;
  });

  return new Proxy(o, { get: strictGetter, set: immutableSetter });
}

function strictGetter<T extends Record<PropertyKey, any>>(
  target: T,
  prop: PropertyKey,
  receiver: any,
) {
  const val: string = Reflect.get(target, prop, receiver);

  // Let symbols pass through untouched (required for Webpack)
  if (typeof prop === 'symbol') {
    return val;
  }
  if (typeof val === 'object' && val !== null) {
    return strictObject(val);
  }
  if (prop in target || isWhitelistedProp(prop)) {
    return val;
  }

  const msg = `CHAM: Trying to access undefined property '${String(prop)}'`;
  throwNonProdException(new ReferenceError(msg));

  return undefined;
}

function immutableSetter<T extends Record<PropertyKey, any>>(target: T, prop: PropertyKey) {
  const msg = `CHAM: Trying to mutate immutable object with '${String(prop)}'`;
  throwNonProdException(new Error(msg));

  return null;
}

export function strictObject<T extends Record<PropertyKey, any>>(obj: T): T {
  if (!supportsProxy()) {
    return obj;
  }
  return deepStrictObject(obj);
}

/**
 * Whitelist properties used by certain tools.
 */
function isWhitelistedProp(prop: PropertyKey): boolean {
  const whitelist: PropertyKey[] = [
    '$$typeof', // React DevTools
    'toJSON', // Storybook
  ];
  return whitelist.includes(prop);
}
