import { Component } from 'react';
import ReactDOM from 'react-dom';

type PropsType = {
  nodeId: string;
};

export default class Portal extends Component<PropsType, any> {
  static defaultProps = {
    nodeId: '',
    children: null,
  };

  render(): JSX.Element {
    const node = this.props.nodeId ? document.getElementById(this.props.nodeId) : document.body;

    return ReactDOM.createPortal(this.props.children, node);
  }
}
