/* eslint no-underscore-dangle: [2, { "allowAfterThis": true }] */

export const ACTION_BUTTON_CMD = 0;
export const ACTION_BUTTON_TEXT = 1;

export class NumberPadActionItem {
  actionType: number;

  doubleWidth: boolean;

  isLast: boolean;

  name: string;

  groupName: string;

  selected: boolean;

  value: string;

  constructor(name: string, value: string, actionType: number = ACTION_BUTTON_CMD) {
    this.name = name;
    this.value = value;
    this.selected = false;
    this.isLast = false;
    this.actionType = actionType;
    this.doubleWidth = false;
  }

  setActionType(type: number) {
    this.actionType = type;
  }

  getActionType(): number {
    return this.actionType;
  }

  setDoubleWidth(isDoubleWidth: boolean) {
    this.doubleWidth = isDoubleWidth;
  }

  getDoubleWidth(): boolean {
    return this.doubleWidth;
  }

  setIsLast(isLast: boolean) {
    this.isLast = isLast;
  }

  getIsLast(): boolean {
    return this.isLast;
  }

  setName(name: string) {
    this.name = name;
  }

  getName(): string {
    return this.name;
  }

  setGroupName(name: string) {
    this.groupName = name;
  }

  getGroupName(): string | null {
    return this.groupName;
  }

  setSelected(seleced: boolean) {
    this.selected = seleced;
  }

  getSelected(): boolean {
    return this.selected;
  }

  setValue(v: string) {
    this.value = v;
  }

  getValue(): string {
    return this.value;
  }
}
