import { NumberPadActionGroup } from './NumberPadActionGroup';
import { NumberPadActionItem } from './NumberPadActionItem';
import { NumberPadActionBar } from './NumberPadActionBar';
import { ActionHandlerType, FormatterValueType, NumberPadType } from './types';
import { formatCurrency } from './NumberPadHelper';

export const ACTION_PLUS_NAME = '+';
export const ACTION_MINUS_NAME = '-';
export const ACTION_DOLLAR_NAME = '$';
export const ACTION_PERCENTAGE_NAME = '%';
export const GROUP_PLUS_MINUS_NAME = 'plus_minus_group';
export const GROUP_AMOUNT_PERCENTAGE = 'amount_percentage_group';
export const BAR_NAME = 'plus_minus_amount_percentage_bar';
export type NumberPadPlusMinusAmountPercentageActionBarType = {
  isNegative?: boolean;
  isPercentage?: boolean;
  showDollarPercentage?: boolean;
  showPlusMinus?: boolean;
};
const defaultParam: NumberPadPlusMinusAmountPercentageActionBarType = {
  showDollarPercentage: true,
  isPercentage: true,
  showPlusMinus: true,
  isNegative: true,
};
export class NumberPadPlusMinusAmountPercentageActionBar extends NumberPadActionBar {
  /**
   * This function is to generate the state according to action bar state
   * @param actionBar
   */
  actionHandler = (actionHandlerInputState: ActionHandlerType): ActionHandlerType => {
    const ret: ActionHandlerType = { ...actionHandlerInputState };
    let action = null;
    this.groups.forEach((group: NumberPadActionGroup) => {
      if (group.groupName === GROUP_PLUS_MINUS_NAME) {
        action = group.actions.find((a: NumberPadActionItem): boolean => a.selected);
        if (action) {
          ret.isNegative = action.name === ACTION_MINUS_NAME;
        }
      } else if (group.groupName === GROUP_AMOUNT_PERCENTAGE) {
        action = group.actions.find((a: NumberPadActionItem): boolean => a.selected);
        if (action) {
          ret.otherData.isPercentage = action.name === ACTION_PERCENTAGE_NAME;
        }
      }
    });
    return ret;
  };

  formatter = (formatterInputState: FormatterValueType): string => {
    let displayText = formatterInputState.value;
    if (formatterInputState.isNegative) {
      displayText = `-${displayText}`;
    }
    if (formatterInputState.otherData.isPercentage) {
      displayText = `${displayText}%`;
    } else {
      displayText = formatCurrency(displayText);
    }
    return displayText;
  };

  keyboardHandler: (event: KeyboardEvent | React.KeyboardEvent) => boolean = (event) => {
    let ret = false;
    if (this.actions) {
      // find the action button according to the name
      let foundGroup = null;
      const foundAction = this.actions.find(
        (action: NumberPadActionItem): boolean => action.name === event.key,
      );
      if (foundAction) {
        foundGroup = this.groups.find(
          (group: NumberPadActionGroup): boolean => group.groupName === foundAction.groupName,
        );
      }
      if (foundGroup && foundAction) {
        // Toggle the selected action item; and save the previous selected action for rollback.
        this.toggleGroupActionItem(foundAction, foundGroup);
        ret = true;
      }
    }
    return ret;
  };

  syncFromState = (state: NumberPadType) => {
    // according to the state to reset the action bar;
    const { isNegative, otherData } = state;
    const { isPercentage } = otherData;
    let syncActionItem = null;
    if (isNegative) {
      syncActionItem = this.getActionItem(GROUP_PLUS_MINUS_NAME, ACTION_MINUS_NAME);
    } else {
      syncActionItem = this.getActionItem(GROUP_PLUS_MINUS_NAME, ACTION_PLUS_NAME);
    }
    if (syncActionItem) {
      this.toggleGroupActionItem(syncActionItem);
    }
    if (isPercentage) {
      syncActionItem = this.getActionItem(GROUP_AMOUNT_PERCENTAGE, ACTION_PERCENTAGE_NAME);
    } else {
      syncActionItem = this.getActionItem(GROUP_AMOUNT_PERCENTAGE, ACTION_DOLLAR_NAME);
    }
    if (syncActionItem) {
      this.toggleGroupActionItem(syncActionItem);
    }
  };

  // @ts-ignore
  constructor(param?: NumberPadPlusMinusAmountPercentageActionBarType = defaultParam) {
    const actionGroups = [];
    let defaultAction: NumberPadActionItem;
    param = { ...defaultParam, ...param };
    if (param.showPlusMinus) {
      const NumberPadPlusMinusGroup = [
        new NumberPadActionItem(ACTION_PLUS_NAME, ACTION_PLUS_NAME),
        new NumberPadActionItem(ACTION_MINUS_NAME, ACTION_MINUS_NAME),
      ];
      if (param.isNegative) {
        [, defaultAction] = NumberPadPlusMinusGroup;
      } else {
        [defaultAction] = NumberPadPlusMinusGroup;
      }
      actionGroups.push(
        new NumberPadActionGroup(GROUP_PLUS_MINUS_NAME, defaultAction, NumberPadPlusMinusGroup),
      );
    }
    if (param.showDollarPercentage) {
      const NumberPadAmountPercentageGroup = [
        new NumberPadActionItem(ACTION_DOLLAR_NAME, ACTION_DOLLAR_NAME),
        new NumberPadActionItem(ACTION_PERCENTAGE_NAME, ACTION_PERCENTAGE_NAME),
      ];

      if (param.isPercentage) {
        [, defaultAction] = NumberPadAmountPercentageGroup;
      } else {
        [defaultAction] = NumberPadAmountPercentageGroup;
      }
      actionGroups.push(
        new NumberPadActionGroup(
          GROUP_AMOUNT_PERCENTAGE,
          defaultAction,
          NumberPadAmountPercentageGroup,
        ),
      );
    }
    super(BAR_NAME, actionGroups);
  }
}
