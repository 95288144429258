import { ACTION_BUTTON_CMD, NumberPadActionItem } from './NumberPadActionItem';
import { NumberPadActionBar } from './NumberPadActionBar';
import { ActionHandlerType, FormatterValueType } from './types';
import { NumberPadActionGroup } from './NumberPadActionGroup';

export const ACTION_QTY_NAME = 'Qty';
export const ACTION_VALUE_NAME = 'Value';
export const GROUP_QTY_AMOUNT = 'qty_amount_group';
export const QUANTITY_VALUE_BAR_NAME = 'qty_value_bar';

export class NumberPadQuantityValueActionBar extends NumberPadActionBar {
  actionHandler = (inputHandlerState: ActionHandlerType): ActionHandlerType => {
    const ret: ActionHandlerType = { ...inputHandlerState };
    const foundAction = this.groups[0].actions.find(
      (action: NumberPadActionItem): boolean => action.selected,
    );
    if (foundAction) {
      ret.otherData.isQuantity = foundAction.name !== ACTION_VALUE_NAME;
    }
    return ret;
  };

  formatter = (formatterInputState: FormatterValueType): string => {
    let displayText = formatterInputState.value;
    const foundAction = this.groups[0].actions.find(
      (action: NumberPadActionItem): boolean => action.selected,
    );
    if (foundAction) {
      if (foundAction.name === ACTION_VALUE_NAME) {
        displayText = `$${formatterInputState.value}`;
      }
    }
    return displayText;
  };

  // @ts-ignore
  constructor(isQuanity: boolean) {
    const qtyAction = new NumberPadActionItem(ACTION_QTY_NAME, '', ACTION_BUTTON_CMD);
    const valueAction = new NumberPadActionItem(ACTION_VALUE_NAME, '$', ACTION_BUTTON_CMD);
    const defaultAction = isQuanity ? qtyAction : valueAction;
    const qtyValueGroup = new NumberPadActionGroup('qty_value_pair', defaultAction, [
      qtyAction,
      valueAction,
    ]);
    super(QUANTITY_VALUE_BAR_NAME, [qtyValueGroup]);
  }
}
