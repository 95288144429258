import React from 'react';
import styled, { css } from 'styled-components';
import { typographyStyleFn, TypographyProps, boldStyleFn } from '../../utils/styleFns';

type PropsType = TypographyProps & {
  /**
   * **Deprecated:** heading should always be bold
   * @deprecated
   */
  bold?: boolean;
  breakWord?: boolean;
  color?: string;
  level?: number;
};

type Headings = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export const Heading: React.FunctionComponent<React.PropsWithChildren<PropsType>> = ({
  level = 1,
  align = 'left',
  bold = true,
  breakWord = false,
  color,
  ...restProps
}) => (
  <DynamicHeading
    as={`h${level}` as Headings}
    level={level}
    align={align}
    bold={bold}
    color={color}
    breakWord={breakWord}
    {...restProps}
  />
);

const headingLevels = {
  1: css<PropsType>`
    font: ${({ theme }) => theme.text.heading};
    color: ${({ theme, color }) => color || theme.colors.text};
  `,
  2: css<PropsType>`
    font: ${({ theme }) => theme.text.heading2};
    color: ${({ theme, color }) => color || theme.colors.text};
  `,
  3: css<PropsType>`
    font: ${({ theme }) => theme.text.heading3};
    color: ${({ theme, color }) => color || theme.colors.text};
  `,
  4: css<PropsType>`
    font: ${({ theme }) => theme.text.heading4};
    color: ${({ theme, color }) => color || theme.colors.text};
  `,
  6: css<PropsType>`
    font: ${({ theme }) => theme.text.heading6};
    color: ${({ theme, color }) => color || theme.colors.textSubdued};
    text-transform: uppercase;
  `,
};

const DynamicHeading = styled.h1<PropsType>`
  ${({ level }) => headingLevels[level]}

  text-align: ${({ align }) => align};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  margin: 0px;
  padding: 0px;
  ${({ breakWord }) => (breakWord ? `word-break: break-word;` : '')}

  ${typographyStyleFn}
  ${boldStyleFn}
`;

export const Heading2: React.FunctionComponent<React.PropsWithChildren<PropsType>> = (props) => (
  <Heading {...props} level={2} />
);

export const Heading3: React.FunctionComponent<React.PropsWithChildren<PropsType>> = (props) => (
  <Heading {...props} level={3} />
);

export const Heading6: React.FunctionComponent<React.PropsWithChildren<PropsType>> = (props) => (
  <Heading {...props} level={6} />
);

export default Heading;
