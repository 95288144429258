import React from 'react';
import styled from 'styled-components';
import { IconArrowRight } from '../Icon';
import { preventPropagation } from '../../utils/interaction';

type PropsType = {
  id?: string;
  onClick?: () => void;
  showArrow?: boolean;
};

const ListViewItem: React.FunctionComponent<React.PropsWithChildren<PropsType>> = (props) => {
  const handleOnClick = (e: React.SyntheticEvent) => {
    if (props.onClick) {
      preventPropagation(e, props.onClick);
    }
  };

  return (
    <Item
      id={props.id}
      hasAction={props.showArrow}
      role={props.showArrow ? 'button' : null}
      onClick={handleOnClick}
    >
      {props.children}
      {props.showArrow && <IconArrowRight />}
    </Item>
  );
};

ListViewItem.defaultProps = {
  showArrow: false,
  onClick: () => {},
};

export const Item = styled.div<{ hasAction?: boolean }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  padding: 16px;

  &:hover {
    cursor: ${(props: Record<string, any>): string => (props.hasAction ? 'pointer' : 'default')};
    background-color: ${({ theme }): string => theme.form.hoverBackgroundColor};
  }
`;

export default ListViewItem;
