import React from 'react';
import styled from 'styled-components';

import AutomaticPromotions from './illustrations/automatic-promotions.svg';
import Bank from './illustrations/bank.svg';
import BarGraph from './illustrations/bar-graph.svg';
import Bin from './illustrations/bin.svg';
import BuildEmptyState from './illustrations/build-empty-state.svg';
import CashRegister from './illustrations/cash-register.svg';
import CreditCard from './illustrations/credit-card.svg';
import DeliveriesDue from './illustrations/deliveries-due.svg';
import EmptySite from './illustrations/empty-site.svg';
import FoodScale from './illustrations/food-scale.svg';
import InsightsEmptyState from './illustrations/insights-empty-state.svg';
import NoAccess from './illustrations/no-access.svg';
import NoMatches from './illustrations/no-matches.svg';
import NoProducts from './illustrations/no-products.svg';
import NoRecipes from './illustrations/no-recipes.svg';
import ParLevels from './illustrations/par-levels.svg';
import PaymentTerminal from './illustrations/payment-terminal.svg';
import Printer from './illustrations/printer.svg';
import ProductShelf from './illustrations/products-shelf.svg';
import ProductsSuppliers from './illustrations/products-suppliers.svg';
import Promotions from './illustrations/promotions.svg';
import RecipeSmall from './illustrations/recipe-small.svg';
import RecurringOrders from './illustrations/recurring-orders.svg';
import Reminders from './illustrations/reminders.svg';
import RequestSent from './illustrations/request-sent.svg';
import SaltPepper from './illustrations/salt-pepper.svg';
import Signature from './illustrations/signature.svg';
import StockCount from './illustrations/stock-count.svg';
import StockLocations from './illustrations/stock-locations.svg';
import StockTransferUpgrade from './illustrations/stocktransfer-upgrade.svg';
import StockTransferWip from './illustrations/stocktransfer-wip.svg';
import WestpacTerminal from './illustrations/westpac-terminal.svg';

type PropsType = {
  description?: string | null;
  illustrationName?: IllustrationName | null;
  url?: string | null;
  /**
   * To fill available space you can set this to 100%
   * for illustrations set by URL
   */
  width?: string;
};

export default function Illustration({
  illustrationName,
  url,
  description,
  width,
}: PropsType): JSX.Element {
  let illustration = null;

  if (illustrationName) {
    illustration = getIllustration(illustrationName);
  } else if (url) {
    illustration = <img src={url} alt={description} width={width} />;
  }

  return <IllustrationContainer>{illustration}</IllustrationContainer>;
}

Illustration.defaultProps = {
  width: 'auto',
};

export const getIllustrationInfoWithName = (): IllustrationInfoWithName[] => {
  return Array.from(Object.entries(illustrationInfoTyped)).map(([name, info]) => ({
    ...info,
    name: name as IllustrationName,
  }));
};
export const getLargeIllustrations = (): IllustrationInfoWithName[] => {
  return getIllustrationInfoWithName().filter((info) => !info.small);
};
export const getSmallIllustrations = (): IllustrationInfoWithName[] => {
  return getIllustrationInfoWithName().filter((info) => info.small);
};
export type IllustrationName = keyof typeof illustrationInfo;
type IllustrationInfo = {
  component: string;
  small?: boolean;
};
type IllustrationInfoWithName = IllustrationInfo & {
  name: IllustrationName;
};
const illustrationInfo = {
  'automatic-promotions': { component: AutomaticPromotions },
  bank: { component: Bank, small: true },
  'bar-graph': { component: BarGraph, small: true },
  bin: { component: Bin },
  'build-empty-state': { component: BuildEmptyState },
  'cash-register': { component: CashRegister },
  'credit-card': { component: CreditCard, small: true },
  'deliveries-due': { component: DeliveriesDue },
  'empty-site': { component: EmptySite },
  'food-scale': { component: FoodScale },
  'insights-empty-state': { component: InsightsEmptyState },
  'no-access': { component: NoAccess },
  'no-matches': { component: NoMatches },
  'no-recipes': { component: NoRecipes },
  'no-products': { component: NoProducts },
  'par-levels': { component: ParLevels },
  'payment-terminal': { component: PaymentTerminal },
  printer: { component: Printer },
  'products-shelf': { component: ProductShelf },
  'products-suppliers': { component: ProductsSuppliers },
  promotions: { component: Promotions },
  'recipe-small': { component: RecipeSmall, small: true },
  'recurring-orders': { component: RecurringOrders },
  reminders: { component: Reminders },
  'request-sent': { component: RequestSent },
  'salt-pepper': { component: SaltPepper },
  signature: { component: Signature },
  'stock-count': { component: StockCount },
  'stock-locations': { component: StockLocations },
  'stocktransfer-upgrade': { component: StockTransferUpgrade },
  'stocktransfer-wip': { component: StockTransferWip },
  'westpac-terminal': { component: WestpacTerminal },
};
// We use this typecheck variable to verify the types of the object above
// Unfortunately we can't tell the object above the property type directly, or we lose the keyof feature :(
const illustrationInfoTyped: {
  [key in IllustrationName]: IllustrationInfo;
} = illustrationInfo;

const getIllustration = (name: IllustrationName): JSX.Element | null => {
  const info = illustrationInfo[name];
  if (!info) {
    return null;
  }
  const IllustrationComponent = info.component;
  return <IllustrationComponent />;
};

const IllustrationContainer = styled.div`
  > svg {
    width: 100%;
  }
`;
