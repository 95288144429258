/* eslint no-underscore-dangle: [2, { "allowAfterThis": true }] */

// in action group, it contains one or more actions
import { NumberPadActionItem } from './NumberPadActionItem';

export class NumberPadActionGroup {
  groupName: string;

  actions: NumberPadActionItem[];

  defaultAction: NumberPadActionItem | null;

  constructor(
    groupName: string,
    defaultAction: NumberPadActionItem | null,
    actions: NumberPadActionItem[],
  ) {
    this.groupName = groupName;
    this.actions = actions;
    this.actions.forEach((action: NumberPadActionItem) => {
      action.groupName = groupName;
    });
    this.defaultAction = defaultAction;
    if (this.defaultAction) {
      this.defaultAction.selected = true;
    }
  }

  setActions(actions: NumberPadActionItem[]) {
    this.actions = actions;
  }

  getActions(): NumberPadActionItem[] {
    return this.actions;
  }

  setGroupName(name: string) {
    this.groupName = name;
  }

  getGroupName(): string {
    return this.groupName;
  }

  setDefaultAction(actionItem: NumberPadActionItem | null) {
    this.defaultAction = actionItem;
  }

  getDefaultAction(): NumberPadActionItem | null {
    return this.defaultAction;
  }
}
