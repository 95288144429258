import React, { Component } from 'react';

import { isAndroid, isIOS } from 'react-device-detect';
import { Decimal } from '../../utils/decimal';
import { IconButton, IconClose, IconBackspace, Overlay } from '../index';
import {
  ActionBar,
  ButtonContainer,
  DialogContainer,
  NumberButton,
  NumberButtons,
  NumberPadContainer,
  NumberScreen,
  ActionButton,
  ActionButtons,
  SubTitle,
  TitleBar,
  TopActionButton,
  SuffixContainer,
} from './NumberPadStyle';
import {
  ActionHandlerType,
  FormatterValueType,
  NumberPadType,
  NumberPadOnCancelFuncType,
  NumberPadOnChangeFuncType,
  NumberPadOnSubmitFuncType,
  NumberPadOnErrorFuncType,
} from './types';
import { NumberPadActionBar } from './NumberPadActionBar';
import { NumberPadActionGroup } from './NumberPadActionGroup';
import { ACTION_BUTTON_CMD, ACTION_BUTTON_TEXT, NumberPadActionItem } from './NumberPadActionItem';
import { colors } from '../../themes';
import { shallowDiffers } from './NumberPadHelper';
import { Heading3 } from '../Heading';
import Paragraph from '../Paragraph';

type RenderActionButtonFuncType = (action: NumberPadActionItem) => JSX.Element;

type PropsType = {
  /**
   * Actions
   */
  actionButtons?: NumberPadActionButtonType[];
  currency?: string;
  /**
   * The number of decimal digits allowed
   */
  decimalDigits?: number;
  /**
   * The decimal separator character, default `'.'`. NOTE: this setting doesn't affect pad buttons
   * by default. Please define custom buttons if you need a localised decimal button.
   */
  decimalSeparator?: string;
  fill?: boolean;
  /**
   * Initial value to display
   */
  initialValue?: string | number;
  /**
   * Is to display the value as negative?
   */
  isNegative?: boolean;
  /**
   * The maximum length of the input allowed. NOTE: the length includes the decimal separator.
   */
  maxLength?: number;
  /**
   * Number pad buttons
   */
  numberPadButtons?: NumberPadButtonType[];
  /**
   * Callback function when click 'close' button, if this function is not set,
   * will unmount NumberPad component
   */
  onCancel?: NumberPadOnCancelFuncType;
  /**
   * Callback function for any state changes in NumberPad
   */
  onChange?: NumberPadOnChangeFuncType;
  /**
   * Callback function if any error occurs
   */
  onError?: NumberPadOnErrorFuncType;
  /**
   * Callback function when click 'submit' button, if return value is true,
   * Numberpad will be closed, otherwise, will be stay open.
   */
  onSubmit?: NumberPadOnSubmitFuncType;
  overlay?: boolean;
  showActionButtons?: boolean;
  /**
   * Is to show NumberPad title?
   */
  showTitle?: boolean;
  /**
   * Subtitle
   */
  subTitle?: React.ReactNode;
  /**
   * Show title if showTitle is set to true
   */
  title?: string;
  /**
   * Actionbars
   */
  topActionBars?: NumberPadActionBar[];
  useSuffix?: string | NumberPadButtonType;
  /**
   * NumberPad z-index
   */
  zIndex?: number;
};

type StateType = {
  activeActionBarIndex?: number;
  clearOnChange?: boolean;
  currency?: string;
  error?: string | null;
  /**
   * Is the value negative?
   */
  isNegative?: boolean;
  otherData?: any;
  /**
   * Numberpad value
   */
  value?: string;
};

const inputTypes = {
  Numeric: '^[0-9.]$',
  Enter: 'Enter',
  Backspace: 'Delete',
  Clear: 'Clear',
  Escape: 'Esc',
  PlusMinus: '^[+-]$',
  DollarPercentage: '^[$%]$',
};

type NumberPadButtonType = {
  className?: string;
  content?: JSX.Element | null;
  id?: string;
  inputType?: string;
  name?: string;
};

// Object.values doesn't work well with types
// https://github.com/facebook/flow/issues/2133

type NumberPadActionButtonType = NumberPadButtonType & {
  content?: JSX.Element | null;
  inputType?: string;
};

const numberPadButtons: NumberPadButtonType[] = [
  {
    id: '7',
    name: '7',
    className: '',
  },
  {
    id: '8',
    name: '8',
    className: '',
  },
  {
    id: '9',
    name: '9',
    className: '',
  },
  {
    id: '4',
    name: '4',
    className: '',
  },
  {
    id: '5',
    name: '5',
    className: '',
  },
  {
    id: '6',
    name: '6',
    className: '',
  },
  {
    id: '1',
    name: '1',
    className: '',
  },
  {
    id: '2',
    name: '2',
    className: '',
  },
  {
    id: '3',
    name: '3',
    className: '',
  },
  {
    id: '0',
    name: '0',
    className: 'tdNumPad0',
  },
  {
    id: '.',
    name: '.',
    className: 'tdNumPadDot',
  },
];

const actionButtons: NumberPadActionButtonType[] = [
  {
    id: 'backspace',
    name: '',
    className: 'tdNumPadBk',
    inputType: inputTypes.Backspace,
    content: <IconBackspace color={colors.white} />,
  },
  {
    id: 'enter',
    name: 'OK',
    className: 'primary tdNumPadEnter',
    inputType: inputTypes.Enter,
  },
];
const INVALID_ACTION_BAR_INDEX = -1;

const SINGLE_LINE_CHARACTER_LIMIT = 35;

const systemDecimalSeparator = '.';

const makeStateImmutable = (state: NumberPadType): NumberPadType => ({
  ...state,
  otherData: { ...state.otherData },
});

export default class NumberPad extends Component<PropsType, StateType> {
  mounted: boolean;

  containerEl: HTMLElement;

  static defaultProps = {
    initialValue: '0',
    isNegative: false,
    showTitle: true,
    title: '',
    subTitle: null,
    decimalDigits: 2,
    decimalSeparator: systemDecimalSeparator,
    maxLength: 13,
    overlay: true,
    topActionBars: [],
    zIndex: 1001,
    numberPadButtons,
    actionButtons,
    fill: false,
    showActionButtons: true,
    currency: 'AUD',
    onChange() {},
    onError() {},
    onCancel() {},
  };

  constructor(props: PropsType) {
    super(props);
    this.mounted = false;
    const initValue = new Decimal(this.props.initialValue)
      .round(this.props.decimalDigits)
      .toString();

    const updatedState: ActionHandlerType = this.actionBarHandler({
      value: initValue,
      isNegative: this.props.isNegative,
      otherData: {},
    });
    this.state = {
      value: initValue,
      error: null,
      clearOnChange: true,
      ...updatedState,
      activeActionBarIndex: INVALID_ACTION_BAR_INDEX,
      currency: this.props.currency,
    };
  }

  componentDidMount() {
    this.mounted = true;
    if (this.containerEl) {
      this.containerEl.focus();
    }
    this.attachRightActionButtonEvents();
  }

  shouldComponentUpdate(nextProps: unknown, nextState: StateType): boolean {
    let needUpdate: boolean = shallowDiffers(nextState, this.state);
    if (!needUpdate) {
      needUpdate = shallowDiffers(nextState.otherData, this.state.otherData);
    }
    if (!needUpdate) {
      needUpdate = shallowDiffers(nextProps, this.props);
    }
    return needUpdate;
  }

  componentWillUnmount() {
    this.mounted = false;
    this.removeRightActionButtonEvents();
  }

  attachRightActionButtonEvents() {
    Object.values(this.props.actionButtons).forEach((button: NumberPadActionButtonType) => {
      const element = document.getElementById(button.id);
      if (element) {
        if (isIOS || isAndroid) {
          element.addEventListener('touchstart', this.handleActionClick(button.inputType));
        } else {
          element.addEventListener('click', this.handleActionClick(button.inputType));
        }
      }
    });
    Object.values(this.props.numberPadButtons).forEach((button: NumberPadActionButtonType) => {
      const element = document.getElementById(button.id);
      if (element && button.inputType && button.inputType !== inputTypes.Backspace) {
        if (isIOS || isAndroid) {
          element.addEventListener('touchstart', this.handleActionClick(button.inputType));
        } else {
          element.addEventListener('click', this.handleActionClick(button.inputType));
        }
      }
    });
  }

  removeRightActionButtonEvents() {
    Object.values(this.props.actionButtons).forEach((button: NumberPadActionButtonType) => {
      const element = document.getElementById(button.id);
      if (element) {
        if (isIOS || isAndroid) {
          element.removeEventListener('touchstart', this.handleActionClick(button.inputType));
        } else {
          element.removeEventListener('click', this.handleActionClick(button.inputType));
        }
      }
    });
    Object.values(this.props.numberPadButtons).forEach((button: NumberPadActionButtonType) => {
      const element = document.getElementById(button.id);
      if (element && button.inputType) {
        if (isIOS || isAndroid) {
          element.removeEventListener('touchstart', this.handleActionClick(button.inputType));
        } else {
          element.removeEventListener('click', this.handleActionClick(button.inputType));
        }
      }
    });
  }

  isValidKeyboardKeyEntered = (event: React.KeyboardEvent): boolean => {
    const validKey = `01234567890${this.props.decimalSeparator}`;
    return validKey.indexOf(event.key) >= 0;
  };

  handleKeyboardEvent = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.handleInput(inputTypes.Enter);
    } else if (event.key === 'Escape') {
      this.handleInput(inputTypes.Escape);
    } else if (event.key === 'Backspace') {
      this.handleInput(inputTypes.Backspace);
    } else if (this.isValidKeyboardKeyEntered(event)) {
      this.handleInput(event.key);
    } else if (this.props.topActionBars) {
      // Send other keys to action bar event handler;
      this.handleActionBarKeyboardEvent(event);
    }
    event.preventDefault();
    event.stopPropagation();
  };

  handleClick = (input: string) => {
    this.handleInput(input || '');
  };

  handleButtonClick = (event: React.MouseEvent) => {
    const target = event.target as HTMLButtonElement;
    if (target && target.innerText) {
      this.handleClick(target.innerText);
    }
    this.stopPropagation(event);
  };

  handleActionClick =
    (input: string) => (event: MouseEvent | TouchEvent | React.MouseEvent | React.TouchEvent) => {
      this.handleClick(input);
      this.stopPropagation(event);
    };

  getActiveActionBar = (
    barIndex: number = this.state.activeActionBarIndex,
    bars: NumberPadActionBar[] | null = this.props.topActionBars,
  ): NumberPadActionBar | null => {
    if (bars && bars.length > barIndex) {
      return bars[barIndex];
    }
    return null;
  };

  formatDisplayValue = (inputValue: string): string => {
    // We don't need to convert separators when `decimalSeparator` = `systemDecimalSeparator`.
    if (this.props.decimalSeparator === systemDecimalSeparator) {
      return inputValue;
    }

    return inputValue.replace(
      new RegExp(`\\${systemDecimalSeparator}`, 'g'),
      this.props.decimalSeparator,
    );
  };

  formatInputValue = (displayValue: string): string => {
    // We don't need to convert separators when `decimalSeparator` = `systemDecimalSeparator`.
    if (this.props.decimalSeparator === systemDecimalSeparator) {
      return displayValue;
    }

    // Remove any `systemDecimalSeparator`s that have been entered (e.g. pasted) before replacing
    // the localised `decimalSeparator`. This avoids creating output with multiple decimals when
    // the string contains both characters, e.g. to avoid turning '1.234,56' into '1.234.56'.
    return displayValue
      .replace(new RegExp(`\\${systemDecimalSeparator}`, 'g'), '')
      .replace(new RegExp(`\\${this.props.decimalSeparator}`, 'g'), systemDecimalSeparator);
  };

  formatter = (): string => {
    const formatterValue: FormatterValueType = { ...this.state };
    let displayText = null;
    const { topActionBars: actionBars } = this.props;
    const activeActionBar = this.getActiveActionBar();
    if (activeActionBar && activeActionBar.formatter) {
      displayText = activeActionBar.formatter(formatterValue);
    } else if (actionBars) {
      actionBars.forEach((actionBar: NumberPadActionBar) => {
        if (actionBar.formatter) {
          displayText = actionBar.formatter(formatterValue);
        }
      });
    }
    if (!displayText) {
      displayText = formatterValue.value;
    }
    return this.formatDisplayValue(displayText);
  };

  actionBarHandler = (actionInput: ActionHandlerType): ActionHandlerType => {
    let actionResult: ActionHandlerType = { ...actionInput };
    const { topActionBars: actionBars } = this.props;
    if (actionBars) {
      actionBars.forEach((actionBar: NumberPadActionBar) => {
        if (actionBar.actionHandler) {
          const immutableState = makeStateImmutable(actionInput);
          if (actionBar && actionBar.actionHandler) {
            const updatedState = actionBar.actionHandler(immutableState);
            actionResult = { ...actionResult, ...updatedState };
          }
        }
      });
    }
    return actionResult;
  };

  handleInput = (input: string) => {
    if (input === inputTypes.Enter) {
      this.onSubmit();
      if (this.mounted) {
        this.setState({ clearOnChange: true });
      }
    } else if (input === inputTypes.Escape) {
      this.props.onCancel();
    } else {
      try {
        const newValue = this.updateValue(input);
        // formatter will return the data like otherData, isNegative
        const updatedState = this.actionBarHandler({
          value: newValue,
          isNegative: this.state.isNegative,
          otherData: this.state.otherData,
        });
        this.handleChange({ value: newValue, ...updatedState });
      } catch (e) {
        this.handleError(e.message);
      }
    }
  };

  onSubmit() {
    const { isNegative, value, otherData } = this.state;
    const amount = new Decimal(value).times(isNegative ? -1 : 1);
    if (this.props.onSubmit) {
      if (this.props.onSubmit(amount, { isNegative, value, otherData }) === true) {
        this.props.onCancel();
      }
    }
  }

  /**
   * This function is to change the new state.
   * If the changes are applied, then return true, otherwise, return false;
   * @param newState
   * @returns {boolean}
   */
  handleChange(newState: { isNegative?: boolean; otherData?: any; value?: string }): boolean {
    const oldState = {
      ...this.state,
    };
    const mergedState = {
      ...oldState,
      ...newState,
    };
    let amount = new Decimal(mergedState.value);
    if (mergedState.isNegative) {
      amount = new Decimal(0).minus(amount);
    }
    if (typeof this.props.onChange === 'function') {
      if (this.props.onChange(amount, mergedState, oldState) === false) {
        return false;
      }
    }
    this.setState({
      error: null,
      clearOnChange: false,
      ...newState,
    });
    return true;
  }

  updateValue(rawInput: string): string {
    const len = this.state.value.length;
    if (rawInput === inputTypes.Backspace) {
      return this.state.clearOnChange ? '0' : this.state.value.substring(0, len - 1) || '0';
    }

    const input = this.formatInputValue(rawInput);

    let newValue = this.state.value;
    if (input.match(new RegExp(inputTypes.Numeric))) {
      if (this.state.clearOnChange) {
        // When a user makes an input for the first time, we just replace
        // the initial value with the new input
        newValue = input === systemDecimalSeparator ? `0${systemDecimalSeparator}` : input;
      } else {
        const dotPos = this.state.value.indexOf(systemDecimalSeparator);
        const currentDecimals = dotPos >= 0 && dotPos !== len - 1 ? len - dotPos - 1 : 0;
        newValue =
          len >= this.props.maxLength ||
          (input === systemDecimalSeparator && this.props.decimalDigits === 0) ||
          currentDecimals === this.props.decimalDigits
            ? this.state.value
            : `${this.state.value}${input}`;

        if (this.state.value === '0' && input !== systemDecimalSeparator) {
          newValue = input;
        }
        try {
          // check for invalid value
          // eslint-disable-next-line no-new
          new Decimal(newValue);
        } catch (e) {
          throw new Error(`Invalid number: ${e.message}`);
        }
      }
    } else {
      throw new Error('Invalid input');
    }

    return newValue;
  }

  handleError(error: string) {
    this.setState({ error });
    if (typeof this.props.onError === 'function') {
      this.props.onError(error);
    }
  }

  stopPropagation(e: any) {
    e.preventDefault();
    e.stopPropagation();
  }

  renderActionBars = (): JSX.Element[] | null => {
    const { topActionBars: actionBars } = this.props;
    if (!actionBars) {
      return null;
    }
    return actionBars.map(this.renderActionBar);
  };

  renderActionBar = (actionBar: NumberPadActionBar, index: number): JSX.Element => {
    const actions = [];
    actionBar.groups.forEach((group: NumberPadActionGroup) => {
      actions.push(...group.actions);
    });
    return (
      <ActionBar key={index}>
        {actions ? actions.map(this.renderActionButton(index)) : null}
      </ActionBar>
    );
  };

  renderActionButton =
    (activeBarIndex: number): RenderActionButtonFuncType =>
    (action: NumberPadActionItem): JSX.Element =>
      (
        <TopActionButton
          key={action.name}
          role="button"
          onClick={this.handleActionBarClick(activeBarIndex, action)}
          doubleWidth={action.doubleWidth}
          selected={action.selected}
          isLast={action.isLast}
        >
          {action.name}
        </TopActionButton>
      );

  handleActionBarClick =
    (activeActionBarIndex: number, actionItemClicked: NumberPadActionItem) =>
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      this.stopPropagation(event);
      // toggle the selected flag
      const actionBar = this.getActiveActionBar(activeActionBarIndex);
      if (!actionBar) {
        return;
      }
      actionBar.toggleGroupActionItem(actionItemClicked);
      const { actionType } = actionItemClicked;
      const immutableState = makeStateImmutable(this.state);
      const { isNegative, otherData } = immutableState;
      let value = '';
      let updatedState: ActionHandlerType | null = null;
      if (actionType === ACTION_BUTTON_CMD) {
        value = immutableState.value;
      } else if (actionType === ACTION_BUTTON_TEXT) {
        value = actionItemClicked.value;
      }
      if (actionBar.actionHandler) {
        updatedState = actionBar.actionHandler({ value, isNegative, otherData });
      }
      if (updatedState) {
        // @ts-ignore
        const bRet = this.handleChange({ ...updatedState, activeActionBarIndex });
        // If the changes were rejected, sync the action bar from the state
        if (!bRet && actionBar.syncFromState) {
          actionBar.syncFromState(this.state);
        }
      }
    };

  /**
   * Handle keyboard input event in action bar if action bar has keyboard handler
   * @param event
   */
  handleActionBarKeyboardEvent = (event: React.KeyboardEvent) => {
    let isEventHandled = false;
    if (this.props.topActionBars) {
      this.props.topActionBars.forEach((actionBar: NumberPadActionBar) => {
        if (actionBar.keyboardHandler) {
          isEventHandled = actionBar.keyboardHandler(event) || isEventHandled;
        }
      });
    }
    if (isEventHandled) {
      const immutableState = makeStateImmutable(this.state);
      const { value, isNegative, otherData } = immutableState;
      // Note: immutableState may be mutated in actionBarHandler
      const updatedState = this.actionBarHandler({ value, isNegative, otherData });
      const bRet = this.handleChange({ value, ...updatedState });
      // If the changes were rejected, sync the action bar from the state
      if (!bRet && this.props.topActionBars) {
        this.props.topActionBars.forEach((actionBar: NumberPadActionBar) => {
          if (actionBar.syncFromState) {
            actionBar.syncFromState(this.state);
          }
        });
      }
    }
    event.stopPropagation();
    event.preventDefault();
  };

  touchClick(clickHandler): Record<string, any> {
    let result = { onClick: clickHandler };
    // if it is on IOS or Android device, use touchEvent than Click event;
    // KO 13211 if we use touchEvent, it will trigger the button beneth
    // the OK, so we still use onClick
    if (isIOS || isAndroid) {
      result = { onClick: clickHandler };
    }
    return result;
  }

  renderDialog(): JSX.Element {
    const HeaderTextComponent =
      this.props.title.length < SINGLE_LINE_CHARACTER_LIMIT ? Heading3 : Paragraph;
    return (
      <DialogContainer
        role="dialog"
        ref={(el: HTMLElement) => {
          this.containerEl = el;
        }}
        onKeyDown={this.handleKeyboardEvent}
        onClick={this.stopPropagation}
        tabIndex={0}
        overlay={this.props.overlay}
        fillStyle={this.props.fill}
      >
        <TitleBar className="ui-dialog-titlebar" show={this.props.showTitle}>
          <HeaderTextComponent className="ui-dialog-title">{this.props.title}</HeaderTextComponent>
          <IconButton onClick={this.handleActionClick(inputTypes.Escape)}>
            <IconClose width={16} height={16} />
          </IconButton>
        </TitleBar>
        {this.props.subTitle === null ? null : <SubTitle>{this.props.subTitle}</SubTitle>}
        <NumberPadContainer fillStyle={this.props.fill}>
          <NumberScreen fillStyle={this.props.fill}>
            {this.formatter()}
            {this.props.useSuffix && (
              <SuffixBar useSuffix={this.props.useSuffix} handleInput={this.handleInput} />
            )}
          </NumberScreen>
          <ButtonContainer fillStyle={this.props.fill}>
            {this.renderActionBars()}
            <NumberButtons
              {...this.touchClick(this.handleButtonClick)}
              fullWidth={!this.props.showActionButtons}
            >
              {this.props.numberPadButtons.map(
                (button: NumberPadButtonType): JSX.Element => (
                  <NumberButton
                    role="button"
                    id={button.id}
                    key={`${button.id}-button`}
                    className={button.className}
                    fillStyle={this.props.fill}
                  >
                    {button.content ? button.content : button.name}
                  </NumberButton>
                ),
              )}
            </NumberButtons>
            {this.props.showActionButtons && (
              <ActionButtons>
                {this.props.actionButtons.map(
                  (button: NumberPadActionButtonType): JSX.Element => (
                    <ActionButton
                      role="button"
                      id={button.id}
                      key={`${button.id}-action`}
                      className={button.className}
                    >
                      {button.content ? button.content : button.name}
                    </ActionButton>
                  ),
                )}
              </ActionButtons>
            )}
          </ButtonContainer>
        </NumberPadContainer>
      </DialogContainer>
    );
  }

  render(): JSX.Element {
    return this.props.overlay ? (
      <Overlay onKeyDown={this.handleKeyboardEvent} onCancel={this.props.onCancel}>
        {this.renderDialog()}
      </Overlay>
    ) : (
      this.renderDialog()
    );
  }
}

type SuffixBarPropsType = {
  handleInput: (inputType: string) => void;
  useSuffix: string | NumberPadButtonType;
};

const SuffixBar = (props: SuffixBarPropsType): JSX.Element => {
  const handleSuffixClick = (): void => {
    if (typeof props.useSuffix === 'string') {
      return undefined;
    }
    if (props.useSuffix.inputType) {
      return props.handleInput(props.useSuffix.inputType);
    }
    return null;
  };

  return (
    <SuffixContainer onClick={handleSuffixClick}>
      {(props.useSuffix as NumberPadButtonType).content
        ? (props.useSuffix as NumberPadButtonType).content
        : props.useSuffix}
    </SuffixContainer>
  );
};
