/* eslint react/no-string-refs: 0 */

import React, { Component, createRef } from 'react';
import { debounce } from 'underscore';
import styled, { css, ThemeConsumer, DefaultTheme } from 'styled-components';
import { shallowEqualObjects } from 'shallow-equal';
import IconButton from '../IconButton';
import { IconArrowLeft, IconArrowRight } from '../Icon';
import { getDataAttributes, DataPropsType } from '../../utils/dataAttributes';

type PropsType = DataPropsType & {
  /**
   * Set the background gradient of the navigation arrows, if enabled.
   */
  arrowsBgColor?: string;
  /**
   * Pass in a array of categories.
   */
  categories?: any[];
  className?: string;
  /**
   *
   * Responsible for rendering the category list items at a given index with style.
   *
   * @param {Object} listProps
   * @param {number} listProps.index
   * @param {Object} listProps.style
   * @param {Array} listProps.data - Shape of passed in categories array.
   */
  itemRenderer?: (item: ItemType) => React.ReactNode;
  /**
   * calls the callback function with the category index.
   * @param {number} index
   * @param {SyntheticEvent} event
   */
  onCategoryClick?: (index: number, itemId: string, e: React.SyntheticEvent) => void;
  /**
   * Scrolls to a provided index
   */
  scrollToIndex?: number;
  /**
   * Shows clickable left and right arrows at the ends of the container, if the container overflows.
   */
  showArrows?: boolean;
};

type ItemType = {
  [itemField: string]: any;
  category?: Record<string, any>;
  index?: number;
  selectorId?: string;
};

type StateType = {
  showLeftArrow: boolean;
  showRightArrow: boolean;
};

export default class CategoryList extends Component<PropsType, StateType> {
  scrollRef: React.RefObject<HTMLUListElement>;

  static defaultProps = {
    categories: [],
    itemRenderer: () => {},
    onCategoryClick: () => {},
    scrollToIndex: 0,
    showArrows: false,
    arrowsBgColor: '#fff',
    className: '',
  };

  constructor(props: PropsType) {
    super(props);
    this.scrollRef = createRef<HTMLUListElement>();
    this.state = {
      showLeftArrow: false,
      showRightArrow: false,
    };
  }

  componentDidMount() {
    this.updateShowArrows();
    window.addEventListener('resize', this.onScrollResize);
  }

  componentDidUpdate(prevProps: PropsType) {
    // Check if we need to scroll the container
    const { scrollToIndex: prevIndex } = prevProps;
    const { scrollToIndex: nextIndex } = this.props;
    const nextRef = this.refs[`cat-${nextIndex}`];
    if (nextIndex !== prevIndex && nextRef) {
      (nextRef as HTMLElement).scrollIntoView({ block: 'center', inline: 'center' });
    }

    this.updateShowArrows();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onScrollResize);
  }

  getShouldShowArrows(): StateType {
    if (!this.props.showArrows) {
      return null;
    }

    const scrollEl = this.scrollRef.current;
    let showLeftArrow = false;
    let showRightArrow = false;

    if (scrollEl) {
      showLeftArrow = scrollEl.scrollLeft > 0;
      showRightArrow = scrollEl.scrollLeft < scrollEl.scrollWidth - scrollEl.clientWidth;
    }
    return {
      showLeftArrow,
      showRightArrow,
    };
  }

  updateShowArrows() {
    const { showLeftArrow, showRightArrow } = this.state;
    const showArrows = this.getShouldShowArrows();
    if (showArrows && !shallowEqualObjects({ showLeftArrow, showRightArrow }, showArrows)) {
      // Normally we shouldn't call setState inside componentDidUpdate, but we need to check the
      // size of the container to determine whether to show the arrows or not.
      // See https://reactjs.org/docs/react-component.html#componentdidupdate for more info.
      this.setState(showArrows);
    }
  }

  onClickLeft = () => {
    this.onClickArrow(-1);
  };

  onClickRight = () => {
    this.onClickArrow(1);
  };

  onClickArrow = (dir: number) => {
    const el = this.scrollRef.current;
    if (!el) {
      return;
    }
    const currentScroll = el.scrollLeft;
    const scrollFactor = 80;
    const nextScroll = dir * scrollFactor;
    el.scrollLeft = currentScroll + nextScroll;
    this.forceUpdate();
  };

  /**
   * We want to be able to hide the arrows if the user scrolls to the left or right,
   * or the page resizes, which means we have to listen for and update on scrolling.
   */
  onScrollResize = debounce(() => {
    this.forceUpdate();
  }, 100);

  render() {
    const {
      categories,
      itemRenderer,
      onCategoryClick,
      scrollToIndex,
      showArrows,
      arrowsBgColor,
      className,
      data,
    } = this.props;
    const { showLeftArrow, showRightArrow } = this.state;

    const arrowProps = {
      width: 16,
      height: 16,
    };

    const arrowConfig = [
      {
        icon: <IconArrowLeft {...arrowProps} />,
        onClick: this.onClickLeft,
        position: 'left',
      },
      {
        icon: <IconArrowRight {...arrowProps} />,
        onClick: this.onClickRight,
        position: 'right',
      },
    ];

    const [leftArrow, rightArrow] = arrowConfig.map(
      ({
        icon,
        onClick,
        position,
      }: {
        icon: React.ReactNode;
        onClick: () => void;
        position: string;
      }): React.ReactNode => (
        <StyledArrow className={`pos-${position}`} bgColor={arrowsBgColor}>
          <IconButton onClick={onClick}>{icon}</IconButton>
        </StyledArrow>
      ),
    );

    const getItem = ({
      category,
      index,
      isSelected,
      theme,
    }: {
      category: any;
      index: number;
      isSelected: boolean;
      theme: DefaultTheme;
    }) => {
      const listItemStyle = {
        borderRadius: '4px',
        padding: 'inherit',
        flexWrap: 'wrap',
        fontWeight: 'bold',
      };

      const style = {
        ...listItemStyle,
        color: isSelected ? theme.text.whiteText : theme.colors.text,
      };
      return { ...category, index, selectorId: getSelectorId(index), style };
    };

    const getSelectorId = (i: number) => `cat-${i}`;

    return (
      <CategoryListContainer className={className}>
        {showLeftArrow && leftArrow}
        <ListItemContainer
          ref={this.scrollRef}
          onScroll={showArrows ? this.onScrollResize : null}
          role="tablist"
        >
          {categories.map(
            (category: Record<string, any>, index: number): React.ReactNode => {
              const isSelected = scrollToIndex === index;

              const onClick: React.EventHandler<React.SyntheticEvent> = (e) => {
                onCategoryClick(index, category.id, e);
                e.stopPropagation();
              };

              /**
               * 2020/04/20 - the below comment is prior to our upgrade to styled-components v5 and may no longer apply
               *
               * Styled components and string refs (as innerRef) is incompatible,
               * so have to use a span as a wrapper.
               * https://www.styled-components.com/docs/api#innerref-prop
               */
              return (
                <span
                  role="tab"
                  tabIndex={0}
                  key={category.id}
                  ref={getSelectorId(index)}
                  onClick={onClick}
                  onKeyPress={(e) => (e.key === 'Enter' || e.key === ' ' ? onClick(e) : undefined)}
                  {...getDataAttributes(data)}
                >
                  <ThemeConsumer>
                    {(theme) => (
                      <StyledListItem selected={isSelected}>
                        {itemRenderer(getItem({ category, index, isSelected, theme }))}
                      </StyledListItem>
                    )}
                  </ThemeConsumer>
                </span>
              );
            },
          )}
        </ListItemContainer>
        {showRightArrow && rightArrow}
      </CategoryListContainer>
    );
  }
}

const CategoryListContainer = styled.div`
  border-radius: 4px 4px 0 0;
  box-shadow: 0 12px 12px -12px rgba(0, 0, 0, 0.2);
  border-bottom: ${(props): string => props.theme.border.defaultBorder};
  margin: 0;
  position: relative;
`;
const ListItemContainer = styled.ul`
  display: flex;
  margin: 0;
  padding: 16px 0 12px;
  overflow: auto;

  > span {
    white-space: nowrap;

    &:first-of-type {
      padding-left: 16px;
    }
    &:last-of-type {
      padding-right: 16px;
    }

    + span {
      margin-left: 8px;
    }
  }
`;

type StyledListType = {
  selected: boolean;
};
const StyledListItem = styled.li<StyledListType>`
  background-color: ${({ selected, theme }) =>
    selected ? theme.background.secondary : theme.background.tertiary};
  color: ${({ selected, theme }) => (selected ? theme.text.whiteText : theme.colors.text)};
  height: 36px;
  padding: 8px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: break-word;
  cursor: pointer;
  border: ${({ theme }) => theme.border.defaultBorder};
`;

interface ArrowGradientProps {
  bgColor?: string;
  gradientPercent?: number;
}
const ArrowGradient = (side: 'right' | 'left', props: ArrowGradientProps) => {
  const { bgColor, gradientPercent = 40 } = props;
  return css`
    background: linear-gradient(to ${side}, transparent 0%, ${bgColor} ${gradientPercent}%);
  `;
};

const StyledArrow = styled.div<ArrowGradientProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  top: 0;
  width: 40px;

  &.pos-left {
    left: 0;
    align-items: flex-start;
    ${(props) => ArrowGradient('left', props)}
  }

  &.pos-right {
    right: 0;
    align-items: flex-end;
    ${(props) => ArrowGradient('right', props)}
  }
`;
