import React from 'react';
import styled from 'styled-components';

export type VerticalAlign = 'top' | 'bottom' | 'center';
export type HorizontalAlign = 'left' | 'right' | 'center';

const getVerticalPosition = (position: VerticalAlign): string => {
  switch (position) {
    case 'top':
      return 'flex-start';
    case 'bottom':
      return 'flex-end';
    case 'center':
      return 'center';
    default:
      return 'flex-start';
  }
};

const getHorizontalPosition = (position: HorizontalAlign): string => {
  switch (position) {
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
    case 'center':
      return 'center';
    default:
      return 'flex-start';
  }
};

const AlignmentContainer = styled.div<PropsType>`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: ${(props): string => getHorizontalPosition(props.horizontal)};
  align-items: ${(props): string => getVerticalPosition(props.vertical)};
`;

type PropsType = {
  horizontal?: HorizontalAlign;
  vertical?: VerticalAlign;
};

export const Alignment: React.FunctionComponent<React.PropsWithChildren<PropsType>> = ({
  vertical = 'top',
  horizontal = 'left',
  children,
}) => {
  return <AlignmentContainer {...{ vertical, horizontal }}>{children}</AlignmentContainer>;
};

export default Alignment;
