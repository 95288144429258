import moment from 'moment';
import { DateUtils } from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import { DateRangeType as RangeType } from '../../types/internal/DateRangeType';
// @ts-ignore
const { parseDate: parseDatePicker, formatDate: formatDatePicker } = MomentLocaleUtils;

/**
 * Standard date format.
 * @type {string}
 */
export const DATE_FORMAT = 'DD/MM/YYYY';

/**
 * Order matters here!
 * It always chooses the first matched format while parsing and formatting.
 * @type {string[]}
 */
export const DATE_FORMATS = [
  'D MMM YYYY',
  'D MMMM YYYY',
  'DD MMM YYYY',
  'DD MMMM YYYY',
  'DD/MM/YYYY',
  'DD/MM/YY',
];

/**
 * Create a numeric dayrange, based on the original implementation from before.
 * @param start
 * @param end
 * @return {{fromDate: number, toDate: number}}
 */
export const createDayRangeNumeric = (start: number, end = 0): RangeType => ({
  fromDate: moment(new Date()).subtract(start, 'days').startOf('day').unix(),

  toDate: moment(new Date()).subtract(end, 'days').endOf('day').unix(),
});

export type DateRangePresetType = {
  [K in string]: {
    name: string;
    value: RangeType;
  };
};

/**
 * An interface to lookup on for getting label and it's related values
 * @type {{
 * Today: {name: string, value: RangeType},
 * Next_7_days: {name: string, value: RangeType},
 * Next_30_days: {name: string, value: RangeType},
 * Yesterday: {name: string, value: RangeType},
 * Past_30_days: {name: string, value: RangeType},
 * Past_7_days: {name: string, value: RangeType}
 * }}
 */
export const dateRangePresets: DateRangePresetType = {
  today: {
    name: 'Today',
    value: createDayRangeNumeric(0),
  },
  yesterday: {
    name: 'Yesterday',
    value: createDayRangeNumeric(1),
  },
  past_7_days: {
    name: 'Past 7 days',
    value: createDayRangeNumeric(7),
  },
  past_30_days: {
    name: 'Past 30 days',
    value: createDayRangeNumeric(30),
  },
};

/**
 * Return a moment formatted date
 * If dates are unselected return an empty string.
 * @param date
 * @return {string}
 */
export const formatDate = (date: Date | null): string => {
  if (date === null) {
    return '';
  }
  return formatDatePicker(date, DATE_FORMATS);
};

/**
 * Date range to display
 * @param from
 * @param to
 * @return {string}
 */
export const formatDateRange = (from: Date, to: Date): string => {
  if (from && !to) {
    return moment(from).format(DATE_FORMAT);
  }
  if (to && !from) {
    return moment(to).format(DATE_FORMAT);
  }
  if (isDayBefore(from, to)) {
    return formatDateRange(to, from);
  }
  return `${moment(from).format(DATE_FORMAT)} - ${moment(to).format(DATE_FORMAT)}`;
};

/**
 * A SegmentType Object creator
 * @param items
 * @return {{name: string, id: number}[]}
 */
export const getSegmentItems = (items: DateRangePresetType): { id: number; name: string }[] =>
  Object.keys(items).map((p: string, index: number): {
    id: number;
    name: string;
  } => ({
    id: index,
    name: items[p].name,
  }));

/**
 * Find the selected segment item.
 * @param items
 * @param item
 * @return {Object|{name: string, id: number}|{}}
 */
export const getSegmentValue = (items: DateRangePresetType, item: string): Record<string, any> =>
  items[item] ? getSegmentItems(items).find((i) => i.name === items[item].name) : {};

/**
 * A wrapper around react-day-picker's date parser with specified format
 * @param str
 */
// @ts-ignore
export const parseDate = (str: string | null): Date => parseDatePicker(str, DATE_FORMATS);

/**
 * If showPresetAsRange is enabled and from/to dates are valid, format it, else show the label.
 * @param {DateRangePresetType} presets
 * @param {boolean} showPresetAsRange
 * @param {string} from
 * @param {string} to
 * @param {string} selectedPreset
 * @return {string} formattedRange
 */
export const getPresetRange = (
  presets: DateRangePresetType,
  showPresetAsRange: boolean,
  selectedPreset: string | null,
  from: Date,
  to: Date,
): string =>
  /**
   * Undefined dates were resolving to current date and modifying the storyshots everyday.
   */ showRangeOrLabel(selectedPreset, showPresetAsRange)
    ? from == null && to == null
      ? ''
      : formatDateRange(from, to)
    : getSelectedPreset(presets, selectedPreset);

export const showRangeOrLabel = (selected: string, asRange: boolean): boolean =>
  selected == null || asRange;

/**
 * Looks up dateRangePresets for provided input
 * @param preset
 * @param {DateRangePresetType} presets
 * @return {*}
 */
export const getSelectedPreset = (presets: DateRangePresetType, preset: string | null): string =>
  preset ? presets[preset].name : '';

/**
 * Wrapper around calculateLeft to pass in viewportWidth, and a buffer by default.
 * @param {number} containerXaxis
 * @param {number} popupWidth
 * @param {number} buffer
 * @return {number}
 */
export const getAutoPlacementLeft = (
  containerXaxis: number,
  popupWidth: number,
  buffer = 24,
): number => calculateLeft(window.innerWidth, containerXaxis, popupWidth, buffer);

/**
 * calculates a left based on formula:
 * viewport width - (x axis + item width + buffer)
 * @param viewportWidth
 * @param containerXAxis
 * @param itemWidth
 * @param buffer
 * @return {number}
 */
export const calculateLeft = (
  viewportWidth: number,
  containerXAxis: number,
  itemWidth: number,
  buffer: number,
): number => viewportWidth - (containerXAxis + itemWidth + buffer);

export const getUnixTimestampFromDay = (date: Date): number => moment(date).startOf('day').unix();

export const getUnixTimestampToDay = (date: Date): number => moment(date).endOf('day').unix();

/**
 * A wrapper around DateUtils.isDayBefore
 * in all cases we check for the to date being before from.
 * @param from
 * @param to
 * @return {boolean}
 */
export const isDayBefore = (from: Date, to: Date): boolean =>
  from != null && to != null && DateUtils.isDayBefore(to, from);

export const findMatchedPreset = (from: Date, to: Date, presets: DateRangePresetType): string => {
  const unixFrom = from ? getUnixTimestampFromDay(from) : undefined;
  const unixTo = to ? getUnixTimestampToDay(to) : undefined;
  /**
   * When users pass in from/to dates,
   * lookup the presets object to find the preset within the matching range.
   * @type {void|[string, *]}
   */
  const result = Object.entries(presets).find(
    ([, preset]): boolean =>
      preset?.value?.fromDate === unixFrom && preset?.value?.toDate === unixTo,
  );
  return result ? result[0] : undefined;
};

/**
 * Atm we maintain presetRange as single source of truth, it's updated only when a user clicks
 * apply button. To reset the component back to previous dates, we use presetRange values
 * instead of relying on from/to or managing their "draft" versions internally.
 * @param presetRange
 * @param delimiter
 * @return {{from: Date, to: Date}}
 */
export const reconstructDatesFromRange = (
  presetRange: string,
  delimiter: string,
): {
  from: Date;
  to: Date;
} => ({
  from: parseDate(presetRange.substr(0, presetRange.indexOf(delimiter)).trim()),

  to: parseDate(presetRange.substr(presetRange.indexOf(delimiter) + 1).trim()),
});
