import React from 'react';
import IconStyle from '../IconStyle';
import IconPropsType from '../types';
import IconSvg from './Wifi.svg';

const IconWifi: React.FunctionComponent<IconPropsType> = (props) => {
  return (
    <IconStyle {...props}>
      <IconSvg {...props} aria-label="wifi" />
    </IconStyle>
  );
};

export default IconWifi;
