import React from 'react';
import styled from 'styled-components';
import Button from '../../Button';
import Group from '../../Group';
import TextInput from '../../TextInput';
import Loading from '../../Loading';

import { HelpPanelStateType, RequiredType, HelpPanelUserDetailsType } from '../types';
import { StateType } from './types';

import validateForm from './EditViewHelpers/validateForm';
import disableConfirmButton from './EditViewHelpers/disableConfirmButton';

type PropsType = {
  formState?: HelpPanelStateType;
  onDetailChanges?: (value: HelpPanelUserDetailsType) => Promise<void>;
  onEditFormChanges?: (formDetails: Record<string, any>) => void;
  prevPage?: () => void;
  required?: RequiredType;
};

class FormEditView extends React.Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
      invalidName: false,
      invalidEmail: false,
      invalidPhoneNo: false,
      isLoading: false,
    };
  }

  UNSAFE_componentWillMount() {
    const { name, email, phone } = this.props.formState;
    this.setState({ name, email, phone });
  }

  onDetailChanges = () => {
    this.props.onEditFormChanges(this.state);

    if (this.props.onDetailChanges) {
      this.setState({ isLoading: true }, () => {
        const { name, email, phone } = this.state;
        const values = { name, email, phone };

        new Promise((resolve: any): void => {
          resolve(this.props.onDetailChanges(values));
        }).then((): void => this.props.prevPage());
      });
    } else {
      this.props.prevPage();
    }
  };

  onFormChanges = <T extends keyof StateType>(key: T, value: StateType[T]) => {
    const { invalidName, invalidEmail, invalidPhoneNo } = this.state;
    const invalidObject = {
      invalidName,
      invalidEmail,
      invalidPhoneNo,
    };
    this.setState({ [key]: value }, (): void =>
      validateForm.call(this, invalidObject, this.props.required, key, value),
    );
  };

  render() {
    const { name, invalidName, email, invalidEmail, phone, invalidPhoneNo, isLoading } = this.state;
    return (
      <FormContainer>
        <TextInput
          label="Name"
          placeholder="Enter your name"
          onChange={(value: string) => {
            this.onFormChanges('name', value);
          }}
          initialValue={name}
          error={invalidName}
        />
        <TextInput
          label="Email"
          placeholder="Enter your email address"
          onChange={(value: string) => {
            this.onFormChanges('email', value);
          }}
          initialValue={email}
          error={invalidEmail}
        />
        <TextInput
          label="Phone number"
          placeholder="Enter your phone number"
          onChange={(value: string) => {
            this.onFormChanges('phone', value);
          }}
          initialValue={phone}
          error={invalidPhoneNo}
        />
        <Button
          fullWidth
          primary
          disabled={disableConfirmButton(this.state, this.props.required)}
          onClick={this.onDetailChanges}
        >
          Confirm details
        </Button>
        {isLoading && <Loading overlay />}
      </FormContainer>
    );
  }
}

const FormContainer = styled(Group)`
  && {
    width: 100%;
  }
`;

export default FormEditView;
