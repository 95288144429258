import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { getVariationName } from '../../utils/getVariationName';
import { declareVariants } from '../../utils/declareVariants';
import { TypographyProps, typographyStyleFn, boldStyleFn, BoldProps } from '../../utils/styleFns';

type Variants = 'hint';

type PropsType = TypographyProps &
  BoldProps & {
    /**
     * **Deprecated:** This should not be necessary
     * @deprecated
     */
    allowWhitespace?: boolean;
    /**
     * **Deprecated:** Use variant instead
     * @deprecated
     */
    hint?: boolean;
    variant?: Variants;
  };

const StyledParagraph = styled.p<
  {
    allowWhitespace?: boolean;
    bold?: boolean;
    color?: string;
    variant?: Variants;
  } & TypographyProps
>`
  font-family: ${({ theme }) => theme.fonts.sans};
  font-size: ${({ theme }) => theme.fontSizes.base};
  line-height: ${({ theme }) => theme.lineHeights.base};
  color: ${({ theme }) => theme.colors.text};
  white-space: ${({ allowWhitespace }) => (allowWhitespace ? 'pre-wrap' : 'inherit')};
  margin: 0;
  padding: 0;

  ${typographyStyleFn}
  ${boldStyleFn}

  ${declareVariants({
    hint: css`
      color: ${({ theme }) => theme.colors.textSubdued};
    `,
  })}
`;

export const Paragraph: React.FunctionComponent<
  React.PropsWithChildren<
    React.PropsWithChildren<PropsType & HTMLAttributes<HTMLParagraphElement> & TypographyProps>
  >
> = ({
  align = 'left',
  hint = false,
  bold = false,
  display = 'block',
  variant,
  color,
  allowWhitespace,
  ...restProps
}) => {
  const derivedVariant = getVariationName<Variants>({ hint }, variant);

  return (
    <StyledParagraph
      allowWhitespace={allowWhitespace}
      align={align}
      bold={bold}
      variant={derivedVariant}
      color={color}
      display={display}
      {...restProps}
    />
  );
};

export default Paragraph;
