import React, { useMemo } from 'react';
import styled from 'styled-components';
import Joyride from 'react-joyride';
import Label from '../Label';
import Paragraph from '../Paragraph';

type PropsType = {
  message: string;
  show?: boolean;
  target: string;
  width?: number;
};

const defaultFloaterProps = {
  disableAnimation: true,
};

export default function Popover({ message, show = false, target, width = 400 }: PropsType) {
  const steps = useMemo(
    () => [
      {
        target,
        content: message,
        disableBeacon: true,
        hideCloseButton: true,
        showSkipButton: false,
        styles: {
          options: {
            width,
          },
        },
      },
    ],
    [target, message],
  );

  return (
    <Joyride
      steps={steps}
      run={show}
      disableOverlay
      scrollToFirstStep
      spotlightPadding={0}
      tooltipComponent={ToolTip}
      floaterProps={defaultFloaterProps}
    />
  );
}

const ToolTip = ({ step, tooltipProps }: Record<string, any>): JSX.Element => (
  <div {...tooltipProps} style={{ ...step.styles.options }}>
    <TooltipBody {...tooltipProps}>
      {step.title && <Label>{step.title}</Label>}
      <Paragraph>{step.content}</Paragraph>
    </TooltipBody>
  </div>
);

const TooltipBody = styled.div`
  position: relative;
  background-color: ${({ theme }): string => theme.card.backgroundColor};
  padding: ${({ theme }): string => theme.card.padding};
  box-shadow: ${({ theme }): string => theme.card.shadow};
  border-radius: ${({ theme }): string => theme.border.radius};
`;
