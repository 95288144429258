import React from 'react';
import { useCallbackOnOuterClick } from '../../hooks';

type PropsType = {
  onClickOutside?: () => void;
};

export const PopupBoundary: React.FunctionComponent<React.PropsWithChildren<PropsType>> = ({
  onClickOutside = () => null,
  children,
}) => {
  const [popupContainer] = useCallbackOnOuterClick(onClickOutside);

  return (
    // Popup has the existing behaviour of swallowing all events, so we must assign it a listener
    // eslint-disable-next-line
    <div onClick={stopPropagation} ref={popupContainer}>
      {children}
    </div>
  );
};

function stopPropagation(e: React.MouseEvent<HTMLDivElement>) {
  e.stopPropagation();
  e.nativeEvent.stopImmediatePropagation();
}

export default PopupBoundary;
