import React, { PureComponent } from 'react';

import { sha256 as sha } from 'js-sha256';

import Tile from './Tile';

import { TileType, ModeType } from './types';

type PropsType = {
  coverTile: boolean;
  /**
   * Maximum number of selectable tiles
   */
  maxCount: number;
  mode: ModeType;
  multiSelect: boolean;
  onSelect: (item: number[]) => void;
  ordered: boolean;
  tiles: TileType[];
};

type TileStateType = {
  selectedItems: number[];
};

export default class Tiles extends PureComponent<PropsType, TileStateType> {
  static defaultProps = {
    tiles: [],
    multiSelect: false,
    ordered: false,
    onSelect: () => {},
    coverTile: false,
    maxCount: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
    };
  }

  componentDidMount() {
    const { tiles, maxCount } = this.props;
    const selectedItems = getSelectedTiles(tiles);

    // Setting selectedItems length to maxCount would also add in extra undefined elements if
    // it's more than the actual selectedItems.
    if (maxCount != null) selectedItems.length = Math.min(selectedItems.length, maxCount);

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ selectedItems });
  }

  selectItem = (e: React.MouseEvent<HTMLDivElement>, currentItem: number) => {
    const { multiSelect } = this.props;
    const selectedItem = this.findItem(currentItem);

    if (selectedItem === currentItem) {
      this.deselectItem(currentItem);
    } else {
      if (!multiSelect) {
        this.setState({ selectedItems: [currentItem] }, this.onSelect);
        return;
      }
      this.onMultiSelect(currentItem);
    }
  };

  onMultiSelect = (currentItem: number) => {
    const { maxCount } = this.props;
    const { selectedItems } = this.state;

    if (maxCount == null || selectedItems.length < maxCount) {
      this.setState(
        (prevState: TileStateType): TileStateType => ({
          selectedItems: [...prevState.selectedItems, currentItem],
        }),
        this.onSelect,
      );
    }
  };

  onSelect = (): void => this.props.onSelect(this.state.selectedItems);

  deselectItem = (item: number) => {
    this.setState(
      (prevState: TileStateType): TileStateType => ({
        selectedItems: prevState.selectedItems.filter((x: number): boolean => x !== item),
      }),
      this.onSelect,
    );
  };

  findItem = (key: number): number | undefined =>
    this.state.selectedItems.find((item: number): boolean => item === key);

  render(): JSX.Element {
    return (
      <>
        {this.props.tiles.map((tile: TileType) => {
          const selectedItem = this.findItem(tile.id);
          const itemIndex = this.state.selectedItems.indexOf(tile.id);
          return (
            <Tile
              id={tile.id}
              key={`${tile.id}_${sha(tile.url || '')}`}
              tile={tile}
              onSelect={this.selectItem}
              selectedItem={selectedItem}
              itemIndex={itemIndex}
              currentMode={this.props.mode}
              ordered={this.props.ordered}
              coverTile={this.props.coverTile}
            />
          );
        })}
      </>
    );
  }
}

const getSelectedTiles = (tiles: TileType[]): number[] => {
  const selectedTiles = [];
  tiles.forEach(({ id, selected }: TileType) => {
    if (selected) {
      selectedTiles.push(id);
    }
  });
  return selectedTiles;
};
