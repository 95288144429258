/**
 * A Generic on EnterKeyPress
 * @param e
 * @param callback
 */
export const onEnterKeyPress: (e: React.KeyboardEvent<any>, callback: () => void) => void = (
  e,
  callback,
) => {
  if (e.keyCode === 13) callback();
};

export const isEmptyString = (value: string): boolean => !value.trim();
