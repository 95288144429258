import React, { useMemo } from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import styled from 'styled-components';
import 'react-multi-email/style.css';
import { validateEmail } from '../../validation/validators/stringValidator';
import Tag from '../Tag';
import { Spacer } from '..';
import { DataPropsType, getDataAttributes } from '../../utils/dataAttributes';

type PropsType = DataPropsType & {
  emails?: string[];
  /*
   * should the emails appear external to the input
   */
  external?: boolean;
  /*
   * mandatory emails can't be removed
   */
  mandatoryEmails?: string[];
  onChange?: (emails: string[]) => void;
  placeholder?: string;
};

const MultiEmailInput: React.FC<PropsType> = ({
  placeholder = 'Enter email',
  emails = [],
  mandatoryEmails = [],
  onChange = () => {},
  external = false,
  data,
}) => {
  const allEmails: string[] = useMemo(
    () => Array.from(new Set([...(mandatoryEmails || []), ...(emails || [])])),
    [emails, mandatoryEmails],
  );

  function removeAnEmail(email: string) {
    onChange(allEmails.filter((x) => x !== email));
  }

  function handleChange(updatedEmails: string[]) {
    const changedEmails = external ? [...allEmails, ...updatedEmails] : updatedEmails;
    if (onChange) {
      onChange(changedEmails);
    }
  }

  return (
    <StyledMultiEmail external={external} {...getDataAttributes(data)}>
      <ReactMultiEmail
        placeholder={placeholder}
        emails={external ? [] : allEmails}
        onChange={handleChange}
        validateEmail={(email: string): boolean => validateEmail(email).isValid}
        getLabel={(email: string, index: number, removeEmail: (index: number) => void) =>
          !external && (
            <Tag
              showClose={!mandatoryEmails || !mandatoryEmails.includes(email)}
              key={index}
              onClose={
                !mandatoryEmails || !mandatoryEmails.includes(email)
                  ? () => removeEmail(index)
                  : undefined
              }
            >
              {email}
            </Tag>
          )
        }
      />
      {external && allEmails.length > 0 && (
        <TagBoundary data-chamid="MultiEmailInput__ExternalEmails">
          <Spacer spacing="s16" />
          {allEmails.map((email: string) => (
            <TagSpacer key={email}>
              <Tag
                showClose={!mandatoryEmails || !mandatoryEmails.includes(email)}
                onClose={
                  !mandatoryEmails || !mandatoryEmails.includes(email)
                    ? () => removeAnEmail(email)
                    : undefined
                }
              >
                {email}
              </Tag>
            </TagSpacer>
          ))}
        </TagBoundary>
      )}
    </StyledMultiEmail>
  );
};

const TagBoundary = styled.div`
  overflow: hidden;
`;

const TagSpacer = styled.div`
  margin-bottom: 4px;
  display: inline-block;
`;

const StyledMultiEmail = styled.div<{ external: boolean }>`
  width: 100%;

  .react-multi-email {
    background: ${({ theme }): string => theme.form.backgroundColor};
    border-radius: 4px;
    padding: 7px;

    input {
      float: inherit;
      font: ${({ theme }): string => theme.text.body};
      color: ${({ theme }): string => theme.colors.text};
      background: ${({ theme }): string => theme.form.backgroundColor};
      padding: 3px 6px !important;

      ${({ external }) => (external ? 'width: 100% !important' : undefined)}
    }

    &.focused {
      padding: 6px;
      border: ${({ theme }): string => theme.form.focusedBorder};
    }

    &.empty {
      padding: 10px;
      input {
        padding: 0 !important;
      }
      span[data-placeholder] {
        position: absolute;
        top: 4px;
        font: ${({ theme }): string => theme.text.body};
        color: ${({ theme }): string => theme.text.hint};
        padding: 7px 4px;
      }
      &.focused {
        padding: 9px;
      }
    }
  }
`;

export default MultiEmailInput;
