import React, { useContext, useState } from 'react';

import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { HelpContext } from './HelpContext';
import Group from '../Group';
import Heading from '../Heading';

import Label from '../Label';
import Link from '../Link';
import Search from '../Search';
import { IconEmail, IconArrowUp, IconArrowDown } from '../Icon';

import { HelpPanelItemType, NewFeatureItemType } from './types';
import { theme as Theme } from '../../themes';
import { getDataAttributes } from '../../utils/dataAttributes';

import { NewFeatureItems } from './NewFeatureItems';
import Divider from '../Divider';
import Paragraph from '../Paragraph';
import Spacer from '../Spacer';

type PropsType = {
  newFeatureItems?: NewFeatureItemType[];
  onEnterKeyPress?: () => void;
  onPageChange?: () => void;
  onSearchChanges?: (value: string) => void;
  relatedItems?: HelpPanelItemType[];
  searchValue?: string;
  showEmailSupport?: boolean;
  showNewFeatureSection: boolean;
  supportInfo?: HelpPanelItemType[];
  supportItems?: HelpPanelItemType[];
  troubleShootingItems?: HelpPanelItemType[];
};

const SEARCH_PLACEHOLDER = 'Search our knowledge base';
const RELATED_ITEMS_HEADING = 'Related to this page';
const SUPPORT_ITEMS_HEADING = '24/7 Help & Support';
const EMAIL_SUPPORT = 'Email us your question';

const getEmailSupport = (onPageChange: () => void): HelpPanelItemType => ({
  id: uuidv4(),
  value: EMAIL_SUPPORT,
  icon: <IconEmail />,
  action: onPageChange,
});

const MainView: React.FunctionComponent<PropsType> = ({
  supportItems,
  showEmailSupport,
  onPageChange,
  showNewFeatureSection,
  ...props
}) => {
  const allSupportItems = [...supportItems];

  if (showEmailSupport) {
    allSupportItems.push(getEmailSupport(onPageChange));
  }
  const onClearSearch = (): void => props.onSearchChanges('');

  const { showOldFeatures } = useContext(HelpContext);

  return (
    <>
      <HelpGroup>
        <Search
          placeholder={SEARCH_PLACEHOLDER}
          onChange={props.onSearchChanges}
          onClear={onClearSearch}
          searchText={props.searchValue}
          onEnterKeyPress={props.onEnterKeyPress}
        />

        {!showOldFeatures && props.relatedItems && (
          <HelpItemList heading={RELATED_ITEMS_HEADING} items={props.relatedItems} />
        )}
        {!showOldFeatures && allSupportItems && (
          <>
            <HelpItemList
              heading={SUPPORT_ITEMS_HEADING}
              items={allSupportItems}
              isSupportComponent
            />
          </>
        )}
      </HelpGroup>
      {showNewFeatureSection && (
        <HelpGroup>
          <NewFeatureItems />
        </HelpGroup>
      )}
      <SupportInfoBar>
        <Group spacing="8px">
          {props.troubleShootingItems &&
            props.troubleShootingItems.map((item: HelpPanelItemType) => {
              return (
                <ListItem
                  key={item.id}
                  onClick={(e: Event) => item.action(e)}
                  {...getDataAttributes(item.data)}
                >
                  <Label>{item.name}</Label>
                </ListItem>
              );
            })}
        </Group>
      </SupportInfoBar>
    </>
  );
};

type HelpItemListPropsType = {
  heading: string;
  isSupportComponent?: boolean;
  items: HelpPanelItemType[];
};

const HelpItemList = ({ items, heading, isSupportComponent }: HelpItemListPropsType): any => {
  const [showMore, setShowMore] = useState(false);
  const itemsRecieved = items ?? [];
  const itemsToShow = showMore ? itemsRecieved : [items[0]];
  const visibleItems = isSupportComponent ? itemsToShow : itemsRecieved;
  return (
    <>
      {isSupportComponent && <Divider />}
      {isSupportComponent && <Spacer />}
      <Heading level={3}>{heading}</Heading>
      <Spacer spacing="s12" />
      <StyledUl>
        {visibleItems.map((item: HelpPanelItemType) => {
          const iconWithColor =
            item.icon && React.cloneElement(item.icon, { color: Theme.colors.interactive });
          return (
            <li key={item.id}>
              <ListItem href={item.href} onClick={item.action} {...getDataAttributes(item.data)}>
                {iconWithColor}
                <Label className="label">{item.value}</Label>
              </ListItem>
            </li>
          );
        })}
      </StyledUl>
      {isSupportComponent && (
        <>
          <Spacer spacing="s8" />
          {showMore ? (
            <ButtonContainer onClick={() => setShowMore(false)}>
              <IconArrowUp color={Theme.colors.interactive} />
              <Paragraph bold color={Theme.colors.interactive}>
                Less options
              </Paragraph>
            </ButtonContainer>
          ) : (
            <ButtonContainer onClick={() => setShowMore(true)}>
              <IconArrowDown color={Theme.colors.interactive} />
              <Paragraph bold color={Theme.colors.interactive}>
                More options
              </Paragraph>
            </ButtonContainer>
          )}
        </>
      )}
      {isSupportComponent && <Spacer />}
      {isSupportComponent && <Divider />}
    </>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const StyledUl = styled.ul`
  &&& {
    list-style-type: none;
    display: contents;
  }
`;

const ListItem = styled(Link)`
  &&& {
    display: flex;
    justify-content: flex-start;
    padding: 12px 0;
    &:hover {
      cursor: pointer;
      .label {
        color: ${({ theme }): string => theme.link.secondary.hoverColor};
      }
    }
    span + .label {
      margin-left: 8px;
      color: ${({ theme }): string => theme.text.labelColor};
    }
  }
`;

const HelpGroup = styled(Group)`
  &&& {
    width: 100%;
  }
`;

const SupportInfoBar = styled.div`
  &&& {
    margin-top: auto;
  }
`;

export default MainView;
