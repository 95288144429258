import styled from 'styled-components';
import React, { InputHTMLAttributes } from 'react';

import { borderDefault, borderFocused } from '../../mixins/borders';

const StyledInput = styled.input`
  background-color: ${({ theme, disabled }): string =>
    disabled ? theme.form.inactiveBackgroundColor : theme.form.backgroundColor};
  padding: 0 40px;
  height: 48px;
  width: 100%;
  border-radius: ${({ theme }): string => theme.border.radius};
  ${borderDefault};
  font-family: ${({ theme }) => theme.fonts.sans};
  font-size: ${({ theme }) => theme.fontSizes.base};
  line-height: ${({ theme }) => theme.lineHeights.base};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  -webkit-font-smoothing: inherit; // Override existing Kounta css

  &::placeholder {
    color: ${({ theme }): string => theme.form.hint.color};
  }

  &:focus-within {
    ${borderFocused};
  }
`;

const Input = React.forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>(
  ({ ...restProps }, forwardedRef) => (
    <StyledInput data-chamid="Input" ref={forwardedRef} {...restProps} />
  ),
);

export default Input;
