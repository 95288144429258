import React, { forwardRef, Component } from 'react';
import styled from 'styled-components';
import { VariableSizeList as List, shouldComponentUpdate } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import Loading from '../Loading';
import Link from '../Link';

type PropsType = {
  fetchCount?: number;
  hasNextPage?: () => void;
  height?: number;
  innerRef?: Record<string, any>;
  isNextPageLoading?: boolean;
  itemHeight?: number;
  itemHeights?: Record<string, any>;
  items?: Record<string, any>[];
  loadNextPage?: (numberOfItems: number, totalItems: number) => void;
  onScroll?: () => void;
  pageSize?: number;
  setRef?: (ref: Record<string, any>) => void;
  threshold?: number;
  totalItemCount?: number;
  width?: number;
};

class InfinityList extends Component<PropsType, any> {
  shouldComponentUpdate = shouldComponentUpdate.bind(this);

  loadMore = (): void =>
    this.props.loadNextPage(this.props.items.length, this.props.items.length + this.props.pageSize);

  itemSize = (index: number): number => this.props.itemHeights[index] || this.props.itemHeight;

  // Every row is loaded except for our loading indicator row.
  isItemLoaded = (index: number): boolean =>
    !this.props.hasNextPage || index < this.props.items.length;

  useARef = (value: Record<string, any>, infiniteLoaderRef: Function) => {
    infiniteLoaderRef(value);
    this.props.setRef(value);
  };

  render(): JSX.Element {
    const {
      items,
      hasNextPage,
      isNextPageLoading,
      loadNextPage,
      totalItemCount,
      fetchCount,
      threshold,
      height: listHeight,
      width: listWidth,
      onScroll,
    } = this.props;

    // If there are more items to be loaded then add an extra row
    // to hold a loading indicator / load more button else use props.totalItemCount
    const itemCount = hasNextPage ? items.length + 1 : totalItemCount;

    // Only load 1 page of items at a time.
    // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
    const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

    return (
      <InfiniteLoader
        isItemLoaded={this.isItemLoaded}
        itemCount={itemCount}
        loadMoreItems={loadMoreItems}
        threshold={threshold}
      >
        {({ onItemsRendered, ref }: Record<string, any>): JSX.Element => (
          <>
            <List
              height={listHeight}
              itemCount={itemCount}
              estimatedItemSize={200}
              itemSize={this.itemSize}
              onItemsRendered={onItemsRendered}
              ref={(value: Record<string, any>) => this.useARef(value, ref)}
              width={listWidth}
              onScroll={onScroll}
            >
              {(listItemProps: Record<string, any>): JSX.Element => (
                <Item
                  {...listItemProps}
                  items={this.props.items}
                  isItemLoaded={this.isItemLoaded}
                  fetchCount={fetchCount}
                  loadMore={this.loadMore}
                />
              )}
            </List>
          </>
        )}
      </InfiniteLoader>
    );
  }
}

const Item = (props: Record<string, any>): JSX.Element => {
  const { index, style, items, isItemLoaded, fetchCount, loadMore } = props;
  let content = items[index];

  if (!isItemLoaded(index) && fetchCount === 1) {
    content = <LoadMoreButton action={loadMore} />;
  } else if (!isItemLoaded(index)) {
    content = <Loader />;
  }
  return <div style={style}>{content}</div>;
};

const LoadMoreButton = ({
  action,
}: {
  action: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => (
  <ListAction>
    <Link onClick={action}>Load more</Link>
  </ListAction>
);

const Loader = () => (
  <ListAction>
    <Loading overlay={false} size={24} showTitle={false} />
  </ListAction>
);

const ListAction = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export default forwardRef((props, ref) => <InfinityList {...props} innerRef={ref} />);
