import React from 'react';
import Container from '../Container';
import Group from '../Group';
import { Heading2 } from '../Heading';
import Paragraph from '../Paragraph';

type PropsType = {
  children?: JSX.Element | null;
  footer?: JSX.Element | null;
  heading?: string;
  subheading?: string;
  verticallyCentered?: boolean;
};

export default function EmptyState(props: PropsType): JSX.Element {
  return (
    <Container centered verticallyCentered={props.verticallyCentered}>
      <Group spacing="32px" center>
        {props.children}
        <Group spacing="8px" center align="center">
          <Heading2 align="center">{props.heading}</Heading2>
          <Paragraph align="center" allowWhitespace>
            {props.subheading}
          </Paragraph>
        </Group>
        <>{props.footer}</>
      </Group>
    </Container>
  );
}

EmptyState.defaultProps = {
  heading: 'Title',
  subheading: '',
  verticallyCentered: true,
  children: null,
  footer: null,
};
