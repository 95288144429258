import React from 'react';
import styled, { css } from 'styled-components';
import LightspeedSvg from './Lightspeed.svg';
import { colors } from '../../themes';

type PropsType = {
  height?: number | 'auto';
  isWhite?: boolean;
  /** **Deprecated** it is against brand guidelines to rotate Lightspeed logo sideways
   * @deprecated
   * */
  rotateSideways?: boolean;
  viewBox?: string;
  width?: number;
};

export const Logo: React.FunctionComponent<PropsType> = ({
  isWhite,
  rotateSideways,
  width,
  height,
  ...restProps
}) => (
  <LogoWrapper {...{ isWhite, rotateSideways, width, height }}>
    <LightspeedSvg {...{ width, height, ...restProps }} aria-label="lightspeed" />
  </LogoWrapper>
);

Logo.defaultProps = {
  width: 99,
  height: 24,
  viewBox: '0 0 99 24',
};

type LogoWrapperProps = {
  height: number | 'auto';
  isWhite?: boolean;
  rotateSideways?: boolean;
  width: number;
};

const rotationStyle = ({ rotateSideways, height, width }: LogoWrapperProps) =>
  rotateSideways
    ? css`
        transform: rotate(-90deg) translate(-${width}px, 0);
        transform-origin: top left;
        height: ${width}px;
        width: ${height}px;
      `
    : null;

const LogoWrapper = styled.div<LogoWrapperProps>`
  fill: ${({ isWhite, theme }) => (isWhite ? colors.white : theme.logo.textColor)};

  ${rotationStyle};
`;

export default Logo;
