import React, { Children } from 'react';
import styled from 'styled-components';

const ListStyle = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const ListItem = styled.li<{ isOrdered?: boolean; itemNumber?: number }>`
  &::before {
    margin-right: 8px;

    ${({ isOrdered, itemNumber, theme }) =>
      isOrdered
        ? `
          content: '${itemNumber}.';
          font-size: ${theme.fontSizes.base};
        `
        : `
          content: '\\2022';
          font-size: 18px;
          font-weight: ${theme.fontWeights.bold};
        `}
  }
`;

const ListItemPosition = styled.span`
  display: inline-block;
`;

type PropsType = {
  ordered?: boolean;
};

const List: React.FunctionComponent<React.PropsWithChildren<PropsType>> = ({
  ordered,
  children,
}) => {
  return (
    <ListStyle as={ordered ? 'ol' : 'ul'}>
      {Children.toArray(children).map((child: JSX.Element, index: number) => (
        <ListItem key={index} isOrdered={ordered} itemNumber={index + 1}>
          <ListItemPosition>{child}</ListItemPosition>
        </ListItem>
      ))}
    </ListStyle>
  );
};

export default List;
