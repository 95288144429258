import React from 'react';

type PropsType = {
  onClose?: () => void;
  onEscape?: () => void;
};

const Escaper: React.FunctionComponent<React.PropsWithChildren<PropsType>> = ({
  onEscape,
  onClose,
  children,
}) => {
  const callback = onEscape || onClose;
  const onKeyDown = (e: React.KeyboardEvent): void => {
    if (e.keyCode === 27) {
      callback();
    }
  };
  return (
    <div role="presentation" onKeyDown={onKeyDown}>
      {children}
    </div>
  );
};

Escaper.defaultProps = {
  onEscape: () => {},
};

/**
 * A Higher order function that takes in any component and returns a new component with Escaper.
 * To make it work with components that don't have an `onEscape` prop, use the existing `onClose`.
 */
export const withEscapeWillClose =
  <P extends any>(C: React.ComponentType<React.PropsWithChildren<P>>) =>
  (props: P & PropsType) =>
    (
      <Escaper onEscape={props.onEscape || props.onClose}>
        <C {...props} />
      </Escaper>
    );

/**
 * A higher order composer utility for react elements with two higher order functions.
 * @param {Function} hocA - First higher order function
 * @param {Function} hocB - Second hof
 * @return {function(Object): *} -
 * Results into a react element with virtual DOM node being:
 * <hocB><hocA><ChildComponent/></hocA></hocB>
 */
export const composeElement =  // eslint-disable-next-line @typescript-eslint/ban-types
  (hocA: Function, hocB: Function): Function =>
  (props: Record<string, any>): React.ReactNode =>
    hocB(hocA(props));

export default Escaper;
