import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Waypoint } from 'react-waypoint';
import { Spring } from 'react-spring/renderprops.cjs';
import { Heading3 } from '../Heading';

type PropsType = {
  children?: JSX.Element | null;
  leftContent?: JSX.Element | null;
  minimisedHeader?: JSX.Element | null;
  offset?: string;
  rightContent?: JSX.Element | null;
  showBottomBorder?: boolean;
  title?: string;
};

type StateType = {
  showMinimisedHeader: boolean;
};

export default class CollapsibleAppHeader extends PureComponent<PropsType, StateType> {
  static defaultProps = {
    showBottomBorder: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      showMinimisedHeader: false,
    };
  }

  onEnter = () => {
    this.setState({ showMinimisedHeader: false });
  };

  onLeave = () => {
    this.setState({ showMinimisedHeader: true });
  };

  render(): JSX.Element {
    const {
      children,
      showBottomBorder,
      offset,
      title,
      leftContent,
      rightContent,
      minimisedHeader,
    } = this.props;

    const { showMinimisedHeader } = this.state;

    return (
      <>
        <StyledCollapsibleAppHeader>
          <Waypoint onEnter={this.onEnter} onLeave={this.onLeave} topOffset={offset}>
            {children}
          </Waypoint>
        </StyledCollapsibleAppHeader>
        {showMinimisedHeader && (
          <Spring
            from={{
              opacity: 0,
            }}
            to={{
              opacity: 1,
            }}
            config={{
              duration: 300,
            }}
          >
            {(styleProps: Record<string, any>): JSX.Element => (
              <MinimisedHeader style={{ ...styleProps }} showBottomBorder={showBottomBorder}>
                {title && (
                  <StyledMinimisedHeader>
                    <Heading3>{title}</Heading3>
                  </StyledMinimisedHeader>
                )}
                {minimisedHeader && minimisedHeader}
              </MinimisedHeader>
            )}
          </Spring>
        )}
        {leftContent && <LeftContent>{leftContent}</LeftContent>}
        {rightContent && <RightContent>{rightContent}</RightContent>}
      </>
    );
  }
}

const StyledCollapsibleAppHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${({ theme }): string => theme.background.default};
  height: auto;
  box-shadow: ${(props: Record<string, any>): string =>
    props.showBottomBorder ? '0px 1px 8px rgba(0, 0, 0, 0.1)' : 'unset'};
`;

const LeftContent = styled.div`
  position: fixed;
  top: 0;
  left: 8px;
  height: 56px;
  display: flex;
  align-items: center;
`;

const RightContent = styled.div`
  position: fixed;
  top: 0;
  right: 8px;
  height: 56px;
  display: flex;
  align-items: center;
`;

const MinimisedHeader = styled.div<{ showBottomBorder: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }): string => theme.background.toolbar};
  height: auto;
  box-shadow: ${(props: Record<string, any>): string =>
    props.showBottomBorder ? '0px 1px 8px rgba(0, 0, 0, 0.1)' : 'unset'};
`;

const StyledMinimisedHeader = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
