import { StateType } from '../types';
import { RequiredType } from '../../types';

const disableConfirmButton = (stateObject: StateType, required?: RequiredType): boolean => {
  const { invalidName, invalidEmail, invalidPhoneNo, name, email, phone } = stateObject;

  let disabled = false;

  // If invalid items, disable it.
  if (invalidName || invalidEmail || invalidPhoneNo) disabled = true;

  // If name, email, or phone is required and is empty disable confirm button.
  if (required) {
    if (required.name && !name) disabled = true;
    if (required.email && !email) disabled = true;
    if (required.phone && !phone) disabled = true;
  }
  return disabled;
};

export default disableConfirmButton;
