import React from 'react';
import Button from '../Button';
import IconButton from '../IconButton';
import Link from '../Link';
import { IconMore } from '../Icon';
import { Mobile, TabletAndDesktop } from '../../utils/responsive';
import Group from '../Group';
import OverflowMenu from './OverflowMenu';
import { ActionListHeaderActionType } from './types';

type PropsType = {
  actions?: ActionListHeaderActionType[];
  showActionsOnMobile?: boolean;
  showMoreOnMobile?: boolean;
};

export default function ActionList(props: PropsType): JSX.Element {
  const [showOverflowMenu, setShowOverflowMenu] = React.useState(false);
  const { actions, showActionsOnMobile, showMoreOnMobile } = props;

  let hasOverflow = false;
  let hasPrimary = false;
  let visibleActions = false;

  const toggleOverflowMenu = () => {
    setShowOverflowMenu(!showOverflowMenu);
  };

  const actionRenderer = (onlyPrimary = false): JSX.Element[] | null => {
    const filterActions = (item: ActionListHeaderActionType): boolean => item.showAsAction;
    const mapActions = (actionItem: ActionListHeaderActionType, index): JSX.Element => {
      const commonProps = {
        key:
          actionItem.key || typeof actionItem.name === 'string'
            ? (actionItem.name as string)
            : `actionItem${index}`,
        disabled: !actionItem.enabled,
        onClick: actionItem.onClick,
      };
      /**
       * Handling primary button scenario
       */
      if (actionItem.primary || onlyPrimary) {
        return (
          <Button primary slim {...commonProps} type={actionItem.buttonType}>
            {actionItem.name}
          </Button>
        );
      }
      /**
       * Handling showActionAsIcon
       */
      if (actionItem.showActionAsIcon) {
        return (
          <IconButton {...commonProps} type={actionItem.buttonType}>
            {actionItem.icon}
          </IconButton>
        );
      }
      /**
       * Default
       */
      return (
        <Link primary {...commonProps} className="menuItem" destructive={actionItem.destructive}>
          {actionItem.name}
        </Link>
      );
    };
    return actions.filter(filterActions).map(mapActions);
  };

  if (actions) {
    visibleActions =
      actions.filter((item: ActionListHeaderActionType): boolean => item.visible).length > 0;
    hasOverflow =
      actions.filter(
        (item: ActionListHeaderActionType): boolean => item.visible && !item.showAsAction,
      ).length > 0;
    hasPrimary =
      actions.filter((item: ActionListHeaderActionType): boolean => item.visible && item.primary)
        .length > 0;
  }

  const renderMoreIcon = (
    <IconButton onClick={toggleOverflowMenu}>
      <IconMore />
    </IconButton>
  );

  /**
   * If it's primary, call actionRenderer with primary.
   * Show a Link if visibleActions
   * Default to actionRenderer
   * @type {Element[]}
   */
  const renderMobileAction = (): any => {
    if (showActionsOnMobile) {
      return actionRenderer();
    }

    const primaryActions = actionRenderer(true);
    return primaryActions && primaryActions.length && hasPrimary && visibleActions ? (
      <Link primary onClick={toggleOverflowMenu}>
        Actions
      </Link>
    ) : (
      primaryActions
    );
  };

  return (
    <div className="headerActions">
      <Mobile>
        <Group horizontal>
          {(!showMoreOnMobile || showActionsOnMobile) && renderMobileAction()}
          {showMoreOnMobile && renderMoreIcon}
        </Group>
      </Mobile>
      <TabletAndDesktop>
        <Group horizontal align="right">
          {actionRenderer()}
          {hasOverflow && (
            <IconButton onClick={toggleOverflowMenu}>
              <IconMore />
            </IconButton>
          )}
        </Group>
      </TabletAndDesktop>
      {showOverflowMenu && <OverflowMenu actions={actions} onClick={toggleOverflowMenu} />}
    </div>
  );
}

ActionList.defaultProps = {
  actions: [],
  showActionsOnMobile: false,
  showMoreOnMobile: false,
};
