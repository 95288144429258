import React from 'react';
import Responsive, { MediaQueryProps } from 'react-responsive';

export { ScreenClassProvider, ScreenClassRender, Visible, Hidden } from 'react-grid-system';

export const Mobile = (props: MediaQueryProps): JSX.Element => (
  <Responsive {...props} maxWidth={767} values={(window as any).testMediaQueryValues} />
);

export const MobileAndTablet = (props: MediaQueryProps): JSX.Element => (
  <Responsive {...props} maxWidth={1024} values={(window as any).testMediaQueryValues} />
);

export const Tablet = (props: MediaQueryProps): JSX.Element => (
  <Responsive
    {...props}
    minWidth={768}
    maxWidth={1024}
    values={(window as any).testMediaQueryValues}
  />
);

export const TabletAndDesktop = (props: MediaQueryProps): JSX.Element => (
  <Responsive {...props} minWidth={768} values={(window as any).testMediaQueryValues} />
);

export const Desktop = (props: MediaQueryProps): JSX.Element => (
  <Responsive {...props} minWidth={1025} values={(window as any).testMediaQueryValues} />
);

export type ResponsiveSizeType =
  | 'Mobile'
  | 'MobileAndTablet'
  | 'Tablet'
  | 'TabletAndDesktop'
  | 'Desktop';

export type ResponsiveQueryPropsType = {
  maximumWidth?: number;

  minimumWidth?: number;
  render?: any;
  responsiveSize?: ResponsiveSizeType;
};

const ResponsiveSizes = {
  Mobile: { minimumWidth: null, maximumWidth: 767 },
  MobileAndTablet: { minimumWidth: null, maximumWidth: 1024 },
  Tablet: { minimumWidth: 768, maximumWidth: 1024 },
  TabletAndDesktop: { minimumWidth: 768, maximumWidth: null },
  Desktop: { minimumWidth: 1025, maximumWidth: null },
};

export const ResponsiveQuery = (props: any): any => {
  const { onChange } = props;
  const { minimumWidth, maximumWidth } = props.responsiveSize
    ? ResponsiveSizes[props.responsiveSize]
    : props;

  return (
    <Responsive minWidth={minimumWidth} maxWidth={maximumWidth} onChange={onChange}>
      {(matches: boolean): void => props.render({ isMatch: matches })}
    </Responsive>
  );
};
