import React from 'react';
import styled from 'styled-components';

type PropsType = {
  children?: JSX.Element | null;
  leftMargin?: boolean;
  status: 'success' | 'alert';
};

export default function NotificationDot(props: PropsType): JSX.Element {
  return (
    <NotificationContainer leftMargin={props.leftMargin}>
      {props.children && props.children}
      <Dot status={props.status} />
    </NotificationContainer>
  );
}

NotificationDot.defaultProps = {
  children: null,
  status: 'alert',
  leftMargin: false,
};

const NotificationContainer = styled.div<{ leftMargin?: boolean }>`
  position: relative;
  width: max-content;
  margin-left: ${({ theme, leftMargin }) => (leftMargin ? theme.space.s24 : theme.space.s0)};
`;

const Dot = styled.div<{ status: 'success' | 'alert' }>`
  position: absolute;
  top: 2px;
  right: 2px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ status, theme }): string => theme.status[status].icon};
`;
