import React, { useContext } from 'react';

import styled from 'styled-components';
import moment from 'moment';
import Label from '../Label';
import Spacer from '../Spacer';
import Button from '../Button';

import Card from '../Card';
import Paragraph from '../Paragraph';
import Image from '../Image';

import { HelpContext } from './HelpContext';
import { NewFeatureItemType } from './types';

export const NewFeatureModal = () => {
  const { onClose, featureId, allProductFeatures, onConfirmModal, newFeaturesAvaliable } =
    useContext(HelpContext);

  const { releaseDate, imageLink, description, title, id } = allProductFeatures.find(
    (el: NewFeatureItemType): boolean => el.id === featureId,
  );

  const isNewFeature = newFeaturesAvaliable.find(
    (el: NewFeatureItemType): boolean => el.id === featureId,
  );

  const handleClick = () => {
    // Sending Ajax request to update the read history only if the new product feature was not read before.
    if (isNewFeature) {
      onConfirmModal(id);
    }
    onClose();
  };

  return (
    <Card
      hasBorderRadius={false}
      element={<Image objectFit="cover" width="100%" imageUrl={imageLink} />}
      width="360px"
      paddingSize="large"
    >
      <StyledBody>
        {isNewFeature ? (
          <StyledReleaseDate newItem>New</StyledReleaseDate>
        ) : (
          <StyledReleaseDate>{moment(releaseDate).format('D MMM YYYY')}</StyledReleaseDate>
        )}

        <Spacer spacing="s8" />
        <Label>{title}</Label>
        <Spacer spacing="s8" />
        <Paragraph>{description}</Paragraph>
        <Spacer spacing="s24" />
        <Button onClick={handleClick} primary fullWidth>
          Got it
        </Button>
      </StyledBody>
    </Card>
  );
};

const StyledReleaseDate = styled.span<{ newItem?: boolean }>`
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ newItem, theme }) =>
    newItem ? theme.fontWeights.bold : theme.fontWeights.normal};
  color: ${({ newItem, theme }) =>
    newItem ? theme.colors.interactive : theme.colors.borderActive};
`;
const StyledBody = styled.div`
  display: flex;
  flex-direction: column;
`;
