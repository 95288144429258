import React from 'react';
import styled from 'styled-components';
import { SpacingTypes } from '../../theme';

type PropsType = {
  backgroundColor?: string;
  fullWidth?: boolean;
  showShadow?: boolean;
  spacing?: SpacingTypes;
};

const Spacer: React.FunctionComponent<PropsType> = ({
  spacing = 's24',
  backgroundColor = 'transparent',
  showShadow = false,
  fullWidth = false,
}) => {
  return <StyledSpacer {...{ spacing, backgroundColor, showShadow, fullWidth }} />;
};

const StyledSpacer = styled.div<PropsType>`
  height: ${(props): string => props.theme.space[props.spacing]};
  width: ${(props): string => (props.fullWidth ? '100%' : props.theme.space[props.spacing])};
  background-color: ${(props): string => props.backgroundColor};
  box-shadow: ${(props): string =>
    props.showShadow ? 'inset 0px 2px 4px rgba(0, 0, 0, 0.1)' : 'unset'};
`;

export default Spacer;
