import { css } from 'styled-components';
import CSSProps from 'csstype';
import { themeGet } from './themeGet';

/**
 * If color is specified then
 * try to search the `colors` theme key for the given value
 * if unsuccessful fallback and search the entire theme, accomodates for using a path like "text.hint"
 */
export type ColorProps = { color?: string };
export const colorStyleFn = css<ColorProps>`
  color: ${({ color }) => {
    if (typeof color === 'string') {
      return /[\w\d]+\.[\w\d]+/.test(color) ? themeGet(color) : themeGet(`colors.${color}`);
    }
    return undefined;
  }};
`;

export type AlignProps = {
  align?: CSSProps.Property.TextAlign;
};
export const alignStyleFn = css<AlignProps>`
  text-align: ${({ align }) => align || undefined};
`;

export type BoldProps = { bold?: boolean };
export const boldStyleFn = css<BoldProps>`
  font-weight: ${({ bold, theme }) => (bold ? theme.fontWeights.bold : theme.fontWeights.normal)};
`;

export type DisplayProps = { display?: CSSProps.Property.Display };
export const displayStyleFn = css<DisplayProps>`
  display: ${({ display }) => display || undefined};
`;

export type TypographyProps = AlignProps & DisplayProps & ColorProps;
export const typographyStyleFn = css`
  ${alignStyleFn}
  ${colorStyleFn}
  ${displayStyleFn}
`;
