export type AnchorAttrsType = {
  /** Will render the button as an anchor instead */
  href?: string;

  /** Anchor [rel] attribute */
  rel?: string;

  /** Anchor [target] attribute */
  target?: string;
};
export function getAnchorAttributes(props: Partial<AnchorAttrsType>): Partial<AnchorAttrsType> {
  if (typeof props !== 'object') {
    return {};
  }
  const { target, href, rel } = props;
  return {
    target,
    href,
    rel,
  };
}
