import React, { PureComponent } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { NavLink, withRouter } from 'react-router-dom';
import { animated, Spring } from 'react-spring/renderprops.cjs';
import { preventPropagation } from '../../utils/interaction';
import { IconArrowDown } from '../Icon';
import { colors } from '../../themes';
import { IconPropsType } from '../../types';
import { getDataAttributes, DataPropsType } from '../../utils/dataAttributes';

type PropsType = DataPropsType & {
  autoSelectChild?: boolean;
  children?: any;
  className?: string;
  href?: string;
  icon?: React.FunctionComponent<React.PropsWithChildren<IconPropsType>> | null;
  id?: string;
  isOpen?: boolean;
  label?: string;
  onClick?: (e?: React.SyntheticEvent) => void;
  selectedItem?: string;
  target?: string;
  useRouter?: boolean;
  value?: string;
};

export type MenuItemType = PropsType;

type StateType = {
  isOpen: boolean;
  selectedItem: string;
};

class MenuItem extends PureComponent<MenuItemType, StateType> {
  static defaultProps = {
    align: 'left',
    useRouter: false,
    isOpen: false,
    onClick: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedItem: this.props.selectedItem,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ isOpen: this.props.isOpen });
  }

  componentDidUpdate(prevProps: PropsType) {
    if (
      prevProps.isOpen !== this.props.isOpen ||
      prevProps.selectedItem !== this.props.selectedItem
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isOpen: this.props.isOpen,
        selectedItem: this.props.selectedItem,
      });
    }
  }

  getSelectedItem = (): MenuItemType | null => {
    if (!this.props.children) {
      return null;
    }
    const foundItem = this.props.children.find(
      (child: MenuItemType): boolean => child.value === this.state.selectedItem,
    );
    return foundItem;
  };

  onClick = (e: React.SyntheticEvent) => {
    if (this.props.children) {
      this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
    }
    preventPropagation(e, this.props.onClick);
  };

  renderItemContents = (isOpen: boolean): JSX.Element => {
    const transition = {
      rotate: {
        from: {
          transform: 'rotate(-90deg)',
        },
        to: {
          transform: isOpen ? 'rotate(-180deg)' : 'rotate(-90deg)',
        },
        config: { duration: 100 },
      },
    };
    return (
      <Item>
        {this.props.label}
        {this.props.children && (
          <Spring {...transition.rotate}>
            {(styleProps: Record<string, any>) => (
              <Indicator style={styleProps}>
                <IconArrowDown width={16} height={16} color={colors.white} />
              </Indicator>
            )}
          </Spring>
        )}
        {this.props.icon && <Indicator>{this.renderMenuIcon()}</Indicator>}
      </Item>
    );
  };

  renderMenuIcon = (): JSX.Element => {
    const Icon = this.props.icon;
    return <Icon width={16} height={16} color={colors.white} />;
  };

  getNavLink = (classes: string, isOpen: boolean): JSX.Element => (
    <NavLink id={this.props.id} to={this.props.value} className={classes}>
      {this.renderItemContents(isOpen)}
    </NavLink>
  );

  getNormalLink = (classes: string, isOpen: boolean): JSX.Element => (
    <StyledLink
      id={this.props.id}
      role="menuitem"
      tabIndex={0}
      href={this.props.href}
      target={this.props.target}
      onClick={this.onClick}
      className={classes}
    >
      {this.renderItemContents(isOpen)}
    </StyledLink>
  );

  renderItem = (isOpen: boolean): JSX.Element => {
    const classes = classnames(
      this.props.children ? 'hasChildren' : null,
      this.props.selectedItem &&
        (this.props.value === this.props.selectedItem || this.getSelectedItem())
        ? 'active'
        : null,
      this.props.className,
    );

    return this.props.useRouter
      ? this.getNavLink(classes, isOpen)
      : this.getNormalLink(classes, isOpen);
  };

  render(): JSX.Element {
    const transition = {
      grow: {
        from: {
          height: 0,
          opacity: 0,
        },
        to: {
          height: this.state.isOpen ? 'auto' : 0,
          opacity: this.state.isOpen ? 1 : 0,
        },
        config: { duration: 200 },
      },
    };
    return (
      <ListItem key={this.props.value} {...getDataAttributes(this.props.data)}>
        {this.renderItem(this.state.isOpen)}
        {this.props.children && (
          <Spring {...transition.grow}>
            {(styleProps: Record<string, any>) => (
              <ChildList style={styleProps}>
                {this.props.children.map(
                  (item: MenuItemType): JSX.Element => (
                    <MenuItem
                      icon={item.icon}
                      id={item.id}
                      className={item.className}
                      key={item.id}
                      label={item.label}
                      value={item.value}
                      href={item.href}
                      target={item.target}
                      onClick={(e: React.SyntheticEvent) => preventPropagation(e, item.onClick)}
                      selectedItem={this.props.selectedItem}
                      data={item.data}
                    />
                  ),
                )}
              </ChildList>
            )}
          </Spring>
        )}
      </ListItem>
    );
  }
}

const ListItem = styled.li`
  ul {
    -webkit-padding-start: 0px;
    -moz-padding-start: 0px;
    list-style-type: none;
    margin-top: 0px;

    li {
      a {
        padding-left: 16px;
      }
    }
  }

  a {
    display: block;
    font: ${({ theme }): string => theme.text.menu};
    color: ${({ theme }): string => theme.colors.text};
    text-decoration: none;
    &:hover {
      cursor: pointer;
      background: ${({ theme }): string => theme.navigation.hoverBackground};
    }
    &.active {
      font: ${({ theme }): string => theme.text.menuSelected};
      background: ${({ theme }): string => theme.navigation.selectedBackground};

      &.hasChildren {
        background: ${({ theme }): string => theme.navigation.backgroundColor};

        &:hover {
          cursor: pointer;
          background: ${({ theme }): string => theme.navigation.hoverBackground};
        }
      }
    }
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  text-align: ${(props: Record<string, any>): string => props.align};
  padding: 16px;
  color: ${({ theme }): string => theme.text.whiteText};

  &.hasChildren {
    padding-bottom: 8px;
    &.selected {
      background: ${({ theme }): string => theme.navigation.backgroundColor};
    }
  }
`;

const Indicator = styled(animated.div)`
  transform-origin: center;
  width: 16px;
  height: 16px;
  margin-left: auto;
`;

const ChildList = styled(animated.ul)`
  will-change: opacity, height;
  overflow: hidden;
`;

const StyledLink = styled.a`
  &:focus {
    outline: 0;
  }
`;

export const RoutedMenuItem = withRouter(MenuItem);

export default (props: MenuItemType): JSX.Element =>
  props.useRouter ? <RoutedMenuItem {...props} /> : <MenuItem {...props} />;
