import React from 'react';
import styled from 'styled-components';
import {
  Avatar,
  Button,
  Group,
  Link,
  Paragraph,
  Spacer,
  TextArea,
  Loading,
  Heading3,
  Heading6,
  Select,
} from '../..';

import { HelpPanelStateType, HelpPanelProductOptionType, HelpPanelOnSendEmailType } from '../types';

type PropsType = {
  canSendEmail?: boolean;
  formState?: HelpPanelStateType;
  invalidQuery?: boolean;
  onFormChanges?: (key: string, value: string) => void;
  onPageChange?: (index: number) => void;
  onSendEmail?: HelpPanelOnSendEmailType;
  productOptions?: HelpPanelProductOptionType[];
  productOptionsDefault?: string;
  showProductOptions?: boolean;
};

const FormView = (props: PropsType) => {
  const [isLoading, showLoader] = React.useState(false);

  const {
    formState,
    onPageChange,
    onFormChanges,
    invalidQuery,
    canSendEmail,
    productOptions,
    productOptionsDefault,
    showProductOptions,
  } = props;
  const { name, email, phone, query, initials, imageUrl } = formState;

  const [product, setProduct] = React.useState(productOptionsDefault);

  const onSendEmail = () => {
    const values = { name, email, phone, query, product };

    if (props.onSendEmail) {
      showLoader(true);
      new Promise((resolve) => resolve(props.onSendEmail(values))).then(() => showLoader(false));
    }
  };

  return (
    <FormContainer>
      <HeadingArea>
        <Heading6>Your details</Heading6>
        <Link primary onClick={onPageChange}>
          Change
        </Link>
      </HeadingArea>
      <ProfileContainer>
        <Avatar initials={initials} imageUrl={imageUrl} size="medium" />
        <Spacer />
        <Group spacing="4px">
          <Paragraph>{name}</Paragraph>
          <Paragraph>{email}</Paragraph>
          <Paragraph>{phone}</Paragraph>
        </Group>
      </ProfileContainer>
      <Spacer spacing="s8" />
      {showProductOptions && Array.isArray(productOptions) && productOptions.length > 0 && (
        <Group>
          <Heading3>Product</Heading3>
          <Select
            options={productOptions}
            updateSelectedOption={(selectedOption) => {
              setProduct(selectedOption.name);
            }}
          />
        </Group>
      )}
      <Group>
        <Heading3>Tell us what&apos;s happening</Heading3>
        <TextArea
          placeholder="Ask your question here"
          value={query}
          onChange={(value: string) => {
            onFormChanges('query', value);
          }}
          error={invalidQuery}
        />
        <Button fullWidth primary disabled={!canSendEmail || invalidQuery} onClick={onSendEmail}>
          Send email
        </Button>
      </Group>
      {isLoading && <Loading overlay />}
    </FormContainer>
  );
};

FormView.defaultProps = {
  showProductOptions: false,
  productOptions: [
    { id: 'pos', name: 'POS' },
    { id: 'bo', name: 'Back Office' },
    { id: 'purchase', name: 'Purchase' },
    { id: 'produce', name: 'Produce' },
    { id: 'insights', name: 'Insights' },
    { id: 'delivery', name: 'Delivery' },
    { id: 'addons', name: 'Add-Ons' },
    { id: 'accounting', name: 'Accounting Integrations' },
    { id: 'developer', name: 'Developer Support' },
    { id: 'other', name: 'Other' },
  ],
  productOptionsDefault: 'pos',
};

export const FormContainer = styled((props: Record<string, any>) => <Group {...props} />)`
  &&& {
    width: 100%;
  }
`;

const HeadingArea = styled.div`
  &&& {
    display: flex;
    justify-content: space-between;
  }
`;

const ProfileContainer = styled.div`
  &&& {
    display: flex;
    flex-direction: row;
  }
`;

export default FormView;
