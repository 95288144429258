import styled from 'styled-components';
import React from 'react';

type PropsType = {
  allowScroll?: boolean;
  fullscreen?: boolean;
};

const StyledPage = styled.div<PropsType>`
  position: ${(props): string => (props.fullscreen ? 'fixed' : 'relative')};
  width: ${(props): string => (props.fullscreen ? '100vw' : '100%')};
  height: ${(props): string => (props.fullscreen ? '100vh' : '100%')};
  top: 0;
  left: 0;
  z-index: ${({ theme }: { theme }): string => theme.zIndex.overlay};
  background-color: ${(props): string => props.theme.background.default};
  overflow: ${(props): string => (props.allowScroll ? 'auto' : 'hidden')};
`;

export const Page: React.FunctionComponent<React.PropsWithChildren<PropsType>> = ({
  ...restProps
}) => <StyledPage {...restProps} />;

Page.defaultProps = {
  fullscreen: false,
  allowScroll: false,
};

export default Page;
