import React from 'react';
import styled, { css } from 'styled-components';
import classnames from 'classnames';
import { getDataAttributes, DataPropsType } from '../../utils/dataAttributes';
import { getVariationName } from '../../utils/getVariationName';
import { declareVariants } from '../../utils/declareVariants';

const LinkComponent = styled.a<{ disabled?: boolean; fullWidth?: boolean; variant?: string }>`
  &&& {
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
    padding: 0;

    font-family: ${({ theme }) => theme.fonts.sans};
    font-size: ${({ theme }) => theme.fontSizes.base};
    line-height: ${({ theme }) => theme.lineHeights.base};
    font-weight: ${({ theme }) => theme.fontWeights.bold};

    &:hover {
      cursor: pointer;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    ${declareVariants({
      primary: css`
        color: ${({ theme }): string => theme.link.primary.color};
        &:hover {
          color: ${({ theme }): string => theme.link.primary.hoverColor};
        }
      `,
      secondary: css`
        color: ${({ theme }): string => theme.link.secondary.color};
        &:hover {
          color: ${({ theme }): string => theme.link.secondary.hoverColor};
        }
      `,
      destructive: css`
        color: ${({ theme }): string => theme.link.destructive.color};
        &:hover {
          color: ${({ theme }): string => theme.link.destructive.hoverColor};
        }
      `,
    })}
  }
`;

type Variants = 'primary' | 'secondary' | 'destructive';

type PropsType = DataPropsType & {
  [all: string]: unknown;
  children?: React.ReactNode;
  /**
   * **Deprecated:** never pass className to a Chameleon component
   * @deprecated
   */
  className?: string;
  /**
   * **Deprecated** Use variant instead
   * @deprecated
   */
  destructive?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  href?: string;
  onClick?: any;
  /**
   * **Deprecated** Use variant instead
   * @deprecated
   */
  primary?: boolean;
  /**
   * **Deprecated** Use variant instead
   * @deprecated
   */
  secondary?: boolean;
  variant?: Variants;
};

const Link: React.FunctionComponent<React.PropsWithChildren<PropsType>> = ({
  children = '',
  className = '',
  destructive = false,
  disabled = false,
  href = null,
  onClick = null,
  primary = false,
  secondary = false,
  variant = 'secondary',
  fullWidth = false,
  data,
  ...restProps
}) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation();
    if (disabled) {
      return;
    }
    if (onClick) {
      e.preventDefault();
      onClick(e);
    }
  };
  const derivedVariant = getVariationName<Variants>({ primary, secondary, destructive }, variant);

  const classes = classnames(disabled ? 'disabled' : '', className);

  return (
    <LinkComponent
      className={classes}
      variant={derivedVariant}
      onClick={handleClick}
      role={onClick ? 'button' : null}
      href={href || null}
      fullWidth={fullWidth}
      disabled={disabled}
      {...getDataAttributes(data)}
      {...restProps}
    >
      {children}
    </LinkComponent>
  );
};

export default Link;
