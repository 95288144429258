import React from 'react';
import IconStyle from '../IconStyle';
import IconPropsType from '../types';
import IconSvg from './phone.svg';

export default function IconPhone(props: IconPropsType): JSX.Element {
  return (
    <IconStyle usingPath {...props}>
      <IconSvg {...props} aria-label="phone" />
    </IconStyle>
  );
}
