import React, { useState } from 'react';
import styled from 'styled-components';
import { Heading2 } from '../Heading';
import Group from '../Group';
import { IconArrowLeft, IconClose } from '../Icon';
import IconButton from '../IconButton';
import Overlay from '../Overlay';
import { validateString, validateNoEmptyString } from '../../validation/validators/stringValidator';

import { HelpContext } from './HelpContext';

import {
  HelpPanelItemType,
  HelpPanelUserInfoType,
  HelpPanelStateType,
  HelpPanelOnSendEmailType,
  HelpPanelProductOptionType,
  PageType,
  SidePagesPropsType,
  RequiredType,
  NewFeatureItemType,
} from './types';
import MainView from './MainView';
import FormView from './EmailForm/FormView';
import FormEditView from './EmailForm/FormEditView';
import PageController from './PageController';
import { NewFeatureModal } from './NewFeatureModal';

import { getInitials } from '../../utils/textFormat';
import Footer from '../Footer';
import Caption from '../Caption';
import Spacer from '../Spacer';

export const pageIndexes = {
  main: 0,
  viewForm: 1,
  editForm: 2,
};

export type PropsType = {
  allProductFeatures?: NewFeatureItemType[];
  canConfirmChanges?: boolean;
  canSendEmail?: boolean;
  currentUser?: HelpPanelUserInfoType;
  onClose?: () => void;
  // onConfirmModal should have Ajax request logic to update the new feature history.
  onConfirmModal?: (newFeatureId: string) => void;
  onDetailChanges?: (value: HelpPanelUserInfoType) => Promise<void> | any;
  onSearch?: (searchValue: string) => void;
  onSendEmail?: HelpPanelOnSendEmailType;
  productName?: string;
  productOptions?: HelpPanelProductOptionType[];
  productOptionsDefault?: string;
  relatedItems?: HelpPanelItemType[];
  required?: RequiredType;
  showEmailSupport?: boolean;
  showNewFeatureSection?: boolean;
  showProductOptions?: boolean;
  supportInfo?: HelpPanelItemType[];
  supportItems?: HelpPanelItemType[];
  title?: string;
  troubleShootingItems?: HelpPanelItemType[];
};

const DEFAULT_PRODUCT_NAME = 'Restaurant POS (O-Series)';
export default class HelpPanel extends PageController<PropsType, HelpPanelStateType> {
  static defaultProps = {
    title: 'How may we help you?',
    showEmailSupport: false,
    required: {
      name: true,
      email: true,
      phone: false,
    },
    productName: DEFAULT_PRODUCT_NAME,
    showNewFeatureSection: false,
    showProductOptions: false,
    allProductFeatures: [],
  };

  initialState: HelpPanelStateType;

  constructor(props: PropsType) {
    super(props);
    this.initialState = {
      ...this.state,
      searchValue: '',
      imageUrl: '',
      initials: '',
      name: '',
      email: '',
      phone: '',
      query: '',
      newFeatureHistory: [],
      invalidQuery: false,
      canSendEmail: false,
    };
    this.state = this.initialState;
  }

  UNSAFE_componentWillMount() {
    const { currentUser } = this.props;
    if (currentUser) {
      const { imageUrl, name, email, phone, newFeatureHistory } = currentUser;
      let { initials } = currentUser;

      if (name && !initials) {
        initials = getInitials(name);
      }
      this.setState({ imageUrl, name, initials, email, phone, newFeatureHistory });
    }
  }

  onSearchChanges = (searchValue: string): void => this.setState({ searchValue });

  onEnterKeyPress = () => {
    if (this.props.onSearch) {
      this.props.onSearch(this.state.searchValue);
    }
  };

  onFormChanges = (key: string, value: string) => {
    // @ts-ignore
    this.setState({ [key]: value }, () => {
      if (key === 'query') {
        this.setState({ canSendEmail: true });
        validateQuery.call(this, value);
      }
    });
  };

  onClose = () => {
    this.setState(this.initialState);
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  onEditFormChanges = (formDetails: Record<string, any>) => {
    const { name, email, phone } = formDetails;
    const initials = getInitials(name);
    this.setState({ name, email, phone, initials });
  };

  onPageChange = (): void => {
    const { name, email } = this.state;

    // If no CurrentUser name and email are present skip to FormEditView
    if (!name || !email) {
      return this.goToPage(pageIndexes.editForm);
    }

    return this.nextPage();
  };

  onGoBack = (): void => {
    const { name, email } = this.state;

    // If currentUser doesn't enters name and email head back to mainPage
    if (!name || !email) {
      return this.goToPage(pageIndexes.main);
    }

    return this.prevPage();
  };

  renderMainView = (): React.ReactNode => (
    <MainView
      relatedItems={this.props.relatedItems}
      supportItems={this.props.supportItems}
      supportInfo={this.props.supportInfo}
      showEmailSupport={this.props.showEmailSupport}
      troubleShootingItems={this.props.troubleShootingItems}
      onSearchChanges={this.onSearchChanges}
      onEnterKeyPress={this.onEnterKeyPress}
      searchValue={this.state.searchValue}
      onPageChange={this.onPageChange}
      showNewFeatureSection={this.props.showNewFeatureSection}
    />
  );

  renderEmailForm = (): React.ReactNode => (
    <FormView
      formState={this.state}
      onFormChanges={this.onFormChanges}
      onPageChange={this.nextPage}
      onSendEmail={this.props.onSendEmail}
      productOptions={this.props.productOptions}
      productOptionsDefault={this.props.productOptionsDefault}
      showProductOptions={this.props.showProductOptions}
      canSendEmail={this.state.canSendEmail}
      invalidQuery={this.state.invalidQuery}
    />
  );

  renderEmailFormEditView = (): React.ReactNode => (
    <FormEditView
      formState={this.state}
      onEditFormChanges={this.onEditFormChanges}
      onDetailChanges={this.props.onDetailChanges}
      required={this.props.required}
      prevPage={this.prevPage}
    />
  );

  getPages = (): PageType[] => [
    { index: pageIndexes.main, title: this.props.title, component: this.renderMainView },
    { index: pageIndexes.viewForm, title: 'Email us', component: this.renderEmailForm },
    {
      index: pageIndexes.editForm,
      title: 'Edit details',
      component: this.renderEmailFormEditView,
    },
  ];

  render() {
    return (
      <SidePages
        allProductFeatures={this.props.allProductFeatures}
        onClose={this.onClose}
        onGoBack={this.onGoBack}
        productName={this.props.productName}
        onConfirmModal={this.props.onConfirmModal}
        currentIndex={this.state.currentIndex}
        pages={this.getPages()}
        newFeatureHistory={this.state.newFeatureHistory}
        supportInfo={this.props.supportInfo}
      />
    );
  }
}

export const validateQuery = function (value: string) {
  const invalidQuery =
    !validateString(value, 255, true).isValid || !validateNoEmptyString(value).isValid;
  this.setState({ invalidQuery });
};

export const SidePages = (props: SidePagesPropsType): JSX.Element => {
  // We don't want to close the whole HelpPanel including backdrop. We just want to temporarily hide the side panel when the new feature modal pops up, which is triggered inside of main page.

  const {
    onClose,
    currentIndex,
    onGoBack,
    pages,
    onConfirmModal,
    newFeatureHistory = [],
    allProductFeatures = [],
    productName,
  } = props;

  const [showSidePanel, setShowSidePanel] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [history, setHistory] = useState(newFeatureHistory);
  const [featureId, setFeatureId] = useState('');
  const [showOldFeatures, setShowOldFeatures] = useState(false);

  const newFeaturesAvaliable = allProductFeatures.filter((el) => {
    return !newFeatureHistory.includes(el.id);
  });

  return (
    <Overlay>
      <HelpContext.Provider
        value={{
          onClose,
          showSidePanel,
          setShowSidePanel,
          showModal,
          setShowModal,
          history,
          setHistory,
          newFeaturesAvaliable,
          allProductFeatures,
          featureId,
          setFeatureId,
          onConfirmModal,
          showOldFeatures,
          setShowOldFeatures,
        }}
      >
        {showSidePanel && (
          <PanelContainer>
            <Container>
              <HeadingSection>
                <Group horizontal spacing="8px">
                  {currentIndex > 0 && (
                    <IconButton onClick={onGoBack}>
                      <IconArrowLeft />
                    </IconButton>
                  )}
                  <Heading2>{pages[currentIndex].title}</Heading2>
                </Group>
                <CloseButton onClick={onClose}>
                  <IconClose height={16} width={16} />
                </CloseButton>
              </HeadingSection>
              <ContentSection>{pages[currentIndex].component()}</ContentSection>
              <Footer
                leftContent={
                  <FooterItemContiner>
                    <ProductDescriptionContainer>
                      <Caption>Product:</Caption>
                      <Spacer spacing="s4" />
                      <Caption>{productName}</Caption>
                    </ProductDescriptionContainer>
                    <Spacer spacing="s8" />
                    {props.supportInfo && (
                      <SupportInfoContainer>
                        {props.supportInfo.map((item: HelpPanelItemType) => {
                          return <Caption key={item.id}>{item.value}</Caption>;
                        })}
                      </SupportInfoContainer>
                    )}
                  </FooterItemContiner>
                }
              />
            </Container>
          </PanelContainer>
        )}

        {showModal && <NewFeatureModal />}
      </HelpContext.Provider>
    </Overlay>
  );
};

const FooterItemContiner = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProductDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const SupportInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;

const PanelContainer = styled.div`
  &&& {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    min-width: 320px;
    max-width: 375px;
    border: 1px solid ${({ theme }): string => theme.header.border};
    border-radius: 0 4px 4px 0;
    background-color: ${({ theme }): string => theme.background.default};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
`;

const Container = styled.div`
  &&& {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const HeadingSection = styled.div`
  &&& {
    display: flex;
    padding: 16px;
  }
`;

const ContentSection = styled.div`
  &&& {
    padding: 8px 16px 0;
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
`;

const CloseButton = styled(IconButton)`
  &&& {
    margin-left: auto;
  }
`;
