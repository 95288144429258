import { Droppable, DroppableProvided } from 'react-beautiful-dnd';
import React from 'react';

export default function ReorderableTableBodyRenderer({ children, ...restProps }) {
  return (
    <Droppable droppableId="reorderableTable">
      {(droppableProvided: DroppableProvided) => (
        <tbody
          ref={droppableProvided.innerRef}
          {...droppableProvided.droppableProps}
          {...restProps}
        >
          {children}
          {droppableProvided.placeholder}
        </tbody>
      )}
    </Droppable>
  );
}
