import React from 'react';
import styled from 'styled-components';

const avatarSizes = {
  auto: '100%',
  small: '32px',
  medium: '48px',
  large: '120px',
  xlarge: '200px',
};

const initialSizes = {
  auto: '100%',
  small: '14px',
  medium: '18px',
  large: '32px',
  xlarge: '56px',
};

const Container = styled.div<{ size: string; square: boolean }>`
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 1px;
  border-radius: ${({ square }) => (square ? '4px' : '50%')};
  background-color: ${({ theme }): string => theme.avatar.backgroundColor};
  box-shadow: ${({ theme }): string => theme.shadows.default};
  overflow: hidden;

  height: ${({ size }) => avatarSizes[size]};
  width: ${({ size }) => avatarSizes[size]};
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Initials = styled.span<{ size: string }>`
  font-family: ${({ theme }) => theme.fonts.sans};
  font-size: ${({ size }) => initialSizes[size]};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  position: absolute;
  color: ${({ theme }) => theme.avatar.color};
`;

type PropsType = {
  alt?: string;
  imageUrl?: string;
  initials?: string;
  size?: string;
  square?: boolean;
};
export const Avatar: React.FunctionComponent<React.PropsWithChildren<PropsType>> = ({
  size = 'medium',
  imageUrl,
  initials,
  children,
  square,
  alt,
}) => {
  return (
    <Container size={size} square={square}>
      {imageUrl && <AvatarImage src={imageUrl} alt={alt} />}
      {initials && !imageUrl && <Initials size={size}>{initials}</Initials>}
      {children}
    </Container>
  );
};

export default Avatar;
