import React from 'react';
import AppHeader from '../AppHeader';
import Container from '../Container';
import Footer from '../Footer';
import IconButton from '../IconButton';
import { IconClose } from '../Icon';
import Page from '../Page';
import ScrollArea from '../ScrollArea';
import Spacer from '../Spacer';
import { Mobile, TabletAndDesktop } from '../../utils/responsive';
import { withEscapeWillClose, composeElement } from '../../utils/escaper';
import { withFocus } from '../../utils/focusElement';

type PropsType = {
  footerLeftContent?: JSX.Element | null;
  footerRightContent?: JSX.Element | null;
  fullscreen?: boolean;
  onClose?: () => void;
  showFooter?: boolean;
  title?: string;
};

/**
 * @component
 */
export class ContextSelector extends React.Component<PropsType, any> {
  static defaultProps = {
    fullscreen: false,
    showFooter: false,
    footerLeftContent: null,
    footerRightContent: null,
    title: 'Select a site',
    onClick: () => {},
  };

  render(): JSX.Element {
    const rightContent = (
      <>
        <Mobile>
          <CloseButton onClick={this.props.onClose} />
        </Mobile>
        <TabletAndDesktop>
          <CloseButton label="Close" labelFirst onClick={this.props.onClose} />
        </TabletAndDesktop>
      </>
    );
    const leftContent = <div />;

    return (
      <Page fullscreen={this.props.fullscreen}>
        <AppHeader title={this.props.title} leftContent={leftContent} rightContent={rightContent} />
        <ScrollArea showBackground={false} offset="80px">
          <Container centered maxWidth="420px">
            <div>
              {this.props.children}
              <Spacer spacing="s96" />
              <Spacer spacing="s96" />
            </div>
          </Container>
        </ScrollArea>
        {!this.props.showFooter ? null : (
          <Footer
            fullscreen={this.props.fullscreen}
            leftContent={this.props.footerLeftContent}
            rightContent={this.props.footerRightContent}
          />
        )}
      </Page>
    );
  }
}

const CloseButton = (props: Record<string, any>): JSX.Element => (
  <IconButton {...props}>
    <IconClose width={16} height={16} />
  </IconButton>
);

export default composeElement(withFocus, withEscapeWillClose)(ContextSelector);
