import React, { Component } from 'react';
import styled from 'styled-components';
import { IconArrowRight } from '../Icon';
import Avatar from '../Avatar';
import Caption from '../Caption';
import Group from '../Group';
import Paragraph from '../Paragraph';
import TextClamp from '../TextClamp';
import TextTruncate from '../TextTruncate';
import { preventPropagation } from '../../utils/interaction';
import { colors } from '../../themes';

const Item = styled.li`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: ${({ theme }): string => theme.form.border};

  &:hover {
    background-color: ${({ theme }): string => theme.navigation.hoverBackground};
  }

  .icon {
    display: none;
  }

  &.clickable {
    cursor: pointer;

    .icon {
      margin-left: 8px;
      display: block;
    }
  }
`;

type PropsType = {
  description?: string;
  id?: string;
  imageUrl?: string;
  isPerson?: boolean;
  label?: string;
  onClick?: () => void;
  shortLabel?: string;
};

export type ContextMenuItemType = PropsType & {
  value?: string;
};

export default class ContextMenuItem extends Component<PropsType, any> {
  static defaultProps = {
    label: '',
    value: '',
    description: '',
    shortLabel: '',
    imageUrl: '',
    isPerson: true,
    useRouter: false,
    onClick: null,
  };

  onClick = (e: React.SyntheticEvent) => {
    if (this.props.onClick) preventPropagation(e, this.props.onClick);
  };

  render(): JSX.Element {
    return (
      <Item
        id={this.props.id}
        role="button"
        onClick={this.onClick}
        className={this.props.onClick ? 'clickable' : ''}
      >
        <Group horizontal horizontalWidth="100%">
          <Avatar
            square={!this.props.isPerson}
            size="small"
            initials={this.props.shortLabel}
            imageUrl={this.props.imageUrl}
          />
          <Group spacing="0px" full>
            <Paragraph color={colors.white}>
              <TextClamp maxLines={2}>{this.props.label}</TextClamp>
            </Paragraph>
            <Caption color={colors.white}>
              <TextTruncate mode="ellipsis">{this.props.description}</TextTruncate>
            </Caption>
          </Group>
        </Group>
        <IconArrowRight className="icon" width={16} height={16} color={colors.white} />
      </Item>
    );
  }
}
