import styled from 'styled-components';

/**
 * Use the color prop on the component you want to colorise instead
 * @deprecated
 */
const InheritColor = styled.div`
  * {
    color: inherit !important;
  }
`;

export default InheritColor;
