/* eslint-disable prefer-template */
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import QuantityInput, { PropsType as QuantityInputProps } from '../QuantityInput/QuantityInput';
import IconButton from '../IconButton';
import { IconPlus, IconMinus } from '../Icon';
import { Decimal } from '../../utils';
import { DataPropsType, DataAttributes, getDataAttributes } from '../../utils/dataAttributes';

export const DEFAULT_UNIT = 1;
export const DEFAULT_MAX_FRACTION_LENGTH = 4;

type PropsType = Omit<QuantityInputProps, 'initialValue'> &
  DataPropsType & {
    allowNegative?: boolean;
    /**
     * Analytics attribute is applied to the decrement button, increment button, and input with the following respective postfixes:
     * ```[analytics]_decrement```, ```[analytics]_increment```, and ```[analytics]_input```
     */
    data?: DataAttributes;
    error?: boolean | null;
    errorMessage?: string;
    id?: string;
    initialValue?: string | number;
    inputRef?: React.RefObject<HTMLInputElement>;
    maxFractionLength?: number;
    maxWidth?: string;
    name?: string;
    onChange?: (value: string, isNumberValid: boolean) => void;
    placeholder?: string;
    preventInvalidInput?: boolean;
    showBorder?: boolean;
    showHighlight?: boolean;
    slim?: boolean;
    stepUnit?: number;
    value?: string | number;
  };

type StateType = {
  value: string;
};

/**
 * Allows the user to increment or decrement a value.
 */
export default class Counter extends PureComponent<PropsType, StateType> {
  static defaultProps = {
    id: '',
    name: '',
    initialValue: '',
    inputRef: null,
    value: '0',
    maxFractionLength: DEFAULT_MAX_FRACTION_LENGTH,
    allowNegative: true,
    onChange: () => {},
    placeholder: '0',
    maxWidth: '144px',
    slim: false,
    showHighlight: true,
    showBorder: true,
    stepUnit: DEFAULT_UNIT,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: String(props.value || props.initialValue || 0),
    };
  }

  componentDidUpdate(prevProps: PropsType) {
    if (
      this.props.value != null &&
      this.props.value !== prevProps.value &&
      this.props.value !== this.state.value
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ value: String(this.props.value) });
    }
  }

  onDecrement = () => {
    const { preventInvalidInput, allowNegative } = this.props;
    const zero = new Decimal('0');
    const oldValue = new Decimal(this.state.value || '0');
    let newValue = oldValue.minus(this.props.stepUnit);
    if (preventInvalidInput && !allowNegative && newValue.lt(zero)) {
      newValue = zero;
    }
    this.handleChanged(newValue.toString());
  };

  onIncrement = () => {
    const oldValue = new Decimal(this.state.value || '0');
    const newValue = oldValue.add(this.props.stepUnit).toString();
    this.handleChanged(newValue);
  };

  handleChanged = (value: string) => {
    this.setState({ value }, (): void => this.onValueChanged(value));
  };

  onValueChanged = (value: string) => {
    if (this.props.onChange) {
      this.props.onChange(value, true);
    }
  };

  render(): JSX.Element {
    const { data, disabled } = this.props;

    const decrement = (
      <PrefixWrapper>
        <IconButton
          onClick={this.onDecrement}
          showOutline={false}
          data={getDataAttributes(data, 'decrement', '')}
          disabled={disabled}
        >
          <IconMinus width={16} height={16} />
        </IconButton>
      </PrefixWrapper>
    );
    const increment = (
      <SuffixWrapper>
        <IconButton
          onClick={this.onIncrement}
          showOutline={false}
          data={getDataAttributes(data, 'increment', '')}
          disabled={disabled}
        >
          <IconPlus width={16} height={16} />
        </IconButton>
      </SuffixWrapper>
    );
    return (
      <StyleWrapper>
        <QuantityInput
          {...this.props}
          initialValue={String(this.props.initialValue)}
          value={this.state.value}
          prefix={decrement}
          suffix={increment}
          onChange={this.handleChanged}
          highlightBorder={false}
          data={getDataAttributes(data, 'input', '')}
        />
      </StyleWrapper>
    );
  }
}

const PrefixWrapper = styled.div`
  margin-left: -4px;
`;

const SuffixWrapper = styled.div`
  margin-right: -4px;
`;

const StyleWrapper = styled.div`
  span[class^='Caption__CaptionText'] {
    text-align: left;
  }
`;
