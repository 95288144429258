import React, { Component, ComponentType } from 'react';
import styled from 'styled-components';
import { SpacingTypes } from '../../theme';
import {
  TableHeaderType,
  TableRowType,
  TableSortDirectionType,
  TableOnSortType,
  TableOnRowClickType,
  TableIdType,
  TableRowPropsType,
} from './types';
import { CustomRowRendererType, RowRenderer } from './RowRenderer';
import { HeaderColumns } from './HeaderColumns';
import { TabletAndDesktop, Mobile } from '../../utils';

type TableComponentType = {
  autoWidth?: boolean;
  fixedHeaders?: boolean;
  /** Should the table have a full border */
  showBorder?: boolean;
  /** Should the rows have effects on hover */
  showHover?: boolean;
  /** Should the rows show a pointer type cursor when hovered */
  showRowClick?: boolean;
  /** Should the rows have a colored border when hovered */
  showRowIndicator?: boolean;
  /** Should the rows have borders between each other  */
  showRowLines?: boolean;
};

const TableComponent = styled.table<TableComponentType>`
  width: ${({ autoWidth }): string => (autoWidth ? 'auto' : '100%')};
  height: 100%;
  display: table;
  border-collapse: collapse;
  background-color: ${({ theme }) => theme.table.rowBackgroundColor};
  border: ${({ showBorder, theme }): string => (showBorder ? theme.table.border : 'none')};
  th {
    text-align: left;

    &.numeric {
      text-align: right;
    }
  }

  thead tr {
    display: ${({ fixedHeaders }): string => (fixedHeaders ? 'block' : 'table-row')};
  }

  tbody {
    display: ${({ fixedHeaders }): string => (fixedHeaders ? 'block' : 'table-row-group')};
    overflow: ${({ fixedHeaders }): string => (fixedHeaders ? 'auto' : 'visible')};
    height: 100%;
    width: 100%;
    tr {
      width: 100%;
      padding-left: ${({ theme }) => theme.space.s8};
      height: ${({ theme }) => theme.space.s56};
      border-bottom: ${({ showRowLines, theme }): string =>
        showRowLines ? theme.table.border : 'none'};
      cursor: ${({ showRowClick }): string => (showRowClick ? 'pointer' : 'auto')};

      &:first-child {
        border-top: ${({ showRowLines, theme }): string =>
          showRowLines ? theme.table.border : 'none'};
      }

      &:hover {
        background-color: ${({ showHover, theme }): string =>
          showHover ? theme.table.rowHoverBackgroundColor : theme.table.rowBackgroundColor};

        td:first-child {
          box-shadow: ${({ showRowIndicator, theme }): string =>
            showRowIndicator ? theme.table.rowHoverIndicator : null};
        }
      }

      &:focus {
        outline: none;
      }
    }
  }
`;

export type TablePropsType = TableComponentType & {
  /**
   * When false the table is set to 100%, when true it takes the width of the columns
   */
  autoWidth?: boolean;
  /** Should the headers behave like 'position: fixed' */
  fixedHeaders?: boolean;
  /**
   * Table header contents.
   * Headers also dictate the styles of cells that fall into their column e.g., align, spacingBetweenRows
   * Header ids are used in the onSort callback and should be unique identifiers
   */
  headers?: TableHeaderType[];
  /** Callback function for row click */
  onRowClick?: TableOnRowClickType;
  /** Callback function for clicking header sort buttons. */
  onSort?: TableOnSortType;
  /** Custom row render component. */
  rowRenderer?: CustomRowRendererType;
  /**
   * Table body contents.
   * Row ids are used in the onRowClick callback and should be unique identifiers
   */
  rows?: TableRowType[];
  showIndent?: boolean;
  /**
   * amount of horizontal spacing between columns
   */
  spacingBetweenCells?: SpacingTypes;
  /**
   * amount of verical spacing between rows
   */
  spacingBetweenRows?: SpacingTypes;
  /** Custom table body render component. */
  tableBodyRenderer?: ComponentType<React.PropsWithChildren<unknown>>;
};

type StateType = {
  headerSort: Map<TableIdType, TableSortDirectionType>;
};

export default class Table extends Component<TablePropsType, StateType> {
  static defaultProps = {
    headers: [],
    rows: [[]],
    showHover: true,
    showRowIndicator: true,
    showRowClick: false,
    showRowLines: true,
    spacingBetweenCells: 'none',
    spacingBetweenRows: 'none',
    showIndent: true,
    showBorder: false,
    autoWidth: false,
    fixedHeaders: false,
    onRowClick: null,
  };

  constructor(props) {
    super(props);

    const headerSort = new Map();
    this.state = {
      headerSort,
    };
  }

  getHeaders() {
    const { headers } = this.props;
    const { headerSort } = this.state;
    return headers
      .map(unhideHeader)
      .filter(filterHiddenHeader)
      .map(setDefaultSortDirection)
      .map(({ id, sortDirection, ...rest }) => ({
        ...rest,
        id,
        sortDirection: headerSort.has(id) ? headerSort.get(id) : sortDirection,
      }));
  }

  setDirection = (headerId: TableIdType, sortDirection: TableSortDirectionType): Promise<void> => {
    return new Promise((resolve) => {
      let direction;
      switch (sortDirection) {
        case 'ascending':
          direction = 'descending';
          break;
        case 'descending':
          direction = 'ascending';
          break;
        default:
          direction = 'ascending';
      }

      this.setState((state) => {
        const headerSort = new Map(state.headerSort);
        this.getHeaders().forEach((h: TableHeaderType) => {
          if (h.id === headerId) {
            headerSort.set(h.id, direction);
          } else {
            headerSort.set(h.id, 'default');
          }
        });
        return { headerSort };
      }, resolve);
    });
  };

  getCurrentHeader = (id: TableIdType): TableHeaderType =>
    this.getHeaders().find((h: TableHeaderType) => id === h.id);

  handleSort = async (itemId: TableIdType, direction: TableSortDirectionType): Promise<void> => {
    await this.setDirection(itemId, direction);
    const header = this.getCurrentHeader(itemId);
    if (this.props.onSort) {
      this.props.onSort(header.id, header.sortDirection);
    }
  };

  setRowProps = (
    onRowClick: TableOnRowClickType,
    rowItemId: TableIdType,
  ): TableRowPropsType | null => {
    if (onRowClick) {
      return {
        role: onRowClick ? 'button' : undefined,
        tabIndex: 0,

        onClick: (e: React.SyntheticEvent): void => onRowClick(e, rowItemId),
      };
    }
    return null;
  };

  render() {
    const {
      showIndent,
      spacingBetweenCells,
      rows,
      onRowClick,
      spacingBetweenRows,
      /*
       * Atm passing in headerProps to avoid that situation when name of the header is empty but
       * there are other settings for that header.
       */
      headers: headerProps,
      onSort,
      autoWidth,
      fixedHeaders,
      showBorder,
      showHover,
      showRowClick,
      showRowIndicator,
      showRowLines,
      children,
      rowRenderer,
      tableBodyRenderer: TableBodyRenderer = DefaultTableBodyRenderer,
      ...restProps
    } = this.props;

    const headers = this.getHeaders();
    return (
      <TableComponent
        {...{
          autoWidth,
          fixedHeaders,
          showBorder,
          showHover,
          showRowClick,
          showRowIndicator,
          showRowLines,
          ...restProps,
        }}
      >
        <thead>
          <tr>
            <Mobile>
              <HeaderColumns
                isMobile
                headers={headers}
                spacingBetweenCells={spacingBetweenCells}
                showIndent={showIndent}
                handleSort={this.handleSort}
                getCurrentHeader={this.getCurrentHeader}
              />
            </Mobile>
            <TabletAndDesktop>
              <HeaderColumns
                isMobile={false}
                headers={headers}
                spacingBetweenCells={spacingBetweenCells}
                showIndent={showIndent}
                handleSort={this.handleSort}
                getCurrentHeader={this.getCurrentHeader}
              />
            </TabletAndDesktop>
          </tr>
        </thead>
        <TableBodyRenderer>
          <Mobile>
            <RowRenderer
              isMobile
              rows={rows}
              headers={headers}
              headerProps={headerProps}
              onRowClick={onRowClick}
              rowRenderer={rowRenderer}
              setRowProps={this.setRowProps}
              showIndent={showIndent}
              spacingBetweenCells={spacingBetweenCells}
              spacingBetweenRows={spacingBetweenRows}
            />
          </Mobile>
          <TabletAndDesktop>
            <RowRenderer
              isMobile={false}
              rows={rows}
              headers={headers}
              headerProps={headerProps}
              onRowClick={onRowClick}
              rowRenderer={rowRenderer}
              setRowProps={this.setRowProps}
              showIndent={showIndent}
              spacingBetweenCells={spacingBetweenCells}
              spacingBetweenRows={spacingBetweenRows}
            />
          </TabletAndDesktop>
        </TableBodyRenderer>
      </TableComponent>
    );
  }
}

function DefaultTableBodyRenderer(props) {
  return <tbody {...props} />;
}

export const unhideHeader = (h: TableHeaderType): TableHeaderType => {
  if (h.hideHeader == null) {
    h.hideHeader = false;
  }
  return h;
};

export const filterHiddenHeader = (h: TableHeaderType) => !h.hideHeader;

export const setDefaultSortDirection = (h: TableHeaderType): TableHeaderType => {
  if (h.sortable && !h.sortDirection) {
    h.sortDirection = 'default';
  }
  // For tables being used in existing worlds where it can be undefined/null set it to true.
  if (typeof h.showMobile === 'undefined' || h.showMobile === null) {
    h.showMobile = true;
  }
  return h;
};
