import { strictObject } from '../../utils/strictObject';

const designColors = {
  white: '#fff',
  black: '#000',
  transparent: 'transparent',
  overlay: 'rgba(75, 75, 75, 0.8)',
  translucent: 'rgba(255,255,255,0.10)',

  // Lightspeed colours

  blue50: '#F5F7FF',
  blue100: '#D6DFFD',
  blue200: '#B9C8FB',
  blue300: '#829FF5',
  blue400: '#537DEC',
  blue500: '#2E61DE', // primary blue
  blue600: '#1844CC',
  blue700: '#0A2BB3',
  blue800: '#031795',
  blue900: '#000A71',
  blue1000: '#00034D',

  green50: '#F5FFF6',
  green100: '#D8FCDC',
  green200: '#BCFAC5',
  green300: '#88F398',
  green400: '#5BE873',
  green500: '#69C571',
  green600: '#1FC641',
  green700: '#0EAD30', // primary green
  green800: '#059118',
  green900: '#006F07',
  green1000: '#014D00',

  maple50: '#FFF5F5',
  maple100: '#FDD6D7',
  maple200: '#FBB9BB',
  maple300: '#F58286',
  maple400: '#ED5153',
  maple500: '#DE2E37', // primary red
  maple600: '#CC1820',
  maple700: '#B30A11',
  maple800: '#950307',
  maple900: '#710003',
  maple1000: '#4D0001',

  orange50: '#FFFBF5',
  orange100: '#FFEDD4',
  orange200: '#FFDFB4',
  orange300: '#FCC37A',
  orange400: '#F5A647',
  orange500: '#EA891E', // primary orange
  orange600: '#D5730C',
  orange700: '#BB5C02',
  orange800: '#9A4800',
  orange900: '#743300',
  orange1000: '#4D2000',

  gray00: '#F8F8F8',
  gray50: '#F2F5F8',
  gray100: '#D7DCE1',
  gray200: '#BCC4CA',
  gray300: '#A3ABB3',
  gray400: '#8B939B',
  gray500: '#737B83',
  gray600: '#5C626A',
  gray700: '#464A51', // primary gray
  gray800: '#2F3137',
  gray850: '#232429',
  gray900: '#18191D',
  gray950: '#111214',
  gray1000: '#020203',
};

export const colors = strictObject(designColors);
