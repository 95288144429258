import React from 'react';
import IconStyle from '../IconStyle';
import IconPropsType from '../types';
import IconSvg from './ArrowLeft.svg';

const IconArrowLeft: React.FunctionComponent<IconPropsType> = (props) => {
  return (
    <IconStyle {...props}>
      <IconSvg {...props} aria-label="arrowLeft" />
    </IconStyle>
  );
};

export default IconArrowLeft;
