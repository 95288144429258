import React from 'react';
import IconButton from '../IconButton';
import { IconArrowLeft, IconArrowRight } from '../Icon';

type NavbarType = {
  className: string;
  onNextClick: () => void;
  onPreviousClick: () => void;
  spacing: string;
  top: string;
};

function Navbar({
  onPreviousClick,
  onNextClick,
  className,
  spacing,
  top,
}: NavbarType): JSX.Element {
  const styleLeft: React.CSSProperties = {
    position: 'absolute',
    left: spacing,
    top,
  };
  const styleRight: React.CSSProperties = {
    position: 'absolute',
    right: spacing,
    top,
  };
  return (
    <div className={className}>
      <div style={styleLeft}>
        <IconButton type="button" onClick={(): void => onPreviousClick()}>
          <IconArrowLeft width={16} height={16} />
        </IconButton>
      </div>
      <div style={styleRight}>
        <IconButton type="button" onClick={(): void => onNextClick()}>
          <IconArrowRight width={16} height={16} />
        </IconButton>
      </div>
    </div>
  );
}

Navbar.defaultProps = {
  onPreviousClick: () => {},
  onNextClick: () => {},
  className: '',
  spacing: '24px',
  top: '16px',
};

export default Navbar;
