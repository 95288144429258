/**
 *  Random generator which generates a random number
 *  using current timestamp,
 *  and converts it to a string with base 36
 *  as numbers of 36 base will have 36 digits: from 0 to z.
 *  More information on base 36 : https://en.wikipedia.org/wiki/Base36
 */

const randomGenerator = () => Math.ceil(+new Date() * Math.random()).toString(36);

export default randomGenerator;
