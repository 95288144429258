import React, { Component } from 'react';
import styled from 'styled-components';
import Table, { TablePropsType } from './Table';
import Spacer from '../Spacer';
import Pagination from '../Pagination';
import { TableRowType } from './types';
import Caption from '../Caption';
import { PaginationPropsType } from '../Pagination/Pagination';

export type PaginatedTablePropsType = {
  /** Disables all internal page number handling.
   * Allows you to control the pagination completely.
   * Note that when this is enabled, only pass the current set of rows. */
  async?: boolean;
  /** Allows the current page to be fully controlled if provided. */
  currentPage?: number;
  initialPage?: number;
  /** Change how the item text is rendered */
  itemTextFormatter?: ({ currentItemStart, currentItemEnd, itemTotal }) => string;
  onPageChange?: (page: number, pageRows: TableRowType[]) => void;
  /** Required if async is true. Allows you to control the total number of pages displayed. */
  pageCount?: number;
  paginationProps?: Omit<Partial<PaginationPropsType>, 'onChange' | 'currentPage' | 'pageCount'>;
  /** Required if async is true. Used to display the total row count caption. */
  rowCount?: number;
  /** If not async, provide all rows. If async, only provide rows for the current page. */
  rows: TableRowType[];
  /** Enables pagination with this number of rows per page. */
  rowsPerPage: number;
  /** Properties for the wrapped table. See the Table component for details. */
  tableProps?: Omit<TablePropsType, 'rows'>;
};
type StateType = {
  currentPage: number;
};

export default class PaginatedTable extends Component<PaginatedTablePropsType, StateType> {
  static defaultProps = {
    initialPage: 0,
  };

  constructor(props: PaginatedTablePropsType) {
    super(props);

    const { async, pageCount, rowCount } = props;
    if (async) {
      if (typeof pageCount === 'undefined' || typeof rowCount === 'undefined') {
        throw new Error('Must provide pageCount and rowCount when in async mode.');
      }
    }

    this.state = {
      currentPage: props.initialPage,
    };
  }

  static getDerivedStateFromProps(
    { async, rows, rowsPerPage, onPageChange }: PaginatedTablePropsType,
    { currentPage }: StateType,
  ) {
    // If pageCount is now less than the current page, and we're not in async mode,
    // reset to the first page
    const pageCount = Math.ceil(rows.length / rowsPerPage);
    if (!async && currentPage >= pageCount) {
      const newPage = Math.max(pageCount - 1, 0);
      if (onPageChange) {
        onPageChange(newPage, paginateRows(rows, newPage, rowsPerPage));
      }
      return {
        currentPage: newPage,
      };
    }
    return {};
  }

  componentDidMount() {
    // So the consumer knows which pages we start with
    this.triggerOnPageChange(this.state.currentPage);
  }

  getCurrentPage() {
    const { currentPage } = this.props;
    if (typeof currentPage !== 'undefined') {
      return currentPage;
    }
    return this.state.currentPage;
  }

  getPageRows(rows = this.props.rows) {
    const { rowsPerPage, async } = this.props;

    if (async) {
      return rows;
    }
    return paginateRows(rows, this.getCurrentPage(), rowsPerPage);
  }

  getPageCount(): number {
    const { async, rowsPerPage, pageCount, rows } = this.props;

    if (async) {
      return pageCount;
    }
    return Math.ceil(rows.length / rowsPerPage);
  }

  getRowCount(): number {
    const { async, rowCount, rows } = this.props;
    if (async && typeof rowCount !== 'undefined') {
      return rowCount;
    }
    return rows.length;
  }

  updateCurrentPage = (page: number) => {
    this.setState({ currentPage: page }, () => {
      this.triggerOnPageChange(page);
    });
  };

  triggerOnPageChange(page: number) {
    const { onPageChange } = this.props;
    if (onPageChange) {
      onPageChange(page, this.getPageRows());
    }
  }

  formatItemText({
    currentItemStart,
    currentItemEnd,
    itemTotal,
  }: {
    currentItemEnd: number;
    currentItemStart: number;
    itemTotal: number;
  }): string {
    const { itemTextFormatter } = this.props;
    if (!itemTextFormatter) {
      return `${currentItemStart}-${currentItemEnd} of ${itemTotal} items`;
    }
    return itemTextFormatter({ currentItemStart, currentItemEnd, itemTotal });
  }

  renderPagination() {
    const { rowsPerPage, paginationProps } = this.props;
    const currentPage = this.getCurrentPage();
    const pageCount = this.getPageCount();
    const rows = this.getPageRows();

    const firstPage = currentPage * rowsPerPage;
    const lastPage = firstPage + rows.length;

    return (
      <>
        <Spacer spacing="s16" />
        <SpaceBetween>
          <Caption>
            {this.formatItemText({
              currentItemStart: Math.min(firstPage + 1, lastPage),
              currentItemEnd: lastPage,
              itemTotal: this.getRowCount(),
            })}
          </Caption>
          <PaginationStyled
            currentPage={currentPage}
            onChange={this.updateCurrentPage}
            pageCount={pageCount}
            {...paginationProps}
          />
        </SpaceBetween>
      </>
    );
  }

  render() {
    const { tableProps } = this.props;

    return (
      <Root>
        <TableWrap>
          <Table {...tableProps} rows={this.getPageRows()} />
        </TableWrap>
        {this.renderPagination()}
      </Root>
    );
  }
}

/**
 * Slices off the current page of rows.
 * @param rows
 * @param currentPage Zero-indexed page number.
 * @param rowsPerPage
 */
export function paginateRows<T>(rows: T[], currentPage: number, rowsPerPage: number): T[] {
  return rows.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage);
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const TableWrap = styled.div`
  flex: 1 1 auto;
`;
const SpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > *:first-child {
    flex-shrink: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    > * {
      + * {
        margin-top: 8px;
      }
    }
  }
`;
const PaginationStyled = styled(Pagination)`
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;
