import React, { FC, memo, MouseEvent, useState } from 'react';
import styled from 'styled-components';
import Caption from '../Caption';
import IconButton from '../IconButton';
import { IconDelete, IconFile, IconTick } from '../Icon';
import randomGenerator from '../../utils/randomGenerator';
import { modes, ModeType, TileType } from './types';
import { colors } from '../../themes';
import { borderDefault } from '../../mixins';
import TextTruncate from '../TextTruncate';

type TilePropsType = {
  coverTile?: boolean;
  currentMode: ModeType;
  id: number;
  itemIndex?: number;
  onClick?: (event: MouseEvent<HTMLDivElement>, id: number) => void;
  onDelete?: (event: MouseEvent<HTMLButtonElement>, id: number) => void;
  onSelect?: (event: MouseEvent<HTMLDivElement>, id: number) => void;
  ordered?: boolean;
  selectedItem?: number;
  tile: TileType;
};

type FileType = 'image' | 'other' | null;

const Tile: FC<TilePropsType> = ({
  id,
  tile,
  onClick,
  onSelect = () => {},
  onDelete = () => {},
  selectedItem,
  itemIndex,
  currentMode = 'view',
  ordered = false,
  coverTile = false,
}) => {
  function handleDelete(event: MouseEvent<HTMLButtonElement>) {
    if (onDelete) {
      onDelete(event, id);
    }
  }

  function handleTileClick(event: MouseEvent<HTMLDivElement>) {
    if (onClick) {
      onClick(event, id);
    }
    if (currentMode !== modes.select || !onSelect) {
      return;
    }
    onSelect(event, id);
  }

  function getSelectedItemBackground(): string {
    if (currentMode === modes.select && typeof selectedItem === 'number') {
      return colors.gray700;
    }
    if (currentMode === modes.delete) {
      return '#FFFFFF';
    }
    return '';
  }

  // eslint-disable-next-line eqeqeq
  const border = typeof selectedItem != 'undefined' ? `solid ${colors.gray700} 2px` : 'unset';

  const selectedItemBackGround = getSelectedItemBackground();

  // eslint-disable-next-line eqeqeq
  const selectedItemMargin = typeof selectedItem != 'undefined' ? '-2px' : 'unset';

  return (
    <StyledTile border={border} onClick={handleTileClick}>
      {currentMode !== modes.view && (
        <SelectionContainer backgroundColor={selectedItemBackGround} margin={selectedItemMargin}>
          <TileWithIcon
            id={id}
            currentMode={currentMode}
            itemIndex={itemIndex}
            selectedItem={selectedItem}
            onDelete={handleDelete}
            ordered={ordered}
          />
        </SelectionContainer>
      )}
      <ImageOrColorTile tile={tile} coverTile={coverTile} />
      {tile.label && (
        <LabelContainer coverTile={coverTile}>
          <Caption align="center">
            <TextTruncate mode="ellipsis">{tile.label}</TextTruncate>
          </Caption>
        </LabelContainer>
      )}
    </StyledTile>
  );
};

const ImageOrColorTile = memo(({ tile, coverTile }: { coverTile: boolean; tile: TileType }) => {
  const [fileType, setFileType] = useState<FileType>(null);

  if (tile.url) {
    if (fileType === 'other') {
      return (
        <OtherFileTypeIcon data-chamid="Tile_OtherFileTypeIcon">
          <IconFile width={48} height={48} label={tile.label} />
        </OtherFileTypeIcon>
      );
    }
    return (
      <StyledImage
        data-chamid="Tile_Image"
        src={tile.url}
        alt=""
        label={tile.label}
        coverTile={coverTile}
        contain={tile.contain}
        onLoad={() => setFileType('image')}
        onError={() => setFileType('other')}
      />
    );
  }
  return <ColorTile color={tile.color} label={tile.label} />;
});

const TileWithIcon = memo(
  ({
    id,
    currentMode,
    ordered,
    selectedItem,
    itemIndex,
    onDelete,
  }: Pick<
    TilePropsType,
    'id' | 'currentMode' | 'ordered' | 'selectedItem' | 'itemIndex' | 'onDelete'
  >) => {
    // Select mode and ordered
    if (currentMode === modes.select && ordered && typeof selectedItem === 'number') {
      return <StyledSelectedNumber>{itemIndex + 1}</StyledSelectedNumber>;
      // Unordered
    }
    if (currentMode === modes.select && !ordered && itemIndex >= 0) {
      return <StyledIconButton>{icons.select}</StyledIconButton>;
    }
    if (currentMode === modes.delete) {
      // Delete mode
      return (
        <StyledIconButton onClick={() => onDelete && onDelete(null, id)}>
          {icons.delete}
        </StyledIconButton>
      );
    }
    return null;
  },
);

const centerIt = { justifyContent: 'center', alignItems: 'center' };

const icons = {
  view: null,
  select: <IconTick key={randomGenerator()} color="#FFFFFF" style={centerIt} />,
  delete: (
    <IconDelete key={randomGenerator()} color="#BC271A" height={20} width={20} style={centerIt} />
  ),
};

const StyledSelectedNumber = styled.div`
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  line-height: 16px;
`;

const StyledTile = styled.div<{ border?: string }>`
  min-height: 128px;
  min-width: 128px;
  border-radius: 4px;
  background-color: #e3e3e3;
  display: flex;
  flex-direction: column;
  position: relative;
  border: ${(props): string => props.border};
`;

const SelectionContainer = styled.div<{ backgroundColor?: string; margin?: string }>`
  height: 32px;
  width: 32px;
  margin: ${(props): string => props.margin};
  position: absolute;
  top: 8px;
  right: 8px;
  border: solid #ffffff 2px;
  border-radius: 50%;
  background-color: ${(props): string => props.backgroundColor};
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  ${centerIt}
`;

const StyledImage = styled.img<{ contain?: boolean; coverTile?: boolean; label?: string }>`
  border-radius: ${(props): string => (props.label && !props.coverTile ? '4px 4px 0 0' : '4px')};
  width: 100%;
  object-fit: ${(props): string => (props.contain ? 'contain' : 'cover')};
  flex: 1 1 auto;
`;

const ColorTile = styled.div<{ color?: string; label?: string }>`
  background-color: ${(props): string => (props.color ? props.color : '')};
  border-radius: ${(props): string => (props.label ? '4px 4px 0 0' : '4px')};
  width: 100%;
  flex: 1 1 auto;
`;

const LabelContainer = styled.div<{ coverTile?: boolean }>`
  display: flex;
  flex-shrink: 0;
  ${centerIt};
  height: 40px;
  ${borderDefault}
  border-radius: 0 0 4px 4px;
  background-color: ${({ theme }) => theme.colors.surface};
  ${(props) => props.coverTile && { position: 'absolute', bottom: 0, width: '100%' }};
  padding: 0 4px;
`;

const StyledIconButton = styled(IconButton)`
  &:hover {
    background: none;
  }
`;

const OtherFileTypeIcon = styled.div<{ label?: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${(props): string => (props.label ? '4px 4px 0 0' : '4px')};
  opacity: 0.5;
  pointer-events: none;
`;

export default memo(Tile);
