import React from 'react';
import styled from 'styled-components';
import { DataPropsType, getDataAttributes } from '../../utils/dataAttributes';
import Alignment from '../Alignment';
import Group from '../Group';
import { Heading2 } from '../Heading';
import { IconClose } from '../Icon';
import IconButton from '../IconButton';

type PropsType = DataPropsType & {
  onClose?: (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>) => void;
  showCloseButton?: boolean;
  title?: string;
};

export const ModalHeader: React.FunctionComponent<PropsType> = ({
  onClose,
  title,
  showCloseButton,
  data,
}) => (
  <ModalHeaderStyle>
    <Group horizontal full>
      <Heading2>{title}</Heading2>
      {showCloseButton && (
        <Alignment horizontal="right">
          <IconButton onClick={onClose} data={getDataAttributes(data, 'close', '')}>
            <IconClose width={16} height={16} />
          </IconButton>
        </Alignment>
      )}
    </Group>
  </ModalHeaderStyle>
);

ModalHeader.defaultProps = {
  title: '',
  showCloseButton: true,
  onClose: () => {},
};

const ModalHeaderStyle = styled.div`
  // Work around to handle layout with Group for the close button
  .group .item {
    width: 100%;
  }
  @supports (display: grid) {
    .group .item {
      width: inherit;
    }
  }
`;

export default ModalHeader;
