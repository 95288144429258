import React, { memo, FC } from 'react';
import styled from 'styled-components';

import { Loading } from '../Loading/Loading';
import { getDataAttributes, DataPropsType } from '../../utils/dataAttributes';

type PropsType = DataPropsType & {
  color?: string;
  title?: string;
};

const LoadingFill: FC<PropsType> = ({ data, color, title }) => {
  return (
    <LoadingContainer {...getDataAttributes(data)}>
      <Loading
        color={color}
        title={title}
        overlay={false}
        showTitle={false}
        usedOnDarkBackground={false}
      />
    </LoadingContainer>
  );
};

export default memo(LoadingFill);

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  z-index: ${({ theme }): number => theme.zIndex.overlay};
  display: flex;
  align-items: center;
  justify-content: center;
`;
