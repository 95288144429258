import React from 'react';
import styled from 'styled-components';
import { colors } from '../../themes';

const ContainerStyle = styled.div<PropsType>`
  height: 100%;
  background-color: ${colors.transparent};
  margin: ${(props): string => (props.hideMargin ? '0px' : '24px 16px 0')};
  display: flex;
  justify-content: ${(props): string => (props.centered ? 'center' : 'flex-start')};
  align-items: ${(props): string => (props.verticallyCentered ? 'center' : 'flex-start')};

  @media screen and (min-width: 768px) {
    margin: ${(props): string => (props.hideMargin ? '0px' : '24px 32px 0')};
  }
`;

const InnerContainer = styled.div<PropsType>`
  width: 100%;
  max-width: ${(props): string => (props.centered ? props.maxWidth : '100%')};
`;

type PropsType = {
  centered?: boolean;
  hideMargin?: boolean;
  maxWidth?: string;
  verticallyCentered?: boolean;
};

/**
 * A container manages the top and side margins the main content of the page.
 *
 * It also controls if the content should be centred when on larger screens.
 * Content is set to a max size of 768px when centred.
 */
export const Container: React.FunctionComponent<React.PropsWithChildren<PropsType>> = ({
  centered,
  verticallyCentered,
  maxWidth,
  hideMargin,
  children,
}) => {
  return (
    <ContainerStyle {...{ centered, verticallyCentered, hideMargin }}>
      <InnerContainer {...{ centered, maxWidth, hideMargin }}>{children}</InnerContainer>
    </ContainerStyle>
  );
};

Container.defaultProps = {
  centered: false,
  verticallyCentered: false,
  maxWidth: '768px',
  hideMargin: false,
};

export default Container;
