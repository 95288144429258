import React from 'react';
import { mount, ReactWrapper } from 'enzyme';
import GlobalContexts from './globalContexts';

function wrapWithTheme(component: React.ReactElement): React.ReactElement<any> {
  return <GlobalContexts>{component}</GlobalContexts>;
}

const mountWithTheme: <P = any, S = any>(
  component: React.ReactElement<P>,
) => ReactWrapper<P, S, React.Component<P, S>> = (component) => {
  const WrappingThemeProvider: React.FC<React.PropsWithChildren<void>> = ({ children }) => (
    <GlobalContexts>{children}</GlobalContexts>
  );

  return mount(component, { wrappingComponent: WrappingThemeProvider });
};

export { mountWithTheme, wrapWithTheme };
