import React from 'react';
import IconStyle from '../IconStyle';
import IconPropsType from '../types';
import IconSvg from './Tick.svg';

export default function IconTick(props: IconPropsType): JSX.Element {
  return (
    <IconStyle usingPath {...props}>
      <IconSvg {...props} aria-label="tick" />
    </IconStyle>
  );
}
