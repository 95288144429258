import React, { Component } from 'react';

import { ColorExtractor } from 'react-color-extractor';

import GridContainer from '../Tiles/GridContainer';
import Tile from '../Tiles/Tile';
import Tiles from '../Tiles/Tiles';
import { TileType } from '../Tiles/types';

type PropsType = {
  imageUrl?: string;
  maxColors?: number;
  onLoad?: (colors: string[], initialColor: string) => void;
  onSelect?: (color: string) => void;
  selectedColor?: string;
};

type ColorType = {
  colors?: string[];
};

type StateType = {
  selectedColor?: string;
} & ColorType;

export default class ColorPalette extends Component<PropsType, StateType> {
  static defaultProps = {
    maxColors: 6,
    selectedColor: null,
  };

  constructor(props: PropsType) {
    super(props);
    this.state = {
      colors: [],
      selectedColor: '',
    };
  }

  componentDidUpdate(prevProps: PropsType) {
    if (prevProps.selectedColor !== this.props.selectedColor) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selectedColor: this.props.selectedColor });
    }
  }

  getColors = (detectedColors?: string[]) => {
    const deduplicatedColors = Array.from(new Set(detectedColors).values());
    const colors = deduplicatedColors.slice(0, this.props.maxColors);

    this.setState({ colors: [...colors], selectedColor: colors[0] });
    if (this.props.onLoad) {
      this.props.onLoad(colors, colors[0]);
    }
  };

  onSelect = (selectedIndexes: number[]) => {
    const index = selectedIndexes[0] || 0;
    this.setState(
      (state) => ({ selectedColor: state.colors[index] }),
      () => {
        if (this.props.onSelect) {
          this.props.onSelect(this.state.colors[index]);
        }
      },
    );
  };

  getTiles = (): TileType[] => {
    return this.state.colors.map(
      (color: string, index: number): TileType => {
        const selected = this.state.selectedColor === color;
        return { id: index, color, label: color.toUpperCase(), selected };
      },
    );
  };

  render() {
    const { imageUrl, maxColors } = this.props;
    const { selectedColor } = this.state;
    return (
      <>
        <ColorExtractor getColors={this.getColors} src={imageUrl} maxColors={maxColors} />
        <ColorTiles
          onSelect={this.onSelect}
          selectedColor={selectedColor}
          tiles={this.getTiles()}
        />
      </>
    );
  }
}

export const ImageTile = ({ imageUrl }: { imageUrl: string }): JSX.Element => (
  <GridContainer>
    <Tile id={0} tile={{ url: imageUrl }} currentMode="view" />
  </GridContainer>
);

export const ColorTiles = ({
  onSelect,
  tiles,
  selectedColor,
}: {
  onSelect: (item: number[]) => void;
  selectedColor: string;
  tiles: TileType[];
}): JSX.Element => (
  <GridContainer>
    <Tiles tiles={tiles} mode="select" onSelect={onSelect} key={selectedColor} />
  </GridContainer>
);
