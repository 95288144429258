import { Property } from 'csstype';
import React, { HTMLAttributes } from 'react';

import styled, { css } from 'styled-components';
import { darkModeCss } from '../../utils/darkModeCss';
import { declareVariants } from '../../utils/declareVariants';
import { getVariationName } from '../../utils/getVariationName';
import { typographyStyleFn, TypographyProps } from '../../utils/styleFns';

const StyledCaption = styled.div<{ bold: boolean; variant?: string } & TypographyProps>`
  font-family: ${({ theme }) => theme.fonts.sans};
  font-size: ${({ theme }) => theme.fontSizes.caption};
  line-height: ${({ theme }) => theme.lineHeights.caption};
  font-weight: ${({ theme, bold }) => (bold ? theme.fontWeights.bold : theme.fontWeights.normal)};
  color: ${({ theme }) => theme.colors.text};

  ${declareVariants({
    error: css`
      color: ${({ theme }) => theme.colors.interactiveAlert};
    `,
    hint: css`
      color: ${({ theme }) => theme.colors.gray500};

      ${darkModeCss(
        css`
          color: ${({ theme }) => theme.colors.text};
        `,
      )};
    `,
  })}

  ${typographyStyleFn}
`;

type Variants = 'error' | 'hint';

type PropsType = {
  align?: Property.TextAlign;
  bold?: boolean;
  /**
   * **Deprecated** Use variant instead
   * @deprecated
   */
  error?: boolean;
  variant?: Variants;
};
export const Caption: React.FunctionComponent<
  React.PropsWithChildren<PropsType & HTMLAttributes<HTMLSpanElement>>
> = ({ error = false, align = 'left', bold = false, variant, children, ...restProps }) => {
  const derivedVariant = getVariationName<Variants>(
    {
      error,
    },
    variant,
  );

  return (
    <StyledCaption bold={bold} align={align} variant={derivedVariant} {...restProps}>
      {children}
    </StyledCaption>
  );
};

export default Caption;
