import React, { ChangeEvent, Component } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import classnames from 'classnames';
import { Property } from 'csstype';
import Group from '../Group';
import Label from '../Label';
import Caption from '../Caption';
import {
  TextInputOnBlurCallBackReturnType,
  TextInputAlignType,
  TextInputFilterType,
} from './types';
import { borderDefault, borderFocused, borderError } from '../../mixins';
import { getDataAttributes, DataPropsType } from '../../utils/dataAttributes';
import { IconCalendar } from '..';

const DEFAULT_TEXT_MAX_LENGTH = 45;

const getLayoutBorderRadius = (
  left?: JSX.Element | null,
  right?: JSX.Element | null,
  defaultRadius?: string,
): string => {
  if (left) {
    return '0 4px 4px 0';
  }
  if (right) {
    return '4px 0 0 4px';
  }
  return defaultRadius;
};

export const Layout = styled.div<
  Pick<
    PropsType,
    'connectedLeft' | 'connectedRight' | 'cursor' | 'disabled' | 'maxWidth' | 'slim'
  > & { padding?: boolean | null }
>`
  &&&& {
    height: ${({ slim }): string => (slim ? '32px' : '48px')};
    width: 100%;
    max-width: ${({ maxWidth }): string => maxWidth || '100%'};
    display: flex;
    flex-direction: row;
    align-items: center;
    -webkit-font-smoothing: inherit; // Override existing Kounta css
    cursor: ${({ cursor }): string => cursor};
    ${({ theme, padding }) => (padding ? `padding: ${theme.space.s12};` : undefined)}

    ${borderDefault};

    border-radius: ${({ connectedLeft, connectedRight, theme }): string =>
      getLayoutBorderRadius(connectedLeft, connectedRight, theme.border.radius)};
    background-color: ${({ theme, disabled }): string =>
      disabled ? theme.form.inactiveBackgroundColor : theme.form.backgroundColor};
    ${borderDefault};

    &:focus,
    &:focus-within {
      ${borderFocused};
      z-index: 10;
    }

    &.error {
      ${borderError};
    }

    &.readonly {
      background-color: ${({ theme }): string => theme.form.inactiveBackgroundColor};
      cursor: ${({ cursor }): string => cursor || 'not-allowed'};

      &:focus,
      &:focus-within {
        border: ${({ theme }): string => theme.border.defaultBorder};
      }
    }

    .prefix,
    .suffix {
      color: ${({ theme }): string => theme.text.hint};
    }
    .prefix {
      padding-left: 11px;
      margin-right: 1px;
    }
    .suffix {
      padding-right: 11px;
      margin-left: 1px;
    }
  }
`;

const Input = styled.input<Pick<PropsType, 'align' | 'cursor' | 'disabled' | 'filter' | 'slim'>>`
  &&&& {
    /* Override existing Kounta */
    -webkit-font-smoothing: inherit;
    height: unset !important;
    padding: 0 !important;

    width: 100%;
    float: none;
    margin: 0 11px;
    border: none;
    text-align: ${({ align }): string => align};
    font: ${({ theme }): string => theme.text.body};
    color: ${({ theme, disabled }): string =>
      disabled ? theme.form.inactiveColor : theme.colors.text};
    background-color: ${({ theme, disabled }): string =>
      disabled ? theme.form.inactiveBackgroundColor : theme.form.backgroundColor};
    cursor: ${({ cursor, disabled }): string => cursor || (disabled ? 'not-allowed' : 'text')};

    &::placeholder {
      color: ${({ theme }): string => theme.text.hint};
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &.readonly {
      background-color: ${({ theme }): string => theme.form.inactiveBackgroundColor};
      cursor: ${({ cursor }): string => cursor || 'not-allowed'};
      &:focus {
        outline: none;
      }
    }
    &[type='number'] {
      touch-action: none;
      ::-webkit-inner-spin-button,
      ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    /* Fix date field appearance on iOS Safari */
    &[type='date'] {
      appearance: none;
      height: calc(100% - 2px) !important;

      &::-webkit-calendar-picker-indicator {
        opacity: 0;
      }
    }

    ${({ filter }) => filterLookup[filter]}
  }
`;

const Icon = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: -36px;
  margin-right: ${({ theme }) => theme.space.s12};
  pointer-events: none;
`;

const ComponentsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const ConnectedRight = styled.div<{ width?: string }>`
  margin-left: -1px;
  width: ${({ width }): string => width};
  && {
    select {
      border-radius: 0 4px 4px 0;
    }
  }
`;

const ConnectedLeft = styled.div<{ width?: string }>`
  margin-right: -1px;
  width: ${({ width }): string => width};
  && {
    select {
      border-radius: 4px 0 0 4px;
    }
  }
`;

const HelperTextContainer = styled.div<{ align: string; maxWidth: string }>`
  max-width: ${({ maxWidth }): string => maxWidth};
  text-align: ${({ align }): string => align};
`;

export type PropsType = DataPropsType & {
  align?: TextInputAlignType;
  autoFocus?: boolean;
  connectedElementWidth?: string;
  connectedLeft?: JSX.Element | null;
  connectedRight?: JSX.Element | null;
  containerClassName?: string;
  /**
   * (Recommended) Set to true to behave as a controlled component where the user must manage state
   */
  controlled?: boolean;
  cursor?: Property.Cursor;
  disabled?: boolean;
  error?: boolean;
  errorTextList?: string[];
  /**
   * Can be used for blurring out text
   */
  filter?: TextInputFilterType;
  helperText?: string;
  initialValue?: string;
  inputFieldId?: string;
  inputFieldName?: string;
  inputHtmlType?: string;
  /**
   * In iOS (specifically iPhones) to show numeric keyboard with a decimal
   */
  inputMode?: 'text' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
  inputRef?: React.RefObject<HTMLInputElement>;
  label?: string;
  /** No effect for input type text, but has effect for date, time, etc... */
  max?: string;
  maxLength?: number;
  maxWidth?: string;
  /** No effect for input type text, but has effect for date, time, etc... */
  min?: string;
  minLength?: number;
  onBlur?: (
    value: string,
    id: string,
  ) => Promise<TextInputOnBlurCallBackReturnType | void> | TextInputOnBlurCallBackReturnType | void;
  onChange?: (value: string, id?: string, event?: ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  /**
   * An onKeyPress listener,
   * For example, NumberInput component uses it to allow only negative sign, decimal point and
   * digits.
   */
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onWheel?: (e: WheelEvent) => void;
  /**
   * A Regex pattern for native inputs
   */
  pattern?: string | RegExp;
  placeholder?: string;
  prefix?: React.ReactNode | null;
  readOnly?: boolean;
  required?: boolean;
  slim?: boolean;
  /** Used for number inputs */
  step?: number;
  suffix?: React.ReactNode | null;
  /** Used to render a helper message for when inputs fail the provided pattern. */
  title?: string;
  value?: React.ReactText;
};

export type StateType = {
  value: string;
};

/**
 * TextInput is the root component for NumberInput
 *
 * This component is best used as a controlled component
 */
export default class TextInput extends Component<PropsType, StateType> {
  static defaultProps = {
    inputHtmlType: '',
    containerClassName: '',
    label: '',
    initialValue: '',
    inputRef: null,
    value: null,
    placeholder: '',
    readOnly: false,
    required: false,
    helperText: '',
    error: false,
    errorTextList: [],
    maxLength: DEFAULT_TEXT_MAX_LENGTH,
    prefix: null,
    suffix: null,
    align: 'left',
    connectedElementWidth: 'auto',
    slim: false,
    disabled: false,
    onChange: () => {},
    onBlur: () => {},
    onFocus: () => {},
    onClick: () => {},
    onKeyPress: () => {},
    onKeyDown: () => {},
    onKeyUp: () => {},
    pattern: undefined,
    onWheel: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      value: (props.value || props.initialValue).toString(),
    };
  }

  componentDidMount() {
    this.addMouseWheelEvent();

    if (this.props.autoFocus) {
      this.focusInput();
    }
  }

  componentDidUpdate(prevProps: PropsType) {
    if (
      this.props.value != null &&
      this.props.value !== prevProps.value &&
      this.props.value !== this.state.value
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ value: this.props.value.toString() });
    }
  }

  inputRef = React.createRef<HTMLInputElement>();

  getInputRef(): React.RefObject<HTMLInputElement> {
    return this.props.inputRef || this.inputRef;
  }

  /**
   * An internal method exposed for focusing on the textInput.
   */
  focusInput = () => {
    if (this.getInputRef().current) {
      this.getInputRef().current.focus();
    }
  };

  /**
   * To get around passive event issues with chrome for mouseWheel event on a
   * numberInput.
   */
  addMouseWheelEvent = () => {
    if (this.getInputRef().current) {
      this.getInputRef().current.addEventListener('wheel', this.props.onWheel);
    }
  };

  handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newInputValue = event.target.value;
    // Avoid cursor jumping problem by seperate set `value` and validation:
    // https://stackoverflow.com/questions/28922275/in-reactjs-why-does-setstate-behave-differently-when-called-synchronously/28922465#28922465
    // 1. Immedicately set the `value`.
    // Pass value to any parent
    this.props.onChange(newInputValue, this.props.inputFieldId, event);
    this.setState({ value: newInputValue });
  };

  /**
   * Use a regex pattern onKeyPress to allow/block characters from being entered in the input.
   * @param e
   */
  onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { which, keyCode } = e;
    const { pattern, onKeyPress } = this.props;

    if (pattern) {
      const charCode = typeof which === 'undefined' ? keyCode : which;
      const charStr = String.fromCharCode(charCode);
      const regex = typeof pattern === 'string' ? new RegExp(pattern) : pattern;
      if (!charStr.match(regex)) {
        e.preventDefault();
      }
    }
    onKeyPress(e);
  };

  handleOnBlur = async (): Promise<void> => {
    if (!this.props.onBlur) {
      return;
    }

    // We can deal with both async and regular functions here,
    // which means the caller isn't forced to use an async function
    // See https://stackoverflow.com/a/43416722
    const maybePromise = Promise.resolve(
      this.props.onBlur(this.state.value, this.props.inputFieldId),
    );
    const result = await maybePromise;

    // Give the caller the ability to reset value to initial while `onBlur`:
    if (result && result.resetToInitialValue) {
      this.setState({ value: this.props.initialValue });
    }
  };

  renderErrorMessages = () => {
    const { errorTextList, align, maxWidth } = this.props;
    return errorTextList.map((errorText: string, index: number) => (
      <HelperTextContainer
        key={index} // eslint-disable-line react/no-array-index-key
        maxWidth={maxWidth}
        align={align}
      >
        <Caption error>{errorText}</Caption>
      </HelperTextContainer>
    ));
  };

  renderHelperTextOrErrorMessage = (): JSX.Element | null => {
    const { helperText, align, maxWidth } = this.props;
    return helperText ? (
      <HelperTextContainer maxWidth={maxWidth} align={align}>
        <Caption>{helperText}</Caption>
      </HelperTextContainer>
    ) : null;
  };

  renderSuffixMarkup = (): JSX.Element | null => {
    if (this.props.inputHtmlType === 'date') {
      return (
        <Icon data-chamid="DateIcon">
          <IconCalendar width={24} height={24} />
        </Icon>
      );
    }

    if (this.props.suffix) {
      return (
        <div className="suffix" id={`${this.props.inputFieldId}Suffix`}>
          {this.props.suffix}
        </div>
      );
    }

    return null;
  };

  render(): React.ReactNode {
    const { filter, data, disabled } = this.props;

    const classes = classnames(
      this.props.error ? 'error' : '',
      this.props.readOnly ? 'readonly' : '',
    );

    const containerClasses = classnames(classes, this.props.containerClassName);

    const prefixMarkup = this.props.prefix ? (
      <div className="prefix" id={`${this.props.inputFieldId}Prefix`}>
        {this.props.prefix}
      </div>
    ) : null;

    return (
      <Group spacing="4px" height="fit-content">
        {this.props.label && <Label htmlFor={this.props.inputFieldId}>{this.props.label}</Label>}
        <ComponentsContainer>
          {this.props.connectedLeft && (
            <ConnectedLeft width={this.props.connectedElementWidth}>
              {this.props.connectedLeft}
            </ConnectedLeft>
          )}
          <Layout
            className={containerClasses}
            maxWidth={this.props.maxWidth}
            slim={this.props.slim}
            connectedLeft={this.props.connectedLeft}
            connectedRight={this.props.connectedRight}
            disabled={disabled}
            cursor={this.props.cursor}
          >
            {prefixMarkup}
            <Input
              ref={this.getInputRef()}
              id={this.props.inputFieldId}
              name={this.props.inputFieldName}
              required={this.props.required}
              type={this.props.inputHtmlType}
              value={this.props.controlled ? this.props.value : this.state.value}
              className={classes}
              align={this.props.align}
              min={this.props.min}
              minLength={this.props.minLength}
              max={this.props.max}
              maxLength={this.props.maxLength}
              placeholder={this.props.placeholder}
              readOnly={this.props.readOnly}
              onChange={this.handleInputChange}
              onBlur={this.handleOnBlur}
              onFocus={this.props.onFocus}
              onClick={this.props.onClick}
              slim={this.props.slim}
              disabled={disabled}
              pattern={convertPatternToHtmlAttr(this.props.pattern)}
              inputMode={this.props.inputMode}
              onKeyPress={this.onKeyPress}
              onKeyDown={this.props.onKeyDown}
              onKeyUp={this.props.onKeyUp}
              step={this.props.step}
              filter={filter}
              cursor={this.props.cursor}
              data-chaminputid="textInput"
              {...getDataAttributes(data)}
            />
            {this.renderSuffixMarkup()}
          </Layout>
          {this.props.connectedRight && (
            <ConnectedRight width={this.props.connectedElementWidth}>
              {this.props.connectedRight}
            </ConnectedRight>
          )}
        </ComponentsContainer>
        {this.renderErrorMessages()}
        {this.renderHelperTextOrErrorMessage()}
      </Group>
    );
  }
}

function convertPatternToHtmlAttr(pattern?: string | RegExp): string | undefined {
  if (typeof pattern === 'undefined' || pattern === null) {
    return undefined;
  }
  let patternStr = typeof pattern === 'string' ? pattern : pattern.toString();

  // HTML patterns don't support starting/ending slashes
  if (patternStr.startsWith('/') && patternStr.endsWith('/')) {
    patternStr = patternStr.substr(1, patternStr.length - 2);
  }
  return patternStr;
}

const filterLookup = {
  blur: ({
    theme: {
      form: {
        filter: { blur },
      },
    },
  }: {
    theme: DefaultTheme;
  }): string => `
      background: ${blur.backgroundColor};
      color: ${blur.color};
      text-shadow: ${blur.textShadow};
    `,
};
