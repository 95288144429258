import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import { colors } from '../../themes';

type PropsType = {
  circlePadding?: string;
  circleSize?: string;
  cols?: number;
  rows?: number;
};
const DragHandle: FunctionComponent<React.PropsWithChildren<PropsType>> = (props): ReactElement => {
  const { rows, cols } = props;
  return (
    <HandleCircles {...props}>
      {new Array(rows * cols).fill(0).map((z: number, i: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <HandleCircle key={i} />
      ))}
    </HandleCircles>
  );
};
DragHandle.defaultProps = {
  rows: 3,
  cols: 2,
  circleSize: '4px',
  circlePadding: '4px',
};
export default DragHandle;

const HandleCircles = styled.div<PropsType>`
  display: grid;
  grid-gap: ${({ circlePadding }) => circlePadding};
  grid-template-columns: repeat(${({ cols, circleSize }) => `${cols}, ${circleSize}`});
  grid-template-rows: repeat(${({ rows, circleSize }) => `${rows}, ${circleSize}`});
`;
const HandleCircle = styled.div`
  border-radius: 50%;
  background-color: ${colors.gray500};
`;
