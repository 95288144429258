import React from 'react';
import IconStyle from '../IconStyle';
import { IconPropsType } from '../types';
import IconSvg from './Spinner.svg';

export default function IconSpinner(props: IconPropsType): JSX.Element {
  return (
    <IconStyle usingPath {...props}>
      <IconSvg {...props} aria-label="spinner" />
    </IconStyle>
  );
}
