export const space = {
  s0: '0',
  s4: '4px',
  s8: '8px',
  s12: '12px',
  s16: '16px',
  s24: '24px',
  s48: '48px',
  s56: '56px',
  s96: '96px',
};

export const borderWidths = {
  default: '1px',
  selected: '2px',
};

export const radii = {
  default: '4px',
  small: '2px',
  extraLarge: '48px',
};

export const shadows = {
  default: '0 2px 10px -2px rgba(0,0,0,0.10)',
  footer: '0 -2px 10px -2px rgba(0,0,0,0.10)',
};

export const zIndices = {
  behind: -1,
  content: 100,
  navigation: 1000,
  popup: 1500,
  overlay: 2000,
  help: 3000,
  support: 4000,
};
