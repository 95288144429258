export const validateInput = (
  key: string | number,
  selectionStart?: number,
  value?: string,
  maxFractionLength?: number,
  maxMixedLength?: number,
  minValue?: number,
  maxValue?: number,
): boolean => {
  // @ts-ignore
  const newValue = isFinite(key) && value ? insertAt(value, key, selectionStart) : value;

  if (!valueInRange(newValue, minValue, maxValue)) {
    return false;
  }

  /**
   * If values before decimal matches max mixed length, prevent default.
   */
  if (
    mixMaxReached(value, maxMixedLength) ||
    (mixMaxReached(value, maxMixedLength) && key === '.') ||
    (getDecimalValueLength(newValue) === maxFractionLength &&
      mixMaxReached(newValue, maxMixedLength))
  ) {
    return false;
  }

  /**
   * Max length reached without decimals, prevent default.
   */
  if (
    getDecimalValueLength(newValue) === 0 &&
    maxMixedLength === valueWithoutDotsAndDashes(value).length
  ) {
    return false;
  }

  /**
   * Max fraction length reached and trying to add a new value at the end, prevent default
   */
  if (
    maxFractionLength > 0 &&
    getDecimalValueLength(value) === maxFractionLength &&
    selectionStart > value.indexOf('.')
  ) {
    return false;
  }

  return true;
};

/**
 * Checks if the whole number places reached maxlength from a string
 * @param value
 * @param maxLength
 * @return {boolean}
 */
export const mixMaxReached = (value: string, maxLength: number): boolean =>
  !value ? false : parseFloat(value.replace('-', '')).toFixed(0).length === maxLength;

export const valueMatchesFormat = (
  valueString: string,
  allowNegative: boolean,
  minLength: number,
  maxLength: number,
  minFractionLength: number,
  maxFractionLength: number,
): boolean => {
  const value = valueString.trim();
  if (value.length === 0) {
    return true;
  }
  let regexStr = '';
  if (allowNegative) {
    regexStr = '-?';
  }
  regexStr = `${regexStr}\\d{${minLength},${maxLength}}`;
  if (maxFractionLength > 1 && value.indexOf('.') !== -1) {
    regexStr = `${regexStr}\\.\\d{${minFractionLength},${maxFractionLength}}`;
  }
  return new RegExp(`^${regexStr}$`).test(value);
};

export const valueInRange = (
  value: string | number,
  minValue?: number | null,
  maxValue?: number | null,
): boolean => {
  // If the value is blank then it is range
  if (value === '') return true;

  const number = Number(value);

  if ((minValue || minValue === 0) && (maxValue || maxValue === 0)) {
    return number >= minValue && number <= maxValue;
  }
  if (minValue) {
    return number >= minValue;
  }
  if (maxValue) {
    return number <= maxValue;
  }
  // If there is no range defined then the value is in range
  return true;
};

/**
 * Returns the length of decimal places
 * @param v
 * @return {number}
 */
export const getDecimalValueLength = (v: string): number =>
  v && v.length && (v.split('.')[1] || []).length;

/**
 * Strips off decimal and negative sign from a given string
 * @param v
 * @return {string}
 */
export const valueWithoutDotsAndDashes = (v: string): string => {
  if (v.length && (v.includes('-') || v.includes('.'))) {
    return v.trim().replace(/-|(\.)/g, '');
  }
  return v;
};

/**
 * Inserts a value at a given position
 * @param str
 * @param sub
 * @param pos
 * @return {string}
 */
export const insertAt = (str: string, sub: string, pos: number): string =>
  `${str.slice(0, pos)}${sub}${str.slice(pos)}`;

/**
 * Construct a decimal value based on given number with min/max decimal places.
 * @param value
 * @param minimumFractionDigits
 * @param maximumFractionDigits
 * @param delimiter
 * @return {string|undefined}
 */
export const constructStringValue = (
  value: number,
  minimumFractionDigits?: number,
  maximumFractionDigits?: number,
  delimiter?: string,
  locales?: string,
): string | null => {
  if (!value) return undefined;

  const localValue = value.toLocaleString(locales, {
    minimumFractionDigits,
    maximumFractionDigits,
  });
  const valueWithoutDelimiter = removeDelimiter(localValue, delimiter);

  // @ts-ignore
  if (isNaN(valueWithoutDelimiter)) return undefined;

  return valueWithoutDelimiter;
};

export const removeDelimiter = (value: string, delimiter: string) =>
  value.replace(new RegExp(delimiter, 'g'), '');
