import React from 'react';
import styled from 'styled-components';

export type TruncateModeType = 'clip' | 'ellipsis' | 'fade';

const ParentSpan = styled.span`
  display: table;
  table-layout: fixed;
  width: 100%;
`;

const InnerSpan = styled.span<{ useEllipsis?: boolean }>`
  display: table-cell;
  text-overflow: ${(props: Record<string, any>): string =>
    props.useEllipsis ? 'ellipsis' : 'clip'};
  overflow: hidden;
  white-space: nowrap;
`;

const FadeSpan = styled.span`
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  display: block;

  &:after {
    content: '';
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40px;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
  }
`;

type PropsType = {
  children?: string | null;
  mode?: TruncateModeType;
  /**
   * **Deprecated** Please set truncateMode to 'ellipsis' to desired effect
   * @deprecated
   */
  useEllipsis?: boolean;
};

export default function TextTruncate(props: PropsType): JSX.Element {
  const useEllipsis = props.useEllipsis || props.mode === 'ellipsis';

  const TextComponent = ({ children: content }: Record<string, any>): JSX.Element =>
    props.mode === 'fade' ? (
      <FadeSpan>{content}</FadeSpan>
    ) : (
      <InnerSpan useEllipsis={useEllipsis}>{content}</InnerSpan>
    );

  return (
    <ParentSpan>
      <TextComponent>{props.children}</TextComponent>
    </ParentSpan>
  );
}

TextTruncate.defaultProps = {
  useEllipsis: false,
  children: null,
  mode: 'clip',
};
