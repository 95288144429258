import React, { useState, useContext, useEffect, ReactElement } from 'react';

import styled, { css } from 'styled-components';
import { HelpContext } from './HelpContext';

import Heading from '../Heading';
import Label from '../Label';
import Link from '../Link';
import { IconArrowLeft } from '../Icon';
import Spacer from '../Spacer';
import Divider from '../Divider';
import StatusTag from '../StatusTag';
import { NewFeatureItemType } from './types';

export const WHATS_NEW_HEADING = "What's new";
export const BACK_TO_WHATS_NEW = 'Back';

const defaultFeature = [
  {
    imageLink: '',
    description: 'You are all caught up.',
    id: 'defaultId',
    releaseDate: '',
    linkText: 'View features',
    title: '',
  },
];

export const NewFeatureItems = (): ReactElement => {
  const {
    setShowSidePanel,
    setShowModal,
    newFeaturesAvaliable,
    allProductFeatures,
    setShowOldFeatures,
    setFeatureId,
  } = useContext(HelpContext);

  const [showNewFeatureHeader, setShowNewFeatureHeader] = useState(true);
  const [featuresToShow, setFeaturesToShow] = useState(defaultFeature);
  const [isDefaultFeature, setIsDefaultFeature] = useState(true);

  const hasNewFeature = newFeaturesAvaliable.length > 0;
  useEffect(() => {
    if (!hasNewFeature) {
      setFeaturesToShow(defaultFeature);
      setIsDefaultFeature(true);
    }

    if (hasNewFeature) {
      setFeaturesToShow(newFeaturesAvaliable);
      setIsDefaultFeature(false);
    }

    if (!showNewFeatureHeader) {
      setFeaturesToShow(allProductFeatures);
      setIsDefaultFeature(false);
    }
  });

  const backToNormalHeader = () => {
    setShowNewFeatureHeader(true);
    setShowOldFeatures(false);
  };

  const openModal = (featureId: string) => {
    setShowSidePanel(false);
    setShowModal(true);
    setFeatureId(featureId);
  };

  const showOldFeatures = () => {
    setShowOldFeatures(true);
    setShowNewFeatureHeader(false);
    setFeaturesToShow(allProductFeatures);
  };

  return (
    <>
      <Spacer spacing="s16" />
      <StyledHeaderWrapper showNewFeatureHeader={showNewFeatureHeader} onClick={backToNormalHeader}>
        {showNewFeatureHeader ? <NormalHeader /> : <HistoryHeader />}
      </StyledHeaderWrapper>
      <Spacer spacing="s16" />
      <StyledUl>
        {featuresToShow.map((item: NewFeatureItemType) => {
          const { title, description, linkText, id } = item;
          return (
            <StyledFeatureItem key={id}>
              <StyledDiv>
                <TitleWrapper>
                  {title && <Label>{title}</Label>}
                  {hasNewFeature && (
                    <TagWrapper>
                      <StatusTag small status="success">
                        New
                      </StatusTag>
                    </TagWrapper>
                  )}
                </TitleWrapper>
                {!isDefaultFeature && <Spacer spacing="s12" />}
                <StyledP>
                  {description}
                  {'  '}
                  {isDefaultFeature ? (
                    <Link onClick={showOldFeatures} primary>
                      {linkText}
                    </Link>
                  ) : (
                    <Link onClick={() => openModal(id)} primary>
                      {linkText}
                    </Link>
                  )}
                </StyledP>
              </StyledDiv>
            </StyledFeatureItem>
          );
        })}
        <Spacer spacing="s8" />
        <Divider />
      </StyledUl>
    </>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.space.s16};
`;
const NormalHeader = () => {
  return (
    <HeadingContext>
      <Heading level={3}>{WHATS_NEW_HEADING}</Heading>
    </HeadingContext>
  );
};

const HistoryHeader = () => {
  return (
    <HeadingContext>
      <IconArrowLeft height={16} width={16} />
      <Heading level={3}>{BACK_TO_WHATS_NEW}</Heading>
    </HeadingContext>
  );
};

const StyledHeaderWrapper = styled.div<{ showNewFeatureHeader: boolean }>`
  display: flex;
  ${({ showNewFeatureHeader }) =>
    !showNewFeatureHeader &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
`;

const HeadingContext = styled.div`
  display: flex;
  align-items: center;
`;

const StyledFeatureItem = styled.li`
  // eslint-disable-next-line @typescript-eslint/no-shadow
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.space.s4};
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.space.s16};
`;

const StyledDiv = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.base};
  line-height: ${({ theme }) => theme.lineHeight.base};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  padding: ${({ theme }) => theme.space.s16};
  margin: ${({ theme }) => theme.space.s0};
`;

const StyledP = styled.p`
  margin: ${({ theme }) => theme.space.s0};
  text-align: left;
  word-break: break-word;
`;

const StyledUl = styled.ul`
  list-style-type: none;
  display: contents;
`;
