import React from 'react';
import styled from 'styled-components';
import Title, { Variants } from '../Title';
import TextTruncate from '../TextTruncate';

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }): string => theme.border.color.default};
  height: 56px;
  padding: 0px 16px;
  background: ${({ theme }): string => theme.navigationBar.headerBackgroundColor};
`;

type PropsType = {
  content?: JSX.Element | null;
  title?: JSX.Element | string;
  truncate?: boolean;
  variant?: Variants;
};

export const TitleHeader: React.FunctionComponent<PropsType> = ({
  title = 'Title',
  content = null,
  variant = 'default',
  truncate = false,
}) => (
  <TopBar>
    <Title variant={variant}>
      {truncate && typeof title === 'string' ? (
        <TextTruncate mode="ellipsis">{title}</TextTruncate>
      ) : (
        title
      )}
    </Title>
    {content}
  </TopBar>
);

export default TitleHeader;
