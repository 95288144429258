import { Decimal } from '../../utils/decimal';
import { NumberPadType, NumberPadSettingType } from './types';

export const NUMBERPAD_DOLLAR_SIGN = '$';
export const NUMBERPAD_PERCENTAGE_SIGN = '%';
export const NUMBERPAD_MINUS_SIGN = '-';
export const NUMBERPAD_PLUS_SIGN = '+';

export const DEFAULT_NUMPERPAD_TYPE: NumberPadType = {
  value: '0',
  isNegative: true,
  otherData: {},
};

export function transformNumberPad(
  numberEntered: NumberPadType = DEFAULT_NUMPERPAD_TYPE,
): NumberPadSettingType {
  return {
    value: numberEntered.value,
    sign: numberEntered.isNegative ? NUMBERPAD_MINUS_SIGN : NUMBERPAD_PLUS_SIGN,
    percentage: numberEntered.otherData.isPercentage ? numberEntered.otherData.isPercentage : false,
  };
}

export function formatNumberPadString(
  numberEntered: NumberPadType,
  precision = 2,
  currency = 'AUD',
): string {
  const sign = numberEntered.isNegative ? NUMBERPAD_MINUS_SIGN : '';
  const decimal = new Decimal(sign + numberEntered.value).toFixed(precision);
  if (numberEntered.otherData.isPercentage) {
    return `${decimal}%`;
  }
  if (numberEntered.otherData.isQuantity) {
    return `${numberEntered.value}`;
  }
  return formatCurrency(decimal, currency);
}

export function getNumberPadStateFromFormattedNumber(formattedNum = '-0%'): NumberPadType {
  const value = formattedNum.match(/\d+\.?\d*/);
  const result: NumberPadType = {
    isNegative: formattedNum.indexOf(NUMBERPAD_MINUS_SIGN) >= 0,
    value: value ? value.join('') : '0',
    otherData: {
      isPercentage: formattedNum.indexOf(NUMBERPAD_PERCENTAGE_SIGN) >= 0,
    },
  };
  return result;
}

/*
  This function is to validate the userNumberInput,
  if the input is for Percentage and the values is less than 100, return false;
  otherwise, return true
 */
export function isPricePercentageValid(
  userNumberInput: NumberPadType,
  showWarningMsg: boolean | null = true,
): boolean {
  const PRICE_PERCENTAGE_LIMIT = 100;
  if (
    userNumberInput.otherData.isPercentage &&
    userNumberInput.isNegative &&
    Number(userNumberInput.value) > PRICE_PERCENTAGE_LIMIT
  ) {
    if (showWarningMsg) {
      // eslint-disable-next-line no-alert
      alert('The discount rate cannot be greater than 100%.');
    }
    return false;
  }
  return true;
}

export function shallowDiffers(obj1: any = null, obj2: any = null): boolean {
  return !Object.keys(obj1).every(
    (key: string): boolean => typeof obj1[key] === 'object' || obj1[key] === obj2[key],
  );
}

export function formatCurrency(value: string, ccy = 'AUD'): string {
  const isNegative = value[0] === '-';
  const decimal = new Decimal(value);
  const currency = decimal.getCurrency(ccy);
  const space = (currency.space && ' ') || '';
  if (currency.prepend) {
    if (isNegative) {
      return `-${currency.sign + space + value.substr(1)}`;
    }
    return currency.sign + space + value;
  }
  return value + space + currency.sign;
}
