import React, { Component } from 'react';
import styled from 'styled-components';
import { getDataAttributes, DataPropsType } from '../../utils/dataAttributes';
import { borderDefault, borderActive, borderFocused } from '../../mixins';
import Paragraph from '../Paragraph';

export type SegmentOptionType = DataPropsType & {
  id: string | number;
  name?: string;
};

const SegmentContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  width: 100%;
  opacity: ${({ disabled }): number => (disabled ? 0.5 : 1)};

  ${({ theme }) => theme.mediaQueries.tabletAndDesktop} {
    width: auto;
  }
`;

const SegmentItem = styled.div<{ disabled?: boolean; fullWidth?: boolean }>`
  cursor: ${({ disabled }): string => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }): string => (disabled ? 'none' : '')};
  height: 48px;
  width: 100%;
  background: ${({ theme }): string => theme.button.segment.backgroundColor};
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${borderDefault};

  &.active {
    background: ${({ theme }): string => theme.button.segment.activeBackground};
    ${borderActive};
  }
  &:focus {
    ${borderFocused};
  }
  &:first-child {
    border-radius: 4px 0 0 4px;
  }
  &:last-child {
    border-radius: 0 4px 4px 0;
  }

  ${({ theme }) => theme.mediaQueries.tabletAndDesktop} {
    width: ${({ fullWidth }): string => (fullWidth ? '-webkit-fill-available' : 'auto')};
  }
`;

type PropsType = {
  defaultValue?: string;
  /**
   * Disables the entire segment controller
   */
  disabled?: boolean;
  /**
   * Makes the segment items full width
   */
  fullWidth?: boolean;
  onChange?: ({ id: string }) => void;
  options?: SegmentOptionType[];
  value?: string | number;
};

type StateType = {
  selectedValue: string | number;
};

export default class Segment extends Component<PropsType, StateType> {
  static defaultProps = {
    onChange: () => {},
    fullWidth: false,
    disabled: false,
  };

  constructor(props: PropsType) {
    super(props);
    this.state = {
      selectedValue: this.props.defaultValue || this.props.value,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: PropsType) {
    if (nextProps.value !== this.state.selectedValue) {
      this.setState({ selectedValue: nextProps.value });
    }
  }

  handleChange: (id: string | number) => void = (id) => {
    const { disabled } = this.props;
    if (disabled) {
      return;
    }
    this.setState({ selectedValue: id });

    if (this.props.onChange) {
      this.props.onChange({ id });
    }
  };

  renderOptions = () => {
    const { disabled } = this.props;
    return this.props.options
      ? this.props.options
          .filter((item) => item.name != null && item.id != null) // clean invalid segments e.g., from docgen
          .map((item: SegmentOptionType) => {
            const selected = this.state.selectedValue === item.id;
            return (
              <SegmentItem
                className={selected ? 'active' : ''}
                disabled={disabled}
                fullWidth={this.props.fullWidth}
                role="tab"
                tabIndex={0}
                key={item.id}
                onClick={(): void => this.handleChange(item.id)}
                {...getDataAttributes(item.data)}
                data-chamid="Segment"
              >
                <Paragraph
                  align="center"
                  bold={selected}
                  color={selected ? 'text' : 'textSubdued'}
                  data-active={selected ? 'true' : 'false'}
                >
                  {item.name}
                </Paragraph>
              </SegmentItem>
            );
          })
      : '';
  };

  render(): JSX.Element {
    const { disabled } = this.props;
    return (
      <SegmentContainer disabled={disabled} role="tablist">
        {this.renderOptions()}
      </SegmentContainer>
    );
  }
}
