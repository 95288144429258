import React from 'react';
import styled from 'styled-components';
import { TableHeaderType } from './types';
import { Heading6 } from '../Heading';
import Group from '../Group';
import { IconSortable, IconDescending, IconAscending } from '../Icon';
import { isParentRelativeUnit } from './helpers';

const Th = styled.th<{
  expand?: boolean;
  showIndent?: boolean;
  spacingBetweenCells?: string;
  width?: string;
}>`
  display: table-cell;
  vertical-align: inherit;
  white-space: ${({ expand }) => (expand ? 'nowrap' : '')};

  &.sortable {
    cursor: pointer;
  }

  &.numeric {
    text-align: right;
  }

  padding-left: ${({ theme, spacingBetweenCells }): string => theme.space[spacingBetweenCells]};
  padding-right: ${({ theme, spacingBetweenCells }): string => theme.space[spacingBetweenCells]};

  &:first-child {
    padding-left: ${({ showIndent }): string => (showIndent ? '8px' : '0px')};
  }
  &:last-child {
    padding-right: ${({ showIndent }): string => (showIndent ? '8px' : '0px')};
  }
`;
const HeadingGroup = styled(Group)`
  grid-template-rows: ${({ height }) => height};
  min-height: 24px;
`;

const TableHeader: React.FunctionComponent<React.PropsWithChildren<TableHeaderType>> = ({
  id,
  sortable,
  width,
  height,
  columnSpan,
  children,
  align,
  sortDirection,
  handleSort,
  ...restProps
}) => {
  const handleClick = (
    e: React.MouseEvent<HTMLTableHeaderCellElement>,
    i: string | number,
  ): void => {
    if (!sortable) {
      return undefined;
    }
    return handleSort(i, sortDirection);
  };

  const icons = {
    default: <IconSortable color="#757575" />,
    ascending: <IconAscending color="#757575" />,
    descending: <IconDescending color="#757575" />,
  };

  return (
    <Th
      key={id}
      onClick={(e: React.MouseEvent<HTMLTableHeaderCellElement>) => handleClick(e, id)}
      className={sortable && 'sortable'}
      width={width}
      {...restProps}
      colSpan={columnSpan}
    >
      <HeadingGroup horizontal spacing="0px" align={align} height={height} className="heading">
        <Heading6 align={align}>
          <MaxWidthFix width={width}>{children}</MaxWidthFix>
        </Heading6>
        {sortable && icons[sortDirection]}
      </HeadingGroup>
    </Th>
  );
};

const MaxWidthFix = styled.div<{ width?: string }>`
  max-width: ${({ width }) => (width && !isParentRelativeUnit(width) ? width : undefined)};
`;

TableHeader.defaultProps = {
  align: 'left',
  height: 'auto',
  sortable: false,
  spacingBetweenCells: 'none',
};

export default TableHeader;
