import React, { Component, ReactElement, createRef } from 'react';
import styled from 'styled-components';
import Table, { TablePropsType } from './Table';
import PaginatedTable, { PaginatedTablePropsType } from './PaginatedTable';
import Checkbox, { PropsType as CheckboxPropsType } from '../Checkbox/Checkbox';
import { CheckboxTableRowType, TableHeaderType, TableIdType, TableRowType } from './types';
import CheckboxSelect from '../CheckboxSelect';
import Select, { SelectOptionType } from '../Select';
import Label from '../Label';
import { DataPropsType, getDataAttributes } from '../../utils/dataAttributes';
import { SpacingTypes } from '../../theme';

enum HeaderCheckType {
  ALL = 'All',
  PAGE = 'Page',
}

export enum HeaderCheckScope {
  ALL = 'all',
  PAGE = 'page',
  SINGLE = 'single',
}

type CheckboxTableRows = CheckboxTableRowType[];

export type Terms = {
  /** Dropdown label for select all rows option */
  headerCheckboxAllText: string;
  /** Dropdown label for select all rows in page option */
  headerCheckboxPageText: string;
  /** Function to override selected items count label */
  headerLabelFormatter: (selectedCount: number) => string;
};

type CheckboxTablePropsType = DataPropsType & {
  headerOverlayContent?: ReactElement;
  /** Recommended if headerOverlayContent is set.
   * Set this to the height of the tallest component in the overlay.
   * As the overlay is absolutely positioned, we need to make sure the regular header has a height
   * set or the overlay will render in a different position and potentially cut off table content.
   */
  headerOverlayHeight?: string;
  /** Headers are not optional for the CheckboxTable. */
  headers: TableHeaderType[];
  onSelect: (rows: TableIdType[], checked: boolean, scope: HeaderCheckScope) => void;
  /** Properties for the wrapped table. See the PaginatedTable component for details. */
  paginatedTableProps?: Omit<PaginatedTablePropsType, 'tableProps' | 'rows'>;
  rows: CheckboxTableRows;
  /** Recommended if using async pagination.
   * Allows consumer to set the selected page count, since the table won't be aware of
   * all the rows at once, just the current page. */
  selectedRowCount?: number;
  /** Properties for the wrapped table. See the Table component for details. */
  tableProps?: Omit<TablePropsType, 'rows' | 'headers'>;
  /**
   * Properties for overriding component text, e.g. when consumer implements
   * i18n. See Terms type for details.
   */
  terms?: Terms;
};

type StateType = {
  headerCheckType: HeaderCheckType;
};

export const TESTID_CHECKBOX_HEADER = 'CheckboxTable__HeaderCheckbox';
export const TESTID_CHECKBOX_BODY = 'CheckboxTable__BodyCheckbox';
export const getCheckboxTestId = (id: TableIdType) => `${TESTID_CHECKBOX_BODY}_${id}`;

export default class CheckboxTable extends Component<CheckboxTablePropsType, StateType> {
  static defaultLabelFormatter: Terms['headerLabelFormatter'] = (selectedCount) => {
    const isPlural = selectedCount !== 1;

    return `${selectedCount} item${isPlural ? 's' : ''} selected`;
  };

  static defaultProps = {
    async: false,
    initialPage: 1,
    paginatedTableProps: {},
    tableProps: {},
  };

  constructor(props: CheckboxTablePropsType) {
    super(props);

    this.state = {
      headerCheckType: HeaderCheckType.PAGE,
    };
  }

  private paginatedTableRef = createRef<PaginatedTable>();

  static HeaderCheckboxWidth = 50;

  static PaginatedOverlayCheckboxWidth = 130;

  static PaginatedCheckboxWidth = 70;

  updateHeaderChecked = (checked: boolean) => {
    const { headerCheckType } = this.state;
    const rows = headerCheckType === HeaderCheckType.PAGE ? this.getPageRows() : this.props.rows;

    this.props.onSelect(
      rows.map((row) => row.id),
      checked,
      headerCheckType === HeaderCheckType.ALL ? HeaderCheckScope.ALL : HeaderCheckScope.PAGE,
    );
  };

  deselectAll() {
    this.props.onSelect(
      this.props.rows.map((row) => row.id),
      false,
      HeaderCheckScope.ALL,
    );
  }

  updateHeaderCheckedType = (option: SelectOptionType) => {
    this.setState({ headerCheckType: option.id as HeaderCheckType }, () => {
      this.deselectAll();
      this.updateHeaderChecked(true);
    });
  };

  onRowCheckedChanged = (id: TableIdType, checked: boolean) => {
    this.props.onSelect([id], checked, HeaderCheckScope.SINGLE);
  };

  isPaginatedTable() {
    const { paginatedTableProps } = this.props;
    return typeof paginatedTableProps === 'object' && Object.keys(paginatedTableProps).length > 0;
  }

  getHeaderColumn() {
    const { headerOverlayHeight } = this.props;

    return {
      id: 'headerCheckbox',
      name: this.renderHeaderCheckbox(false),
      width: `${this.getHeaderCheckboxWidth(false)}px`,
      height: typeof headerOverlayHeight === 'undefined' ? 'auto' : headerOverlayHeight,
      showIndent: false,
    };
  }

  getPageRows(): CheckboxTableRowType[] {
    const { rows } = this.props;
    if (this.paginatedTableRef.current) {
      const pageRows = this.paginatedTableRef.current.getPageRows(rows);
      const ids = new Set(pageRows.map((row) => row.id));
      return rows.filter((row) => ids.has(row.id));
    }
    return rows;
  }

  getCheckedRows(rows: CheckboxTableRowType[] = this.props.rows): CheckboxTableRowType[] {
    return rows.filter((row) => row.checked);
  }

  getCheckboxRows(): TableRowType[] {
    const { rows } = this.props;
    return rows.map(({ content, checked, id, ...rest }) => {
      return {
        ...rest,
        id,
        content: [
          {
            id: 'CheckboxTable__CheckboxCol',
            value: (
              <TableCheckbox
                forceChecked={checked}
                onChange={(c: boolean) => this.onRowCheckedChanged(id, c)}
                data={{
                  chamid: getCheckboxTestId(id),
                }}
              />
            ),
          },
          ...content,
        ],
      };
    });
  }

  getHeaderCheckboxWidth(isOverlay: boolean) {
    if (!this.isPaginatedTable()) {
      return CheckboxTable.HeaderCheckboxWidth;
    }
    if (isOverlay) {
      return CheckboxTable.PaginatedOverlayCheckboxWidth;
    }
    return CheckboxTable.PaginatedCheckboxWidth;
  }

  getHeaderLabel(selectedCount: number) {
    const { headerLabelFormatter = CheckboxTable.defaultLabelFormatter } = this.props?.terms || {};

    return headerLabelFormatter(selectedCount);
  }

  renderHeaderCheckbox(isOverlay: boolean) {
    const { headerCheckType } = this.state;
    const { terms } = this.props;

    const headerOptions = [
      {
        id: HeaderCheckType.PAGE,
        name: terms?.headerCheckboxPageText || 'Page',
      },
      {
        id: HeaderCheckType.ALL,
        name: terms?.headerCheckboxAllText || 'All',
      },
    ];

    const rows = headerCheckType === HeaderCheckType.PAGE ? this.getPageRows() : this.props.rows;
    const rowCount = rows.length;
    const checkedRowsCount = this.getCheckedRows(rows).length;
    const allChecked = checkedRowsCount > 0 && checkedRowsCount === rowCount;
    const indeterminate = checkedRowsCount > 0 && !allChecked;

    const checkbox = (
      <Checkbox
        forceChecked={allChecked}
        indeterminate={indeterminate}
        onChange={this.updateHeaderChecked}
        data={{
          chamid: TESTID_CHECKBOX_HEADER,
        }}
      />
    );

    if (!this.isPaginatedTable()) {
      return <HeaderCheckboxWrap>{checkbox}</HeaderCheckboxWrap>;
    }

    const selectPadding: SpacingTypes = 'small';

    /* The hero select displays when there has been no selections made
     * It always keeps an empty value to maintain its slim appearance
     * When Page or All is selected, it performs that selection and shows the overlay */
    const heroSelect = (
      <Select
        options={headerOptions}
        padding={selectPadding}
        placeholder=" "
        value=" "
        updateSelectedOption={this.updateHeaderCheckedType}
      />
    );

    /* The overlay select displays when a selection has been made.
     * Selecting Page or All will also immediately trigger that selection, like the heroSelect. */
    const overlaySelect = (
      <Select
        options={headerOptions}
        padding={selectPadding}
        value={headerCheckType}
        updateSelectedOption={this.updateHeaderCheckedType}
      />
    );

    return (
      <PaginatedHeaderCheckboxWrap width={this.getHeaderCheckboxWidth(isOverlay)}>
        <CheckboxSelect
          checkbox={checkbox}
          select={isOverlay ? overlaySelect : heroSelect}
          outerPadding={8}
          innerPadding={isOverlay ? 8 : -8}
        />
      </PaginatedHeaderCheckboxWrap>
    );
  }

  renderHeaderOverlay() {
    const { selectedRowCount } = this.props;
    const rows = this.getCheckedRows();
    const selectedCount = typeof selectedRowCount !== 'undefined' ? selectedRowCount : rows.length;

    if (!selectedCount) {
      return null;
    }

    return (
      <HeaderOverlay>
        {this.renderHeaderCheckbox(true)}
        <HeaderLabelWrap>
          <Label>{this.getHeaderLabel(selectedCount)}</Label>
        </HeaderLabelWrap>
        {this.props.headerOverlayContent}
      </HeaderOverlay>
    );
  }

  render() {
    const { paginatedTableProps, headers, data } = this.props;
    const restTableProps = this.props.tableProps;

    // Add header col
    const checkboxHeaders = [this.getHeaderColumn(), ...headers];
    const checkboxRows = this.getCheckboxRows();

    const tableProps: TablePropsType = {
      ...restTableProps,
      headers: checkboxHeaders,
    };

    let table;
    if (this.isPaginatedTable()) {
      table = (
        <PaginatedTable
          {...paginatedTableProps}
          ref={this.paginatedTableRef}
          rows={checkboxRows}
          tableProps={tableProps}
        />
      );
    } else {
      table = <Table {...tableProps} rows={checkboxRows} />;
    }

    return (
      <Root {...getDataAttributes(data)}>
        {this.renderHeaderOverlay()}
        {table}
      </Root>
    );
  }
}
const Root = styled.div`
  position: relative;
`;
const TableCheckbox = (props: CheckboxPropsType) => (
  <CheckboxWrap>
    <Checkbox {...props} padding="0px" />
  </CheckboxWrap>
);
const CheckboxWrap = styled.div`
  > * {
    display: block;
  }
`;
const HeaderCheckboxWrap = styled.div`
  display: flex;
  padding-left: 8px;
`;
const PaginatedHeaderCheckboxWrap = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  padding-right: 5px;
  padding-top: 6px;
  padding-bottom: 6px;
`;
const HeaderOverlay = styled.div`
  position: absolute;
  z-index: 5;
  top: 1px;
  left: 0;
  background-color: ${({ theme }) => theme.table.rowHoverBackgroundColor};
  display: flex;
  align-items: center;
  width: 100%;
`;
const HeaderLabelWrap = styled.div`
  margin: 0 14px;
`;
