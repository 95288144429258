import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import React, { cloneElement } from 'react';
import styled, { css } from 'styled-components';
import { CustomRowRendererType } from '../RowRenderer';
import { colors } from '../../../themes';

const ReorderableRowRenderer: CustomRowRendererType = ({
  rowRendererProps,
  children,
  ...restProps
}) => {
  const { rowItem, index } = rowRendererProps;
  return (
    <Draggable draggableId={rowItem.id.toString()} index={index}>
      {(draggableProvided: DraggableProvided, snapshot) => {
        let dragHandle;
        let restChildren = [];
        if (Array.isArray(children) && children.length) {
          [dragHandle, ...restChildren] = children;
          dragHandle = cloneElement(dragHandle, {
            ...draggableProvided.dragHandleProps,
            style: {
              backgroundColor: colors.gray50,
            },
          });
        }
        return (
          <DraggableRow
            ref={draggableProvided.innerRef}
            isDragging={snapshot.isDragging}
            {...draggableProvided.draggableProps}
            {...restProps}
          >
            {dragHandle}
            {restChildren}
          </DraggableRow>
        );
      }}
    </Draggable>
  );
};
export default ReorderableRowRenderer;

const DraggableRow = styled.tr`
  ${({ isDragging }: { isDragging: boolean }) =>
    isDragging &&
    css`
      background: ${colors.gray50};

      /* maintain cell width while dragging */
      display: table;
    `}
`;
