import React from 'react';
import styled from 'styled-components';
import Container from '../Container';
import Group from '../Group';
import { Mobile, TabletAndDesktop } from '../../utils/responsive';

const FooterStyle = styled.div`
  position: ${(props: Record<string, any>): string => (props.fullscreen ? 'fixed' : 'relative')};
  width: ${(props: Record<string, any>): string => (props.fullscreen ? '100vw' : '100%')};
  z-index: ${({ theme }): number => theme.zIndex.navigation};
  bottom: 0;
  left: 0;
  background-color: ${(props: Record<string, any>): string => props.theme.footer.backgroundColor};
  padding: ${(props: Record<string, any>): string => props.theme.footer.padding};
  box-shadow: ${(props: Record<string, any>): string => props.theme.footer.shadow};
`;

type PropsType = {
  [divProps: string]: any;
  fullscreen?: boolean;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
};

/**
 * Footer is a component that sticks to the bottom of the page.
 * It can be used in a Page or ContextSelector.
 *
 * You can add things to the left or right of a footer.
 * On mobile the left side stacks on top of the right side
 */
export const Footer: React.FunctionComponent<PropsType> = (props) => (
  <FooterStyle {...props}>
    <Mobile>
      <Group full spacing="8px">
        {props.leftContent}
        {props.rightContent}
      </Group>
    </Mobile>
    <TabletAndDesktop>
      <Container hideMargin>
        <Group horizontal full>
          {props.leftContent}
          {props.rightContent}
        </Group>
      </Container>
    </TabletAndDesktop>
  </FooterStyle>
);

Footer.defaultProps = {
  fullscreen: false,
  leftContent: null,
  rightContent: null,
};

export default Footer;
