import React, { Component } from 'react';
import styled from 'styled-components';
import ActionList, { ActionListHeaderActionType } from '../ActionList';
import { Heading3 } from '../Heading';

type PropsType = {
  actions?: ActionListHeaderActionType[];
  headerOverlay?: JSX.Element | null;
  leftContent?: JSX.Element | null;
  rightContent?: JSX.Element | null;
  showBottomBorder?: boolean;
  showHeaderOverlay?: boolean;
  showMoreIconOnMobile?: boolean;
  title?: string;
};

export default class AppHeader extends Component<PropsType, any> {
  static defaultProps = {
    leftContent: null,
    rightContent: null,
    showBottomBorder: true,
    title: '',
    showMoreIconOnMobile: false,
    actions: [],
    headerOverlay: null,
    showHeaderOverlay: false,
  };

  render(): JSX.Element {
    const {
      showBottomBorder,
      leftContent,
      rightContent,
      title,
      actions,
      headerOverlay,
      showHeaderOverlay,
    } = this.props;

    return (
      <TopBar showBottomBorder={showBottomBorder}>
        <GridLayout>
          <LeftContent>{leftContent}</LeftContent>
          <MiddleContent>
            <Heading3 align="center">{title}</Heading3>
          </MiddleContent>
          <RightContent>
            {rightContent || (
              <ActionList actions={actions} showMoreOnMobile={this.props.showMoreIconOnMobile} />
            )}
          </RightContent>
        </GridLayout>
        {showHeaderOverlay && headerOverlay}
      </TopBar>
    );
  }
}

const TopBar = styled.div<{ showBottomBorder?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.background.default};
  border-bottom: ${(props): string =>
    props.showBottomBorder ? props.theme.border.defaultBorder : 'none'};
  height: 56px;
  padding: 0px 8px;
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const MiddleContent = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  max-width: 100%;

  h3 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const RightContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const GridLayout = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: ${({ theme }) => theme.space.s16};
`;
