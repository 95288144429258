import { DefaultTheme } from 'styled-components';

/**
 * passthroughPatterns describes a set of values that themeGet will not attempt to search the theme for.
 * This enables us to wrap themes in strictObject and to throw when invalid values are accessed
 * but to continue to allow useful browser css properties through themeGet.
 */
const passthroughPatterns: Array<RegExp> = [
  /inherit|currentColor/, // color inheritence
  /auto/,
  /\d+%/,
  /#[\w\d]{3,}/,
  /\d+px/,
];
const isPassthrough = (input: string) => passthroughPatterns.some((pattern) => pattern.test(input));

/*
 * The themeGet function is an existential getter function that can be used in any style declaration to get a value from your theme, with support for fallback values. This helps prevent errors from throwing when a theme value is missing, which can be helpful when unit testing styled-components.
 * https://github.com/styled-system/styled-system/tree/master/packages/theme-get
 */
function get<Theme = DefaultTheme>(obj: Theme, key: string, def: string) {
  const keyArr = key && key.split ? key.split('.') : [key];
  const result = keyArr.reduce(
    (accumulator, newKey) => (accumulator ? accumulator[newKey] : null),
    obj,
  );
  return result ?? def;
}

export function themeGet<Props = Record<string, unknown>, Theme = DefaultTheme>(
  path: string | undefined | null,
  /** if fallback is not specified, then the last part of the path becomes the fallback */
  fallback = null,
) {
  const lastPath = path?.split('.')?.pop();

  // handle possible coercion of path
  if (
    typeof path !== 'string' ||
    lastPath === 'null' ||
    lastPath === 'undefined' ||
    lastPath === ''
  ) {
    return fallback;
  }

  if (isPassthrough(lastPath)) return lastPath;

  return (props: Props & { theme: Theme }) => get<Theme>(props?.theme, path, fallback || lastPath);
}

export default themeGet;
