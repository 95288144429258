import React from 'react';
import styled from 'styled-components';
import sprite from './logos/cardlogos.png';
import Illustration from '../Illustration';
import { IconBank } from '../Icon';

type PropsType = {
  /**
   * **Deprecated** Please use paymentType
   * @deprecated
   */
  cardType?: string;
  disabled?: boolean;
  /**
   * Default is blank and renders a default credit card
   *
   * Accepted values:
   * bank,visa,mastercard,amex,maestro
   */
  paymentType?: string;
};

export default function PaymentLogo(props: PropsType): JSX.Element {
  const logoType = props.paymentType ? props.paymentType : props.cardType;

  const getPaymentComponent = (type: string) => {
    switch (type) {
      case '':
      case undefined:
        return <DefaultCreditCard />;
      case 'bank':
        return <Bank />;
      default:
        return <PaymentCard {...{ paymentType: logoType }} />;
    }
  };

  return (
    <PaymentContainer disabled={props.disabled}>{getPaymentComponent(logoType)}</PaymentContainer>
  );
}

PaymentLogo.defaultProps = {
  paymentType: '',
  disabled: false,
};

function getCardPosition(cardType: string): string {
  switch (cardType) {
    case 'visa':
      return '0px';
    case 'amex':
      return '51px';
    case 'mastercard':
      return '153px';
    case 'maestro':
      return '102px';
    default:
      return '0px';
  }
}

const PaymentContainer = styled.div<{ disabled?: boolean }>`
  background-color: ${({ theme }): string => theme.paymentlogo.backgroundColor};
  border-radius: 3px;
  height: 32px;
  width: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: ${(props): string => (props.disabled ? 'grayscale(100%)' : 'none')};
  opacity: ${(props): number => (props.disabled ? 0.6 : 1)};
`;

const PaymentCard = styled.div<{ paymentType?: string }>`
  height: 32px;
  width: 51px;
  background-image: ${(props): string => (props.paymentType === '' ? 'none' : `url(${sprite})`)};
  background-position: ${(props): string => getCardPosition(props.paymentType)} 0px;
`;

const BankContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  width: 100%;
  height: 100%;
`;

const DefaultCreditCard = (): JSX.Element => <Illustration illustrationName="credit-card" />;

const Bank = (): JSX.Element => (
  <BankContainer>
    <IconBank />
  </BankContainer>
);
