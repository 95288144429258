/* eslint no-underscore-dangle: 0, react/no-did-update-set-state: 0 */

import React, { PureComponent, createRef } from 'react';

import { List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { isEqual } from 'underscore';
import AutoSizer from '../AutoSizer';
import {
  SectionType,
  SectionRendererType,
  ItemRendererType,
  ScrollMeasurementType,
  CellMeasurerCacheType,
  FlatItemsType,
} from './types';

type PropsType = {
  /**
   * An id is necessary if a sectionList will be re-mounted.
   * As it caches individual row measurements internally, this helps scrolling to a position on
   * remount.
   */
  id?: string | number;
  /**
   * Callback with a mapped section's current Item.
   * @param item -  is whatever typed item(s) you passed in for a section.
   */
  itemRenderer?: (item: ItemRendererType) => JSX.Element;
  onMount?: (args: OnMountCallbackParamType) => void;
  onScroll?: (
    scrollToIndex: number | null,
    scrollToSection: number | null,
    scrollTop: number | null,
    autoScroll: boolean,
  ) => void;
  /**
   * Gets scrollTop on scroll
   * @param scrollTop
   */
  onScrollPosition?: (scrollTop: number) => void;
  onSectionChange?: (scrollToSection: number) => void;
  /**
   * A callback for componentWillUnmount, gives you back the last known
   * scrollTop position in case if it has to be remounted and at the same
   * position.
   * @param args: {{ scrollTop: number, currentItem: number, currentSection:
   *  number }}
   */
  onUnmount?: (args: OnUnmountCallbackParamType) => void;
  /**
   * To reduce flickering while scrolling, pre load rows.
   * Try to use a lower number, as using a higher number would defeat the
   * purpose of virtualizing a list for performance gains.
   * more info here :
   * https://github.com/bvaughn/react-virtualized/blob/master/docs/overscanUsage.md
   */
  overscanRowCount?: number;
  /**
   * "Jump to" or scroll to a particular item's index.
   */
  scrollToIndex?: number;
  /**
   * "Jump to" or scroll to a particular section's index.
   */
  scrollToSection?: number;
  /**
   * "Jump to" a scrollTop position.
   */
  scrollTop?: number;
  /**
   * Ways to use a sectionRenderer:
   *
   * [a] Use it to render an entire section along with mapped items and don't
   * pass in an itemRenderer.
   *
   * [b] Use this and itemRenderer individually.
   *
   *
   * @param SectionType - A SectionType Object at current index.
   */
  sectionRenderer?: (section: SectionRendererType) => JSX.Element;
  /**
   * A way to add/override a single item's style.
   */
  sectionStyle?: Record<string, any>;
  /**
   * An array of SectionType Objects,
   * expected shape:
   * @param {string} id - Number | String
   * @param {string} title - String
   * @param {object} data - An Object with your typed properties.
   */
  sections?: SectionType[];
};

type OnUnmountCallbackParamType = {
  scrollToIndex: number;
  scrollToSection: number;
  scrollTop: number;
};

type OnMountCallbackParamType = {
  totalHeight: number;
};

type StateType = {
  items: Array<FlatItemsType>;
};

const requiredSectionStyle = {
  display: 'inline-grid',
  gridGap: '4px',
};

const sectionListStore: any = {
  cacheList: {},
  sectionMeasurements: {},
  generateCache(id: string | number = 0) {
    if (this.cacheList[id]) {
      return;
    }
    this.cacheList[id] = new CellMeasurerCache({
      fixedWidth: true,
      fixedHeight: false,
      minHeight: 48,
    });
  },
  emptyCache() {
    this.cacheList = {};
    this.sectionMeasurements = {};
  },
  setSectionMeasurements(measurements: Record<string, any>) {
    this.sectionMeasurements = measurements;
  },
};

export default class SectionList extends PureComponent<PropsType, StateType> {
  cache: CellMeasurerCacheType;

  /**
   *  Returns true if:
   *    - Both section lists have an equal number of sections
   *    - Each section from the first list have an equal number of items with
   *        its adjacent section in the second list
   */
  static sectionsHaveEqualStructure(first: SectionType[], second: SectionType[]): boolean {
    if (first.length !== second.length) {
      return false;
    }

    return !first.some(({ data }, index) => {
      return data.length !== second[index].data.length;
    });
  }

  static defaultProps = {
    onMount: () => {},
    onUnmount: () => {},
    itemRenderer: () => {},
    onScroll: () => {},
    onSectionChange: () => {},
    onScrollPosition: () => {},
    scrollToIndex: 0,
    scrollTop: 0,
    sectionRenderer: () => {},
    sections: [],
    sectionStyle: requiredSectionStyle,
  };

  constructor(props: PropsType) {
    super(props);
    this.state = {
      items: getFlatItems(props.sections),
    };
    sectionListStore.generateCache(props.id);
  }

  componentDidMount() {
    const { scrollTop, id = 0, onMount, scrollToSection, scrollToIndex } = this.props;
    const { cacheList } = sectionListStore;

    this._isMounted = true;

    if (scrollToSection) {
      this.setCurrentlyScrollingSection(scrollToSection);
    }

    if (cacheList[id] && cacheList[id]._rowHeightCache) {
      const totalHeight = Object.values<number>(cacheList[id]._rowHeightCache).reduce(
        (x: number, y: number): number => x + y,
        0,
      );

      onMount({ totalHeight });
    }

    if (scrollToIndex > 0) {
      if (scrollTop > 0) {
        this.setTargetPosition(scrollTop);
      }
      this.scrollToRow(scrollToIndex);
    } else if (scrollTop > 0) {
      this.jumpToPosition(scrollTop);
    }
  }

  componentDidUpdate(
    { scrollTop: prevScrollTop, sections: prevSections }: PropsType,
    { items: prevStateItems }: StateType,
  ) {
    const { sections, scrollTop } = this.props;
    const newItems = getFlatItems(sections);

    if (scrollTop !== prevScrollTop && scrollTop !== this.scrollTopCache) {
      this.jumpToPosition(scrollTop);
    }

    if (!isEqual(prevStateItems, newItems)) {
      /**
       * Binding row renderer to the instance is one of the solutions to fix the react virtualized's
       * inner grid problem, that doesn't update the values
       * https://github.com/bvaughn/react-virtualized/issues/1262
       */
      this.setState(
        (prevState: StateType): StateType => ({
          ...prevState,
          items: [...newItems],
        }),
      );
      // If items counts are different
      // then updateLayout because the cache would have the old section/item heights cached
      // https://stackoverflow.com/questions/43837279/dynamic-row-heights-with-react-virtualized-and-new-cellmeasurer
      // https://github.com/bvaughn/tweets/blob/37d0139736346db16b9681d5b859a4e127964518/src/components/TweetList.js#L126-L132
      if (
        prevStateItems.length !== newItems.length ||
        !SectionList.sectionsHaveEqualStructure(prevSections, sections)
      ) {
        this.updateLayout();
      }
    }
  }

  componentWillUnmount() {
    const { onUnmount } = this.props;
    const { currentlyScrollingItem, currentlyScrollingSection, scrollTopCache } = this;

    this._isMounted = false;

    onUnmount({
      scrollTop: scrollTopCache,
      scrollToIndex: currentlyScrollingItem,
      scrollToSection: currentlyScrollingSection,
    });
  }

  listRef: Record<string, any> = createRef();

  currentlyScrollingItem: number = this.props.scrollToIndex;

  currentlyScrollingSection: number = this.props.scrollToSection;

  targetIndex = 0;

  targetSection = 0;

  targetPosition = 0;

  scrollTopCache = 0;

  _isMounted = false;

  requestedScroll = false;

  requestedSectionScroll = false;

  requestedPositionScroll = false;

  targetIndexMet = true;

  targetSectionMet = true;

  targetPositionMet = true;

  listCache = new CellMeasurerCache({
    fixedWidth: true,
    fixedHeight: false,
    minHeight: 48,
  });

  scrollToRow = (index: number) => {
    const { current } = this.listRef;
    if (current) {
      current.scrollToRow(index);
      setTimeout(() => {
        current.scrollToRow(index);
      }, 100);
    }
  };

  rowRenderer = ({
    index,
    key,
    parent,
    style,
    isScrolling,
    isVisible,
  }: Record<string, any>): CellMeasurer => {
    const { sectionStyle, sectionRenderer, itemRenderer } = this.props;
    const { items } = this.state;

    const customSectionStyle = { ...requiredSectionStyle, ...sectionStyle, ...style };

    return (
      <CellMeasurer
        cache={this.listCache}
        columnIndex={0}
        key={key}
        rowIndex={index}
        parent={parent}
      >
        {(): JSX.Element => (
          <div className="item-container" style={customSectionStyle}>
            {items[index].title &&
              sectionRenderer({ ...items[index], index, isVisible, isScrolling })}
            {items[index].sectionItem &&
              itemRenderer({ item: items[index], key, isVisible, isScrolling })}
          </div>
        )}
      </CellMeasurer>
    );
  };

  setScrollTopCache = (scrollTop: number) => {
    this.scrollTopCache = scrollTop;
  };

  onScroll = (scrollMeasurements: ScrollMeasurementType) => {
    const { currentlyScrollingSection, currentlyScrollingItem } = this;

    const { onScroll, onScrollPosition } = this.props;
    const { _isMounted: isMounted } = this;

    const { scrollTop } = scrollMeasurements;

    if (!isMounted || currentlyScrollingItem == null) {
      return;
    }

    this.setScrollTopCache(scrollTop);

    if (this.requestedPositionScroll) {
      if (scrollTop === this.targetPosition && !this.targetPositionMet) {
        // Reset flags
        this.targetPositionMet = true;
        this.requestedPositionScroll = false;
      }
    }

    const autoScroll =
      this.requestedSectionScroll || this.requestedScroll || this.requestedPositionScroll;

    if (scrollTop === 0) {
      // If we are back at the top
      // then force the section and item to be the first one
      onScroll(0, 0, scrollTop, autoScroll);
    } else {
      onScroll(currentlyScrollingItem, currentlyScrollingSection, scrollTop, autoScroll);
    }

    onScrollPosition(scrollTop);
  };

  setTargetPosition = (scrollTop: number) => {
    this.targetPosition = scrollTop;
    this.targetPositionMet = false;
  };

  setTargetIndex = (index: number) => {
    this.targetIndex = index;
    this.targetIndexMet = false;
  };

  setTargetSection = (sectionIndex: number) => {
    this.targetSection = sectionIndex;
    this.targetSectionMet = false;
  };

  jumpToPosition = (scrollTop: number) => {
    const { current } = this.listRef;
    if (current) {
      this.setTargetPosition(scrollTop);
      this.setScrollTopCache(scrollTop);
      this.requestedPositionScroll = true;
      current.scrollToPosition(scrollTop);
      setTimeout(() => current.scrollToPosition(scrollTop), 100);
    }
  };

  jumpToRow = (index: number) => {
    this.requestedScroll = true;
    this.requestedSectionScroll = false;
    this.setTargetIndex(index);
    this.scrollToRow(index);
  };

  jumpToSection = (scrollToSection: number) => {
    const { items } = this.state;
    const sectionFromItems = getSectionsFromItems(items);
    const sectionToScrollTo = sectionFromItems[scrollToSection];
    const itemIndex = items.indexOf(sectionToScrollTo);

    this.requestedScroll = false;
    this.requestedSectionScroll = true;
    this.setTargetSection(scrollToSection);
    this.scrollToRow(itemIndex);
  };

  clearCache = (): void => sectionListStore.emptyCache();

  forceUpdateList = () => {
    const { id = 0 } = this.props;
    if (sectionListStore.cacheList[id]) {
      sectionListStore.cacheList[id].clearAll();
    }
    this.forceUpdateGrid();
  };

  forceUpdateGrid = () => {
    const { current } = this.listRef;
    if (current) {
      current.forceUpdateGrid();
    }
  };

  updateLayout = () => {
    this.listCache.clearAll();
    const { current } = this.listRef;
    if (current) {
      current.recomputeRowHeights();
    }
  };

  setCurrentlyScrollingItem = (itemIndex: number) => {
    this.currentlyScrollingItem = itemIndex;
  };

  setCurrentlyScrollingSection = (sectionIndex: number) => {
    this.currentlyScrollingSection = sectionIndex;
  };

  render(): JSX.Element {
    const { items } = this.state;
    const { overscanRowCount } = this.props;

    const cache = this.listCache;
    const rowHeight = cache ? cache.rowHeight : 0;
    return (
      <AutoSizer>
        {({ height, width }: { height: number; width: number }): List => (
          <List
            ref={this.listRef}
            deferredMeasurementCache={cache}
            height={height}
            onScroll={this.onScroll}
            overscanRowCount={overscanRowCount}
            rowCount={items.length}
            rowHeight={rowHeight}
            rowRenderer={this.rowRenderer}
            // *** Important ***
            // Without passing in a no-op, when props.section changes, items aren't refreshed
            onRowsRendered={({ startIndex }: { startIndex: number; stopIndex: number }) => {
              if (startIndex === 0) {
                return;
              }

              const sectionFromItems = getSectionsFromItems(items);
              const sectionIndex = sectionFromItems.findIndex(
                (x: Record<string, any>): boolean =>
                  x.id === items[Math.min(startIndex + 1, items.length - 1)].sectionId,
              );

              this.setCurrentlyScrollingItem(startIndex);
              this.setCurrentlyScrollingSection(sectionIndex);

              const startOffset = this.listRef.current.getOffsetForRow({
                alignment: 'start',
                index: startIndex,
              });
              const lastOffset = this.listRef.current.getOffsetForRow({
                alignment: 'end',
                index: items.length - 1,
              });
              const currentBottom = startOffset + height;
              const inBottomArea = currentBottom >= lastOffset;

              if (this.requestedScroll || this.requestedSectionScroll) {
                // Requested a scroll by calling a "jump" function
                if (this.requestedScroll) {
                  if (
                    (startIndex === this.targetIndex ||
                      this.scrollTopCache === this.targetPosition) &&
                    !this.targetIndexMet
                  ) {
                    // Reset flags
                    this.targetIndexMet = true;
                    this.requestedScroll = false;

                    this.targetPositionMet = true;
                    this.props.onSectionChange(sectionIndex);
                  }
                } else if (this.requestedSectionScroll) {
                  if (
                    (sectionIndex === this.targetSection || inBottomArea) &&
                    !this.targetSectionMet
                  ) {
                    // Reset flags
                    this.targetSectionMet = true;
                    this.requestedSectionScroll = false;
                    this.props.onSectionChange(this.targetSection);
                  }
                }
              } else {
                // Normal scroll
                this.props.onSectionChange(sectionIndex);
              }
            }}
            scrollToAlignment="start"
            width={width}
          />
        )}
      </AutoSizer>
    );
  }
}

/**
 * Sections have a title and items have a name.
 * @param sections
 * @return {Array<$NonMaybeType<T>>|Array<{id: (string|number), title: string,
 *  data: Object}>}
 */
export const getSectionsFromItems = (sections: FlatItemsType[]) =>
  sections.filter((x: SectionType | FlatItemsType): boolean => x.title != null);

export const findItemIndexById: (
  items: Array<Record<string, any>>,
  itemId: string | number,
) => number = (items, itemId): number =>
  items.findIndex((i: Record<string, any>): boolean => i.id === itemId);

/**
 * Flatten all section and it's data to show individual rows in
 * react-virtualized's rowRenderer.
 * @param sections
 * @return {[]}
 */
export const getFlatItems = (sections: SectionType[]): FlatItemsType[] => {
  const items = [];
  sections.forEach((s: SectionType) => {
    items.push({ id: s.id, sectionId: s.id, title: s.title });
    if (s.data) {
      s.data.forEach((i: Record<string, any>): number =>
        items.push({ ...i, sectionId: s.id, sectionItem: true }),
      );
    }
  });
  return items;
};
