import React, { Component } from 'react';

import ReactDOM from 'react-dom';
import styled from 'styled-components';

type PropsType = {
  /**
   * This uses React Portal to to inject the overlay element to the Body element.
   */
  forceRenderToDOM: boolean;
  mobileFullScreen: boolean;
  onCancel: () => void;
  onClick: ((event: React.SyntheticEvent) => void) | null;
  onKeyDown: ((event: React.KeyboardEvent<HTMLDivElement>) => void) | null;
  preventBackgroundScroll: boolean;
};

const OverlayContainer = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }): string => theme.overlay.backgroundColor};
  z-index: ${({ theme }): number => theme.zIndex.overlay};
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  margin: auto 0;
  width: 100%;

  @media screen and (max-width: 767px) {
    &.mobileFullScreen {
      height: max-content;
    }
  }
`;

export default class Overlay extends Component<React.PropsWithChildren<PropsType>, any> {
  static defaultProps = {
    onCancel: () => {},
    onKeyDown: null,
    onClick: null,
    mobileFullScreen: false,
    forceRenderToDOM: false,
    preventBackgroundScroll: false,
  };

  componentDidMount = () => {
    if (this.props.preventBackgroundScroll) {
      // We want to stop the scrolling of the background <body> while Overlay is open
      const body = document.getElementsByTagName('body')[0];
      body.style.overflow = 'hidden';
    }
  };

  componentWillUnmount = () => {
    if (this.props.preventBackgroundScroll) {
      // We want to allow the scrolling of the background <body> again
      const body = document.getElementsByTagName('body')[0];
      body.style.overflow = '';
    }
  };

  handleClick = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    if (typeof this.props.onClick === 'function') {
      this.props.onClick(event);
      return;
    }
    this.props.onCancel();
  };

  handleKeyboardEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(event);
      return;
    }
    if (event.key === 'Escape') {
      this.props.onCancel();
    }
  };

  render() {
    const RenderOverlayContainer = (
      <OverlayContainer
        tabIndex={0}
        onKeyDown={this.handleKeyboardEvent}
        onClick={this.handleClick}
      >
        <Content className={this.props.mobileFullScreen ? 'mobileFullScreen' : ''}>
          {this.props.children}
        </Content>
      </OverlayContainer>
    );

    const overlayPortal = ReactDOM.createPortal(RenderOverlayContainer, document.body);
    return <>{this.props.forceRenderToDOM ? overlayPortal : RenderOverlayContainer}</>;
  }
}
