import React, { Component } from 'react';
import styled, { ThemeProvider, ThemeConsumer } from 'styled-components';
import classnames from 'classnames';

import Alignment from '../Alignment';
import Avatar from '../Avatar';
import Caption from '../Caption';
import ContextMenuItem, { ContextMenuItemType } from './ContextMenuItem';
import Group from '../Group';
import { IconHelp, IconExpand, IconCollapse, IconClose, IconArrowDown } from '../Icon';
import Link from '../Link';
import IconButton from '../IconButton';
import Logo from '../Logo';
import MenuItem, { MenuItemType } from './MenuItem';
import Popup from '../Popup';
import TextTruncate from '../TextTruncate';
import { preventPropagation } from '../../utils/interaction';
import { getThemeFromColor } from '../../utils/themeSelector';
import { theme as KountaThemeLight, colors } from '../../themes';

const Panel = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }): string => theme.navigation.backgroundColor};

  width: 64px;

  &.expanded {
    min-width: 200px;
  }

  &.fullWidth {
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${({ theme }): string => theme.space.s16};
  background-color: ${({ theme }): string => theme.navigation.headerBackground};
  position: relative;
  min-height: 56px;

  .collapsedLayout {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &:focus,
  &:active {
    outline: none;
  }

  &:hover {
    cursor: pointer;
    background: ${({ theme }): string => theme.navigation.secondaryMenuBackground};
  }
`;

const HeaderClickArea = styled.div`
  padding: 16px;
  margin: -16px;

  &:focus,
  &:active {
    outline: none;
  }
`;

const CollapseArea = styled.div`
  position: absolute;
  right: 4px;
  top: 12px;
`;

const CloseArea = styled.div`
  position: absolute;
  right: 8px;
  top: 12px;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
`;

const ExpandContainer = styled.div`
  margin-top: 8px;
`;

const NavTitleWrapper = styled.div`
  background-color: ${({ theme }): string => theme.navigation.contextButtonBackground};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 32px 16px;
`;

const NavTitleButton = styled.div<{ onClick?: any }>`
  margin: 0;
  padding: 0;
  width: 100%;
  height: fit-content;

  &:hover {
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  }
`;

const NavTitle = styled.div`
  font: ${({ theme }): string => theme.text.lead};
  color: ${({ theme }): string => theme.text.whiteText};
  white-space: nowrap;

  &.rotated {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    position: relative;
  }
`;

const ContextButton = styled.div`
  font: ${({ theme }): string => theme.text.body};
  color: ${({ theme }): string => theme.text.whiteText};
  padding: 16px;
  border-radius: 4px;
  background-color: ${({ theme }): string => theme.navigation.contextButtonBackground};
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
  height: 48px;
  align-items: center;
  display: flex;

  &.smallSquare {
    width: 48px;
    justify-content: center;
    padding: 0;
  }

  &:focus {
    background: ${({ theme }): string => theme.navigation.secondaryHoverBackground};
    outline: none;
  }
`;

const ContextLabel = styled.div`
  font: ${({ theme }): string => theme.text.caption};
  color: ${({ theme }): string => theme.text.whiteText};
  font-weight: bold;
`;

const SecondaryMenu = styled.div<{ expanded?: boolean }>`
  font: ${({ theme }): string => theme.text.body};
  color: ${({ theme }): string => theme.text.whiteText};
  position: absolute;
  bottom: 32px;
  left: ${(props): string => (props.expanded ? '172px' : '48px')};
  width: max-content;
  max-width: 320px;
  background: ${({ theme }): string => theme.navigation.secondaryMenuBackground};
  border-radius: ${({ theme }): string => theme.border.radius};
  box-shadow: ${({ theme }): string => theme.shadows.default};
  z-index: ${({ theme }): number => theme.zIndex.popup};

  &.fullPanel {
    left: 0;
    top: 0;
    width: 100%;
    max-width: unset;
    height: 100%;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      a {
        padding: 0;
        font-size: 14px;
        cursor: pointer;
      }

      &:hover {
        background-color: ${({ theme }): string => theme.navigation.secondaryHoverBackground};
      }
    }
  }
`;

const Body = styled.ul`
  width: 100%;
  height: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;

  background-color: ${({ theme }): string => theme.navigation.backgroundColor};
`;

const Footer = styled.div<{ collapsed?: boolean; showMenuAsPanel?: boolean }>`
  width: 100%;
  padding: ${(props): string => (props.collapsed ? '8px' : '16px')};
  position: ${(props): string => (props.showMenuAsPanel ? 'unset' : 'relative')};
`;

export type LeftNavSecondaryMenuType = {
  contexts?: ContextMenuItemType[];
  items?: MenuItemType[];
};

type MenuState = { [x: string]: boolean };

export type LeftNavMenuPropsType = {
  allowMultipleExpanded?: boolean;
  /**
   * **Deprecated** use a ChameleonRoot to control color schemes via theme instead
   * @deprecated
   */
  backgroundColor?: string;
  contextDescription?: string;
  contextLabel?: string | null | false;
  expanded?: boolean;
  footerContent?: JSX.Element | null;
  fullWidth?: boolean;
  imageUrl?: string;
  items?: MenuItemType[];
  onClickContext?: () => void;
  onClickHelp?: () => void;
  onClickLogo?: () => void;
  onClose?: () => void;
  secondaryMenu?: LeftNavSecondaryMenuType;
  selectedItem?: string;
  shortLabel?: string;
  showAdmin?: boolean;
  showClose?: boolean;
  showContextAvatar?: boolean;
  showHelp?: boolean;
  showToggle?: boolean;
  title?: string;
  useRouter?: boolean;
};

type StateType = {
  expanded: boolean;
  isInMenu: boolean;
  menuStates: MenuState;
  selectedItem: string;
  showSecondaryMenu: boolean;
};

export default class LeftNav extends Component<LeftNavMenuPropsType, StateType> {
  secondaryMenuEl: HTMLElement;

  static defaultProps = {
    contextLabel: '',
    contextDescription: '',
    shortLabel: '',
    imageUrl: '',
    showContextAvatar: false,
    expanded: false,
    showToggle: true,
    showClose: false,
    fullWidth: false,
    icon: null,
    items: null,
    selectedItem: '',
    secondaryMenu: null,
    showHelp: true,
    useRouter: false,
    footerContent: null,
    showAdmin: false,
    allowMultipleExpanded: true,
    onClickContext: () => {},
  };

  constructor(props: LeftNavMenuPropsType) {
    super(props);

    const states: MenuState = {};
    this.props.items.forEach((item: MenuItemType) => {
      states[item.id] = item.isOpen;
    });

    this.state = {
      expanded: props.expanded,
      showSecondaryMenu: false,
      isInMenu: false,
      menuStates: states,
      selectedItem: props.selectedItem,
    };
  }

  componentDidUpdate(prevProps: LeftNavMenuPropsType) {
    if (this.props.expanded !== prevProps.expanded) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ expanded: this.props.expanded });
    }
  }

  toggle = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  };

  handleContextClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    this.setState((prevState) => ({ showSecondaryMenu: !prevState.showSecondaryMenu }));
    this.props.onClickContext();
  };

  hideSecondaryMenu = () => {
    this.setState({ showSecondaryMenu: false });
  };

  getContextButton(): any {
    if (!this.props.contextLabel) {
      return null;
    }

    if (!this.state.expanded) {
      return (
        <ContextButton
          id="nav-context"
          className="smallSquare"
          tabIndex={0}
          role="button"
          onClick={this.handleContextClick}
        >
          <Avatar
            square
            size="small"
            initials={this.props.shortLabel}
            imageUrl={this.props.imageUrl}
          />
        </ContextButton>
      );
    }

    return (
      <ContextButton
        id="nav-context"
        tabIndex={0}
        role="button"
        onClick={this.handleContextClick}
        style={{ padding: '16px 12px' }}
      >
        <Group horizontal horizontalWidth="100%" spacing="8px">
          {this.props.showContextAvatar && (
            <Avatar
              square
              size="small"
              initials={this.props.shortLabel}
              imageUrl={this.props.imageUrl}
            />
          )}
          <Group spacing="0px">
            <ContextLabel>
              <TextTruncate>{this.props.contextLabel}</TextTruncate>
            </ContextLabel>
            <Caption color={colors.white}>
              <TextTruncate>{this.props.contextDescription}</TextTruncate>
            </Caption>
          </Group>
        </Group>
      </ContextButton>
    );
  }

  onClickLogo = (e: React.SyntheticEvent) => {
    if (this.props.onClickLogo) {
      preventPropagation(e, this.props.onClickLogo);
    }
  };

  getExpandedHeader(): JSX.Element {
    return (
      <Header>
        <HeaderClickArea role="button" tabIndex={0} onClick={this.onClickLogo}>
          <Group horizontal spacing="4px">
            <Group horizontal spacing="4px">
              <NavTitle data-chamid="LeftNav__Title--expanded">{this.props.title}</NavTitle>
              {this.props.onClickLogo && (
                <IconArrowDown
                  data-chamid="LeftNav__Title--arrow"
                  width={12}
                  height={12}
                  color={colors.white}
                />
              )}
            </Group>
          </Group>
        </HeaderClickArea>
        {this.state.isInMenu && this.props.showToggle && (
          <CollapseArea>
            <IconButton onClick={this.toggle}>
              <IconCollapse color={colors.white} />
            </IconButton>
          </CollapseArea>
        )}
        {this.props.showClose && (
          <CloseArea>
            <IconButton onClick={this.props.onClose}>
              <IconClose width={16} height={16} color={colors.white} />
            </IconButton>
          </CloseArea>
        )}
      </Header>
    );
  }

  getDefaultHeader(): JSX.Element {
    return (
      <Header>
        <LogoContainer>
          <Link onClick={this.onClickLogo}>
            <Logo isWhite />
          </Link>
        </LogoContainer>
        {this.props.showToggle && (
          <CollapseArea>
            <IconButton onClick={this.toggle}>
              <IconCollapse color={colors.white} />
            </IconButton>
          </CollapseArea>
        )}
      </Header>
    );
  }

  getCollapsedHeader(): JSX.Element {
    return (
      <>
        <NavTitleButton role="button" onClick={this.onClickLogo}>
          <NavTitleWrapper>
            {this.props.title ? (
              <NavTitle data-chamid="LeftNav__Title--collapsed" className="rotated">
                {this.props.title}
              </NavTitle>
            ) : (
              /** Lightspeed logo should not go sideways, we must create an alternative CHAM-581 */
              <Logo isWhite rotateSideways />
            )}
          </NavTitleWrapper>
        </NavTitleButton>
        {this.props.showToggle && (
          <ExpandContainer>
            <IconButton onClick={this.toggle}>
              <IconExpand color={colors.white} />
            </IconButton>
          </ExpandContainer>
        )}
      </>
    );
  }

  onClickHelp = (e: React.SyntheticEvent) => {
    if (this.props.onClickHelp) {
      preventPropagation(e, this.props.onClickHelp);
    }
  };

  getHelpButton(): any {
    if (!this.props.showHelp) {
      return null;
    }

    if (!this.state.expanded) {
      return (
        <ContextButton
          id="nav-help"
          className="smallSquare"
          tabIndex={0}
          role="button"
          onClick={this.onClickHelp}
        >
          <IconHelp color={colors.white} />
        </ContextButton>
      );
    }

    // return the expanded help
    return (
      <ContextButton id="nav-help" tabIndex={0} role="button" onClick={this.onClickHelp}>
        <Group horizontal full spacing="16px">
          <IconHelp width={24} height={24} color={colors.white} />
          <div style={{ marginLeft: '-24px' }}>
            <ContextLabel>Help & support</ContextLabel>
          </div>
        </Group>
      </ContextButton>
    );
  }

  getFooter(): JSX.Element {
    return (
      <Group spacing="8px">
        {this.props.footerContent && this.props.footerContent}
        {this.getHelpButton()}
        {this.getContextButton()}
      </Group>
    );
  }

  getHeader(): JSX.Element {
    if (this.state.expanded) {
      if (this.props.title) {
        return this.getExpandedHeader();
      }
      return this.getDefaultHeader();
    }
    return this.getCollapsedHeader();
  }

  renderSecondaryMenu(): JSX.Element | null {
    if (!this.props.secondaryMenu) {
      return null;
    }

    const isLargePanel = this.props.expanded && this.props.fullWidth;

    return (
      <SecondaryMenu
        ref={(el: HTMLElement) => {
          this.secondaryMenuEl = el;
        }}
        expanded={this.state.expanded}
        className={isLargePanel ? 'fullPanel' : ''}
      >
        <Popup onClickOutside={this.hideSecondaryMenu}>
          <Group spacing="0px">
            {this.props.fullWidth && this.props.expanded && (
              <HeadingSection>
                <Alignment horizontal="right" vertical="center">
                  <IconButton onClick={this.handleContextClick}>
                    <IconClose height={16} width={16} color={colors.white} />
                  </IconButton>
                </Alignment>
              </HeadingSection>
            )}
            <ul>
              {this.props.secondaryMenu.contexts &&
                this.props.secondaryMenu.contexts.map(
                  (contextItem: ContextMenuItemType): JSX.Element => (
                    <ContextMenuItem
                      id={contextItem.id}
                      key={contextItem.id}
                      isPerson={contextItem.isPerson}
                      label={contextItem.label}
                      description={contextItem.description}
                      shortLabel={contextItem.shortLabel}
                      imageUrl={contextItem.imageUrl}
                      onClick={contextItem.onClick}
                    />
                  ),
                )}
              {this.props.secondaryMenu.items &&
                this.props.secondaryMenu.items.map(
                  (item: MenuItemType): JSX.Element => (
                    <MenuItem
                      icon={item.icon}
                      id={item.id}
                      key={`secondaryMenu-${item.id || item.label}`}
                      label={item.label}
                      value={item.value}
                      href={item.href}
                      target={item.target}
                      onClick={item.onClick}
                    />
                  ),
                )}
            </ul>
          </Group>
        </Popup>
      </SecondaryMenu>
    );
  }

  onMouseEnterMenu = () => {
    if (!this.state.isInMenu) {
      this.setState({ isInMenu: true });
    }
  };

  onMouseLeaveMenu = () => {
    if (this.state.isInMenu) {
      this.setState({ isInMenu: false });
    }
  };

  onMenuFocus = () => {
    if (!this.state.isInMenu) {
      this.setState({ isInMenu: true });
    }
  };

  handleParentClick = (id: string, callback?: () => void) => {
    let selectedMenuItem = this.props.selectedItem;
    let childCallback;
    const newMenu: MenuState = {};
    this.props.items.forEach((menuItem: MenuItemType) => {
      // By default we allow for multiple menu nodes to be open
      // If allowMultipleExpanded is false
      // then we will do a check to ensure that the item clicked is the only item open
      // We are using props to control this rather than calling an open/close method
      newMenu[menuItem.id] = this.props.allowMultipleExpanded
        ? menuItem.isOpen
        : menuItem.id === id;
      if (menuItem.id === id && menuItem.autoSelectChild && menuItem.children) {
        selectedMenuItem = menuItem.children[0].value;
        childCallback = menuItem.children[0].onClick;
      }
    });

    if (childCallback) {
      childCallback();
    } else if (callback) {
      callback();
    }

    this.setState({ menuStates: newMenu, selectedItem: selectedMenuItem });
  };

  renderMenu(): JSX.Element[] | null {
    if (this.props.items) {
      return this.props.items.map(
        (item: MenuItemType): JSX.Element => (
          <MenuItem
            icon={item.icon}
            id={item.id}
            className={item.className}
            key={`menu-${item.id || item.label}`}
            label={item.label}
            value={item.value}
            href={item.href}
            target={item.target}
            onClick={(): void => this.handleParentClick(item.id, item.onClick)}
            selectedItem={this.state.selectedItem}
            useRouter={this.props.useRouter}
            isOpen={this.state.menuStates[item.id]}
            data={item.data}
          >
            {item.children}
          </MenuItem>
        ),
      );
    }

    return null;
  }

  render(): JSX.Element {
    const classes = classnames(
      this.state.expanded ? 'expanded' : '',
      this.props.fullWidth ? 'fullWidth' : '',
    );

    return (
      <ThemeConsumer>
        {(theme) => (
          <ThemeProvider
            theme={
              this.props.backgroundColor
                ? getThemeFromColor(this.props.backgroundColor)
                : theme || KountaThemeLight
            }
          >
            <Panel
              onMouseEnter={this.onMouseEnterMenu}
              onMouseLeave={this.onMouseLeaveMenu}
              onClick={this.onMenuFocus}
              className={classes}
            >
              {this.props.showAdmin && <AdminPanel>KAdmin</AdminPanel>}
              {this.getHeader()}
              <Body role="menu">{this.state.expanded ? this.renderMenu() : null}</Body>
              <Footer
                collapsed={!this.state.expanded}
                showMenuAsPanel={this.state.expanded && this.props.fullWidth}
              >
                {this.getFooter()}
                {this.state.showSecondaryMenu ? this.renderSecondaryMenu() : null}
              </Footer>
            </Panel>
          </ThemeProvider>
        )}
      </ThemeConsumer>
    );
  }
}

const HeadingSection = styled.div`
  &&& {
    display: flex;
    padding: 8px;
  }
`;

const AdminPanel = styled.div`
  background-color: ${colors.maple700};
  color: ${colors.white};
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 4px;
  width: 100%;
`;
