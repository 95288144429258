import { fontSizes, fontWeights, lineHeights, fonts } from './foundations/fonts';
import { breakpoints, mediaQueries } from './foundations/media';
import { space, borderWidths, radii, shadows, zIndices } from './foundations/layout';
import { colors } from './foundations/colors';
import { CoreTheme } from '../theme';

const DEPRECATED_LEGACY = {
  screen: {
    xsmall: '320px',
    small: '375px',
    medium: '600px',
    large: '768px',
    xlarge: '992px',
    xxlarge: '1368px',
  },
  spacing: {
    ...space,
    none: '0px',
    xsmall: '4px',
    small: '8px',
    mediumSlim: '12px',
    medium: '16px',
    large: '24px',
    xlarge: '48px',
    xxlarge: '96px',
    xxxlarge: '144px',
  },
  fontSize: {
    ...fontSizes,
    button: '15px',
    title: '32px',
    lead: '20px',
  },
  fontWeight: {
    ...fontWeights,
    light: 200,
  },
  lineHeight: {
    ...lineHeights,
    tight: '16px',
    base: '24px',
    subHeading: '32px',
    heading: '40px',
  },
  shadow: shadows,
  zIndex: zIndices,
  borderSize: borderWidths,
  text: {
    body: `${fontWeights.normal} ${fontSizes.base}/${lineHeights.base} ${fonts.sans}`,
    heading: `${fontWeights.normal} ${fontSizes.heading1}/${lineHeights.heading1} ${fonts.sans}`,
    heading2: `${fontWeights.normal} ${fontSizes.heading2}/${lineHeights.heading2} ${fonts.sans}`,
    heading3: `${fontWeights.normal} ${fontSizes.heading3}/${lineHeights.heading3} ${fonts.sans}`,
    heading4: `${fontWeights.normal} ${fontSizes.heading4}/${lineHeights.heading4} ${fonts.sans}`,
    heading6: `${fontWeights.bold} ${fontSizes.heading6}/${lineHeights.heading6} ${fonts.sans}`,
    label: `${fontWeights.bold} ${fontSizes.base}/${lineHeights.base} ${fonts.sans}`,
    caption: `${fontWeights.normal} ${fontSizes.caption}/${lineHeights.caption} ${fonts.sans}`,
    lead: `${fontWeights.normal} 20px/24px ${fonts.sans}`,
    menu: `${fontWeights.normal} ${fontSizes.base}/${lineHeights.caption} ${fonts.sans}`,
    menuSelected: `${fontWeights.bold} ${fontSizes.base}/${lineHeights.caption} ${fonts.sans}`,
    menuHeading: `${fontWeights.bold} ${fontSizes.base}/${lineHeights.caption} ${fonts.sans}`,
    menuActions: `${fontWeights.normal} ${fontSizes.base}/${lineHeights.caption} ${fonts.sans}`,
    productTitle: `${fontWeights.normal} 20px/24px ${fonts.sans}`,
    title: `${fontWeights.normal} ${fontSizes.heading1}/${lineHeights.heading1} ${fonts.sans}`,
  },
};

export const coreTheme: CoreTheme = {
  borderWidths,
  breakpoints,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  mediaQueries,
  radii,
  shadows,
  space,
  zIndices,
};

export const coreThemeWithLegacy: CoreTheme & typeof DEPRECATED_LEGACY = {
  ...DEPRECATED_LEGACY,
  ...coreTheme,
  fontSizes: {
    ...fontSizes,
    ...DEPRECATED_LEGACY.fontSize,
  },
  fontWeights: {
    ...fontWeights,
    ...DEPRECATED_LEGACY.fontWeight,
  },
  space: {
    ...space,
    ...DEPRECATED_LEGACY.spacing,
  },
};

export default coreThemeWithLegacy;
