import { Breakpoints } from '../../theme';

const mobileMin = '0px';
const tabletMin = '768px';
const desktopMin = '992px';

const bp: string[] & { [key in Breakpoints]?: string } = [mobileMin, tabletMin, desktopMin];
// aliases
bp.mobile = mobileMin;
bp.tablet = tabletMin;
bp.desktop = desktopMin;

export const breakpoints = bp;

export const mediaQueries = {
  mobile: `@media screen and (max-width: calc(${breakpoints.tablet} - 1px))`,
  mobileAndTablet: `@media screen and (max-width: calc(${breakpoints.desktop} - 1px))`,
  tablet: `@media screen and (min-width: ${breakpoints.tablet}) and (max-width: calc(${breakpoints.desktop} - 1px))`,
  tabletAndDesktop: `@media screen and (min-width: ${breakpoints.tablet})`,
  desktop: `@media screen and (min-width: ${breakpoints.desktop})`,
};
