import React, { ReactElement } from 'react';
import styled, { ThemeConsumer } from 'styled-components';
import { GithubPicker } from 'react-color';
import TextInput from '../TextInput';
import Popup from '../Popup';
import IconColorPicker from './IconColorPicker';

type PropsType = {
  closeOnColorChange?: boolean;
  /** Set `color` if you want to use this as a controlled component. */
  color?: string;
  colors: string[];
  /** Set `initialColor` if you want the component to manage its own state. */
  initialColor?: string;
  maxWidth?: string;
  onColorChange?: (value: { hex: string }) => void;
  /** One of the color picker components from `react-color`. */
  pickerComponent?: ReactElement;
  pickerZIndex?: number;
  placeholder?: string;
  readonlyInput?: boolean;
  showColorPicker?: boolean;
};

type StateType = {
  color: string;
  isFocused: boolean;
  showColorPicker: boolean;
};

export default class ColorPicker extends React.Component<PropsType, StateType> {
  static normalizeHex(hex?: string) {
    return hex ? hex.toUpperCase() : '';
  }

  static defaultProps = {
    closeOnColorChange: true,
    maxWidth: '140px',
    onColorChange: () => {},
    pickerZIndex: 1,
    placeholder: 'Choose...',
  };

  constructor(props: PropsType) {
    super(props);
    this.state = {
      showColorPicker: this.props.showColorPicker,
      isFocused: false,
      color: ColorPicker.normalizeHex(this.props.initialColor || this.props.color),
    };
  }

  static getDerivedStateFromProps(props: PropsType, state: StateType) {
    if (props.color && state.color !== props.color) {
      return {
        color: ColorPicker.normalizeHex(props.color),
      };
    }
    return null;
  }

  showPicker = (show?: boolean) => {
    this.setState((state) => ({
      showColorPicker: typeof show === 'boolean' ? show : !state.showColorPicker,
    }));
  };

  changeColor = ({ hex }: { hex: string }) => {
    const cleanHex = ColorPicker.normalizeHex(hex);
    const value = { hex: cleanHex };

    this.setState({ color: cleanHex });
    if (this.props.closeOnColorChange) {
      this.setState({ showColorPicker: false });
    }
    if (this.props.onColorChange) {
      this.props.onColorChange(value);
    }
  };

  render(): JSX.Element {
    const { readonlyInput = false } = this.props;
    const prefix = (
      // eslint-disable-next-line jsx-a11y/interactive-supports-focus
      <StyledPrefix
        isFocused={this.state.isFocused}
        backgroundColor={this.state.color}
        isEmpty={!this.state.color}
        onClick={() => this.showPicker()}
        role="button"
      >
        {!this.state.color && (
          <ThemeConsumer>
            {(theme) => <IconColorPicker color={theme.text.hint} width={36} height={36} />}
          </ThemeConsumer>
        )}
      </StyledPrefix>
    );

    const { pickerComponent = <GithubPicker triangle="top-left" /> } = this.props;

    return (
      <StyledRoot>
        <Popup onClickOutside={() => this.showPicker(false)}>
          <StyledRow onClick={readonlyInput ? () => this.showPicker(true) : undefined}>
            <TextInput
              initialValue={this.state.color}
              value={this.state.color}
              onChange={(value: string) => this.changeColor({ hex: value })}
              prefix={prefix}
              placeholder={this.props.placeholder}
              onBlur={() => {
                this.setState({ isFocused: false });
              }}
              onFocus={() => {
                this.setState({ isFocused: true, showColorPicker: false });
              }}
              cursor={readonlyInput ? 'pointer' : undefined}
              readOnly={readonlyInput}
              maxWidth={this.props.maxWidth}
            />
            {this.state.showColorPicker && (
              <PickerWrap zIndex={this.props.pickerZIndex}>
                {React.cloneElement(pickerComponent, {
                  color: this.state.color,
                  colors: this.props.colors,
                  onChangeComplete: this.changeColor,
                  width: '100%',
                })}
              </PickerWrap>
            )}
          </StyledRow>
        </Popup>
      </StyledRoot>
    );
  }
}

const StyledRoot = styled.div`
  &,
  & > * {
    display: inline-block;
  }
`;
type StyledPrefixProps = {
  backgroundColor?: string;
  isEmpty?: boolean;
  isFocused?: boolean;
};
const StyledPrefix = styled.div<StyledPrefixProps>`
  margin-left: ${(props): string => (props.isFocused ? '-10px' : '-11px')};
  border-radius: 2px;
  width: ${(props): string => (props.isFocused ? '44px' : '46px')};
  height: ${(props): string => (props.isFocused ? '44px' : '46px')};
  background-color: ${(props): string => props.backgroundColor};
  // Center color picker icon
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const StyledRow = styled.div`
  position: relative;
`;
const PickerWrap = styled.div<{ zIndex: number }>`
  position: absolute;
  width: 100%;
  z-index: ${(p) => p.zIndex};

  > * {
    box-sizing: border-box !important;
  }
`;
