/**
 * Provides backwards compatibility for old components by
 * centrifuging multiple boolean props into a single variant string
 */
export function getVariationName<T extends string>(
  bools: {
    [key in T]?: boolean;
  },
  variant?: T,
): T | null {
  const variantEntry = Object.entries<boolean>(bools)
    .filter(([, value]) => value === true)
    .shift();
  if (variantEntry) return variantEntry[0] as T;
  if (variant) return variant;
  return null;
}
