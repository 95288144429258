import React, { PureComponent, createRef } from 'react';
import styled from 'styled-components';
import AutoSizer, { SizeType } from '../AutoSizer';
import TopButton from '../TopButton';
import InfinityList from './InfinityList';
import ItemMeasurer from './ItemMeasurer';

type PropsType = {
  backToTopThreshold?: number;
  /**
   * @param {number} startIndex
   * @param {number} stopIndex
   * @returns {array} items
   * */
  getItems?: (startIndex: number, stopIndex: number) => Promise<unknown>;
  /**
   * **Deprecated** Function -
   * Instead of calling methods.resetComponent,
   * pass in a `unique` key to the VirtualizedList to reset it.
   *
   * Removing support for getMethods API in Chameleon V5
   *
   * @deprecated
   * @param {object} methods
   *   @param {function} methods.resetComponent Resets the component.
   *   @param {function} methods.scrollToTop Scrolls To Top.
   * */
  getMethods?: (methods: Record<string, any>) => void;
  itemHeight?: number;
  /**
   *   @param {object} item
   *  @returns {Element} StyledItem.
   * */
  itemRenderer?: (item: Record<string, any>) => JSX.Element;
  pageSize?: number;
  /**
   * The threshold item from bottom when reached,
   * calls getItems with new start/stop indexes.
   * */
  threshold?: number;
  /**
   * The total item count value of the dataset.
   */
  totalItemCount?: number;
};

type StateType = {
  fetchCount?: number;
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  items: Array<unknown>;
  showBackToTop: boolean;
};

type ScrollType = {
  scrollDirection: string;
  scrollOffset: number;
  scrollUpdateWasRequested: boolean;
};

export default class VirtualizedList extends PureComponent<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      hasNextPage: false,
      isNextPageLoading: false,
      items: [],
      fetchCount: 0,
      showBackToTop: false,
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.getMethods) {
      this.setMethods();
    }
    this.setState({ hasNextPage: true });
    this.listIsMounted = true;
  }

  componentWillUnmount() {
    this.listIsMounted = false;
  }

  /**
   * To make sure setstate functions are not called after VirtualizedList unmounts.
   * This is an issue while testing storyshots.
   * @type {boolean}
   */
  listIsMounted = false;

  itemHeights: unknown = {};

  infinityListRef = createRef();

  lastScrollPosition = 0;

  setMethods = () => {
    this.props.getMethods({
      resetComponent: this.resetComponent,
      scrollToTop: this.scrollToTop,
    });
  };

  resolveItemsAndRender = (startIndex: number, stopIndex: number, resolver: any) => {
    this.props
      .getItems(startIndex, stopIndex)
      .then((data: Array<unknown>): Array<unknown> => this.passItemsToRenderer(data))
      .then((data: Array<unknown>): Array<unknown> => this.transformItemsAndMeasure(data))
      .then((data: Array<unknown>): Array<unknown> => resolver(this.setItemsFromRenderer(data)));
  };

  itemRenderer = (item: Record<string, any>): JSX.Element => this.props.itemRenderer(item);

  passItemsToRenderer = (r: Array<unknown>): Array<unknown> => {
    if (r.length === 0) {
      this.setState({ hasNextPage: false });
      return [];
    }
    return r.map(this.itemRenderer);
  };

  getItemHeight = (o: Record<string, any>) => {
    if (o.client.height > this.props.itemHeight) {
      this.itemHeights[o.id] = o.client.height;
    }
  };

  transformItemsAndMeasure = (items: Array<unknown>): Array<unknown> =>
    items.map(this.measureItemHeight);

  measureItemHeight = (item: Record<string, any>): JSX.Element => (
    <ItemMeasurer getItemHeight={this.getItemHeight}>{item}</ItemMeasurer>
  );

  setItemsFromRenderer = (items: Array<unknown>) => {
    if (!this.listIsMounted) return;
    this.setState(
      (prevState) => ({
        isNextPageLoading: true,
        fetchCount: prevState.fetchCount + 1,
        items: [...prevState.items].concat(items),
      }),
      () => {
        // To avoid Load more button from showing up when there are fewer items than the
        // intended page size.
        if (items.length < this.props.pageSize) {
          this.setState({ hasNextPage: false });
        }
        this.setState({ isNextPageLoading: false });
      },
    );
  };

  loadNextPage = (startIndex: number, stopIndex: number): Promise<void> | undefined => {
    if (startIndex === stopIndex && this.state.fetchCount === 1) {
      return undefined;
    }
    // New stopIndex if the difference of start & stop indexes is not the pageSize, set it to
    // pagesize, else just return same value.
    let newStopIndex =
      stopIndex - startIndex !== this.props.pageSize ? stopIndex + this.props.pageSize : stopIndex;

    // If stopIndex is more than totalItemCount, set newStopIndex to be the totalItemCount
    if (newStopIndex > this.props.totalItemCount) {
      newStopIndex = this.props.totalItemCount;
    }

    if (startIndex > newStopIndex) {
      this.setState({ hasNextPage: false, isNextPageLoading: false });
      return undefined;
    }
    return new Promise((resolve): Promise<void> | void =>
      this.resolveItemsAndRender(startIndex, newStopIndex, resolve),
    );
  };

  onScroll = (scroll: ScrollType) => {
    // using the offset to calculate the delta from previous scroll event
    const offset = scroll.scrollOffset;
    const direction = scroll.scrollDirection;

    if (scroll.scrollUpdateWasRequested && this.state.showBackToTop) {
      this.setState({ showBackToTop: false });
    }

    if (Math.abs(offset - this.lastScrollPosition) > this.props.backToTopThreshold) {
      this.lastScrollPosition = offset;

      // Only change the state if there is a difference in direction
      if (
        direction === 'backward' &&
        !this.state.showBackToTop &&
        !scroll.scrollUpdateWasRequested
      ) {
        this.setState({ showBackToTop: true });
      } else if (direction === 'forward' && this.state.showBackToTop) {
        this.setState({ showBackToTop: false });
      }
    }
  };

  scrollToTop = (): void =>
    this.infinityListRef &&
    // @ts-ignore
    this.infinityListRef.scrollToItem &&
    // @ts-ignore
    this.infinityListRef.scrollToItem(0);

  resetComponent = () => {
    this.scrollToTop();
    // @ts-ignore
    this.setState({ items: [], fetchCount: 0, hasNextPage: true });
  };

  // @ts-ignore
  setRef = (ref: Record<string, any>) => {
    // @ts-ignore
    this.infinityListRef = ref;
  };

  render(): JSX.Element {
    const { hasNextPage, isNextPageLoading, items, fetchCount, showBackToTop } = this.state;

    const { threshold, itemHeight, pageSize, totalItemCount } = this.props;

    return (
      <AutoSizer>
        {({ height, width }: SizeType): JSX.Element => (
          <>
            <VirtualizedContainer>
              <InfinityList
                ref={this.infinityListRef}
                // @ts-ignore
                setRef={this.setRef}
                hasNextPage={hasNextPage}
                isNextPageLoading={isNextPageLoading}
                items={items}
                itemHeights={this.itemHeights}
                loadNextPage={this.loadNextPage}
                fetchCount={fetchCount}
                threshold={threshold}
                height={height}
                width={width}
                itemHeight={itemHeight}
                pageSize={pageSize}
                onScroll={this.onScroll}
                totalItemCount={items.length || totalItemCount}
              />
            </VirtualizedContainer>
            {showBackToTop && (
              <BottomActionArea>
                <TopButton onClick={this.scrollToTop} />
              </BottomActionArea>
            )}
          </>
        )}
      </AutoSizer>
    );
  }
}

// @ts-ignore error TS2339: Property 'defaultProps' does not exist on type
VirtualizedList.defaultProps = {
  getItems: () => {},
  itemRenderer: () => {},
  threshold: 2,
  pageSize: 10,
  itemHeight: 75,
  backToTopThreshold: 20,
};
const VirtualizedContainer = styled.div`
  position: relative;
`;

const BottomActionArea = styled.div`
  position: absolute;
  bottom: 16px;
  left: 50%;
  margin-left: -24px;
`;

/** @exports VirtualizedList by default. */
