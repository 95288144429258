import React from 'react';
import ChameleonRoot from '../components/ChameleonRoot';
import { setupClientRects } from './testShims';

const GlobalContexts = ({ children }) => {
  setupClientRects();
  // hardcoded to render desktop views for testing
  (window as any).testMediaQueryValues = { width: 1024 };

  return <ChameleonRoot>{children}</ChameleonRoot>;
};
export default GlobalContexts;
