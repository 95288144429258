export const ELLIPSIS_MARKER_LEFT = -1;
export const ELLIPSIS_MARKER_RIGHT = -2;

export const isEllipsisMarker = (page: number) => page < 0;

export function getIndex<T>(arr: T[], i: number): T {
  return arr.slice(i)[0];
}
export function setIndex<T>(arr: T[], i: number, ...values: T[]) {
  arr.splice(i, values.length, ...values);
}

export function clamp(n: number, min: number, max: number): number {
  if (n > max) {
    return max;
  }
  if (n < min) {
    return min;
  }
  return n;
}

/**
 * Builds a list of page numbers to display.
 * @param current   The current page number.
 * @param min       The smallest page number, inclusive.
 * @param max       The largest page number, inclusive.
 * @param pageLimit The number of pages (including ellipsis) to show at any one time.
 */
export function paginate(current: number, min: number, max: number, pageLimit: number): number[] {
  const list = getMiddlePages(current, min, max, pageLimit);

  const first = list[0];
  const second = list[1];
  const secondLast = getIndex(list, -2);
  const last = getIndex(list, -1);

  // See if we need to add ellipsis at the front
  // We also need to check we're not cutting off the current page, which can happen if pageLimit
  // is less than 5, and we can't comfortably show min ... current ... max
  if (first > min && ![first, second].includes(current)) {
    list[0] = min;
    list[1] = ELLIPSIS_MARKER_LEFT;
  }

  // See if we need to add ellipsis at the end
  if (last < max && ![last, secondLast].includes(current)) {
    setIndex(list, -1, max);
    setIndex(list, -2, ELLIPSIS_MARKER_RIGHT);
  }
  return list;
}

// Adapted from https://gist.github.com/keon/5380f81393ad98ec19e6
function getMiddlePages(current: number, min: number, max: number, pageLimit: number): number[] {
  const list = [];
  if (pageLimit < 1) {
    // Prevent infinite loop
    return list;
  }

  const clamped = Math.min(current, max);
  let upperLimit = clamped;
  let lowerLimit = clamped;

  if (max <= min) {
    // Prevent infinite loop
    return [current];
  }

  let numPages = 1;
  let addLow = true;

  for (let i = 1; i < pageLimit * 2; i++) {
    if (addLow && lowerLimit > min) {
      lowerLimit--;
      numPages++;
    }
    if (!addLow && upperLimit < max) {
      upperLimit++;
      numPages++;
    }
    addLow = !addLow;
    if (numPages === pageLimit) {
      break;
    }
  }

  for (let i = lowerLimit; i <= upperLimit; i++) {
    list.push(i);
  }
  return list;
}
