import { ProductAppType, ProductType } from './types';

export const defaultProducts: {
  [K in ProductType]: ProductAppType;
} = {
  kounta: {
    id: 'link_app_backoffice',
    name: 'Back Office',
    hasSubscription: true,
    selected: false,
    hidden: false,
    productUrl: 'https://my.kounta.com/profile',
    tryLabel: 'Try',
    tryUrl: 'https://my.kounta.com/signup',
    imageUrl: 'https://cdn.kounta.com/core/images/products/backoffice-b.jpg',
  },
  pos: {
    id: 'link_app_pos',
    name: 'POS',
    hasSubscription: true,
    selected: false,
    hidden: false,
    productUrl: 'https://my.kounta.com/pos/kounta',
    tryLabel: 'Try',
    tryUrl: 'https://my.kounta.com/profile/pricingplans',
    imageUrl: 'https://cdn.kounta.com/core/images/products/pos.jpg',
  },
  insights: {
    id: 'link_app_insights',
    name: 'Insights',
    hasSubscription: false,
    selected: false,
    hidden: false,
    productUrl: 'https://insights.kounta.com',
    tryLabel: 'Try',
    tryUrl: 'https://insights.kounta.com',
    imageUrl: 'https://cdn.kounta.com/core/images/products/insights.jpg',
  },
  purchase: {
    id: 'link_app_purchase',
    name: 'Purchase',
    hasSubscription: false,
    selected: false,
    hidden: false,
    productUrl: 'https://purchase.kounta.com',
    tryLabel: 'Try',
    tryUrl: 'https://my.kounta.com/plans/purchase',
    imageUrl: 'https://cdn.kounta.com/core/images/products/purchase.jpg',
  },
  produce: {
    id: 'link_app_produce',
    name: 'Produce',
    hasSubscription: false,
    selected: false,
    hidden: false,
    productUrl: 'https://produce.kounta.com',
    tryLabel: 'Try',
    tryUrl: 'https://my.kounta.com/plans/produce',
    imageUrl: 'https://cdn.kounta.com/core/images/products/produce-b.jpg',
  },
};

export const ProductOrder = ['kounta', 'pos', 'insights', 'purchase', 'produce'];
