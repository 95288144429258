import styled from 'styled-components';

const DateContainer = styled.div`
  font: ${({ theme }): string => theme.text.body};
  background: ${({ theme }): string => theme.calendar.background};

  .DayPicker {
    width: 100%;
    max-width: 368px;
  }

  .DayPicker-Caption {
    text-align: center;
  }

  .DayPicker-Day {
    padding: 0;
    width: 48px;
    height: 48px;
    color: ${({ theme }): string => theme.calendar.color};

    &.DayPicker-Day--today {
      color: ${({ theme }): string => theme.calendar.todayColor};
      border-radius: 0;
    }

    &.DayPicker-Day--disabled {
      color: ${({ theme }): string => theme.calendar.inactiveColor};
      background: ${({ theme }): string => theme.calendar.inactiveBackgroundColor};
    }

    &:focus {
      outline: none;
    }
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    color: ${({ theme }): string => theme.calendar.selectedColor};
    background-color: ${({ theme }): string => theme.calendar.selectedBackgroundColor};
    border-radius: ${({ theme }): string => theme.calendar.selectedRadius};

    &:hover {
      color: ${({ theme }): string => theme.calendar.selectedHoverColor};
      background-color: ${({ theme }): string => theme.calendar.selectedHoverBackgroundColor};
      border-radius: ${({ theme }): string => theme.calendar.selectedRadius};
    }

    &:focus {
      outline: none;
    }
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside) {
    &:hover {
      color: ${({ theme }): string => theme.calendar.color};
      background-color: ${({ theme }): string => theme.calendar.hoverBackgroundColor};
      border-radius: ${({ theme }): string => theme.radii.default};
    }

    &:focus {
      outline: none;
    }
  }
`;

export default DateContainer;
