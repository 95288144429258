import React from 'react';
import IconStyle from '../IconStyle';
import IconPropsType from '../types';
import IconSvg from './Clock.svg';

const IconClock: React.FunctionComponent<IconPropsType> = (props) => {
  return (
    <IconStyle {...props}>
      <IconSvg {...props} aria-label="clock" />
    </IconStyle>
  );
};

export default IconClock;
