import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import Heading from '../Heading';
import Card from '../Card';
import Paragraph from '../Paragraph';
import UserRow, { UserType } from './UserRow';
import Spacer from '../Spacer';

type PropsType = {
  footer?: ReactElement;
  height?: string;
  maxWidth?: number;
  subtitle?: string;
  title: string;
  users: UserType[];
};

const UserLogin: FunctionComponent<PropsType> = ({
  height,
  maxWidth,
  title,
  subtitle,
  users,
  footer,
}: PropsType) => {
  return (
    <Root maxWidth={maxWidth}>
      <Card paddingSize="large" height={height}>
        <FlexColumn>
          <CardHeader>
            <Heading>{title}</Heading>
            <Paragraph>{subtitle}</Paragraph>
          </CardHeader>
          <Spacer spacing="s24" />
          <CardBody>
            {users.map((user, i) => (
              <RowBorder key={user.email} hasTopBorder={i === 0}>
                <UserRow {...user} />
              </RowBorder>
            ))}
          </CardBody>
          {!!footer && (
            <footer>
              <Spacer spacing="s24" />
              {footer}
            </footer>
          )}
        </FlexColumn>
      </Card>
    </Root>
  );
};
UserLogin.defaultProps = {
  maxWidth: 320,
  height: 'auto',
  subtitle: 'Choose an account to login',
};

export default UserLogin;
const Root = styled.div<{ maxWidth: number }>`
  max-width: ${({ maxWidth }) => maxWidth}px;
`;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const CardHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const CardBody = styled.ul`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto; // User list should fill available space
  list-style: none;
  margin: 0;
  padding: 0;
`;
const RowBorder = styled.li<{ hasTopBorder: boolean }>`
  border-top: ${({ hasTopBorder, theme }) => hasTopBorder && theme.border.defaultBorder};
  border-bottom: ${({ theme }) => theme.border.defaultBorder};
`;
