import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { displayStyleFn, DisplayProps, AlignProps, alignStyleFn } from '../../utils/styleFns';
import { declareVariants } from '../../utils/declareVariants';

export type Variants = 'default' | 'small';

type PropsType = HTMLAttributes<HTMLHeadingElement> &
  AlignProps &
  DisplayProps & {
    variant?: Variants;
  };

const StyledTitle = styled.h1<PropsType>`
  font-family: ${({ theme }) => theme.fonts.sans};
  font-size: ${({ theme }) => theme.fontSizes.heading2};
  line-height: ${({ theme }) => theme.lineHeights.heading2};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  color: ${({ theme }) => theme.colors.text};
  padding: 0;
  margin: 0;

  ${({ theme }) => theme.mediaQueries.tabletAndDesktop} {
    ${declareVariants({
      small: css<PropsType>`
        font-size: ${({ theme }) => theme.fontSizes.heading2};
        line-height: ${({ theme }) => theme.fontSizes.heading2};
      `,
      default: css<PropsType>`
        font-size: ${({ theme }) => theme.fontSizes.heading1};
        line-height: ${({ theme }) => theme.lineHeights.heading1};
      `,
    })}
  }

  ${alignStyleFn}
  ${displayStyleFn}
`;

export const Title: React.FunctionComponent<React.PropsWithChildren<PropsType>> = ({
  ...restProps
}) => <StyledTitle {...restProps} />;

Title.defaultProps = {
  variant: 'default',
};

export default Title;
