import React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '../../utils';

type StatusType = 'info' | 'alert' | 'success' | 'warning';

type StatusProps = {
  inactive?: boolean;
  outline?: boolean;
  small?: boolean;
  status?: StatusType;
  textColor: string;
};

// used by Kounta/Purchase project
export const STATUS_LABELS = {
  info: 'info',
  success: 'success',
  warning: 'warning',
  alert: 'alert',
};

const fillStyle = ({ status, outline, textColor }: StatusProps) =>
  !outline &&
  css`
    color: ${({ theme }) => (textColor ? themeGet(textColor) : theme.colors.textOnStatus)};
    background-color: ${({ theme }) => theme.status[status].surface};
  `;

const outlineStyle = ({ status, outline }: StatusProps) =>
  outline &&
  css`
    color: ${({ theme }) => theme.colors.text};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.status[status].outline} inset;
    background-color: transparent;
  `;

const Status = styled.div<StatusProps>`
  font: ${({ theme }) => theme.text.body};
  font-size: ${({ small, theme }) => (small ? theme.space.s12 : '14px')};
  border-radius: ${({ small, theme }) => (small ? theme.space.s8 : theme.space.s16)};
  min-width: ${({ small, theme }) => (small ? theme.space.s0 : '88px')};
  height: ${({ small, theme }) => (small ? theme.space.s16 : theme.space.s24)};
  padding: ${({ small, theme }) =>
    small ? `${theme.space.s0} ${theme.space.s8}` : `${theme.space.s0} ${theme.space.s16}`};
  line-height: ${({ small, theme }) => (small ? theme.space.s16 : 'unset')};
  text-align: center;

  ${fillStyle}
  ${outlineStyle}

  opacity: ${({ inactive }) => inactive && '0.5'};
`;

type PropsType = {
  children?: React.ReactNode;
  inactive?: boolean;
  onClick?: () => void;
  /**
   * Show an outline version of status
   */
  outline?: boolean;
  small?: boolean;
  /**
   * Status can be info, success, warning, alert
   */
  status?: StatusType;
  textColor?: string;
};

export const StatusTag: React.FunctionComponent<React.PropsWithChildren<PropsType>> = ({
  onClick,
  status,
  outline,
  inactive,
  children,
  textColor,
  small,
}) => (
  <Status
    textColor={textColor}
    onClick={onClick}
    status={status}
    outline={outline}
    inactive={inactive}
    small={small}
  >
    {children}
  </Status>
);

StatusTag.defaultProps = {
  status: 'info',
  outline: false,
  textColor: '',
  inactive: false,
  children: '',
  small: false,
  onClick: () => {},
};

export default StatusTag;
