import { useState, useRef, useEffect, SyntheticEvent } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export const preventPropagation = (e: SyntheticEvent, callback: () => void) => {
  if (e) e.stopPropagation();
  if (callback) {
    callback();
  }
};

export const usePrevious = (value: Record<string, any>): Record<string, any> => {
  const ref = useRef();
  useEffect((): void => {
    // @ts-ignore
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const useMeasure = (): Array<{ bounds: Record<string, any>; ref: Record<string, any> }> => {
  const ref = useRef();
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 });
  const [ro] = useState(() => new ResizeObserver(([entry]) => set(entry.contentRect)));

  useEffect(() => {
    if (ref.current) ro.observe(ref.current);
    return () => ro.disconnect();
  });
  // @ts-ignore
  return [{ ref }, bounds];
};
