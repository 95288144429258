import React from 'react';
import IconStyle from '../IconStyle';
import IconPropsType from '../types';
import IconSvg from './PlusMinus.svg';

export default function IconPlusMinus(props: IconPropsType): JSX.Element {
  return (
    <IconStyle {...props}>
      <IconSvg {...props} aria-label="plus-minus" />
    </IconStyle>
  );
}
