import { DefaultTheme, FlattenInterpolation, ThemeProps } from 'styled-components';

/**
 * darkModeCss is a simple wrapper for styled-component's css function
 * which limits css from being applied unless the theme is dark
 * this enables Chameleon to write darkmode specific styles
 * without extending the theme object per component
 */
export const darkModeCss = (css: FlattenInterpolation<ThemeProps<DefaultTheme>>) => ({ theme }) =>
  theme.name === 'Kounta Dark Theme' ? css : undefined;
