import React, { ReactElement, FunctionComponent } from 'react';
import styled from 'styled-components';

type PropsType = {
  checkbox: ReactElement;
  /** Padding between the checkbox and the select text. */
  innerPadding?: number;
  /** Padding between the checkbox and the edges of the container */
  outerPadding?: number;
  select: ReactElement;
};
const CheckboxSelect: FunctionComponent<PropsType> = ({
  checkbox,
  select,
  outerPadding,
  innerPadding,
}: PropsType) => {
  return (
    <Root>
      <CheckboxWrap padding={outerPadding}>{checkbox}</CheckboxWrap>
      <SelectWrap outerPadding={outerPadding} innerPadding={innerPadding}>
        {select}
      </SelectWrap>
    </Root>
  );
};

CheckboxSelect.defaultProps = {
  outerPadding: 8,
  innerPadding: 8,
};

export default CheckboxSelect;

const Root = styled.div`
  position: relative;
`;
const CHECKBOX_WIDTH = 24;
const CheckboxWrap = styled.div<{ padding: number }>`
  position: absolute;
  z-index: 1;
  left: ${({ padding }) => padding}px;
  /* Center vertically */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const SelectWrap = styled.div<{ innerPadding: number; outerPadding: number }>`
  select {
    padding-left: ${({ outerPadding, innerPadding }) => {
      return CHECKBOX_WIDTH + outerPadding + innerPadding;
    }}px !important;
  }
`;
