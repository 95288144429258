import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { BorderWidths, SpacingTypes } from '../../theme';
import { themeGet } from '../../utils';

type PropsType = {
  color?: string;
  contentPadding?: SpacingTypes;
  lineWidth?: string | BorderWidths;
};
const LabelledDivider: FunctionComponent<React.PropsWithChildren<PropsType>> = ({
  children,
  color,
  contentPadding,
  lineWidth,
}) => {
  return (
    <Root>
      <Line color={color} width={lineWidth} />
      <Content paddingSize={contentPadding}>{children}</Content>
      <Line color={color} width={lineWidth} />
    </Root>
  );
};

LabelledDivider.defaultProps = {
  contentPadding: 's12',
  lineWidth: 'default',
};

export default LabelledDivider;

const Root = styled.div`
  display: flex;
  align-items: center;
`;
const Line = styled.div<{ color: string; width: string }>`
  flex: 1 1 auto;
  box-sizing: border-box;
  border-bottom-color: ${({ color, theme }) => color || theme.colors.text};
  border-bottom-style: solid;
  border-bottom-width: ${({ width }) => themeGet(`borderWidths.${width}`)};
`;
const Content = styled.div<{ paddingSize: SpacingTypes }>`
  flex-shrink: 0;
  padding: 0 ${({ paddingSize }) => themeGet(`space.${paddingSize}`)};
`;
