import React, { Component } from 'react';
import TextAreaAutoSize from 'react-textarea-autosize';
import classnames from 'classnames';
import styled from 'styled-components';
import Label from '../Label';
import Caption from '../Caption';

const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TextAreaStyled = styled<any>(TextAreaAutoSize)`
  &&&& {
    min-height: ${(props): string => props.initialsize || 'unset'};
    max-height: ${(props): string => props.maxsize || 'unset'};
    width: 100%;
    flex-grow: 1;
    padding: 12px;
    resize: auto;
    background-color: ${({ theme }): string => theme.form.backgroundColor};
    -webkit-font-smoothing: inherit; // Override existing Kounta css
    border-radius: ${({ theme }): string => theme.border.radius};
    border: ${({ theme }): string => theme.border.defaultBorder};
    font: ${({ theme }): string => theme.text.body};
    color: ${({ theme }): string => theme.colors.text};
    resize: ${({ resize }: PropsType): string => resize};
    &::placeholder {
      color: ${({ theme }): string => theme.text.hint};
    }
    &.error {
      padding: 11px;
      border: ${({ theme }): string => theme.border.errorBorder};
    }

    &:focus {
      padding: 11px;
      border: ${({ theme }): string => theme.border.focusedBorder};
      box-shadow: none;
      outline: 0;
      outline-color: ${({ theme }): string => theme.border.focusedBorder};
      outline-width: 2px;
    }

    &.readonly {
      background-color: ${({ theme }): string => theme.form.inactiveBackgroundColor};
      &:focus {
        border: ${({ theme }): string => theme.border.defaultBorder};
        outline: none;
      }
    }
  }
`;

export type PropsType = {
  autoFocus?: boolean;
  error?: boolean;
  errorTextList?: string[];
  helperText?: string;
  /**
   *  Minimum height
   * */
  initialSize?: string;
  inputFieldId?: string;
  inputFieldName?: string;
  inputHtmlType?: string;
  label?: string;
  maxLength?: number;
  /**
   *  Maximum height
   * */
  maxSize?: string;
  onBlur?: () => void;
  /**
   *  @param {string} value
   *  @param {string} id
   * */
  onChange?: (value: string, id: string) => void;
  onFocus?: () => void;
  placeholder?: string;
  readOnly?: boolean;
  /**
   * none|both|horizontal|vertical|initial|inherit;
   */
  resize?: string;
  value?: string;
};

export type StateType = {
  value: string;
};
export default class TextArea extends Component<PropsType, StateType> {
  static defaultProps = {
    autoFocus: false,
    inputHtmlType: '',
    inputFieldId: '',
    inputFieldName: '',
    label: '',
    value: '',
    placeholder: '',
    readOnly: false,
    helperText: '',
    error: false,
    resize: 'none',
    errorTextList: [],
    onChange: () => {},
    onBlur: () => {},
    onFocus: () => {},
    initialSize: 'unset',
    maxSize: 'unset',
  };

  constructor(props: PropsType) {
    super(props);
    this.state = {
      value: '',
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.value) this.setState({ value: this.props.value });
  }

  componentDidUpdate(prevProps: PropsType) {
    if (
      this.props.value != null &&
      this.props.value !== prevProps.value &&
      this.props.value !== this.state.value
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ value: this.props.value });
    }
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newInputValue = event.target.value;
    // Avoid cursor jumping problem by seperate set `value` and validation:
    // https://stackoverflow.com/questions/28922275/in-reactjs-why-does-setstate-behave-differently-when-called-synchronously/28922465#28922465
    // 1. Immedicately set the `value`.
    // this.setState({ value: newInputValue });
    this.setState({ value: newInputValue });
    // Pass value to any parent
    this.props.onChange(newInputValue, this.props.inputFieldId);
  };

  renderErrorMessages = () =>
    this.props.errorTextList.map((errorText: string, index: number) => (
      <Caption
        key={index} // eslint-disable-line react/no-array-index-key
        error
      >
        {errorText}
      </Caption>
    ));

  render() {
    const classes = classnames(
      this.props.error ? 'error' : '',
      this.props.readOnly ? 'readonly' : '',
    );

    return (
      <TextAreaContainer>
        {this.props.label && <Label htmlFor={this.props.inputFieldId}>{this.props.label}</Label>}
        <TextAreaStyled
          id={this.props.inputFieldId}
          name={this.props.inputFieldName}
          value={this.state.value}
          placeholder={this.props.placeholder}
          className={classes}
          maxLength={this.props.maxLength}
          type={this.props.inputHtmlType}
          readOnly={this.props.readOnly}
          onChange={this.handleInputChange}
          onBlur={this.props.onBlur}
          onFocus={this.props.onFocus}
          resize={this.props.resize}
          initialsize={this.props.initialSize}
          maxsize={this.props.maxSize}
          autoFocus={this.props.autoFocus}
        />
        {this.renderErrorMessages()}
        {this.props.helperText && <Caption>{this.props.helperText}</Caption>}
      </TextAreaContainer>
    );
  }
}
