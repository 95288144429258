import React from 'react';
import styled from 'styled-components';
import { colors } from '../../themes';

type StyledImageTypes = {
  height?: string;
  objectFit?: string;
  width?: string;
};
const StyledImage = styled.img<StyledImageTypes>`
  height: ${(props): string => props.height};
  width: ${(props): string => props.width};
  object-fit: ${(props): string => props.objectFit};
  background-color: ${colors.gray00};
`;

type PropsType = {
  altText: string;
  height: string;
  imageUrl: string;
  objectFit: string;
  width: string;
};

const Image = (props: PropsType): JSX.Element => (
  <StyledImage
    src={props.imageUrl}
    alt={props.altText}
    objectFit={props.objectFit}
    height={props.height}
    width={props.width}
  />
);

Image.defaultProps = {
  objectFit: 'cover',
  altText: '',
  height: '100%',
  width: '100%',
};

export default Image;
