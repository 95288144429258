import chroma from 'chroma-js';
import { DefaultTheme } from 'styled-components';
import { theme, darkTheme } from '../themes';

const getDefaultTheme = (): DefaultTheme => theme;

const useDarkTheme = (hex: string): boolean =>
  // Luminance gauges a colour on a scale of
  // 0 for darkest black and 1 for lightest white.
  chroma(hex).luminance() < 0.5;

const getThemeFromColor = (hex: string): DefaultTheme => (useDarkTheme(hex) ? darkTheme : theme);

export { useDarkTheme, getDefaultTheme, getThemeFromColor };
