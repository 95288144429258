import React from 'react';
import { TableHeaderType, TableOnSortType } from './types';
import TableHeader from './TableHeader';

type HeaderColumnPropsType = {
  getCurrentHeader: (id: string | number) => TableHeaderType;
  handleSort: TableOnSortType;
  headers: TableHeaderType[];
  isMobile: boolean;
  showIndent: boolean;
  spacingBetweenCells: string;
};

export const HeaderColumns: React.FunctionComponent<HeaderColumnPropsType> = (props) => {
  const { isMobile, headers, spacingBetweenCells, showIndent, handleSort, getCurrentHeader } =
    props;
  return (
    <>
      {headers
        .filter(
          (h): boolean =>
            ((isMobile && h.showMobile) || !isMobile) && h.name !== undefined && h.name !== '',
        )
        .map((header) => {
          const currentHeader = getCurrentHeader(header.id);
          return (
            <TableHeader
              key={header.id}
              // Place these properties before spread to allow individual column to override
              showIndent={showIndent}
              spacingBetweenCells={spacingBetweenCells}
              {...currentHeader}
              handleSort={handleSort}
              sortDirection={currentHeader.sortDirection}
            >
              {header.name}
            </TableHeader>
          );
        })}
    </>
  );
};
