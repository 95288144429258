import React, { Component } from 'react';
import styled from 'styled-components';
import { Spring } from 'react-spring/renderprops.cjs';
import { transitionsConfig } from '../../themes/foundations/transitions';
import IconButton from '../IconButton';
import { IconClose } from '../Icon';
import { StatusTypes } from '../../theme';

export type SheetPropsType = {
  alignCloseCenter?: boolean;
  autoClose?: boolean;
  autoCloseDuration?: number;
  bottomOffset?: number;
  height?: string;
  onClose?: () => void;
  showShadow?: boolean;
  status?: StatusTypes;
  width?: string;
};

type StateType = {
  slideUp: boolean;
};

class Sheet extends Component<React.PropsWithChildren<SheetPropsType>, StateType> {
  static defaultProps = {
    alignCloseCenter: false,
    autoClose: true,
    autoCloseDuration: 2500,
    bottomOffset: 40,
    showShadow: true,
    onClose: () => {},
    height: 'auto',
    width: 'max-content',
  };

  constructor(props) {
    super(props);
    this.state = { slideUp: true };
  }

  componentDidMount() {
    if (this.props.autoClose) {
      this.timeouts.push(window.setTimeout(this.handleClose, this.props.autoCloseDuration));
    }
  }

  componentWillUnmount() {
    this.timeouts.forEach(clearTimeout);
  }

  timeouts: number[] = [];

  /**
   * A 150ms timer to nicely transit out before calling onClose callback (which in most cases
   * will hide the Sheet component at an HOC level)
   */
  setTimer = () => {
    this.timeouts.push(window.setTimeout(this.props.onClose, 150));
  };

  /**
   * Manually clicking on close icon should set slideUp to false which triggers the timer.
   */
  handleClose = (): void => this.setState({ slideUp: false }, this.setTimer);

  /**
   * Used as Spring's onRest callback,
   * if slideUp animation is true don't call onClose callback.
   */
  onClose = () => {
    if (this.state.slideUp) {
      return;
    }
    this.props.onClose();
  };

  render(): JSX.Element {
    const { slideUp } = this.state;
    const { alignCloseCenter, bottomOffset, showShadow, children, height, width, status } =
      this.props;
    return (
      <StyledContainer>
        <Spring
          {...(slideUp ? slideUpTransition(bottomOffset) : slideDownTransition(bottomOffset))}
          onRest={this.onClose}
        >
          {(styleProps) => (
            <StyledSheet
              style={styleProps}
              height={height}
              width={width}
              showShadow={showShadow}
              status={status}
            >
              <AbsoluteWrapper>
                <RelativeWrapper alignCloseCenter={alignCloseCenter}>
                  <IconButton onClick={this.handleClose}>
                    <IconClose width={16} height={16} />
                  </IconButton>
                </RelativeWrapper>
              </AbsoluteWrapper>
              {children}
            </StyledSheet>
          )}
        </Spring>
      </StyledContainer>
    );
  }
}

export const slideUpTransition = (bottomOffset: number): Record<string, any> => ({
  from: { opacity: 0, bottom: 0 },
  to: { opacity: 1, bottom: bottomOffset },
  config: transitionsConfig.bounce,
});

export const slideDownTransition = (bottomOffset: number): Record<string, any> => ({
  from: { opacity: 1, bottom: bottomOffset },
  to: { opacity: 0, bottom: 0 },
});

const AbsoluteWrapper = styled.div`
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
`;

type CloseButtonPropsType = { alignCloseCenter?: boolean };
const RelativeWrapper = styled.div<CloseButtonPropsType>`
  display: flex;
  height: 100%;
  align-items: ${({ alignCloseCenter }) => (alignCloseCenter ? 'center' : 'flex-start')};
  position: relative;
  padding: 16px;
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
`;

type StyledSheetType = Required<Pick<SheetPropsType, 'height' | 'showShadow' | 'status' | 'width'>>;

const StyledSheet = styled.div<StyledSheetType>`
  position: fixed;
  width: ${({ width }): string => width};
  min-height: ${({ height }): string => height};
  z-index: ${({ theme }): number => theme.zIndex.overlay};
  background-color: ${({ theme, status }): string =>
    status ? theme.status[status].surfaceSubdued : theme.dialog.backgroundColor};
  overflow: hidden;
  box-shadow: ${({ showShadow }): string =>
    showShadow ? '1px 4px 24px -2px rgba(0,0,0,0.16)' : 'unset'};
  border-radius: 6px;
  padding: 24px;
`;

export default Sheet;
