import React from 'react';
import styled from 'styled-components';

import { StatusTypes } from '../../theme';

type PropsType = {
  borderWidth?: number;
  circle?: boolean;
  height?: number;
  showBorder?: boolean;
  status?: StatusTypes;
  /**
   * **Deprecated** Use status instead
   * @deprecated
   */
  type?: 'info' | 'success' | 'warning' | 'alert' | 'callout';
  width?: number;
};

/**
 * A badge is used to highlight a number or icon
 */
const Badge: React.FunctionComponent<React.PropsWithChildren<PropsType>> = ({
  type,
  status,
  circle,
  width,
  height,
  borderWidth,
  showBorder,
  children,
}) => {
  const aliasedStatus = type === 'callout' ? 'warning' : type || status;
  const content = cloneWithColor({ element: children });
  return (
    <StyledBadge
      status={aliasedStatus}
      circle={circle}
      width={width}
      height={height}
      borderWidth={borderWidth}
      showBorder={showBorder}
    >
      {content}
    </StyledBadge>
  );
};

Badge.defaultProps = {
  status: 'info',
  showBorder: true,
  width: 40,
  height: 40,
  borderWidth: 2,
};

const StyledBadge = styled.div<PropsType>`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme, status }): string => theme.status[status].icon};
  background-color: ${({ theme, status }): string => theme.status[status].surface};
  width: ${({ width }): number => width}px;
  height: ${({ height }): number => height}px;
  border-radius: ${({ circle }): string => (circle ? '50%' : '4px')};
  border: ${({ borderWidth }): number => borderWidth}px solid
    ${({ theme, status, showBorder }): string =>
      showBorder ? theme.status[status].icon : 'transparent'};
`;

const cloneWithColor = ({ element }: { element: React.ReactNode }): React.ReactNode => {
  if (React.isValidElement(element)) {
    return React.cloneElement(React.Children.only(element) as any, {
      color: 'currentColor',
    });
  }
  return element;
};

export default Badge;
