export type TransitionConfigType = {
  friction: number;
  tension: number;
  velocity: number;
};

export type TransitionType = {
  config: TransitionConfigType;
  from: Record<string, any>;
  to: Record<string, any>;
};

export const transitionsConfig = {
  bounce: {
    tension: 520,
    friction: 25,
    velocity: 15,
  },
  smooth: {
    tension: 170,
    friction: 26,
    velocity: 6,
  },
};

export const transitions = {
  fadeIn: {
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: transitionsConfig.smooth,
  },
  fadeOut: {
    from: { opacity: 1 },
    to: { opacity: 0 },
    config: transitionsConfig.smooth,
  },
  slideUp: {
    from: { marginTop: window.innerHeight },
    to: { marginTop: 0 },
    config: transitionsConfig.smooth,
  },
  slideDown: {
    from: { marginTop: 0 },
    to: { marginTop: window.innerHeight },
    config: transitionsConfig.smooth,
  },
};
