import { ValidationResultType, VALIDATION_SUCCEED_RESPONSE } from '../ValidationTypes';

const SPECIAL_SCRIPT_TAG = /(<([^>]+)>)/gi;

export function validateNoSpecialCharacters(value: string): ValidationResultType {
  if (SPECIAL_SCRIPT_TAG.test(value)) {
    return {
      isValid: false,
      validationText: 'Special character is not allowed',
    };
  }

  return VALIDATION_SUCCEED_RESPONSE;
}

export function validateMinLength(value: string, minLengthAllowed: number): ValidationResultType {
  if (value.length < minLengthAllowed) {
    return {
      isValid: false,
      validationText: `Must be at least ${minLengthAllowed} characters`,
    };
  }

  return VALIDATION_SUCCEED_RESPONSE;
}

export function validateMaxLength(value: string, maxLengthAllowed: number): ValidationResultType {
  if (value.length > maxLengthAllowed) {
    return {
      isValid: false,
      validationText: `Must not exceed ${maxLengthAllowed} characters`,
    };
  }

  return VALIDATION_SUCCEED_RESPONSE;
}

export function validateNoEmptyString(trimmedValue: string): ValidationResultType {
  // https://stackoverflow.com/questions/3937513/javascript-validation-for-empty-input-field
  if (!trimmedValue.replace(/\s+/, '').length) {
    return {
      isValid: false,
      validationText: 'Empty text is not allowed',
    };
  }
  return VALIDATION_SUCCEED_RESPONSE;
}

export function validateString(
  value: string,
  maxLengthAllowed: number,
  allowAllDigits = false,
  minLength = 2,
): ValidationResultType {
  const allDigits = /^\d+$/;
  const specialScriptTag = /(<([^>]+)>)/gi;

  if (value.length < minLength) {
    return {
      isValid: false,
      validationText: `Must be at least ${minLength} characters`,
    };
  }

  if (value.length > maxLengthAllowed) {
    return {
      isValid: false,
      validationText: `Must not exceed ${maxLengthAllowed} characters`,
    };
  }

  if (!allowAllDigits && allDigits.test(value)) {
    return {
      isValid: false,
      validationText: 'Cannot be all digits',
    };
  }

  if (specialScriptTag.test(value)) {
    return {
      isValid: false,
      validationText: 'Special character is not allowed',
    };
  }

  return VALIDATION_SUCCEED_RESPONSE;
}

/**
 * 1. Have to be more than 2 characters;
 * 2. Should not longer than 45 characters;
 *  Ref: protected/assets/scripts/modules/signup/component.person.template.html
 * 3. Does not allow only digits;
 */
export function validateName(value: string, minLength?: number): ValidationResultType {
  const maxNameLength = 45;
  return validateString(value, maxNameLength, false, minLength);
}

// KO-12474: Capture MYOB Partner Client ID on Advisor Sign Up
export function validateMYOBclientId(value: string): ValidationResultType {
  const maxNameLength = 45;
  return validateString(value, maxNameLength, true);
}

export function validateAddress(value: string): ValidationResultType {
  const maxAddressLength = 255;
  return validateString(value, maxAddressLength);
}

export function validateBusinessName(value: string): ValidationResultType {
  //  ['CompanyName, CompanyCode', 'length', 'max' => 50],
  // from "protected/models/MasterCompanyEntity.php" rules
  const maxBusinessNameLength = 50;
  return validateString(value, maxBusinessNameLength);
}

export function validatePassword(passwordValue: string): ValidationResultType {
  if (passwordValue.length < 6) {
    return {
      isValid: false,
      validationText: 'Password must be at least 6 characters',
    };
  }
  if (!/[0-9]/.test(passwordValue) && !/[!,@,#,$,%,^,&,*,?,_,~]/.test(passwordValue)) {
    return {
      isValid: false,
      validationText: 'Password must have at least one number or a special character',
    };
  }
  if (!/[A-Z]/.test(passwordValue) || !/[a-z]/.test(passwordValue)) {
    return {
      isValid: false,
      validationText: 'Password must contain an uppercase and lowercase letter',
    };
  }
  return VALIDATION_SUCCEED_RESPONSE;
}

const PHONE_NUMBER_MIN_LENGTH = 8;
const PHONE_NUMBER_MAX_LENGTH = 20;
export const ERR_PHONE_TOO_SHORT = `Must be at least ${PHONE_NUMBER_MIN_LENGTH} numbers`;
export const ERR_PHONE_START_WITH_PLUS = 'Phone number needs to have + at the beginning only';
export const ERR_PHONE_ALLOWED_CHARS = 'Only numbers and + - () sign are allowed';
export const ERR_PHONE_TOO_LONG = `Must not exceed ${PHONE_NUMBER_MAX_LENGTH} characters`;
export const ERR_PHONE_WRONG_AREA_CODE = 'Area code incorrect';

export function validatePhoneNumber(
  value: string,
  prefix: string,
  plusPrefix = true,
  minLength = 8,
): ValidationResultType {
  // Max length 15 including area codes, to be safe (with "+/-/whiteSpace/()" ), we use 20 here
  // https://en.wikipedia.org/wiki/Telephone_numbering_plan
  // https://stackoverflow.com/questions/3350500/international-phone-number-max-and-min
  const maxPhoneNumberLength = 20;
  const numbersRegexWithOptionalPlus = /^\+?[0-9-()\s]*$/gm;

  if (value.length < minLength) {
    // https://stackoverflow.com/questions/14894899/what-is-the-minimum-length-of-a-valid-international-phone-number
    return {
      isValid: false,
      validationText: ERR_PHONE_TOO_SHORT,
    };
  }

  if (plusPrefix && value.lastIndexOf('+') !== 0) {
    return {
      isValid: false,
      validationText: ERR_PHONE_START_WITH_PLUS,
    };
  }

  if (!numbersRegexWithOptionalPlus.test(value)) {
    return {
      isValid: false,
      validationText: ERR_PHONE_ALLOWED_CHARS,
    };
  }

  if (value.length > maxPhoneNumberLength) {
    return {
      isValid: false,
      validationText: ERR_PHONE_TOO_LONG,
    };
  }

  if (!value.startsWith(prefix)) {
    return {
      isValid: false,
      validationText: ERR_PHONE_WRONG_AREA_CODE,
    };
  }

  return VALIDATION_SUCCEED_RESPONSE;
}

export const validateEmail = (emailId: string, minLength = 1): ValidationResultType => {
  if (minLength === 0 && emailId === '') return VALIDATION_SUCCEED_RESPONSE;

  const INVALID_EMAIL_TEXT = 'Invalid email address';

  // Updated Regex to one which is being used in core.
  // This Regex also allows plus signs in email.
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(emailId)) {
    return {
      isValid: false,
      validationText: INVALID_EMAIL_TEXT,
    };
  }
  return VALIDATION_SUCCEED_RESPONSE;
};
