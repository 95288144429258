import React from 'react';
import styled from 'styled-components';
import IconButton from '../IconButton';
import { IconArrowUp } from '../Icon';

const StyledTopButton = styled(IconButton)`
  border: ${({ theme }): string => theme.border.defaultBorder};
  background-color: ${({ theme }): string => theme.button.backgroundColor};
  box-shadow: 0 8px 16px -2px rgba(0, 0, 0, 0.1);
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }): string => theme.button.hoverBackgroundColor};
  }
`;

type PropsType = {
  [any: string]: any;
  onClick?: (event: React.MouseEvent) => void;
  type?: 'submit' | 'reset' | 'button';
};

export const TopButton: React.FunctionComponent<React.PropsWithChildren<PropsType>> = ({
  onClick,
  type,
  ...restProps
}) => {
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onClick) {
      onClick(e);
    }
  };
  return (
    <StyledTopButton type={type} onClick={handleClick} size="large" {...restProps}>
      <IconArrowUp />
    </StyledTopButton>
  );
};

TopButton.defaultProps = {
  onClick: () => {
    window.scroll(0, 0);
  },
  type: 'button',
};

export default TopButton;
