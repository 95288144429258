import React from 'react';
import IconStyle from '../IconStyle';
import IconPropsType from '../types';
import IconSvg from './backdate.svg';

const IconBackdate: React.FunctionComponent<IconPropsType> = (props) => {
  return (
    <IconStyle {...props}>
      <IconSvg {...props} aria-label="backdate" />
    </IconStyle>
  );
};

export default IconBackdate;
