import { css, DefaultTheme } from 'styled-components';

const borderCommon = ({ theme }: { theme: DefaultTheme }) => css`
  border-style: solid;
  border-width: ${theme.borderWidths.default};
  outline: none;
`;

export const borderDefault = ({ theme }: { theme: DefaultTheme }) => css`
  ${borderCommon}
  border-color: ${theme.border.color.default};
`;

export const borderFocused = ({ theme }: { theme: DefaultTheme }) => css`
  ${borderCommon}
  border-color: ${theme.border.color.focused};
  box-shadow: 0 0 0 ${theme.borderWidths.default} ${theme.border.color.focused} inset;
`;

export const borderActive = ({ theme }: { theme: DefaultTheme }) => css`
  ${borderCommon}
  border-color: ${theme.border.color.active};
  box-shadow: 0 0 0 ${theme.borderWidths.default} ${theme.border.color.active} inset;
`;

export const borderError = ({ theme }: { theme: DefaultTheme }) => css`
  ${borderCommon}
  border-color: ${theme.border.color.error};
  box-shadow: 0 0 0 ${theme.borderWidths.default} ${theme.border.color.error} inset;
`;
