import React, { Component } from 'react';
import styled from 'styled-components';
import Box from '../Box';
import Paragraph from '../Paragraph';
import Divider from '../Divider';
import Spacer from '../Spacer';
import { SpacingTypes } from '../../theme';

export type RadioButtonItemType = {
  checked: boolean;
  disabled?: boolean;
  /** @deprecated Deprecated, use disabled instead. */
  inactive?: boolean;
  inputValue: string;
  label?: string;
};

type PropsType = RadioButtonItemType & {
  children?: React.ReactNode;
  inputName: string;
  onChange: (change: RadioButtonItemType) => void;
  padding?: SpacingTypes;
  selectedContent?: React.ReactNode;
};

type StateType = {
  checked: boolean;
};

export default class RadioButton extends Component<PropsType, StateType> {
  static defaultProps = {
    checked: false,
    label: null,
    onChange: () => {},
  };

  constructor(props: PropsType) {
    super(props);
    this.state = { checked: props.checked };
  }

  UNSAFE_componentWillReceiveProps(nextProps: PropsType) {
    this.setState({ checked: nextProps.checked });
  }

  handleChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange({
      inputValue: changeEvent.target.value,
      checked: changeEvent.target.checked,
    });
  };

  render(): JSX.Element {
    const content = this.props.children ? (
      this.props.children
    ) : (
      <Paragraph>{this.props.label}</Paragraph>
    );

    const { padding, inputName, inputValue, selectedContent } = this.props;
    const { checked } = this.state;

    const disabled =
      typeof this.props.inactive !== 'undefined' ? this.props.inactive : this.props.disabled;

    return (
      <Radio disabled={disabled}>
        <Box padding={padding} selected={checked} disabled={disabled} showHover>
          <Flex>
            <Input
              disabled={disabled}
              value={inputValue}
              type="radio"
              name={inputName}
              onChange={this.handleChange}
              checked={checked}
              data-chaminputid="radioButtonInput"
            />
            <CheckMark />
            <ContentWrap>
              <Content disabled={disabled}>{content}</Content>
              {checked && selectedContent && (
                <>
                  <Spacer />
                  <SelectedContentDivider />
                  <Spacer />
                  {selectedContent}
                </>
              )}
            </ContentWrap>
          </Flex>
        </Box>
      </Radio>
    );
  }
}

const Flex = styled.div`
  display: flex;
`;
const ContentWrap = styled.div`
  flex: 1 1 0;
`;

const Content = styled.div<{ disabled: boolean }>`
  margin-left: 32px;

  cursor: ${({ disabled }) => disabled && 'not-allowed'};
  opacity: ${({ disabled }) => disabled && '0.5'};
`;

const CheckMark = styled.div`
  /* Create a custom radio button */
  flex-shrink: 0;
  position: absolute;
  height: 22px;
  width: 22px;
  background-color: ${({ theme }): string => theme.radio.backgroundColor};
  border: ${({ theme }): string => theme.radio.border};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Create the indicator (the dot/circle - hidden when not checked) */
  &:after {
    content: '';
    display: none;
  }
  /* Style the indicator (dot/circle) */
  &:after {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${({ theme }): string => theme.radio.selectedBackgroundColor};
  }
`;

const Radio = styled.label<{ disabled: boolean }>`
  display: block;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${({ theme, disabled }): string => disabled && theme.form.inactiveColor};

  /* On mouse-over, add a grey background colour */
  &:hover input {
    ~ ${CheckMark} {
      background-color: ${({ theme }): string => theme.form.hoverBackgroundColor};
    }
  }

  /* When the radio button is checked, add the selected colour */
  input:checked ~ ${CheckMark} {
    border: ${({ theme }): string => theme.radio.selectedBorder};
  }

  /* Show the indicator (dot/circle) when checked */
  input:checked ~ ${CheckMark}:after {
    display: block;
  }

  input[disabled] ~ ${CheckMark} {
    cursor: not-allowed;
    background-color: ${({ theme }): string => theme.radio.inactiveBackgroundColor};
  }
`;

const Input = styled.input`
  width: auto;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 16px;
`;

const SelectedContentDivider = styled(Divider)`
  width: auto;
  margin-left: -24px;
  margin-right: -24px;
`;
