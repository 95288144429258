import React from 'react';
import IconStyle from '../IconStyle';
import IconPropsType from '../types';
import IconSvg from './Keyboard.svg';

const IconKeyboard: React.FunctionComponent<IconPropsType> = (props) => {
  return (
    <IconStyle usingPath {...props}>
      <IconSvg {...props} aria-label="keyboard" />
    </IconStyle>
  );
};

export default IconKeyboard;
