import React from 'react';
import IconStyle from '../IconStyle';
import IconPropsType from '../types';
import IconSvg from './Indeterminate.svg';

export default function IconIndeterminate(props: IconPropsType): JSX.Element {
  return (
    <IconStyle usingPath {...props}>
      <IconSvg {...props} aria-label="indeterminate" />
    </IconStyle>
  );
}
