export const VALIDATION_SUCCEED_RESPONSE = {
  isValid: true,
  validationText: null,
};

export type ValidationType =
  | 'name'
  | 'email'
  | 'password'
  | 'address'
  | 'business name'
  | 'business abn';

export type ValidationResultType = {
  isValid: boolean;
  validationText: string | null;
};
