import { FontSize, FontSizes, LineHeight, Fonts, Font, FontWeight, FontWeights } from '../../theme';

export const fontSizes: { [key in FontSizes]: FontSize } = {
  base: '15px',
  caption: '12px',
  heading1: '30px',
  heading2: '24px',
  heading3: '18px',
  heading4: '16px',
  heading6: '12px',
};

export const lineHeights: { [key in FontSizes]: LineHeight } = {
  base: '24px',
  caption: '16px',
  heading1: '40px',
  heading2: '32px',
  heading3: '24px',
  heading4: '24px',
  heading6: '16px',
};

export const fonts: { [key in Fonts]: Font } = {
  sans: 'Akkurat-Regular, Helvetica, Arial, sans-serif',
};

export const fontWeights: { [key in FontWeights]: FontWeight } = {
  normal: 500,
  bold: 700,
};
