import React, { Component, createRef } from 'react';

type ItemMeasurerType = {
  children: React.ReactNode;
  getItemHeight: Function;
};

export default class ItemMeasurer extends Component<ItemMeasurerType, any> {
  componentDidMount() {
    if (this.itemRef.current && this.props.getItemHeight) this.setChildHeight();
  }

  itemRef = createRef<any>();

  setChildHeight = (): void => this.props.getItemHeight(this.getChildHeight());

  getChildHeight = (): Record<string, any> | undefined => {
    if (!this.itemRef.current) return undefined;
    const {
      clientHeight,
      clientWidth,
      clientTop,
      clientLeft,
      offsetParent: { className = '' } = {},
      offsetHeight,
      offsetWidth,
      offsetTop,
      offsetLeft,
    } = this.itemRef.current;

    return {
      // @ts-ignore
      id: this.props.children.key,
      client: {
        height: clientHeight,
        width: clientWidth,
        top: clientTop,
        left: clientLeft,
      },
      offset: {
        height: offsetHeight,
        width: offsetWidth,
        top: offsetTop,
        left: offsetLeft,
      },
      parent: className,
    };
  };

  render(): JSX.Element {
    return <div ref={this.itemRef}>{this.props.children}</div>;
  }
}
