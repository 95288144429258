import { DefaultTheme } from 'styled-components';
import { coreThemeWithLegacy } from './coreTheme';
import { colors } from './foundations/colors';
import { ColorTokens } from '../theme';
import { strictObject } from '../utils/strictObject';

const { borderWidths, shadows, radii, space } = coreThemeWithLegacy;

export const colorTokens: ColorTokens = {
  border: colors.gray100,
  borderActive: colors.gray600,
  iconAlert: colors.maple800,
  iconInfo: colors.gray700,
  iconSuccess: colors.green800,
  iconWarning: colors.orange800,
  interactive: colors.blue500,
  interactiveHover: colors.blue700,
  interactiveAlert: colors.maple700,
  interactiveAlertHover: colors.maple800,
  interactiveFocused: colors.blue500,
  surface: colors.white,
  surfaceAlert: colors.maple200,
  surfaceAlertSubdued: colors.maple100,
  surfaceInfo: colors.blue100,
  surfaceInfoSubdued: colors.blue50,
  surfaceSuccess: colors.green200,
  surfaceSuccessSubdued: colors.green100,
  surfaceWarning: colors.orange200,
  surfaceWarningSubdued: colors.orange100,
  text: colors.gray700,
  textAlert: colors.maple1000,
  textInactive: colors.gray400,
  textInfo: colors.blue1000,
  textSubdued: colors.gray600,
  textSuccess: colors.green1000,
  textWarning: colors.orange1000,
  textOnStatus: colors.gray1000,
  outlineAlert: colors.maple300,
  outlineSuccess: colors.green400,
  outlineWarning: colors.orange400,
  outlineInfo: colors.blue300,
};

const theme: DefaultTheme = {
  name: 'Kounta Theme',
  ...coreThemeWithLegacy,

  colors: {
    ...colorTokens,
    ...colors,
  },
  background: {
    default: colors.white,
    scrollArea: colors.gray50,
    secondary: colors.gray700,
    tertiary: colors.transparent,
    toolbar: colors.gray50,
  },
  view: {
    color: colorTokens.text,
    backgroundColor: colorTokens.surface,
  },
  divider: {
    border: `${borderWidths.default} solid ${colors.gray50}`,
  },
  separator: {
    backgroundColor: colors.gray200,
    border: `${borderWidths.default} solid ${colors.gray200}`,
  },
  navigationBar: {
    backgroundColor: colors.gray50,
    border: `${borderWidths.default} solid ${colors.gray200}`,
    headerBackgroundColor: colorTokens.surface,
    shadow: shadows.default,
  },
  header: {
    border: `${borderWidths.default} solid ${colors.gray200}`,
  },
  mainNavigation: {
    backgroundColor: colors.gray900,
  },
  overlay: {
    backgroundColor: colors.overlay,
  },
  dialog: {
    backgroundColor: colors.white,
    color: colorTokens.text,
    selectionBackground: colors.gray50,
    shadow: '0px -3px 4px rgba(0, 0, 0, 0.16)',
  },
  text: {
    ...coreThemeWithLegacy.text,
    color: colorTokens.text,
    whiteText: colors.white,
    labelColor: colorTokens.text,
    primary: colorTokens.interactive,
    secondary: colors.gray700,
    error: colorTokens.interactiveAlert,
    hint: colors.gray400,
    descriptionColor: colorTokens.textSubdued,
  },
  link: {
    primary: {
      color: colorTokens.interactive,
      hoverColor: colorTokens.interactiveHover,
    },
    secondary: {
      color: colors.gray800,
      hoverColor: colors.gray900,
    },
    destructive: {
      color: colorTokens.interactiveAlert,
      hoverColor: colorTokens.interactiveAlertHover,
    },
  },
  button: {
    color: colorTokens.interactive,
    backgroundColor: colors.transparent,
    hoverColor: colors.gray700,
    hoverBackgroundColor: colors.gray50,
    primary: {
      color: colors.white,
      backgroundColor: colors.blue500,
      borderColor: colors.blue500,
      hoverColor: colors.white,
      hoverBackgroundColor: colors.blue600,
      hoverBorderColor: colors.blue600,
    },
    secondary: {
      color: colorTokens.interactive,
      backgroundColor: colors.white,
      borderColor: colorTokens.interactive,
      hoverColor: colors.blue600,
      hoverBackgroundColor: colors.blue50,
      hoverBorderColor: colors.blue600,
    },
    destructive: {
      color: colors.white,
      backgroundColor: colors.maple500,
      borderColor: colors.maple500,
      hoverColor: colors.white,
      hoverBackgroundColor: colors.maple800,
      hoverBorderColor: colors.maple800,
    },
    segment: {
      backgroundColor: colors.gray50,
      activeBackground: colors.white,
    },
  },
  form: {
    border: `${borderWidths.default} solid ${colors.gray200}`,
    borderColor: colors.gray200,
    selectedBorder: `${borderWidths.selected} solid ${colorTokens.borderActive}`,
    color: colorTokens.text,
    inactiveColor: colorTokens.textInactive,
    selectedBackgroundColor: colors.gray50,
    hint: {
      // this is actually placeholder text
      color: colorTokens.textInactive,
    },
    backgroundColor: colors.white,
    hoverBackgroundColor: colors.gray50,
    inactiveBackgroundColor: colors.gray50,
    focusedBorder: `${borderWidths.selected} solid ${colorTokens.interactiveFocused}`,
    filter: {
      blur: {
        backgroundColor: colors.gray50,
        color: colors.transparent,
        textShadow: '0 0 5px rgba(0,0,0,0.5)',
      },
    },
  },
  checkbox: {
    border: `${borderWidths.default} solid ${colors.gray600}`,
    inactiveBackgroundColor: colors.gray50,
  },
  radio: {
    border: `${borderWidths.default} solid ${colors.gray400}`,
    selectedBorder: `${borderWidths.selected} solid ${colorTokens.borderActive}`,
    backgroundColor: colorTokens.surface,
    selectedBackgroundColor: colorTokens.borderActive,
    inactiveBackgroundColor: colors.gray50,
  },
  toggle: {
    activeTextColor: colors.white,
    inactiveTextColor: colors.gray700,
    activeBackgroundColor: colorTokens.borderActive,
    inactiveBackgroundColor: colors.gray200,
  },
  border: {
    radius: radii.default,
    color: {
      default: colors.gray100,
      active: colorTokens.borderActive,
      focused: colorTokens.interactiveFocused,
      error: colorTokens.interactiveAlert,
    },
    defaultBorder: `${borderWidths.default} solid ${colors.gray100}`,
    selectedBorder: `${borderWidths.selected} solid ${colorTokens.borderActive}`,
    errorBorder: `${borderWidths.selected} solid ${colors.maple600}`,
    focusedBorder: `${borderWidths.selected} solid ${colors.blue500}`,
  },
  box: {
    defaultBorder: `${borderWidths.selected} solid ${colors.gray100}`,
    selectedBorder: `${borderWidths.selected} solid ${colors.gray700}`,
    hoverBorder: `${borderWidths.selected} solid ${colors.gray400}`,
    backgroundColor: colors.white,
  },
  icon: {
    color: colors.gray700,
    secondaryColor: colorTokens.interactive,
  },
  logo: {
    textColor: colors.black,
  },
  table: {
    border: `1px solid ${colors.gray100}`,
    rowBackgroundColor: colorTokens.surface,
    rowHoverIndicator: `-4px 0 0 0 ${colors.blue400}`,
    rowHoverBackgroundColor: colors.blue50,
    rowSelectedBackgroundColor: colors.gray50,
  },
  navigation: {
    backgroundColor: colors.gray900,
    border: `${borderWidths.default} solid ${colors.blue400}`,
    hoverBackground: colors.translucent,
    selectedBackground: colors.blue400,
    headerBackground: colors.gray900,
    contextButtonBackground: colors.gray800,
    secondaryMenuBackground: colors.gray800,
    secondaryHoverBackground: colors.gray1000,
  },
  calendar: {
    background: colors.white,
    color: colorTokens.text,
    todayColor: colorTokens.interactive,
    hoverBackgroundColor: colors.gray200,
    selectedColor: colors.white,
    selectedBackgroundColor: colors.gray700,
    selectedHoverColor: colors.white,
    selectedHoverBackgroundColor: colors.gray700,
    selectedRadius: radii.default,
    inactiveColor: colors.gray400,
    inactiveBackgroundColor: colors.white,
  },
  avatar: {
    backgroundColor: colors.gray100,
    color: colors.gray700,
  },
  paymentlogo: {
    backgroundColor: colors.white,
  },
  card: {
    border: `${borderWidths.default} solid ${colors.gray50}`,
    radius: radii.default,
    backgroundColor: colors.white,
    padding: space.s16,
    shadow: shadows.default,
  },
  footer: {
    backgroundColor: colors.white,
    padding: space.s16,
    shadow: shadows.footer,
  },
  numberpad: {
    number: {
      color: colorTokens.text,
      backgroundColor: colors.white,
    },
    screen: {
      color: colorTokens.text,
    },
  },
  snackBar: {
    color: colorTokens.text,
    background: colors.white,
    border: colors.gray50,
    radius: radii.extraLarge,
    action: {
      success: colors.green700,
      error: colors.maple800,
    },
  },
  status: {
    success: {
      surfaceSubdued: colorTokens.surfaceSuccessSubdued,
      surface: colorTokens.surfaceSuccess,
      textOnSurface: colorTokens.textSuccess,
      icon: colorTokens.iconSuccess,
      outline: colorTokens.outlineSuccess,
    },
    alert: {
      surfaceSubdued: colorTokens.surfaceAlertSubdued,
      surface: colorTokens.surfaceAlert,
      textOnSurface: colorTokens.textAlert,
      icon: colorTokens.iconAlert,
      outline: colorTokens.outlineAlert,
    },
    warning: {
      surfaceSubdued: colorTokens.surfaceWarningSubdued,
      surface: colorTokens.surfaceWarning,
      textOnSurface: colorTokens.textWarning,
      icon: colorTokens.iconWarning,
      outline: colorTokens.outlineWarning,
    },
    info: {
      surfaceSubdued: colorTokens.surfaceInfoSubdued,
      surface: colorTokens.surfaceInfo,
      textOnSurface: colorTokens.textInfo,
      icon: colorTokens.iconInfo,
      outline: colorTokens.outlineInfo,
    },
  },
  pagination: {
    color: colorTokens.text,
    selectedBackground: colors.gray700,
    selectedColor: colors.white,
  },
};

export default strictObject<typeof theme>(theme);
