import { useEffect, useRef, MutableRefObject } from 'react';

const getHandleOutsideClick = ({
  callback,
  boundaryElement,
}: {
  boundaryElement: MutableRefObject<HTMLElement>;
  callback: () => void;
}) => {
  return ({ target }: { target: EventTarget }) => {
    if (target instanceof HTMLElement && !boundaryElement.current?.contains(target)) {
      callback();
    }
  };
};

export const useCallbackOnOuterClick = (callback: () => void) => {
  const boundaryElement = useRef<HTMLDivElement>(null);
  const listener = getHandleOutsideClick({ callback, boundaryElement });

  useEffect(() => {
    window.document.addEventListener('click', listener);

    return () => window.document.removeEventListener('click', listener);
  }, [callback]);

  return [boundaryElement];
};
