export const getUrlWithQuerystring = (
  baseUrl: string,
  values?: { [x: string]: string },
): string | null => {
  if (baseUrl && values) {
    const params = new URLSearchParams(values);
    const joinChar = baseUrl.indexOf('?') === -1 ? '?' : '&';
    return `${baseUrl}${joinChar}${params.toString()}`;
  }

  return baseUrl;
};
