import { memo } from 'react';
import styled from 'styled-components';
import { Item } from './ListViewItem';

const StyledListView = styled.div`  
  border-top: ${({ theme }): string => theme.border.defaultBorder};
  border-bottom: ${({ theme }): string => theme.border.defaultBorder};
  ${Item} + ${Item} {
    border-top: ${({ theme }): string => theme.border.defaultBorder};
  }
`;

const ListView = memo(StyledListView);

export default ListView;
