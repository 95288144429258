import React, { Component } from 'react';
import TextInput from '../TextInput';

export type PropsType = {
  blackList?: string[];
  helperText?: string;
  id?: string;
  initialValue?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  label?: string;
  maxLength?: number;
  minLength?: number;
  name?: string;
  onChange?: (value: string, isPwdValid: boolean) => {};
  placeholder?: string;
  whiteList?: string[];
};

export type StateType = {
  errorMsg: string[];
  value: string;
};

export const DEFAULT_MIN_PWD_LENGTH = 6;
export const DEFAULT_MAX_PWD_LENGTH = 45;

export default class PasswordInput extends Component<PropsType, StateType> {
  textInputType: string;

  static defaultProps = {
    blackList: [],
    helperText: '',
    id: 'password_id',
    initialValue: '',
    inputRef: null,
    label: 'Password',
    maxLength: DEFAULT_MAX_PWD_LENGTH,
    minLength: DEFAULT_MIN_PWD_LENGTH,
    name: 'password',
    onChange: () => {},
    placeholder: '',
    whiteList: [],
  };

  constructor(props: PropsType) {
    super(props);
    this.textInputType = 'password';
    this.state = {
      errorMsg: [],
      value: this.props.initialValue,
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  onChangeHandler = (value: string) => {
    const errorMsg = this.validatePwd(value);
    this.setState({
      errorMsg,
      value,
    });
    if (this.props.onChange) {
      this.props.onChange(value, errorMsg.length === 0);
    }
  };

  validatePwd = (password: string): string[] => {
    const result: string[] = [];
    const value = password.trim();
    if (value.length === 0) {
      return result;
    }
    if (value === '' || password.length < this.props.minLength) {
      result.push(`Password must be at least ${this.props.minLength} characters`);
    }
    if (!/[0-9]/.test(value) && !/[!,@#$%^&*?_~]/.test(value)) {
      result.push('Password must have at least one number or a special character');
    }
    if (!/[A-Z]/.test(value) || !/[a-z]/.test(value)) {
      result.push('Password must contain an uppercase and lowercase letter');
    }
    // check blackList
    if (this.checkBlackList(password).length > 0) {
      result.push(`Password must not contain ${this.props.blackList.join(',')}`);
    }
    if (this.checkWhiteList(password).length > 0) {
      result.push(`Password must contain ${this.props.whiteList.join(',')}`);
    }
    return result;
  };

  checkBlackList = (password: string): string[] =>
    this.props.blackList.filter(this.filterBlackName(password));

  checkWhiteList = (password: string): string[] =>
    this.props.whiteList.filter(this.filterNoneWhiteName(password));

  filterBlackName = (pwd: string) => (blackName: string): boolean => pwd.indexOf(blackName) >= 0;

  filterNoneWhiteName = (pwd: string) => (whiteName: string): boolean => pwd.indexOf(whiteName) < 0;

  render = (): JSX.Element => (
    <div className="password">
      <TextInput
        inputRef={this.props.inputRef}
        inputHtmlType="password"
        maxLength={this.props.maxLength}
        inputFieldId={this.props.id}
        inputFieldName={this.props.name}
        label={this.props.label}
        initialValue={this.state.value}
        onChange={this.onChangeHandler}
        error={this.state.errorMsg.length > 0}
        errorTextList={this.state.errorMsg}
        placeholder={this.props.placeholder}
        helperText={this.props.helperText}
      />
    </div>
  );
}
