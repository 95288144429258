const initialsRegex = /[^0-9A-Za-z\u{4E00}-\u{9FFF}\u{3400}-\u{4DBF}\u{20000}-\u{2A6DF}\u{2A700}-\u{2EBEF}\u{2E80}-\u{2FD5}\u{3041}-\u{3096}]/gu;

function getInitials(text: string): string {
  // if text is empty then just return it
  if (!text) {
    return text;
  }

  // Split the text into an array and take 3 letters max
  const words = text.split(' ', 3);

  const charArray = [];
  if (words.length === 1) {
    // If one word then capitialise first letter and lowercase second
    charArray.push(words[0].substring(0, 1).toUpperCase());
    charArray.push(words[0].substring(1, 2).toLowerCase());
  } else {
    // Else return concat of 2-3 letters
    for (let index = 0; index < words.length; index++) {
      charArray.push(words[index].substring(0, 1).toUpperCase());
    }
  }

  return charArray.join('').replace(initialsRegex, '');
}

export { getInitials };
