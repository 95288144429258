import { Dispatch, useState } from 'react';
import { DragDropContext, DragDropContextProps, DropResult } from 'react-beautiful-dnd';
import { TablePropsType } from '../Table';
import ReorderableRowRenderer from './ReorderableRowRenderer';
import ReorderableTableBodyRenderer from './ReorderableTableBodyRenderer';

type DragDropProps = Pick<DragDropContextProps, 'onBeforeCapture' | 'onDragEnd'>;
type TableProps = Pick<
  TablePropsType,
  'rowRenderer' | 'tableBodyRenderer' | 'showHover' | 'showRowIndicator'
>;
type HookProps = {
  DragDropContext: typeof DragDropContext;
  dragDropProps: DragDropProps;
  tableProps: TableProps;
};
export default function useTableReorderable<T>(
  dispatchReorder: Dispatch<(prevState: T[]) => T[]>,
): HookProps {
  const [showHover, setShowHover] = useState(true);
  const onBeforeCapture = () => {
    setShowHover(false);
  };
  const onDragEnd = (result: DropResult) => {
    setShowHover(true);
    const { destination, source } = result;
    // Drop cancelled
    if (!destination) {
      return;
    }
    // Same location
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    dispatchReorder((oldRows) => {
      const newRows = [...oldRows];
      const [moveList] = newRows.splice(source.index, 1);
      newRows.splice(destination.index, 0, moveList);
      return newRows;
    });
  };

  const tableProps: TableProps = {
    rowRenderer: ReorderableRowRenderer,
    tableBodyRenderer: ReorderableTableBodyRenderer,
    showHover,
    showRowIndicator: showHover,
  };
  const dragDropProps: DragDropProps = {
    onBeforeCapture,
    onDragEnd,
  };
  return {
    tableProps,
    dragDropProps,
    DragDropContext,
  };
}
