import React from 'react';
import styled, { ThemeConsumer, DefaultTheme } from 'styled-components';
import Group from '../Group';
import Link from '../Link';
import { getVariationName } from '../../utils/getVariationName';
import { themeGet } from '../../utils/themeGet';
import { IconPropsType } from '../Icon/types';
import { AnchorAttrsType, getAnchorAttributes } from '../../utils/anchorAttributes';
import { getDataAttributes, DataPropsType } from '../../utils/dataAttributes';

const getSize = (size: SizeType): string => {
  switch (size) {
    case 'small':
      return '24px';
    case 'large':
      return '48px';
    default:
      return '32px';
  }
};

type IconButtonStyleTypes = {
  disabled?: boolean;
  label?: string;
  showOutline?: boolean;
  size?: SizeType;
};
const IconButtonStyle = styled.button<IconButtonStyleTypes>`
  border: none;
  color: ${({ theme }): string => theme.text.labelColor};
  padding: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: ${({ size }): string => getSize(size)};
  height: ${({ size }): string => getSize(size)};
  border-radius: ${({ label }): string => (label ? '4px' : '50%')};
  background-color: ${({ theme }) => theme.button.backgroundColor};
  padding-left: ${({ label, theme }): string => (label ? theme.space.s4 : theme.space.s0)};
  padding-right: ${({ label, theme }): string => (label ? theme.space.s8 : theme.space.s0)};
  opacity: ${({ disabled }): string => (disabled ? '0.5' : '1')};
  text-decoration: none;

  &:hover {
    cursor: ${({ disabled }): string => (disabled ? 'not-allowed' : 'pointer')};
    background-color: ${({ theme }) => theme.button.hoverBackgroundColor};
  }
  &:focus {
    outline: ${({ showOutline }): string =>
      showOutline ? '-webkit-focus-ring-color auto 4px' : 'none'};
  }
`;

export type SizeType = 'small' | 'default' | 'large';

type Variants = 'primary' | 'secondary' | 'destructive';

type PropsType = (DataPropsType & AnchorAttrsType) & {
  className?: string;
  /**
   * **Deprecated** Use variant instead
   * @deprecated
   */
  destructive?: boolean;
  disabled?: boolean;
  label?: string;
  labelFirst?: boolean;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>) => void;
  /**
   * **Deprecated** Use variant instead
   * @deprecated
   */
  primary?: boolean;
  /**
   * **Deprecated** Use variant instead
   * @deprecated
   */
  secondary?: boolean;
  showOutline?: boolean;
  size?: SizeType;
  type?: 'reset' | 'submit' | 'button';
  variant?: Variants;
};

const IconButton: React.FunctionComponent<React.PropsWithChildren<PropsType>> = ({
  label = '',
  labelFirst = false,
  children = '',
  disabled = false,
  size = 'default',
  onClick,
  type,
  showOutline = true,
  primary = false,
  secondary = false,
  destructive = false,
  variant = 'secondary',
  className,
  data,
  ...restProps
}) => {
  const variationName = getVariationName<Variants>({ primary, secondary, destructive }, variant);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (disabled) {
      return;
    }
    if (onClick) {
      onClick(e);
    }
  };

  const child = React.Children.only(children) as React.ReactElement<IconPropsType>;
  const getButtonContent = (theme: DefaultTheme) =>
    React.cloneElement(child, {
      color:
        child.props?.color ||
        themeGet(`link.${variationName}.color`)({
          theme,
        }),
    });

  const { href } = restProps;
  /* Empty string is a valid href */
  const isAnchor = !!href || href === '';
  /* For unknown historical reasons, type defaults to submit instead of button. */
  const buttonType = typeof type !== 'undefined' ? type : 'submit';
  /* We don't want to prevent the default bubbling for links, which is what handleClick does */
  const onClickHandler = isAnchor && typeof onClick !== 'function' ? undefined : handleClick;

  return (
    <ThemeConsumer>
      {(theme) => (
        <IconButtonStyle
          as={isAnchor ? 'a' : 'button'}
          type={isAnchor ? type : buttonType}
          onClick={onClickHandler}
          size={size}
          label={label}
          showOutline={showOutline}
          className={className}
          disabled={disabled}
          {...getAnchorAttributes(restProps)}
          {...getDataAttributes(data)}
        >
          {label ? (
            <Group horizontal spacing="4px">
              {labelFirst ? null : getButtonContent(theme)}
              {/* It's invalid to nest an <a> inside another <a>, so this should be a span */}
              <Link as="span" onClick={onClickHandler} variant={variationName}>
                {label}
              </Link>
              {labelFirst ? getButtonContent(theme) : null}
            </Group>
          ) : (
            getButtonContent(theme)
          )}
        </IconButtonStyle>
      )}
    </ThemeConsumer>
  );
};

export default IconButton;
