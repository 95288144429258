export type DataPropsType = {
  data?: DataAttributes;
};
export type DataAttributes = {
  [key: string]: string;
};

export function getDataAttributes(
  dataAttributes: DataAttributes,
  postfix?: string,
  keyPrefix = 'data-',
): DataAttributes {
  if (typeof dataAttributes !== 'object') {
    return {};
  }
  const attrs = {};
  Object.keys(dataAttributes).forEach((key) => {
    attrs[`${keyPrefix}${key}`] = postfix
      ? `${dataAttributes[key]}_${postfix}`
      : dataAttributes[key];
  });
  return attrs;
}
