import { NumberPadActionGroup } from './NumberPadActionGroup';
import { ACTION_BUTTON_TEXT, NumberPadActionItem } from './NumberPadActionItem';
import { NumberPadActionBar } from './NumberPadActionBar';
import { ActionHandlerType, FormatterValueType } from './types';

import { formatCurrency } from './NumberPadHelper';

export const BAR_NAME = 'bartab-preset';
export class NumberPadBarTabAmountActionBar extends NumberPadActionBar {
  actionHandler = (actionHandlerInputState: ActionHandlerType): ActionHandlerType => {
    const ret: ActionHandlerType = { ...actionHandlerInputState };
    return ret;
  };

  formatter = (formatterInputState: FormatterValueType): string => {
    let displayText = formatterInputState.value;
    displayText = formatCurrency(formatterInputState.value, formatterInputState.currency);
    if (formatterInputState.isNegative) {
      displayText = `-${displayText}`;
    }
    return displayText;
  };

  // @ts-ignore
  constructor() {
    const defaultAction = null;
    const actionGroups = [
      new NumberPadActionGroup('dollar-presets', defaultAction, [
        new NumberPadActionItem('$25', '25', ACTION_BUTTON_TEXT),
        new NumberPadActionItem('$50', '50', ACTION_BUTTON_TEXT),
        new NumberPadActionItem('$100', '100', ACTION_BUTTON_TEXT),
        new NumberPadActionItem('$200', '200', ACTION_BUTTON_TEXT),
      ]),
    ];
    super(BAR_NAME, actionGroups);
  }
}
