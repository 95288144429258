import React from 'react';
import styled from 'styled-components';
import Group from '../Group';
import IconButton from '../IconButton';
import { IconMenu } from '../Icon';
import Title from '../Title';
import { TopBar } from '../TitleHeader';
import Overlay from '../Overlay';
import LeftNav, { LeftNavMenuPropsType } from '../LeftNav';
import NotificationDot from '../NotificationDot';

type PropsType = {
  content?: JSX.Element | null;
  /**
   * Whenever we show or hide menu,
   * this callback gets called with a boolean value to update your local state.
   * @param showMenu
   */
  handleMenuChange?: (showMenu: boolean) => void;
  menuProps?: LeftNavMenuPropsType;
  menuStatus?: 'success' | 'alert';
  showBorder?: boolean;
  /**
   * Property to show or hide the menu.
   */
  showMenu?: boolean;
  showMenuStatus?: boolean;
  showTitle?: boolean;
  title?: string;
};

type NavPropsType = LeftNavMenuPropsType & {
  onClose: () => void;
};

const TitleMenuHeader = (props: PropsType): JSX.Element => {
  const onMenuOpen = (): void => props.handleMenuChange(true);
  const onMenuClose = (): void => props.handleMenuChange(false);
  const navConfig: NavPropsType = {
    ...props.menuProps,
    onClose: onMenuClose,
  };

  const MenuButton = (): JSX.Element => (
    <IconButton onClick={onMenuOpen}>
      <IconMenu width={20} height={20} />
    </IconButton>
  );

  const MenuButtonWithNotification = ({
    menuStatus,
  }: {
    menuStatus?: 'success' | 'alert';
  }): JSX.Element => (
    <NotificationDot status={menuStatus}>
      <MenuButton />
    </NotificationDot>
  );

  return (
    <>
      <ModifiedTopBar showBorder={props.showBorder}>
        <Group horizontal spacing="8px">
          {props.showMenuStatus ? <MenuButtonWithNotification /> : <MenuButton />}
          {props.showTitle && <Title>{props.title}</Title>}
        </Group>
        {props.content}
      </ModifiedTopBar>
      {props.showMenu && <Menu {...navConfig} />}
    </>
  );
};

TitleMenuHeader.defaultProps = {
  title: 'Title',
  showTitle: true,
  showBorder: true,
  showMenuStatus: false,
  menuStatus: 'success',
  content: null,
  handleMenuChange: () => {},
};

const ModifiedTopBar = styled(TopBar)<{ showBorder: boolean }>`
  padding-left: 8px;
  border-bottom: ${({ showBorder, theme }): string =>
    showBorder ? theme.border.defaultBorder : 'none'};
`;

const Menu = (props: NavPropsType): JSX.Element => (
  <Overlay preventBackgroundScroll onClick={props.onClose}>
    <MenuContainer>
      <LeftNav {...props} />
    </MenuContainer>
  </Overlay>
);

const MenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 80%;
`;

export default TitleMenuHeader;
