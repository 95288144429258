import React, { Children, Component } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { HorizontalAlign } from '../Alignment/Alignment';

const GroupComponent = styled.div<PropsType>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${(props: PropsType): string => props.height};

  &.full {
    height: 100%;
  }

  > .item + .item {
    margin-top: ${({ spacing }): string => spacing};
  }

  &.horizontal {
    width: ${(props: PropsType): string => props.horizontalWidth};

    /* To be use independent of full for backwards compatibility */
    > .item {
      height: ${(props: PropsType): string => props.itemHeight};
    }

    &.full {
      height: auto;
      width: 100%;

      > .item {
        height: ${(props: PropsType): string => props.itemHeight};
      }
    }

    flex-direction: row;
    align-items: center;
    > .item + .item {
      margin-top: 0;
      margin-left: ${({ spacing }): string => spacing};
    }

    &.even {
      justify-content: space-between;
      > .item {
        width: 100%;
      }
    }
  }

  &.center {
    > .item {
      display: flex;
      justify-content: center;
    }
  }

  margin-left: ${(props: PropsType): string =>
    props.align === 'right' || props.align === 'center' ? 'auto' : '0'};
  margin-right: ${(props: PropsType): string =>
    props.align === 'left' || props.align === 'center' ? 'auto' : '0'};

  @supports (display: grid) {
    > .item + .item {
      margin-top: 0;
    }

    display: grid;
    grid-row-gap: ${({ spacing }): string => spacing};
    grid-auto-columns: minmax(0, 100%);

    &.horizontal {
      > .item + .item {
        margin-left: 0;
      }
      grid-column-gap: ${({ spacing }): string => spacing};
      grid-auto-columns: minmax(auto, max-content);
      grid-auto-flow: column;

      &.full {
        grid-auto-columns: auto;
      }

      > .item {
        display: flex;
        align-items: center;
      }

      &.even {
        > .item {
          width: inherit;
        }
        grid-auto-columns: 1fr;
      }
    }
  }
`;

type PropsType = {
  [x: string]: any;
  /**
   * **Deprecated** Should use the Alignment component
   * @deprecated
   * Align of the group (left, center, right)
   */
  align?: HorizontalAlign;
  /**
   * Center align items
   */
  center?: boolean;
  /**
   * Custom class name
   */
  className?: string;
  /**
   * Spaces things out evenly using space-between
   */
  even?: boolean;
  /**
   * Takes up 100% of the available width.
   */
  full?: boolean;
  /**
   * Height of the group component. Default this value is not set
   */
  height?: string;
  /**
   * Lay out items horizontally. By default components are stacked vertically
   */
  horizontal?: boolean;
  /**
   * The width when in Horizontal mode. Default is set to max-content
   */
  horizontalWidth?: string;
  /**
   * Allows you to set the height of all items in a group
   */
  itemHeight?: string;
  /**
   * Spacing between elements
   */
  spacing?: string;
};

/**
 * @deprecated
 * **Deprecated** Use your own layout instead
 *
 * Group is a layout wrapper which employs CSS flex and CSS grid
 *
 * It is somewhat unpredictable and thus is best relegated to simple use cases.
 *
 *
 * Using Group, you will have to additionally wrap children with `<Alignment />`
 * to achieve the desired layout
 */
export default class Group extends Component<PropsType, {}> {
  static defaultProps = {
    className: '',
    spacing: '16px',
    horizontal: false,
    align: 'left',
    full: false,
    even: false,
    itemHeight: '100%',
    horizontalWidth: 'max-content',
    height: '',
  };

  render(): JSX.Element {
    const itemsMarkup = Children.toArray(this.props.children)
      .filter((child: JSX.Element): JSX.Element => child)
      .map(
        (child: JSX.Element, index: number): JSX.Element => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className="item">
            {child}
          </div>
        ),
      );

    const classNames = classnames(
      'group',
      this.props.horizontal ? 'horizontal' : '',
      this.props.full ? 'full' : '',
      this.props.even ? 'even' : '',
      this.props.center ? 'center' : '',
      this.props.className,
    );

    return (
      <GroupComponent {...this.props} className={classNames} role="group">
        {itemsMarkup}
      </GroupComponent>
    );
  }
}
