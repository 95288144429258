import { DefaultTheme, FlattenInterpolation, ThemeProps } from 'styled-components';

type VariantDeclarations<T> = {
  [key: string]: FlattenInterpolation<ThemeProps<DefaultTheme> & T>;
};
export const declareVariants = <T extends unknown>(variants: VariantDeclarations<T>) => {
  return Object.keys(variants).map((key) => (props) =>
    props.variant === key ? variants[key] : null,
  );
};
