import React from 'react';
import { Container as GridContainer, setConfiguration, ContainerProps } from 'react-grid-system';

type PropsType = {
  /**
   * Array of numbers representing container widths at xs, sm, md, lg, xl
   */
  containerWidths?: number[];
};

type RefinedContainerProps = Pick<
  ContainerProps,
  'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'fluid' | 'style' | 'component' | 'children'
>;

/**
 * You would want to use the GridLayout to align your content to a 12 column grid.
 * The concept of using this kind of grid is familiar if you worked with Bootstrap in the past.
 */
export const Container: React.FunctionComponent<
  React.PropsWithChildren<PropsType & RefinedContainerProps>
> = ({ containerWidths, xs, sm, md, lg, xl, fluid, style, component, children }) => {
  const defaultContainerWidths = [327, 432, 580, 580, 580];
  setConfiguration({
    gridColumns: 12,
    gutterWidth: 8,
    breakpoints: [375, 480, 768, 1024, 1440],
    containerWidths: containerWidths || defaultContainerWidths,
  });
  // explicit spreading here prevents overlap between div types and container types
  return (
    <GridContainer {...{ xs, sm, md, lg, xl, fluid, style, component }}>{children}</GridContainer>
  );
};

export default Container;
