import React, { Component } from 'react';
import AdvancedSelect, {
  AdvancedSelectOptionType,
  LoadOptionCallbackType,
} from '../AdvancedSelect';
import Group from '../Group';
import Label from '../Label';

type PropsType = {
  allowCreate?: boolean;
  async?: boolean;
  autoFocus?: boolean;
  backspaceRemovesValue?: boolean;
  clearSelectedItem?: boolean;
  error?: boolean;
  escapeClearsValue?: boolean;
  initialInputValue?: string;
  inputValue?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  isFocused?: boolean;
  isSearchable?: boolean;
  itemLabel?: string;
  itemList?: AdvancedSelectOptionType[];
  itemNotFound?: JSX.Element | null;
  /**
   * **Deprecated** Not implemented at the moment
   * @deprecated
   */
  itemNotFoundAction?: () => void;
  itemNotFoundMessage?: string;
  loadItems?: LoadOptionCallbackType;
  onBlur?: (selectedOption?: any) => void;
  onChange?: (selectedOption?: any) => void;
  onCreateOption?: (value: string) => void;
  onFocus?: (selectedOption?: any) => void;
  onInputChange?: () => void;
  /**
   * **Deprecated** use placeholder instead
   * @deprecated
   * */
  placeHolder?: string;
  placeholder?: string;
  selectedItem?: number;
  value?: AdvancedSelectOptionType | AdvancedSelectOptionType[] | string | null;
};

type StateType = {
  defaultOptions: AdvancedSelectOptionType[];
  defaultSelectedValue: AdvancedSelectOptionType | AdvancedSelectOptionType[] | string | null;
  inputValue: string;
  isClearable: boolean;
};

export default class AutoComplete extends Component<PropsType, StateType> {
  static defaultProps = {
    allowCreate: false,
    clearSelectedItem: false,
    itemNotFound: null,
    itemNotFoundMessage: 'Item not found',
    itemNotFoundAction: () => {},
    placeholder: 'Search for an item',
    error: false,
    autoFocus: false,
    isFocused: false,
    isSearchable: true,
    isClearable: false,
    isDisabled: false,
    backspaceRemovesValue: true,
    escapeClearsValue: true,
    loadItems: () => {},
    onBlur: () => {},
    onCreateOption: () => {},
    onInputChange: () => {},
    onFocus: () => {},
  };

  constructor(props: PropsType) {
    super(props);
    this.state = {
      defaultOptions: this.selectOptions(),
      defaultSelectedValue: this.props.value || this.selectedValue(),
      isClearable: this.props.isClearable,
      inputValue: this.props.initialInputValue,
    };
  }

  static getDerivedStateFromProps(
    props: PropsType,
    state: Record<string, any>,
  ): Record<string, any> | null {
    if (state.inputValue !== props.inputValue) {
      return {
        inputValue: props.inputValue,
      };
    }
    return null;
  }

  componentDidMount = () => {
    this.setState({ isClearable: this.props.isClearable });
  };

  selectOptions = (): AdvancedSelectOptionType[] => {
    if (!this.props.itemList) return [];

    return this.props.itemList.map(
      (x: Record<string, any>): AdvancedSelectOptionType => ({
        label: x.label || x.value,
        value: x.value,
      }),
    );
  };

  selectedValue = (): any => {
    if (this.props.initialInputValue) {
      return this.selectOptions().find(
        (option: AdvancedSelectOptionType): boolean =>
          option.value === this.props.initialInputValue,
      );
    }
    return null;
  };

  handleOnChange = (selectedOption: any) => {
    if (this.props.onChange) this.props.onChange(selectedOption);
  };

  handleOnFocus = (selectedOption: any) => {
    // Display the clear button when selectedOption is present
    this.setState((prevState) => ({
      isClearable: !!(selectedOption || prevState.defaultSelectedValue),
    }));

    if (this.props.onFocus) this.props.onFocus(selectedOption);
  };

  handleOnBlur = (selectedOption: any) => {
    // When leaving the component, if the clear button is displayed, hide it
    if (this.state.isClearable) {
      this.setState({ isClearable: false });
    }
    if (this.props.onBlur) this.props.onBlur(selectedOption);
  };

  render(): JSX.Element {
    return (
      <Group full spacing="0px">
        {this.props.itemLabel && <Label>{this.props.itemLabel}</Label>}
        <AdvancedSelect
          allowCreate={this.props.allowCreate}
          async={this.props.async}
          autoFocus={this.props.autoFocus}
          backspaceRemovesValue={this.props.backspaceRemovesValue}
          defaultValue={this.state.defaultSelectedValue}
          error={this.props.error}
          escapeClearsValue={this.props.escapeClearsValue}
          inputValue={this.state.inputValue}
          isClearable={this.state.isClearable}
          isDisabled={this.props.isDisabled}
          isFocused={this.props.isFocused}
          isSearchable={this.props.isSearchable}
          loadOptions={this.props.loadItems}
          noOptionsMessage={this.props.itemNotFoundMessage}
          onBlur={this.handleOnBlur}
          onChange={this.handleOnChange}
          onCreateOption={this.props.onCreateOption}
          onFocus={this.handleOnFocus}
          onInputChange={this.props.onInputChange}
          openMenuOnClick={false}
          options={this.state.defaultOptions}
          placeholder={this.props.placeholder || this.props.placeHolder}
          showDropdownIndicator={false}
        />
      </Group>
    );
  }
}
