import React from 'react';
import styled from 'styled-components';
import { Spring, animated } from 'react-spring/renderprops.cjs';
import { preventPropagation } from '../../utils/interaction';
import { IconArrowDown } from '../Icon';
import { colors } from '../../themes';

export type PropsType = {
  children?: JSX.Element;
  defaultIsOpen?: boolean;
  header?: string | null | JSX.Element | null;
  headerOpened?: string | null | JSX.Element | null;
  id?: string;
  onClick?: () => void;
  showIndicator?: boolean;
};

type StateType = {
  isOpen: boolean;
};

export default class AccordionItem extends React.PureComponent<PropsType, StateType> {
  static defaultProps = {
    header: '',
    showIndicator: true,
    defaultIsOpen: false,
    onClick: () => {},
  };

  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ isOpen: this.props.defaultIsOpen });
  }

  onClick = (e: React.SyntheticEvent) => {
    if (this.props.children) {
      this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
    }
    preventPropagation(e, this.props.onClick);
  };

  headerTemplate = (): string | null | JSX.Element | null =>
    this.state.isOpen && this.props.headerOpened ? this.props.headerOpened : this.props.header;

  render(): JSX.Element {
    const { isOpen } = this.state;
    const { children, id, showIndicator } = this.props;
    const transition = {
      grow: {
        from: {
          height: 0,
          opacity: 0,
        },
        to: {
          height: isOpen ? 'auto' : 0,
          opacity: isOpen ? 1 : 0,
        },
        config: { duration: 200 },
      },
      rotate: {
        from: {
          transform: 'rotate(0deg)',
        },
        to: {
          transform: isOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
        },
        config: { duration: 200 },
      },
    };
    return (
      <StyledAccordionItem isOpen={isOpen} className={isOpen && 'isOpen'}>
        <StyledAccordionWrapper isOpen={isOpen}>
          <StyledLink id={id} role="button" tabIndex={0} onClick={this.onClick}>
            {this.headerTemplate()}
            {showIndicator && (
              <Spring {...transition.rotate}>
                {(styleProps: Record<string, any>): any => (
                  <Indicator style={styleProps}>
                    <IconArrowDown color={colors.gray600} />
                  </Indicator>
                )}
              </Spring>
            )}
          </StyledLink>
          {children && (
            <Spring {...transition.grow}>
              {(styleProps: Record<string, any>): any => (
                <ChildContent style={styleProps}>{children}</ChildContent>
              )}
            </Spring>
          )}
        </StyledAccordionWrapper>
      </StyledAccordionItem>
    );
  }
}

export const StyledAccordionItem = styled.div<{ isOpen: boolean }>`
  background-color: ${({ theme }): string => theme.form.inactiveBackgroundColor};
  box-shadow: ${(props: Record<string, any>): string =>
    props.isOpen ? 'inset 0 4px 4px -4px rgba(0,0,0,0.2)' : 'unset'};
  padding: ${(props: Record<string, any>): string => (props.isOpen ? '8px 0' : '0')};
`;

const StyledAccordionWrapper = styled.div<{ isOpen: boolean }>`
  box-shadow: ${(props: Record<string, any>): string =>
    props.isOpen ? '0 2px 4px 0 rgba(0,0,0,0.10)' : 'unset'};
`;

const Indicator = styled(animated.div)`
  transform-origin: center;
  width: 24px;
  height: 24px;
  margin-left: auto;
`;

const ChildContent = styled(animated.div)`
  overflow: hidden;
  background-color: ${({ theme }): string => theme.background.default};
`;

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: ${({ theme }): string => theme.background.default};

  &:hover {
    cursor: pointer;
    background: ${({ theme }): string => theme.form.hoverBackgroundColor};
  }
  &:focus {
    outline: 0;
  }
`;
