import React from 'react';
import IconStyle from '../IconStyle';
import IconPropsType from '../types';
import IconSvg from './Filter.svg';

const IconFilter: React.FunctionComponent<IconPropsType> = (props) => {
  return (
    <IconStyle {...props}>
      <IconSvg {...props} aria-label="filter" />
    </IconStyle>
  );
};

export default IconFilter;
