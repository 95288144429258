import React from 'react';
import styled from 'styled-components';
import Link from '../Link';
import Group from '../Group';
import Overlay from '../Overlay';
import Popup from '../Popup';
import { Heading2 } from '../Heading';
import IconButton from '../IconButton/IconButton';
import { IconClose } from '../Icon';
import { Desktop, MobileAndTablet } from '../../utils/responsive';
import { ActionListHeaderActionType } from './types';

const filterByProperty = (item: ActionListHeaderActionType): boolean =>
  item.visible && !item.primary;
const mapActionItem = (actionItem: ActionListHeaderActionType, index): JSX.Element => (
  <Link
    key={
      actionItem.key || typeof actionItem.name === 'string'
        ? (actionItem.name as string)
        : `overflowMenu${index}`
    }
    className="menuItem"
    disabled={!actionItem.enabled}
    destructive={actionItem.destructive}
    onClick={actionItem.onClick}
  >
    <Group horizontal>
      {actionItem.icon}
      {actionItem.name}
    </Group>
  </Link>
);

type PropsType = {
  actions?: ActionListHeaderActionType[];
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  right?: string;
  top?: string;
};

export const OverflowMenu: React.FunctionComponent<PropsType> = ({
  actions,
  onClick,
  top,
  right,
}) => {
  const desktopStyle: React.CSSProperties = { position: 'relative' };
  const mobileActionsStyle: React.CSSProperties = { position: 'absolute', right: '16px' };
  const filteredActions = actions.filter(filterByProperty).map(mapActionItem);
  return (
    <>
      <MobileAndTablet>
        <Overlay onClick={onClick}>
          <StyledOverflowMenu>
            <Group horizontal full>
              <Heading2>Actions</Heading2>
              <div style={mobileActionsStyle}>
                <IconButton
                  destructive={false}
                  primary={false}
                  secondary={false}
                  showOutline={false}
                  size="default"
                  onClick={onClick}
                  type="button"
                >
                  <IconClose width={16} height={16} />
                </IconButton>
              </div>
            </Group>
            {filteredActions}
          </StyledOverflowMenu>
        </Overlay>
      </MobileAndTablet>
      <Desktop>
        <div style={desktopStyle}>
          <Popup onClickOutside={onClick}>
            <PopupOverflowMenu top={top} right={right}>
              <Group>{filteredActions}</Group>
            </PopupOverflowMenu>
          </Popup>
        </div>
      </Desktop>
    </>
  );
};

OverflowMenu.defaultProps = {
  top: '24px',
  right: '16px',
};

const StyledOverflowMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 75%;
  height: 100%;
  border-radius: 4px 0 0 4px;
  padding: 16px 16px 16px 24px;
  background-color: ${({ theme }) => theme.dialog.backgroundColor};
  border: 1px solid ${({ theme }) => theme.border.defaultBorder};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  .menuItem {
    margin-top: 48px;
    justify-content: flex-start;
  }

  @media screen and (min-width: 768px) {
    width: 40%;
  }
`;

const PopupOverflowMenu = styled.div`
  position: fixed;
  top: ${(props: PropsType): string | null => props.top};
  right: ${(props: PropsType): string | null => props.right};
  padding: 16px 24px;
  border-radius: ${({ theme }): string => theme.border.radius};
  background-color: ${({ theme }): string => theme.background.default};
  border: ${({ theme }): string => theme.border.defaultBorder};
  box-shadow: ${({ theme }): string => theme.shadows.default};
  z-index: ${({ theme }): number => theme.zIndex.popup};
`;

export default OverflowMenu;
