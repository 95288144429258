import { css, DefaultTheme } from 'styled-components';

export const unstyledButtonMixin = ({ theme }: { theme: DefaultTheme }) => css`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize line-height. Cannot be changed from normal in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable input types in iOS */
  appearance: none;
  -webkit-appearance: none;

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &:hover {
    background-color: transparent;
  }
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 ${theme.borderWidths.selected} ${theme.border.color.focused} inset;
  }
`;
