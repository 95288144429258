/* eslint jsx-a11y/no-noninteractive-tabindex: 0 */
import React, { createRef } from 'react';

type PropsType = {
  children?: Record<string, any>;
  focus?: boolean;
};

export default class FocusElement extends React.Component<PropsType, any> {
  static defaultProps = {
    children: {},
    focus: true,
  };

  componentDidMount() {
    if (this.el.current && this.el.current !== document.activeElement && this.props.focus) {
      this.el.current.focus();
    }
  }

  el = createRef<HTMLInputElement>();

  render(): JSX.Element {
    const { children } = this.props;
    return (
      <div ref={this.el} tabIndex={0} style={{ outline: 'none' }}>
        {children}
      </div>
    );
  }
}

export const withFocus =
  (C: React.ComponentType<React.PropsWithChildren<any>>) =>
  (props: Record<string, any>): JSX.Element =>
    (
      <FocusElement>
        <C {...props} />
      </FocusElement>
    );
