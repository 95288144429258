import React from 'react';
import styled, { ThemeProvider, ThemeConsumer } from 'styled-components';
// https://github.com/babel/babel/issues/9543
import { Spring } from 'react-spring/renderprops.cjs';
import { ProductAppType, ProductType } from './types';
import { defaultProducts, ProductOrder } from './constants';
import Card from '../Card';
import Heading from '../Heading';
import { IconClose } from '../Icon';
import IconButton from '../IconButton';
import Link from '../Link';
import Page from '../Page';
import Spacer from '../Spacer';
import ScrollArea from '../ScrollArea';
import { Mobile, Tablet, Desktop } from '../../utils/responsive';
import { getThemeFromColor } from '../../utils/themeSelector';
import { getUrlWithQuerystring } from '../../utils/querystring';
import Image from '../Image';
import { withEscapeWillClose } from '../../utils/escaper';
import { withFocus } from '../../utils/focusElement';
import Logo from '../Logo';
import { theme as KountaThemeLight, colors } from '../../themes';

export type PropsType = {
  /**
   * **Deprecated** use a ChameleonRoot to control color schemes via theme instead
   * @deprecated
   */
  backgroundColor?: string;
  /**
   * If populated, the company id will be added as a querystring so that
   * the product handling the query can select the company context
   */
  companyId?: string | null;
  onClose?: () => void;
  products?: {
    [K in ProductType]: ProductAppType;
  };
  productsList?: ProductAppType[];
  /**
   * If populated, the site id will be added as a querystring so that
   * the product handling the query can select the site context
   */
  siteId?: string | null;
  siteImageUrl?: string;
  siteName?: string;
};

export const AppSwitcher: React.FunctionComponent<PropsType> = (props) => {
  // combine props
  // https://stackoverflow.com/questions/40428847/react-component-defaultprops-objects-are-overridden-not-merged
  const productsList = ProductOrder.map(
    (product: ProductType): ProductAppType => ({
      id: getProductValueString(props.products, product, 'id') || defaultProducts[product].id,
      name: getProductValueString(props.products, product, 'name') || defaultProducts[product].name,

      productUrl: getUrlWithContext(
        getProductValueString(props.products, product, 'productUrl') ||
          defaultProducts[product].productUrl,
        props.siteId,
        props.companyId,
      ),

      tryLabel:
        getProductValueString(props.products, product, 'tryLabel') ||
        defaultProducts[product].tryLabel,

      tryUrl: getUrlWithContext(
        getProductValueString(props.products, product, 'tryUrl') || defaultProducts[product].tryUrl,
        props.siteId,
        props.companyId,
      ),

      imageUrl:
        getProductValueString(props.products, product, 'imageUrl') ||
        defaultProducts[product].imageUrl,
      hasSubscription: getProductValueBoolean(
        props.products,
        product,
        'hasSubscription',
        defaultProducts[product].hasSubscription,
      ),
      selected: getProductValueBoolean(props.products, product, 'selected', false),
      hidden: getProductValueBoolean(
        props.products,
        product,
        'hidden',
        defaultProducts[product].hidden,
      ),
    }),
  );

  const newProps: PropsType = {
    siteName: props.siteName,
    siteImageUrl: props.siteImageUrl,
    backgroundColor: props.backgroundColor,
    siteId: props.siteId,
    companyId: props.companyId,
    products: props.products,
    onClose: props.onClose,
    productsList,
  };

  return (
    <ThemeConsumer>
      {(theme) => (
        <ThemeProvider
          theme={
            props.backgroundColor
              ? getThemeFromColor(props.backgroundColor)
              : theme || KountaThemeLight
          }
        >
          <>
            <Mobile>
              <PortraitWithFocus {...newProps} />
            </Mobile>
            <Tablet orientation="portrait">
              <PortraitWithFocus {...newProps} />
            </Tablet>
            <Tablet orientation="landscape">
              <LandscapeWithFocus {...newProps} />
            </Tablet>
            <Desktop>
              <LandscapeWithFocus {...newProps} />
            </Desktop>
          </>
        </ThemeProvider>
      )}
    </ThemeConsumer>
  );
};

AppSwitcher.defaultProps = {
  siteId: '',
  companyId: '',
  onClose: () => {},
};

const getProductValueString = (
  products: {
    [K in ProductType]: ProductAppType;
  },
  product: ProductType,
  field: string,
): string | null => {
  if (products && product) {
    return products[product][field];
  }
  return null;
};

const getProductValueBoolean = (
  products: {
    [K in ProductType]: ProductAppType;
  },
  product: ProductType,
  field: string,
  defaultValue: boolean,
): boolean | null => {
  if (products && product) {
    return products[product][field];
  }
  return defaultValue;
};

const getUrlWithContext = (
  baseUrl: string | null,
  siteId: string | null,
  companyId: string | null,
): string | null => {
  if (baseUrl && (siteId || companyId)) {
    const params: Record<string, any> = {};

    if (siteId) {
      params.siteId = siteId;
    }
    if (companyId) {
      params.companyId = companyId;
    }

    return getUrlWithQuerystring(baseUrl, params);
  }
  return baseUrl;
};

const AnchorContainer = styled.a`
  text-decoration: none;
`;

const StyledCard = styled((props: Record<string, any>) => <Card {...props} />)`
  margin: 8px;
  border-bottom: ${(props): string => (props.selected ? '4px solid #2E61DE' : 'none')};

  &:active,
  &:hover {
    cursor: pointer;
    border-bottom: 4px solid ${({ theme }): string => theme.separator.backgroundColor};
  }
`;

const ProductListing = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
`;

const ProductStacked = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  max-width: 320px;
  padding: 0 16px;
  width: 100%;
`;

const StyledHorizontalCard = styled((props: Record<string, any>) => <Card {...props} />)`
  margin: 8px 0;
  width: 100%;
  display: flex;
  overflow: hidden;
  border-right: ${({ selected }): string =>
    selected ? `4px solid ${colors.blue500}` : `4px solid ${colors.transparent}`};

  &:active,
  &:hover {
    cursor: pointer;
    border-right: 4px solid ${({ theme }): string => theme.separator.backgroundColor};
  }
`;

const SiteImageContainer = styled.div<{ imageUrl?: string }>`
  @media screen and (orientation: landscape) and (max-height: 500px) {
    display: none;
  }
  display: block;
  width: 96px;
  min-height: 96px;
  background-size: cover;
  background-image: url(${(props): string => props.imageUrl});
`;

const LogoContainer = styled.div`
  position: fixed;
  top: 32px;
  left: 32px;
`;

const CloseContainer = styled.div`
  position: fixed;
  top: 32px;
  right: 32px;
`;

const CloseMobileContainer = styled.div`
  position: fixed;
  top: 16px;
  right: 16px;
`;

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
`;

const ProductDetailsMobileArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 96px;
`;

const ProductDetailsMobile = styled.div`
  min-width: 96px;
  height: 48px;
`;

const SwitcherBackground = styled.div<{ backgroundColor?: string }>`
  background-color: ${({ theme }): string => theme.navigationBar.backgroundColor};
  height: 1px;
  width: 1px;
  border-radius: 50%;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const StackedSwitcherBackground = styled((props: Record<string, any>) => (
  <SwitcherBackground {...props} />
))`
  flex-direction: column;
  overflow-y: auto;
  height: auto;
  width: 100%;
  min-height: 100%;
  border-radius: 0;
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  z-index: 1;
  max-height: 100%;
`;

const PortraitSwitcher = (props: PropsType): JSX.Element => (
  <Page fullscreen>
    <Spring
      from={{
        opacity: 0,
        transform: 'rotate(0deg)',
      }}
      to={{
        opacity: 1,
        transform: 'rotate(-90deg)',
      }}
      config={{
        delay: 200,
        duration: 400,
      }}
    >
      {(styleProps: Record<string, any>): JSX.Element => (
        <CloseMobileContainer style={styleProps}>
          <IconButton size="large" onClick={props.onClose}>
            <IconClose />
          </IconButton>
        </CloseMobileContainer>
      )}
    </Spring>
    <ScrollArea>
      <StackedSwitcherBackground backgroundColor={props.backgroundColor}>
        <Spacer spacing="s48" />
        {props.siteImageUrl && <SiteImageContainer imageUrl={props.siteImageUrl} />}

        <Heading>{props.siteName}</Heading>
        <Spacer spacing="s16" />
        <ProductStacked>
          {props.productsList
            .filter((item: ProductAppType): boolean => !item.hidden)
            .map(
              (product: ProductAppType): JSX.Element => (
                <AnchorContainer
                  key={product.id}
                  id={product.id}
                  href={product.hasSubscription ? product.productUrl : product.tryUrl}
                >
                  <StyledHorizontalCard
                    hidePadding
                    selected={product.selected}
                    elementOnLeft
                    element={
                      <Image height="96px" width="96px !important" imageUrl={product.imageUrl} />
                    }
                  >
                    <ProductDetailsMobileArea>
                      <ProductDetailsMobile>
                        <Link variant="primary" as="span">
                          {product.hasSubscription ? 'Launch' : product.tryLabel}
                        </Link>
                        <Heading level={3}>{product.name}</Heading>
                      </ProductDetailsMobile>
                    </ProductDetailsMobileArea>
                  </StyledHorizontalCard>
                </AnchorContainer>
              ),
            )}
        </ProductStacked>
        <Spacer spacing="s24" />
      </StackedSwitcherBackground>
    </ScrollArea>
  </Page>
);

const LandscapeSwitcher = (props: PropsType): JSX.Element => {
  return (
    <Page fullscreen>
      <Container>
        <Spring
          from={{
            transform: 'scale(0)',
          }}
          to={{
            transform: 'scale(6000)',
          }}
          config={{
            duration: 800,
          }}
        >
          {(styleProps: Record<string, any>): JSX.Element => (
            <SwitcherBackground backgroundColor={props.backgroundColor} style={styleProps} />
          )}
        </Spring>

        <Spring
          from={{
            opacity: 0,
            transform: 'rotate(0deg)',
          }}
          to={{
            opacity: 1,
            transform: 'rotate(-90deg)',
          }}
          config={{
            delay: 600,
            duration: 400,
          }}
        >
          {(styleProps: Record<string, any>): JSX.Element => (
            <CloseContainer style={styleProps}>
              <IconButton size="large" onClick={props.onClose}>
                <IconClose />
              </IconButton>
            </CloseContainer>
          )}
        </Spring>

        <LogoContainer>
          <Logo width={140} height="auto" />
        </LogoContainer>

        <Spring
          from={{
            opacity: 0,
          }}
          to={{
            opacity: 1,
          }}
          config={{
            duration: 1000,
          }}
        >
          {(styleProps: Record<string, any>): JSX.Element => (
            <Content style={styleProps}>
              {props.siteImageUrl && <SiteImageContainer imageUrl={props.siteImageUrl} />}
              <Spacer spacing="s16" />
              <Heading>{props.siteName}</Heading>
              <Spacer />
              <Heading level={6}>The Lightspeed Platform</Heading>
              <Spacer spacing="s16" />
              <ProductListing>
                {props.productsList
                  .filter((item: ProductAppType): boolean => !item.hidden)
                  .map(
                    (product: ProductAppType): JSX.Element => (
                      <StyledCard
                        key={product.id}
                        hidePadding
                        width="144px"
                        selected={product.selected}
                      >
                        <AnchorContainer
                          id={product.id}
                          href={product.hasSubscription ? product.productUrl : product.tryUrl}
                        >
                          <Image height="auto" imageUrl={product.imageUrl} />
                          <ProductDetails>
                            <Link variant="primary" as="span">
                              {product.hasSubscription ? 'Launch' : product.tryLabel}
                            </Link>
                            <Heading level={3}>{product.name}</Heading>
                          </ProductDetails>
                        </AnchorContainer>
                      </StyledCard>
                    ),
                  )}
              </ProductListing>
              <Spacer spacing="s96" />
            </Content>
          )}
        </Spring>
      </Container>
    </Page>
  );
};

const PortraitWithFocus = withFocus(PortraitSwitcher);
const LandscapeWithFocus = withFocus(LandscapeSwitcher);

export default withEscapeWillClose<PropsType>(AppSwitcher);
