import React from 'react';

import Select, {
  Async,
  AsyncCreatable,
  components as SelectComponents,
  Creatable,
  createFilter,
} from 'react-select';
import { Props as SelectProps } from 'react-select/lib/Select';
import { AsyncProps } from 'react-select/lib/Async';
import { SelectComponentsConfig } from 'react-select/lib/components';
import { IndicatorProps, LoadingIconProps } from 'react-select/lib/components/indicators';
import {
  IndicatorContainerProps,
  ValueContainerProps,
} from 'react-select/lib/components/containers';
import { MenuListComponentProps, NoticeProps } from 'react-select/lib/components/Menu';
import { OptionProps } from 'react-select/lib/components/Option';
import { StylesConfig } from 'react-select/lib/styles';
import styled, { DefaultTheme, ThemeConsumer } from 'styled-components';
import { FixedSizeList } from 'react-window';
import { ValueType } from 'react-select/lib/types';
import { getDataAttributes, DataPropsType } from '../../utils/dataAttributes';
import { IconArrowDown, IconClear, IconTick } from '../Icon';
import Group from '../Group';
import Label from '../Label';
import Loading from '../Loading';
import { deepClone } from '../../utils/cloner';

const OPTION_ITEM_HEIGHT = 36;

const DropdownIndicator = <T extends InternalOptionsType>(props: IndicatorProps<T>) =>
  SelectComponents.DropdownIndicator && (
    <SelectComponents.DropdownIndicator {...props}>
      <IconArrowDown />
    </SelectComponents.DropdownIndicator>
  );

const ClearIndicator = <T extends InternalOptionsType>(props: IndicatorProps<T>) =>
  SelectComponents.ClearIndicator && (
    <SelectComponents.ClearIndicator {...props}>
      <IconClear />
    </SelectComponents.ClearIndicator>
  );

const LoadingIndicator = <T extends InternalOptionsType>({
  maxReached,
  ...props
}: LoadingIconProps<T> & { maxReached: boolean }): JSX.Element => {
  if (maxReached) {
    // @ts-ignore   This component requires a children prop but we don't have one
    return <ClearIndicator {...props} />;
  }
  return (
    <div style={{ marginRight: '8px' }}>
      <Loading {...props} overlay={false} showTitle={false} size={20} />
    </div>
  );
};

const IndicatorContainerWrapper = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
`;

const IndicatorsContainer = <T extends InternalOptionsType>(
  props: IndicatorContainerProps<T>,
): JSX.Element => (
  <IndicatorContainerWrapper>
    <SelectComponents.IndicatorsContainer {...props} />
  </IndicatorContainerWrapper>
);

const NoOptionsMessage = <T extends InternalOptionsType>(props: NoticeProps<T>): JSX.Element => (
  <SelectComponents.NoOptionsMessage {...props}>
    {props.selectProps.noOptionsMessage({ inputValue: '' })}
  </SelectComponents.NoOptionsMessage>
);

const ValueContainer = <T extends InternalOptionsType>(
  props: ValueContainerProps<T> & {
    getMultiValueLabel: GetMultiValueLabelFunc<T>;
    multiValueLabelLimit: number;
  },
): JSX.Element => {
  const { children, selectProps, getMultiValueLabel, multiValueLabelLimit } = props;

  const valueComponent = children;
  if (
    selectProps.value &&
    Array.isArray(selectProps.value) &&
    selectProps.value.length > multiValueLabelLimit
  ) {
    const optionCount = selectProps.value.length;
    const multiValueLabel = getMultiValueLabel(optionCount, props);
    const newChildren = deepClone(children);
    newChildren[0] = multiValueLabel;

    return (
      <SelectComponents.ValueContainer {...props}>{newChildren}</SelectComponents.ValueContainer>
    );
  }

  return (
    <SelectComponents.ValueContainer {...props}>{valueComponent}</SelectComponents.ValueContainer>
  );
};

const MenuList = <T extends InternalOptionsType>(
  props: MenuListComponentProps<T> & {
    isAsync?: boolean;
    maxMultiSelectItems: number;
    maxReached: boolean;
  },
): JSX.Element => {
  const { options, children, maxHeight, getValue, maxReached, isMulti, isAsync } = props;
  const { isLoading } = props.selectProps;

  const value = getValue();
  const valueArray = Array.isArray(value) ? value : [value];
  const [lastSelectedVal] = valueArray;

  const getOptionsCount = (
    optLength: number,
    valLength: number,
    isMultiSelect: boolean,
  ): number => {
    if (!optLength) {
      return 1;
    }

    /* Reduce count for already selected options
     * to prevent extra container height
     */
    if (isMultiSelect && valLength) {
      return optLength - valLength;
    }

    return optLength;
  };

  /**
   * options do not get updated when using an async AdvancedSelect with loadOptions
   * instead, only children changes
   * thus we must ensure that we account for when children should be used as the source of truth
   * instead of options
   */
  let childrenCount = 0;
  if (Array.isArray(children)) {
    childrenCount = children.length;
  } else if (children) {
    childrenCount = 1;
  }
  const optionsCount = isAsync
    ? childrenCount
    : getOptionsCount(options.length, valueArray.length, isMulti);

  const optionsHeight = optionsCount > 8 ? maxHeight : optionsCount * OPTION_ITEM_HEIGHT;
  const initialOffset = Math.min(
    options.indexOf(lastSelectedVal) * OPTION_ITEM_HEIGHT,
    optionsHeight - OPTION_ITEM_HEIGHT,
  );

  if (!Array.isArray(children) || childrenCount === 0 || isLoading) {
    return children ? <>{children}</> : null;
  }

  if (maxReached) {
    return <NoOptionsMessage innerProps={{}} {...props} />;
  }

  return (
    <FixedSizeList
      height={optionsHeight}
      itemCount={optionsCount}
      itemSize={OPTION_ITEM_HEIGHT}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }): JSX.Element => <div style={style}>{children[index]}</div>}
    </FixedSizeList>
  );
};

const Option = <T extends InternalOptionsType>({
  children,
  innerProps,
  isSelected,
  isTickLeft,
  ...props
}: OptionProps<T> & { isTickLeft?: boolean }) => {
  const { onMouseMove, onMouseOver, ...restInnerProps } = innerProps;
  const newProps = {
    isSelected,
    ...props,
    innerProps: {
      ...restInnerProps,
      onMouseMove: () => undefined,
      onMouseOver: () => undefined,
    },
  };

  const Tick: JSX.Element = <IconTick width={16} height={16} />;

  return (
    <StyledOption {...newProps} className="advancedSelect--option">
      {isSelected && isTickLeft && <IconWrap>{Tick}</IconWrap>}
      <ContentWrap>{children}</ContentWrap>
      {isSelected && !isTickLeft && <IconWrap position="right">{Tick}</IconWrap>}
    </StyledOption>
  );
};

const StyledOption = styled(SelectComponents.Option)`
  display: flex !important;
  align-items: center;
`;
const IconWrap = styled.span<{ position?: 'left' | 'right' }>`
  display: flex;
  margin-right: 6px;
  align-items: center;
  justify-content: ${({ position }) => (position === 'right' ? 'flex-start' : 'flex-end')};
`;
const ContentWrap = styled.div`
  flex-grow: 1;
`;

type GroupOptionType<T = InternalOptionsType> = T & {
  options?: T[];
};
type InternalOptionsType = {
  label?: string | JSX.Element;
  value?: string;
};
export type AdvancedSelectOptionType = GroupOptionType;
export type AdvancedSelectSingleOptionType = InternalOptionsType;

export type MenuPlacement = 'auto' | 'bottom' | 'top';
export type LoadOptionCallbackType = (
  inputValue?: string,
  callback?: () => InternalOptionsType[],
) => number | Promise<InternalOptionsType[]> | void;

type GetMultiValueLabelFunc<T extends InternalOptionsType> = (
  count: number,
  props: ValueContainerProps<T>,
) => string;
type PropsType<OptionType extends InternalOptionsType> = DataPropsType & {
  allowCreate?: boolean;
  allowSelectAll?: boolean;
  async?: boolean;
  autoFocus?: boolean;
  backspaceRemovesValue?: boolean;
  blurInputOnSelect?: boolean;
  captureMenuScroll?: boolean;
  closeMenuOnScroll?: boolean;
  closeMenuOnSelect?: boolean;
  defaultInputValue?: string;
  defaultMenuIsOpen?: boolean;
  defaultValue?: OptionType | OptionType[] | string;
  error?: boolean;
  escapeClearsValue?: boolean;
  getMultiValueLabel?: GetMultiValueLabelFunc<OptionType>;
  hideSelectedOptions?: boolean;
  id?: string;
  inputId?: string;
  inputValue?: string;
  isClearable?: boolean;
  isConnectedLeft?: boolean;
  isConnectedRight?: boolean;
  isDisabled?: boolean;
  isFocused?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  /**
   * Decide whether the tick appears in left side or right side. Default is left side.
   */
  isTickLeft: boolean;
  label?: string;
  loadOptions?: LoadOptionCallbackType;
  loadingMessage?: string;
  /**
   * String or a callback with itemCount to show a custom message.
   * @param itemCount
   */
  maxItemsSelectedMessage?: string | ((itemCount: number) => string);
  /**
   * Maximum number of items to be selected if multiselect
   */
  maxMultiSelectItems?: number;
  menuIsOpen?: boolean;
  menuPlacement?: MenuPlacement;
  menuShouldBlockScroll?: boolean;
  multiSelect?: boolean;
  multiValueLabelLimit?: number;
  noOptionsMessage?: string;
  onBlur?: (selectedOption: SelectedOptionType<OptionType>) => void;
  onChange?: (selectedOption: SelectedOptionType<OptionType>) => void;
  onCreateOption?: (value: string) => void;
  onFocus?: (selectedOption: SelectedOptionType<OptionType>) => void;
  onInputChange?: () => void;
  onMenuClose?: () => void;
  onMenuOpen?: () => void;
  onMountInputElement?: (input: HTMLInputElement) => void;
  openMenuOnClick?: boolean;
  openMenuOnFocus?: boolean;
  options?: GroupOptionType<OptionType>[] | OptionType[];
  /**
   * **Deprecated** Use placeholder instead
   * @deprecated
   */
  placeHolder?: string;
  placeholder?: string;
  selectAllLabel?: string;
  selectAllValue?: string;
  selectProps?: SelectProps<OptionType>;
  selectRef?: React.RefObject<ReactSelectStateManager>;
  showDropdownIndicator?: boolean;
  showSeparator?: boolean;
  value?: ValueType<OptionType>;
};

export type SelectedOptionType<T extends InternalOptionsType = InternalOptionsType> = ValueType<T>;
type StateType<T extends InternalOptionsType> = {
  maxReached: boolean;
  selectedOption: SelectedOptionType<T>;
};

export type ReactSelectStateManager = {
  select: {
    controlRef?: HTMLDivElement;
    focusedOptionRef?: HTMLDivElement;
    inputRef?: HTMLInputElement;
    menuListRef?: HTMLDivElement;
  };
};

export default class AdvancedSelect<
  OptionType extends InternalOptionsType = InternalOptionsType,
> extends React.Component<PropsType<OptionType>, StateType<OptionType>> {
  static defaultProps = {
    showSeparator: false,
    showDropdownIndicator: true,
    placeholder: 'Select...',
    isSearchable: false,
    isTickLeft: true,
    isClearable: false,
    async: false,
    options: [],
    onBlur: () => {},
    maxItemsSelectedMessage: (itemCount: number): string =>
      `You are limited to ${itemCount} selections`,
    loadingMessage: 'Loading...',
    allowCreate: false,
    allowSelectAll: false,
    selectAllLabel: 'Select all',
    selectAllValue: '*',
    getMultiValueLabel: (count: number): string => `${count} selected`,
  };

  constructor(props: PropsType<OptionType>) {
    super(props);
    this.state = {
      selectedOption: this.getSelectedOption(),
      maxReached: false,
    };
  }

  componentDidMount() {
    const { defaultValue, value, maxMultiSelectItems, data } = this.props;
    let valueCount = 0;
    if (defaultValue && Array.isArray(defaultValue)) {
      valueCount = defaultValue.length;
    }
    if (value && Array.isArray(value)) {
      valueCount = value.length;
    }

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ maxReached: valueCount >= maxMultiSelectItems });

    const searchInput = this.getSearchInputElement();
    if (searchInput) {
      const dataAttrs = getDataAttributes(data);
      Object.keys(dataAttrs).forEach((key: string) => {
        searchInput.setAttribute(key, dataAttrs[key]);
      });
      if (this.props.onMountInputElement) {
        this.props.onMountInputElement(searchInput);
      }
    }
  }

  reactSelectRef = React.createRef<ReactSelectStateManager>();

  getReactSelectRef(): React.RefObject<ReactSelectStateManager> {
    return this.props.selectRef || this.reactSelectRef;
  }

  handleChange = (selectedOption?: SelectedOptionType<OptionType>) => {
    const { maxMultiSelectItems, options, selectAllValue, onChange } = this.props;

    const maxReached = Array.isArray(selectedOption)
      ? selectedOption.length >= maxMultiSelectItems
      : false;

    if (
      !maxReached &&
      selectedOption &&
      Array.isArray(selectedOption) && // is array for multiselect
      selectedOption.find((x: OptionType): boolean => x.value === selectAllValue)
    ) {
      // Set "all options" when Select All is selected
      selectedOption = options;
    }

    this.setState({ selectedOption, maxReached }, () => {
      if (onChange) {
        onChange(selectedOption);
      }
    });
  };

  handleBlur = () => {
    const { onBlur } = this.props;
    const { selectedOption } = this.state;
    if (onBlur) {
      onBlur(selectedOption);
    }
  };

  handleFocus = () => {
    const { onFocus } = this.props;
    const { selectedOption } = this.state;
    if (onFocus) {
      onFocus(selectedOption);
    }
  };

  noOptionsMessage = (): string => {
    const { maxMultiSelectItems, maxItemsSelectedMessage, noOptionsMessage } = this.props;
    const { maxReached } = this.state;
    if (maxReached) {
      return typeof maxItemsSelectedMessage === 'function'
        ? maxItemsSelectedMessage(maxMultiSelectItems)
        : maxItemsSelectedMessage;
    }
    return noOptionsMessage;
  };

  getDefaultValue() {
    const { defaultValue, options } = this.props;
    if (typeof defaultValue === 'string') {
      return options.find((x) => x.value === defaultValue);
    }
    return defaultValue || null;
  }

  getSelectedOption = (): OptionType | undefined => {
    const { value, options } = this.props;
    return (
      value &&
      options.find((option: OptionType): boolean => option.value === (value as OptionType).value)
    );
  };

  /**
   * Always returns an array regardless of selection
   */
  getSelectedOptions(): OptionType[] {
    const { selectedOption } = this.state;
    if (Array.isArray(selectedOption)) {
      return selectedOption;
    }
    if (selectedOption) {
      return [selectedOption as OptionType];
    }
    return [];
  }

  /**
   * If max items are selected in async mode, return the message instead of loading message.
   * @return {function(): string}
   */
  getLoadingMessage = (): string => {
    const { maxReached } = this.state;
    const { maxMultiSelectItems, maxItemsSelectedMessage, loadingMessage } = this.props;
    if (maxReached) {
      return typeof maxItemsSelectedMessage === 'function'
        ? maxItemsSelectedMessage(maxMultiSelectItems)
        : maxItemsSelectedMessage;
    }
    return loadingMessage;
  };

  /**
   * Returns the HTMLInputElement from the internal react-select component.
   */
  getSearchInputElement(): HTMLInputElement | undefined {
    const ref = this.getReactSelectRef().current;
    if (!ref || !ref.select) {
      return undefined;
    }
    // @ts-expect-error react-select Creatable somehow wraps select inside another select
    const { select } = ref.select;
    if (select) {
      return select.inputRef;
    }
    return ref.select.inputRef;
  }

  render(): JSX.Element {
    const { maxReached } = this.state;
    const {
      selectProps: ReactSelectProps,
      allowCreate,
      async,
      autoFocus,
      backspaceRemovesValue,
      blurInputOnSelect,
      captureMenuScroll,
      closeMenuOnScroll,
      closeMenuOnSelect,
      defaultInputValue,
      defaultMenuIsOpen,
      escapeClearsValue,
      id,
      inputValue,
      isClearable,
      isDisabled,
      isFocused,
      isLoading,
      isSearchable,
      isTickLeft,
      getMultiValueLabel,
      hideSelectedOptions,
      inputId,
      menuIsOpen,
      menuPlacement,
      menuShouldBlockScroll,
      multiSelect,
      multiValueLabelLimit,
      onInputChange,
      onCreateOption,
      onMenuClose,
      onMenuOpen,
      openMenuOnClick,
      openMenuOnFocus,
      options,
      placeholder,
      placeHolder,
      value,
      allowSelectAll,
      selectAllLabel,
      selectAllValue,
    } = this.props;

    const valueContainer = (props: ValueContainerProps<OptionType>): JSX.Element => (
      <ValueContainer
        {...props}
        getMultiValueLabel={getMultiValueLabel}
        multiValueLabelLimit={multiValueLabelLimit}
      />
    );

    const valueItem = {
      ValueContainer: valueContainer,
    };

    const coreComponents: SelectComponentsConfig<OptionType> = {
      DropdownIndicator,
      ClearIndicator,
      Option,
      IndicatorsContainer,
      ...(allowSelectAll ? valueItem : null),
    };

    const getCoreProps = (theme: DefaultTheme): SelectProps<OptionType> => ({
      ...ReactSelectProps,
      'aria-labelledby': id,
      autoFocus,
      backspaceRemovesValue,
      blurInputOnSelect,
      captureMenuScroll,
      closeMenuOnScroll,
      closeMenuOnSelect,
      defaultInputValue,
      defaultMenuIsOpen,
      defaultValue: this.getDefaultValue(),
      escapeClearsValue,
      filterOption: createFilter({ ignoreAccents: false }),
      hideSelectedOptions,
      ignoreAccents: false,
      inputId,
      inputValue,
      isDisabled,
      isLoading,
      isFocused,
      isSearchable,
      isTickLeft,
      isClearable,
      isMulti: multiSelect,
      menuIsOpen,
      menuShouldBlockScroll,
      onBlur: this.handleBlur,
      onChange: this.handleChange,
      onCreateOption,
      onFocus: this.handleFocus,
      onInputChange,
      onMenuClose,
      onMenuOpen,
      openMenuOnClick,
      openMenuOnFocus,
      menuPlacement,
      noOptionsMessage: this.noOptionsMessage,
      placeholder: placeholder || placeHolder,
      value,
      styles: getCustomStyle({ ...this.props, theme }),
    });

    // @ts-ignore   We can't guarantee that this type matches the subtype passed in
    const selectAllOption: OptionType = {
      value: selectAllValue,
      label: <SelectAllContainer>{selectAllLabel}</SelectAllContainer>,
    };

    const selectOptions = [...options];
    if (allowSelectAll) {
      // Don't show the "Select all" option when everything has been selected
      const allSelected = value && options && (value as OptionType[]).length === options.length;
      if (!allSelected) {
        selectOptions.unshift(selectAllOption);
      }
    }

    const getSelectProps = (theme: DefaultTheme): SelectProps<OptionType> => ({
      ...getCoreProps(theme),
      ref: this.getReactSelectRef(),
      options: maxReached ? this.getSelectedOptions() : selectOptions,
      components: {
        ...coreComponents,
      },
    });

    const getAsyncSelectProps = (
      theme: DefaultTheme,
    ): AsyncProps<OptionType> & SelectProps<OptionType> => ({
      ...getCoreProps(theme),
      cacheOptions: !maxReached, // Don't cache the items when max reached
      // @ts-ignore   Don't know why we change the loadOptions func when maxReached, needs to be fixed
      loadOptions: maxReached ? this.getSelectedOption : this.props.loadOptions,
      loadingMessage: this.getLoadingMessage,
      defaultOptions: selectOptions,
      components: {
        ...coreComponents,
        LoadingIndicator: (props: LoadingIconProps<OptionType>): JSX.Element => (
          <LoadingIndicator {...props} maxReached={maxReached} />
        ),
        NoOptionsMessage,
        MenuList: (props: MenuListComponentProps<OptionType>) => (
          <MenuList
            {...props}
            maxReached={maxReached}
            maxMultiSelectItems={this.props.maxMultiSelectItems}
            isAsync
          />
        ),
      },
    });

    const getSelectComponent = (theme: DefaultTheme) => {
      if (async) {
        if (allowCreate) {
          return <AsyncCreatable {...getAsyncSelectProps(theme)} />;
        }
        return <Async {...getAsyncSelectProps(theme)} />;
      }
      if (allowCreate) {
        return <Creatable {...getSelectProps(theme)} />;
      }
      return <Select {...getSelectProps(theme)} />;
    };

    return (
      <ThemeConsumer>
        {(theme) => (
          <SelectGroup spacing="4px" height="fit-content">
            {this.props.label && <Label htmlFor={this.props.id}>{this.props.label}</Label>}
            {getSelectComponent(theme)}
          </SelectGroup>
        )}
      </ThemeConsumer>
    );
  }
}

function getCustomStyle<T extends InternalOptionsType>({
  error,
  showSeparator,
  showDropdownIndicator,
  isConnectedLeft,
  isConnectedRight,
  isFocused,
  theme,
}: PropsType<T> & { theme: DefaultTheme }): StylesConfig {
  const hoverStyle = { border: theme.form.border, cursor: 'pointer' };
  const focusedStyle = { border: theme.form.focusedBorder, boxShadow: 'none', padding: '0px' };
  const errorStyle = { border: theme.border.errorBorder, boxShadow: 'none', padding: '0px' };
  const inputStyle = { height: 'unset !important' };

  return {
    control: (styles, { isSelected, isDisabled }) => {
      styles.border = theme.form.border;
      styles.borderRadius = getBorderRadius(isConnectedLeft, isConnectedRight);
      styles.padding = '1px';
      if (error) {
        styles.border = errorStyle.border;
        styles.padding = errorStyle.padding;
      }
      if (isFocused || isSelected) {
        styles.border = focusedStyle.border;
        styles.boxShadow = focusedStyle.boxShadow;
      }
      const borderStyles = error ? errorStyle : focusedStyle;
      const borderHoverStyles = error ? errorStyle : hoverStyle;
      return {
        ...styles,
        backgroundColor: isDisabled
          ? theme.form.inactiveBackgroundColor
          : theme.form.backgroundColor,
        color: theme.form.color,
        minHeight: '48px',
        height: 'auto',
        margin: '0px',
        'div>input': { ...inputStyle },
        ':hover': { ...borderHoverStyles },
        ':focus': { ...borderStyles },
        ':focus-within': { ...borderStyles },
        ':active': { ...borderStyles },
      };
    },
    menu: (styles) => ({
      ...styles,
      backgroundColor: theme.form.backgroundColor,
    }),
    option: (
      styles,
      {
        isDisabled,
        data, // for createable https://github.com/JedWatson/react-select/issues/3377
        isSelected,
      },
    ) => ({
      ...styles,
      backgroundColor: isDisabled
        ? theme.form.inactiveBackgroundColor
        : isSelected
        ? theme.form.selectedBackgroundColor
        : theme.form.backgroundColor,
      color: isDisabled
        ? theme.form.inactiveColor
        : // eslint-disable-next-line no-underscore-dangle
        data.__isNew__
        ? theme.text.primary
        : theme.form.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      // eslint-disable-next-line no-underscore-dangle
      fontWeight: data.__isNew__ ? 'bold' : 'normal',
    }),
    input: (styles) => ({
      ...styles,
      color: theme.form.color,
      margin: '0',
      padding: '8px 4px',
    }),
    placeholder: (styles) => ({
      ...styles,
      color: theme.form.hint.color,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: theme.form.color,
      padding: '8px 0',
      borderColor: theme.border.color.focused,
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: '0 8px',
      minHeight: '32px',
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      color: theme.form.color,
      display: showSeparator ? 'block' : 'none',
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: theme.form.color,
      display: showDropdownIndicator ? 'block' : 'none',
    }),
    clearIndicator: (styles) => ({
      ...styles,
      color: theme.form.color,
      display: 'block',
      height: '42px',
    }),
    noOptionsMessage: (styles) => ({
      ...styles,
      color: theme.form.color,
      textAlign: 'left',
    }),
    loadingMessage: (styles) => ({
      ...styles,
      color: theme.form.color,
      textAlign: 'left',
    }),
  };
}

const getBorderRadius = (isLeft: boolean, isRight: boolean): string => {
  if (isLeft) {
    return '4px 0 0 4px';
  }
  if (isRight) {
    return '0 4px 4px 0';
  }
  return '4px';
};

const SelectGroup = styled(Group)`
  .advancedSelect--option:hover {
    background-color: ${({ theme }) => theme.form.hoverBackgroundColor};
    cursor: pointer;
  }
`;

const SelectAllContainer = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.border.defaultBorder};
  padding: 8px 12px;
  margin: -8px -12px;
`;
