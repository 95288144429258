import React from 'react';
import styled, { css } from 'styled-components';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import Group from '../Group';
import Loading from '../Loading';
import { colors } from '../../themes';
import { getVariationName } from '../../utils/getVariationName';
import { getDataAttributes, DataPropsType } from '../../utils/dataAttributes';
import { AnchorAttrsType, getAnchorAttributes } from '../../utils/anchorAttributes';
import { declareVariants } from '../../utils/declareVariants';

const ButtonComponent = styled.button<ButtonPropsType>`
  &&& {
    font-family: ${({ theme }) => theme.fonts.sans};
    font-size: ${({ theme }) => theme.fontSizes.base};
    line-height: ${({ theme }) => theme.lineHeights.base};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 48px;
    border-radius: ${({ theme }) => theme.radii.default};
    border-width: ${({ theme }) => theme.borderWidths.default};
    border-style: solid;
    padding: 0px ${({ theme }) => theme.space.s16};
    margin: 0;
    -webkit-font-smoothing: inherit; // Override existing Kounta css

    // Link styles
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }

    &.fullWidth {
      width: 100%;
    }

    &.slim {
      height: 32px;
    }

    &.large {
      padding: ${({ theme }): string => theme.space.s8} ${({ theme }): string => theme.space.s16};
      height: 72px;
    }

    ${declareVariants({
      destructive: css`
        color: ${({ theme }): string => theme.button.destructive.color};
        background-color: ${({ theme }): string => theme.button.destructive.backgroundColor};
        border-color: ${({ theme }) => theme.button.destructive.borderColor};
        &:hover&:not(.mobile) {
          color: ${({ theme }): string => theme.button.destructive.hoverColor};
          background-color: ${({ theme }): string => theme.button.destructive.hoverBackgroundColor};
          border-color: ${({ theme }): string => theme.button.destructive.hoverBorderColor};
        }
      `,

      secondary: css`
        color: ${({ theme }): string => theme.button.secondary.color};
        background-color: ${({ theme }): string => theme.button.secondary.backgroundColor};
        border-color: ${({ theme }): string => theme.button.secondary.borderColor};
        &:hover&:not(.mobile) {
          color: ${({ theme }): string => theme.button.secondary.hoverColor};
          background-color: ${({ theme }): string => theme.button.secondary.hoverBackgroundColor};
          border-color: ${({ theme }): string => theme.button.secondary.hoverBorderColor};
        }
      `,

      primary: css`
        color: ${({ theme }): string => theme.button.primary.color};
        background-color: ${({ theme }): string => theme.button.primary.backgroundColor};
        border-color: ${({ theme }): string => theme.button.primary.borderColor};
        &:hover&:not(.mobile) {
          color: ${({ theme }): string => theme.button.primary.hoverColor};
          background-color: ${({ theme }): string => theme.button.primary.hoverBackgroundColor};
          border-color: ${({ theme }): string => theme.button.primary.hoverBorderColor};
        }
      `,
    })}

    &:focus {
      outline: none;
    }

    &.loading,
    &.disabled {
      cursor: not-allowed;
    }

    &.loading {
      position: relative;
    }

    &.disabled {
      opacity: 0.5;
    }
  }
`;

type Variants = 'primary' | 'secondary' | 'destructive';

export type ButtonPropsType = (DataPropsType & AnchorAttrsType) & {
  /**
   * @deprecated
   * Never pass className to a Chameleon component
   */
  className?: string;
  /**
   * **Deprecated** Use variant instead
   * @deprecated
   */
  destructive?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  icon?: JSX.Element | null;
  large?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /**
   * **Deprecated** Use variant instead
   * @deprecated
   */
  primary?: boolean;
  /**
   * **Deprecated** Use variant instead
   * @deprecated
   */
  secondary?: boolean;
  showLoading?: boolean;
  slim?: boolean;
  type?: 'submit' | 'reset' | 'button';
  variant?: Variants;
};

export const Button: React.FunctionComponent<React.PropsWithChildren<ButtonPropsType>> = ({
  children,
  className = '',
  destructive = false,
  disabled = false,
  fullWidth = false,
  icon = null,
  large = false,
  onClick,
  primary = false,
  secondary = false,
  showLoading = false,
  slim = false,
  type,
  variant = 'secondary',
  data,
  ...restProps
}) => {
  const variationName = getVariationName<Variants>({ primary, secondary, destructive }, variant);
  const buttonClasses = classNames(
    slim ? 'slim' : '',
    fullWidth ? 'fullWidth' : '',
    disabled ? 'disabled' : '',
    showLoading ? 'loading' : '',
    large ? 'large' : '',
    isMobile ? 'mobile' : '',
    className,
  );

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (disabled || showLoading) {
      return;
    }
    if (onClick) {
      onClick(e);
    }
  };

  const getButtonContent = (): React.ReactNode => {
    if (icon) {
      return (
        <Group horizontal spacing="4px">
          {icon}
          {children}
        </Group>
      );
    }
    if (showLoading) {
      return (
        <>
          <LoadingWrap>
            <Loading
              showTitle={false}
              overlay={false}
              size={20}
              color={variationName === 'secondary' ? 'interactive' : colors.white}
            />
          </LoadingWrap>
          <LoadingChildrenWrap>{children}</LoadingChildrenWrap>
        </>
      );
    }

    return children;
  };

  const { href } = restProps;
  /* Empty string is a valid href */
  const isAnchor = !!href || href === '';
  /* For unknown historical reasons, type defaults to submit instead of button. */
  const buttonType = typeof type !== 'undefined' ? type : 'submit';
  /* We don't want to prevent the default behaviour for links, which is what handleClick does */
  const onClickHandler = isAnchor && typeof onClick !== 'function' ? undefined : handleClick;

  return (
    <ButtonComponent
      as={isAnchor ? 'a' : 'button'}
      className={buttonClasses}
      /* We shouldn't add an onclick handler for links unless explicitly added */
      onClick={onClickHandler}
      /* Type is a valid property for button and anchor */
      type={isAnchor ? type : buttonType}
      disabled={disabled}
      variant={variationName}
      {...getAnchorAttributes(restProps)}
      {...getDataAttributes(data)}
    >
      {getButtonContent()}
    </ButtonComponent>
  );
};

export default Button;

const LoadingWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const LoadingChildrenWrap = styled.div`
  visibility: hidden;
`;
