import React, { Component } from 'react';
import TextInput, { PropsType as TextInputProps } from '../TextInput/TextInput';
import {
  validateInput,
  mixMaxReached,
  constructStringValue,
  valueMatchesFormat,
  valueInRange,
} from './numberUtils';
import { DataPropsType } from '../../utils/dataAttributes';

export type PropsType = Omit<TextInputProps, 'value' | 'onChange'> &
  DataPropsType & {
    allowNegative?: boolean;
    connectedElementWidth?: string;
    connectedLeft?: JSX.Element | null;
    connectedRight?: JSX.Element | null;
    /**
     * Delimiter for formatted number
     */
    delimiter?: string;
    error?: boolean | null;
    errorMessage?: string;
    errorMessagesAllowed?: boolean;
    helperText?: string;
    id?: string;
    initialValue?: string | number;
    inputRef?: React.RefObject<HTMLInputElement>;
    label?: string;
    maxFractionLength?: number;
    maxMixedLength?: number;
    maxValue?: number | null;
    /** @ignore */
    maxWidth?: string;
    minFractionLength?: number;
    minMixedLength?: number;
    minValue?: number | null;
    name?: string;
    onChange?: (value: string, isNumberValid: boolean) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    placeholder?: string;
    /**
     * By turning this on, the input type changes to 'text' and props like step won't work.
     */
    preventInvalidInput?: boolean;
    readOnly?: boolean;
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/number#Allowing_decimal_values
    step?: number | null;
    value?: string | number;
  };

type StateType = {
  value: string;
};

export const DEFAULT_MIN_MIXED_LENGTH = 1;
export const DEFAULT_MAX_MIXED_LENGTH = 12;
export const DEFAULT_MIN_FRACTION_LENGTH = 0;
export const DEFAULT_MAX_FRACTION_LENGTH = 4;
export const DEFAULT_STEP = 0.0001;

const NUMERIC_REGEX = /^-?\d*\.?\d*$/;

const ERROR_INPUT_STR = 'The number you entered is not correct.';

/**
 * **Deprecated** Should use `DecimalInput` instead.
 * @deprecated
 */
export default class NumberInput extends Component<PropsType, StateType> {
  static defaultProps = {
    initialValue: '',
    inputRef: null,
    value: '',
    helperText: '',
    label: '',
    minMixedLength: DEFAULT_MIN_MIXED_LENGTH,
    maxMixedLength: DEFAULT_MAX_MIXED_LENGTH,
    minFractionLength: DEFAULT_MIN_FRACTION_LENGTH,
    maxFractionLength: DEFAULT_MAX_FRACTION_LENGTH,
    step: DEFAULT_STEP,
    allowNegative: true,
    onChange: () => {},
    onKeyPress: () => {},
    onKeyDown: () => {},
    placeholder: '',
    errorMessagesAllowed: false,
    errorMessage: ERROR_INPUT_STR,
    error: undefined,
    delimiter: ',',
  };

  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({ value: String(this.props.value || this.props.initialValue) });
  }

  componentDidUpdate(prevProps: PropsType) {
    if (
      this.props.value != null &&
      this.props.value !== prevProps.value &&
      this.props.value !== this.state.value
    ) {
      // this is an antipattern and should never be implemented again. It exists only to support legacy behaviour
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ value: String(this.props.value) });
    }
  }

  onChangeHandler = (value: string) => {
    this.setState({
      value,
    });
    if (this.props.onChange) {
      this.props.onChange(value, this.isNumberValid(value));
    }
  };

  onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const {
      allowNegative,
      preventInvalidInput,
      onKeyDown,
      minFractionLength,
      maxFractionLength,
      maxMixedLength,
      delimiter,
      minValue,
      maxValue,
    } = this.props;
    const { value } = this.state;
    const inputEl = e.target as HTMLInputElement;

    if (preventInvalidInput) {
      /**
       * Prevent default for following cases if current key is .
       *  - Already includes a decimal sign
       *  - Negative sign following a decimal sign
       *  - Max whole numbers reached
       *  - Negative sign after entered value
       */
      if (e.key === '.') {
        if (
          value &&
          (value.includes('.') ||
            value === '-' ||
            mixMaxReached(value, maxMixedLength) ||
            maxFractionLength <= 0)
        ) {
          e.preventDefault();
          return;
        }
      }

      if (e.key === '-') {
        if (!allowNegative) {
          e.preventDefault();
          return;
        }
        // Handles auto negative sign in beginning
        const negatedValue = Number(value) * -1;

        const decimalValue = constructStringValue(
          negatedValue,
          minFractionLength,
          maxFractionLength,
          delimiter,
        );
        if (decimalValue && valueInRange(decimalValue, minValue, maxValue)) {
          this.setState({ value: decimalValue });
        }

        // We handled this input of (-) so we stop the event from bubbling
        if (value) {
          // Allows for '-' to pass
          e.preventDefault();
          return;
        }
      }

      // Input is a number
      const inputNumber = parseFloat(e.key);
      if (Number.isFinite(inputNumber)) {
        // Pad with zero if necessary
        if (value && value[0] === '.') {
          const zeroPaddedValue = `0${value}`;
          this.setState({ value: zeroPaddedValue });
          e.preventDefault();
          return;
        }

        const validInput = validateInput(
          e.key,
          inputEl.selectionStart,
          value,
          maxFractionLength,
          maxMixedLength,
          minValue,
          maxValue,
        );

        if (!validInput) {
          // ignore if it is not a valid input
          e.preventDefault();
          return;
        }

        this.setState({ value });
      }
    }

    onKeyDown(e);
  };

  isNumberValid = (numValue: string): boolean => {
    // if error property is set, use external number validation result
    if (this.props.error !== undefined) {
      return !this.props.error;
    }

    const {
      minValue,
      maxValue,
      allowNegative,
      minMixedLength,
      maxMixedLength,
      minFractionLength,
      maxFractionLength,
    } = this.props;

    if (numValue || numValue === '') {
      const value = numValue.trim();
      return (
        valueInRange(value, minValue, maxValue) &&
        valueMatchesFormat(
          value,
          allowNegative,
          minMixedLength,
          maxMixedLength,
          minFractionLength,
          maxFractionLength,
        )
      );
    }
    return false;
  };

  getErrorMessages = (): string[] => {
    if (this.props.errorMessagesAllowed && !this.isNumberValid(this.state.value)) {
      return [this.props.errorMessage];
    }
    return [];
  };

  onWheel = (e: WheelEvent) => e.preventDefault();

  onBlur = (value: string) => {
    this.isNumberValid(value);
  };

  render() {
    const {
      id,
      name,
      helperText,
      label,
      preventInvalidInput,
      placeholder,
      step,
      initialValue,
      inputRef,
      maxWidth,
    } = this.props;

    const { value: stateValue } = this.state;

    return (
      <TextInput
        {...this.props}
        inputRef={inputRef}
        initialValue={String(initialValue)}
        inputFieldId={id}
        inputFieldName={name}
        label={label}
        value={stateValue}
        onChange={this.onChangeHandler}
        error={!this.isNumberValid(stateValue)}
        errorTextList={this.getErrorMessages()}
        placeholder={placeholder}
        helperText={helperText}
        inputHtmlType={preventInvalidInput ? 'text' : 'number'}
        inputMode="decimal"
        pattern={NUMERIC_REGEX}
        title="Please enter numbers only."
        onWheel={this.onWheel}
        onKeyDown={this.onKeyDown}
        step={step}
        maxWidth={maxWidth}
      />
    );
  }
}
