import React from 'react';
import IconStyle from '../Icon/IconStyle';
import IconPropsType from '../Icon/types';
import IconSvg from './IconColorPicker.svg';

const IconColorPicker: React.FunctionComponent<IconPropsType> = (props) => {
  return (
    <IconStyle {...props}>
      <IconSvg
        {...props}
        aria-label="colorpicker"
        data-attribute="Color Picker by Roselin Christina.S from thenounproject.com"
      />
    </IconStyle>
  );
};

export default IconColorPicker;
