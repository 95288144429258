import React, { Component } from 'react';
import DayPicker, { DayModifiers, DayPickerProps } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import DateContainer from './DateContainer';
import DateNavbar from './DateNavbar';

type PropsType = {
  date?: Date;
  onDateChange?: (date: Date, modifiers: DayModifiers) => void;
  onFocusChange?: (focused: boolean) => void;
};

type StateType = {
  selectedDate?: Date | null;
};

export default class DatePicker extends Component<PropsType & DayPickerProps, StateType> {
  static defaultProps = {
    initialDate: new Date(),
    onDateChange: () => {},
    onFocusChange: () => {},
  };

  constructor(props: PropsType) {
    super(props);
    this.state = {
      selectedDate: this.props.date,
    };

    this.onDateChange = this.onDateChange.bind(this);
  }

  onDateChange = (date: Date, modifiers: DayModifiers) => {
    const { disabled } = modifiers;
    if (disabled) {
      return;
    }
    this.setState({ selectedDate: date });
    this.props.onDateChange(date, modifiers);
  };

  onFocusChange = (focused: boolean) => {
    this.props.onFocusChange(focused);
  };

  render(): JSX.Element {
    return (
      <DateContainer>
        <DayPicker
          onDayClick={this.onDateChange}
          selectedDays={this.state.selectedDate}
          navbarElement={<DateNavbar />}
          {...this.props}
        />
      </DateContainer>
    );
  }
}
