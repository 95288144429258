import styled from 'styled-components';
import React from 'react';

const StyledDivider = styled.div`
  width: 100%;
  border: ${({ theme }): string => theme.divider.border};
`;

/**
 * A divider is used to separate two or more sections in page.

 It is best to include a Spacer before and after the divider to help make that separation clearer.
 */
export const Divider: React.FunctionComponent<unknown> = () => <StyledDivider />;

export default Divider;
