import React from 'react';
import styled from 'styled-components';
import Overlay from '../Overlay';
import Paragraph from '../Paragraph';
import ScreenReaderOnly from '../ScreenReaderOnly';
import { IconSpinner } from '../Icon';

type PropsType = LoadingProps & {
  isLoading?: boolean;
  overlay?: boolean;
};

type LoadingProps = {
  color?: string;
  showTitle?: boolean;
  size?: number;
  title?: string;
  usedOnDarkBackground?: boolean;
};

const LoadingDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

const Spinner = styled.div<{ size: number }>`
  animation: rotation 1.2s cubic-bezier(0.25, 0.8, 0.65, 0.6) infinite;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const LoadingSpinner: React.FunctionComponent<LoadingProps> = ({
  size = 40,
  color = 'interactive',
  showTitle = true,
  title = 'Loading',
  usedOnDarkBackground = true,
}) => {
  return (
    <LoadingDiv>
      <Spinner size={size}>
        <IconSpinner width={size} height={size} color={color} />
      </Spinner>
      {showTitle ? (
        <Paragraph color={usedOnDarkBackground ? 'white' : undefined}>{title}</Paragraph>
      ) : (
        <ScreenReaderOnly>{title}</ScreenReaderOnly>
      )}
    </LoadingDiv>
  );
};

export const Loading: React.FunctionComponent<PropsType> = ({
  isLoading = true,
  overlay = true,
  ...spinnerProps
}) => {
  if (!isLoading) {
    return null;
  }

  const SpinnerContext = overlay ? Overlay : React.Fragment;

  return (
    <SpinnerContext>
      <LoadingSpinner {...spinnerProps} />
    </SpinnerContext>
  );
};

export default Loading;
