import React from 'react';
// https://github.com/babel/babel/issues/9543
import { Spring } from 'react-spring/renderprops.cjs';
import styled from 'styled-components';
import { transitionsConfig, TransitionConfigType } from '../../themes/foundations/transitions';
import Paragraph from '../Paragraph';
import { IconTick, IconClose } from '../Icon';
import IconButton from '../IconButton';
import Badge from '../Badge';
import { colors } from '../../themes';
import { DataPropsType, getDataAttributes } from '../../utils/dataAttributes';

type PropsType = DataPropsType & {
  /**
   * Sets the time of the animation in milliseconds
   */
  autoHide?: boolean;
  autoHideDuration?: number;
  message?: string | JSX.Element;
  onClose?: () => void;
  /**
   * Toggle close button
   */
  showClose?: boolean;
  showSnackBar?: boolean;
  type?: 'success' | 'error';
};

type TransitionType = {
  bottom: number;
  opacity: number;
};

type StateType = {
  showSnackBar: boolean;
  transition: {
    config?: TransitionConfigType;
    from: TransitionType;
    to: TransitionType;
  };
};

export default class SnackBar extends React.Component<PropsType, StateType> {
  timeouts: Array<unknown>;

  static defaultProps = {
    autoHide: true,
    autoHideDuration: 2500,
    onClose: null,
    showClose: false,
  };

  constructor(props: any) {
    super(props);
    this.state = {
      showSnackBar: true,

      transition: {
        from: { opacity: 0, bottom: 0 },
        to: { opacity: 1, bottom: 40 },
        config: transitionsConfig.bounce,
      },
    };
    this.timeouts = [];
  }

  componentDidMount() {
    if (this.props.autoHide && this.props.autoHideDuration) {
      this.timeouts.push(setTimeout(this.hideSnackBar, this.props.autoHideDuration));
    }
  }

  componentWillUnmount() {
    this.clearTimeouts();
  }

  clearTimeouts = () => {
    this.timeouts.forEach(clearTimeout);
  };

  hideSnackBar = () => {
    if (this.state.showSnackBar) {
      const hideTransition = { from: { opacity: 1, bottom: 40 }, to: { opacity: 0, bottom: 0 } };
      this.setState({
        transition: hideTransition,
      });
      this.timeouts.push(setTimeout(this.closeSnackBar, 1500));
    }
  };

  closeSnackBar = () => {
    this.hideSnackBar();
    this.setState({
      showSnackBar: false,
    });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render(): JSX.Element {
    const message =
      typeof this.props.message !== 'string' ? (
        this.props.message
      ) : (
        <Paragraph>{this.props.message}</Paragraph>
      );
    return (
      <StyledContainer {...getDataAttributes(this.props.data)}>
        <Spring {...this.state.transition}>
          {(SpringStyle: Record<string, any>): JSX.Element => (
            <StyledSnackBar style={SpringStyle}>
              <IconContainer>{icon[this.props.type]}</IconContainer>
              <MessageContainer>{message}</MessageContainer>
              {(this.props.onClose || this.props.showClose) && (
                <IconButton
                  onClick={this.closeSnackBar}
                  data={getDataAttributes(this.props.data, 'close', '')}
                >
                  <IconClose color={colors.gray700} height={16} width={16} />
                </IconButton>
              )}
            </StyledSnackBar>
          )}
        </Spring>
      </StyledContainer>
    );
  }
}

const icon = {
  success: (
    <Badge status="success" circle height={32} width={32}>
      <IconTick height={16} width={16} />
    </Badge>
  ),
  error: (
    <Badge status="alert" circle height={32} width={32}>
      <IconClose height={16} width={16} />
    </Badge>
  ),
};

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledSnackBar = styled.div`
  color: ${({ theme }): string => theme.snackBar.color};
  background-color: ${({ theme }): string => theme.snackBar.background};
  z-index: ${({ theme }): number => theme.zIndex.popup};
  position: fixed;
  padding: 16px 16px 16px 24px;
  border: solid ${({ theme }): string => theme.snackBar.border} 1px;
  border-radius: ${({ theme }): string => theme.snackBar.radius};
  box-shadow: ${({ theme }): string => theme.shadows.default};
  display: flex;
  align-items: center;
`;

const MessageContainer = styled.div`
  margin: 0 16px 0 0;
`;

const IconContainer = styled.div`
  margin: 0 8px 0 0;
`;
