import React, { ReactElement, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import Avatar from '../Avatar';
import IconProfile from '../Icon/IconProfile';
import Paragraph from '../Paragraph';
import Caption from '../Caption';
import TextTruncate from '../TextTruncate';
import { unstyledButtonMixin } from '../../mixins/elements';

export type UserType = {
  avatar?: ReactElement;
  email: string;
  name: string;
  onClick?: () => unknown;
};

const UserRow = ({ avatar, email, name, onClick }: UserType) => {
  const theme = useContext(ThemeContext);
  if (!avatar) {
    avatar = (
      <Avatar size="auto">
        <IconProfile width={24} height={24} color={theme.avatar.color} />
      </Avatar>
    );
  }
  return (
    <UserWrap onClick={onClick}>
      <ContentWrap>
        <AvatarWrap>{avatar}</AvatarWrap>
        <TextWrap>
          <Paragraph>
            <TextTruncate useEllipsis>{name}</TextTruncate>
          </Paragraph>
          <Caption color="text.hint">
            <TextTruncate useEllipsis>{email}</TextTruncate>
          </Caption>
        </TextWrap>
      </ContentWrap>
    </UserWrap>
  );
};

export default UserRow;

const UserWrap = styled.button`
  ${unstyledButtonMixin};
  padding: 12px 8px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.button.hoverBackgroundColor};
  }
`;
const ContentWrap = styled.div`
  display: flex;
  align-items: center;
`;
const AvatarWrap = styled.aside`
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 12px;
`;
const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
