import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import IconButton from '../IconButton';
import { IconClose } from '../Icon';
import Group from '../Group';
import Paragraph from '../Paragraph';
import Caption from '../Caption';

type PropsType = {
  onClick?: () => void;
  onClose?: () => void;
  /** Show a close button on the tag. */
  showClose?: boolean;
  /** Show the tag text in a smaller size. */
  small?: boolean;
};

const Tag: FunctionComponent<React.PropsWithChildren<PropsType>> = ({ onClose, ...props }) => {
  let content = props.children;
  if (typeof props.children === 'string') {
    if (props.small) {
      content = <Caption>{props.children}</Caption>;
    } else {
      content = <Paragraph>{props.children}</Paragraph>;
    }
  }
  return (
    <StyledTag {...props}>
      <StyledGroup horizontal spacing="8px">
        {content}
        {props.showClose && (
          <IconButton
            size="small"
            data={{ chamid: 'CloseIconButton' }}
            onClick={onClose ?? props.onClick}
          >
            <IconClose width={12} height={12} />
          </IconButton>
        )}
      </StyledGroup>
    </StyledTag>
  );
};
export default Tag;

Tag.defaultProps = {
  onClick: () => {},
  small: false,
  showClose: true,
};

const StyledTag = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: ${({ theme }): string => theme.avatar.backgroundColor};
  padding: ${(props: PropsType): string => (props.small ? '0' : '4px')} 8px;
  padding-right: ${(props: PropsType): string => (props.showClose ? '4px' : null)};
  margin: 0 2px;
  border-radius: 4px;
`;
const StyledGroup = styled(Group)`
  min-height: 24px;
`;
