import React from 'react';
import styled from 'styled-components';
import Group from '../Group';
import Logo from '../Logo';

const TopBar = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }): string => theme.border.color.default};
  height: 56px;
  padding: 0px 8px;
  .rightContent {
    margin-left: auto;
  }
`;

type PropsType = {
  leftContent?: JSX.Element | null;
  rightContent?: JSX.Element | null;
};

const PageHeader: React.FunctionComponent<PropsType> = ({ leftContent, rightContent }) => {
  return (
    <TopBar>
      <Group horizontal spacing="4px">
        {leftContent}
        <Logo />
      </Group>
      <div className="rightContent">{rightContent}</div>
    </TopBar>
  );
};

PageHeader.defaultProps = {
  leftContent: null,
  rightContent: null,
};

export default PageHeader;
