import React, { Component } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { IconArrowDown } from '../Icon';
import Group from '../Group';
import Label from '../Label';
import { borderDefault, borderFocused, borderError } from '../../mixins';
import { SpacingTypes } from '../../theme';
import { themeGet } from '../../utils';
import { getDataAttributes, DataPropsType } from '../../utils/dataAttributes';

export type SelectOptionType = {
  id: string | number;
  name: string;
};

const hasSetPadding = (padding?: SpacingTypes): boolean => typeof padding !== 'undefined';

const Container = styled.div<{ disabled?: boolean; padding?: SpacingTypes }>`
  position: relative;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.form.inactiveBackgroundColor : theme.form.backgroundColor};

  select {
    color: ${({ theme, disabled }) => (disabled ? theme.form.inactiveColor : theme.colors.text)};
    appearance: none;
    position: relative;
    padding: ${({ padding, theme }) => themeGet(`space.${padding}`, theme.space.s12)};
    padding-right: 40px;
    height: ${({ padding }) => (hasSetPadding(padding) ? 'auto' : '48px')};
    width: 100%;
    font: ${({ theme }): string => theme.text.body};
    border-radius: ${({ theme }): string => theme.border.radius};
    ${borderDefault};
    background-color: transparent;
    text-overflow: ellipsis;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      ${borderFocused};
    }

    option {
      color: ${({ theme }): string => theme.form.color};
    }

    option[value=''] {
      color: ${({ theme }): string => theme.form.hint.color};
    }
  }

  &.error {
    select {
      ${borderError};
    }
  }

  &.disabled {
    select {
      background-color: ${({ theme }): string => theme.form.inactiveBackgroundColor};
    }

    .arrow {
      opacity: 0.4;
    }
  }
`;

type PropsType = DataPropsType & {
  defaultValue?: string;
  disabled?: boolean;
  error?: boolean;
  id?: string;
  label?: string;
  options?: SelectOptionType[];
  padding?: SpacingTypes;
  placeholder?: string;
  updateSelectedOption?: (selectedOption: SelectOptionType) => void;
  /** Use value to fully control the component. */
  value?: string;
};

type StateType = {
  selectedOption: string;
};

export default class Select extends Component<PropsType, StateType> {
  static defaultProps = {
    placeholder: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedOption: this.props.defaultValue,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: PropsType) {
    this.setState({ selectedOption: nextProps.defaultValue });
  }

  handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = e.target.value;
    this.setState({ selectedOption });

    if (this.props.updateSelectedOption) {
      this.props.updateSelectedOption({ id: selectedOption, name: selectedOption });
    }
  };

  getValue() {
    if (typeof this.props.value !== 'undefined') {
      return this.props.value;
    }
    return this.state.selectedOption;
  }

  render() {
    const options = [];
    const { placeholder, id, label, error, disabled, padding, data } = this.props;
    if (placeholder !== '') {
      options.push(
        <option key="placeholder" value="">
          {this.props.placeholder}
        </option>,
      );
    }
    this.props.options.forEach((option: SelectOptionType) => {
      options.push(
        <option key={option.id} value={option.id}>
          {option.name}
        </option>,
      );
    });

    const classNames = classnames(error ? 'error' : '', disabled ? 'disabled' : '');
    return (
      <Group spacing="4px" height="fit-content">
        {label && <Label htmlFor={id}>{label}</Label>}
        <Container className={classNames} disabled={disabled} padding={padding}>
          <ArrowBox padding={padding} className="arrow">
            <IconArrowDown />
          </ArrowBox>
          <select
            id={id}
            disabled={disabled}
            value={this.getValue()}
            onChange={this.handleChange}
            {...getDataAttributes(data)}
          >
            {options}
          </select>
        </Container>
      </Group>
    );
  }
}

const ArrowBox = styled.div<{ padding?: SpacingTypes }>`
  position: absolute;
  right: calc(${({ padding, theme }) => themeGet(`space.${padding}`, theme.space.s12)} - 2px);
  // Vertically align
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
`;
