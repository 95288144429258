import React, { PureComponent } from 'react';
// https://github.com/babel/babel/issues/9543
import { Spring } from 'react-spring/renderprops.cjs';
import { transitions, TransitionType } from '../../themes/foundations/transitions';
import Page from '../Page';

type PropsType = {
  enterTransition?: TransitionType;
  leaveTransition?: TransitionType;
  onClosed?: () => void;
};

type StateType = {
  isVisible: boolean;
};

export default class Panel extends PureComponent<PropsType, StateType> {
  static defaultProps = {
    enterTransition: { ...transitions.slideUp },
    leaveTransition: { ...transitions.slideDown },
    children: null,
    onClosed: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      isVisible: true,
    };
  }

  close = (): void => this.setState({ isVisible: false });

  handleClose = () => {
    if (this.state.isVisible) return;

    if (this.props.onClosed) {
      this.props.onClosed();
    }
  };

  render(): JSX.Element {
    return (
      <Spring
        {...(this.state.isVisible ? this.props.enterTransition : this.props.leaveTransition)}
        onRest={this.handleClose}
      >
        {(styleProps) => (
          <Page fullscreen style={styleProps}>
            {this.props.children}
          </Page>
        )}
      </Spring>
    );
  }
}
