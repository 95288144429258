import styled from 'styled-components';
import React, { Component } from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import Label from '../Label';

type PropsType = {
  label?: string;
  onChange?: (address: string) => void;
  onSelect?: (address: string, components: any) => void;
};

type StateType = {
  address?: string;
};

const SuggestionsList = styled.div<{ suggestions: any[] }>`
  position: absolute;
  top: 56px;
  border: 1px solid #e3e3e3;
  background: white;
  border-radius: 4px;
  box-shadow: 2px 4px 10px 0px #0000001a;
  padding: 16px;
  z-index: 1;
  display: ${(props): string => (props.suggestions.length > 0 ? 'block' : 'none')};
`;

export default class AddressLookup extends Component<PropsType, StateType> {
  static defaultProps: PropsType = {
    label: '',
    onChange: () => {},
    onSelect: () => {},
  };

  constructor(props: PropsType) {
    super(props);
    this.state = { address: '' };
  }

  handleChange = (address: string) => {
    this.setState({ address });
    if (this.props.onChange) {
      this.props.onChange(address);
    }
  };

  handleSelect = (address: string) => {
    geocodeByAddress(address).then((results: Record<string, any>[]) => {
      this.props.onSelect(results[0].formatted_address, results[0].address_components);
      this.setState({ address: results[0].formatted_address });
    });
  };

  render = (): PlacesAutocomplete => (
    <PlacesAutocomplete
      value={this.state.address}
      onChange={this.handleChange}
      onSelect={this.handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }: Record<string, any>): any => (
        <div style={{ position: 'relative' }}>
          <Label>{this.props.label}</Label>
          <input
            data-chaminputid="addressLookupInput"
            {...getInputProps({
              placeholder: 'Search Places ...',
              className: 'location-search-input',
            })}
          />
          <SuggestionsList suggestions={suggestions}>
            {suggestions.map(
              (suggestion: Record<string, any>): Record<string, any> => {
                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div {...getSuggestionItemProps(suggestion, { className, style })}>
                    <span>{suggestion.description}</span>
                  </div>
                );
              },
            )}
          </SuggestionsList>
        </div>
      )}
    </PlacesAutocomplete>
  );
}
