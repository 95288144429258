import styled, { css } from 'styled-components';
import { colors } from '../../themes';

export type NumberPadButtonType = {
  className?: string;
  id?: string;
  name?: string;
};
const noOverlayCss = css`
  border-color: ${({ theme }) => theme.form.borderColor};
  border-style: solid;
  border-width: 0 1px 0 1px;
`;

type Fill = {
  fillStyle?: boolean;
};

type Overlay = {
  overlay?: boolean;
};

type Show = {
  show?: boolean;
};

type FullWidth = {
  fullWidth?: boolean;
};

type Selected = {
  selected?: boolean;
};

type DoubleWidth = {
  doubleWidth?: boolean;
};

type IsLast = {
  isLast?: boolean;
};

function numberPadWidth({ fillStyle }: Fill): string {
  /* 95px width / button x 4 + 3px margin (1px / button except last) */
  return fillStyle ? '100%' : '380px';
}

function numberPadHeight({ fillStyle }: Fill): string {
  return fillStyle ? '100%' : 'auto';
}

function numberScreenHeight({ fillStyle }: Fill): string {
  return fillStyle ? '20%' : '70px';
}

function showOverlay({ overlay }: Overlay) {
  return overlay ? '' : noOverlayCss;
}

function showComponent({ show }: Show): string {
  return show ? 'flex' : 'none';
}

function numberPadButtonWidth({ fullWidth }: FullWidth): string {
  return fullWidth ? '100%' : '75%';
}

function numberButtonHeight({ fillStyle }: Fill): string {
  return fillStyle ? 'auto' : '83px';
}

function actionColour({ selected }: Selected): string {
  return selected ? colors.blue500 : colors.gray600;
}

function topButtonBarWidth({ doubleWidth, isLast }: DoubleWidth & IsLast): string {
  const rightMargin = isLast ? 0 : 1;
  const width = doubleWidth ? '50%' : '25%';

  return `calc(${width} - ${rightMargin}px)`;
}

function topActionButtonMargin({ isLast }: IsLast): string {
  return isLast ? '0 0 1px 0' : '0 1px 1px 0';
}

export const DialogContainer = styled.div<Fill & Overlay>`
  overflow: hidden;
  background: white;
  outline: 0;
  height: ${numberPadHeight};
  width: ${numberPadWidth};
  margin: auto;
  ${showOverlay}
`;

export const TitleBar = styled.div<Show>`
  width: 100%;
  height: 55px;
  padding: 5px 5px 5px 15px;
  position: relative;
  font-size: 20px;
  line-height: 24px;
  font-weight: 200;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.background.toolbar};
  color: ${({ theme }) => theme.colors.text};
  display: ${showComponent};
  justify-content: space-between;
  align-items: center;
  border-top: solid 1px ${({ theme }) => theme.form.borderColor};
`;

export const CloseButton = styled.div`
  background: url(/themes/kounta/pos/images/sitedesign-sprites-buttonicons.png) no-repeat;
  background-size: 50px 1125px;
  background-position: 5px -272px;
  color: transparent;
  cursor: pointer;
`;

export const SubTitle = styled.div`
  padding: 20px;
  background: whitesmoke;
  span {
    font-weight: bold;
  }
`;

export const NumberPadContainer = styled.div<Fill>`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.form.borderColor};
  width: auto;
  min-height: 95px;
  height: ${numberPadHeight};
  touch-action: none;

  border-bottom: 1px solid ${({ theme }) => theme.form.borderColor};
`;

export const NumberScreen = styled.div<Fill>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  min-height: 64px;
  height: ${numberScreenHeight};
  margin: 0;
  padding: 0 10px;
  border: none;
  background-color: ${({ theme }) => theme.background.default};
  font-size: 50px;
  color: ${({ theme }): string => theme.numberpad.screen.color};
  overflow: hidden;
  border-bottom: solid 1px ${({ theme }) => theme.form.borderColor};
  border-top: solid 1px ${({ theme }) => theme.form.borderColor};
  box-sizing: border-box;
  white-space: nowrap;
`;

export const ButtonContainer = styled.div<Fill>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: ${numberPadHeight};
`;

export const TopActionButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  name: actionButton;
`;

export const ActionBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  name: actionbar;
`;

export const NumberButtons = styled.div<FullWidth>`
  width: ${numberPadButtonWidth};
  display: flex;
  flex-wrap: wrap;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-row-gap: 1px;
    grid-column-gap: 1px;
  }
`;

export const ActionButtons = styled.div`
  width: 25%;
  flex-direction: column;
`;

export const Button = styled.div`
  display: flex;
  font-size: 32px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const TopActionButton = styled(Button)<DoubleWidth & IsLast & Selected>`
  width: ${topButtonBarWidth};
  height: 83px;
  color: ${actionColour};
  background: ${({ theme }): string => theme.background.toolbar};
  margin: ${topActionButtonMargin};
`;

export const NumberButton = styled(Button)<Fill>`
  width: calc(100% * (1 / 3) - 1px);
  height: ${numberButtonHeight};
  color: ${({ theme }): string => theme.numberpad.number.color};
  background: ${({ theme }): string => theme.numberpad.number.backgroundColor};
  margin: ${({ fillStyle }): string => (fillStyle ? '0' : '0 1px 1px 0')};
  &.tdNumPadDot,
  &.tdNumPad0 {
    margin: 0 1px 0 0;
  }

  &.tdNumPad0 {
    width: calc(100% * (2 / 3) - 1px);
  }

  @supports (display: grid) {
    width: 100%;

    &.tdNumPad0 {
      width: 100%;
      grid-column-start: 1;
      grid-column-end: 3;
    }
    margin: 0;
  }
`;

export const ActionButton = styled(Button)`
  width: 100%;
  color: ${({ theme }): string => theme.button.primary.color};
  height: 167px;
  line-height: 167px;

  &.primary {
    background: ${({ theme }): string => theme.button.primary.backgroundColor};
  }
  &.tdNumPadBk {
    background-color: ${colors.gray600};
    border: none;
    margin: 0 0 1px 0;
  }
  &.tdNumPadEnter {
    height: 167px;
    margin: 0;
  }
`;

export const SuffixContainer = styled.div`
  font: ${({ theme }): string => theme.text.heading2};
  padding-left: 8px;
  color: ${colors.gray300};
`;
