import React from 'react';
import styled from 'styled-components';
import {
  TableCellType,
  TableHeaderType,
  TableOnRowClickType,
  TableIdType,
  TableRowType,
  TableRowPropsType,
} from './types';
import { TableCell } from './TableCell';

type RowRendererType = {
  /**
   * For reading other header properties (such as align, showMobile, etc) for current header
   */
  headerProps?: TableHeaderType[];
  headers?: TableHeaderType[];
  isMobile?: boolean;
  onRowClick?: TableOnRowClickType;
  rowRenderer?: CustomRowRendererType;
  rows?: TableRowType[];
  setRowProps?: (
    onRowClick: TableOnRowClickType,
    rowItemId: TableIdType,
  ) => TableRowPropsType | null;
  showIndent?: boolean;
  spacingBetweenCells?: string;
  spacingBetweenRows?: string;
};
export type CustomRowRendererType = React.ComponentType<CustomRowRendererPropsType>;
export type CustomRowRendererPropsType = {
  rowRendererProps: {
    index: number;
    rowItem: TableRowType;
  };
  selected: boolean;
};

export const RowRenderer = (props: RowRendererType) => {
  const {
    headers,
    headerProps,
    isMobile,
    rowRenderer = DefaultRowRenderer,
    rows,
    onRowClick,
    setRowProps,
    showIndent,
    spacingBetweenCells,
    spacingBetweenRows,
  } = props;
  return (
    <>
      {rows.map((rowItem: TableRowType, i: number) => {
        const rowProps = setRowProps(onRowClick, rowItem.id);
        return (
          <Tr
            key={rowItem.id}
            rowRenderer={rowRenderer}
            rowRendererProps={{
              rowItem,
              index: i,
            }}
            {...rowProps}
            selected={rowItem.selected}
            data-selected={rowItem.selected ? 'true' : undefined}
          >
            {rowItem.content
              .filter(
                (
                  cell: TableCellType,
                  index: number,
                ): boolean => // Filter out results by mobile with header property showMobile
                  // else if it isn't mobile or no headers found show all items.
                  (isMobile && headers[index] && headers[index].showMobile) ||
                  isMobile === false ||
                  headers.length === 0,
              )
              .map((cell: TableCellType, index) => {
                /**
                 * For reading all header properties (such as align, showMobile, etc) for current
                 * header, use propHeaders.
                 */
                const currentHeader = headerProps[index];
                return (
                  <TableCell
                    key={cell.id}
                    spacingBetweenCells={spacingBetweenCells}
                    spacingBetweenRows={spacingBetweenRows}
                    {...currentHeader}
                    showIndent={showIndent}
                  >
                    {cell.value}
                  </TableCell>
                );
              })}
          </Tr>
        );
      })}
    </>
  );
};

const DefaultRowRenderer = ({
  rowRendererProps,
  ...restProps
}: CustomRowRendererPropsType &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>) => (
  <tr {...restProps} />
);

const CustomRowRenderer: React.FunctionComponent<
  React.PropsWithChildren<
    CustomRowRendererPropsType & {
      rowRenderer: React.ComponentType<unknown>;
    }
  >
> = ({ rowRenderer: Renderer, ...rest }) => {
  return <Renderer {...rest} />;
};
const Tr = styled(CustomRowRenderer)`
  background-color: ${({ selected, theme }) => selected && theme.table.rowSelectedBackgroundColor};
`;
