import React from 'react';
import IconStyle from '../IconStyle';
import IconPropsType from '../types';
import IconSvg from './Descending.svg';

export default function IconDescending(props: IconPropsType): JSX.Element {
  return (
    <IconStyle {...props}>
      <IconSvg {...props} aria-label="descending-order" />
    </IconStyle>
  );
}
