import React from 'react';
import styled, { ThemeConsumer, DefaultTheme } from 'styled-components';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { colors } from '../../themes/foundations/colors';

type PropsType = {
  height?: string;
  maxValue?: number;
  minValue?: number;
  text?: string;
  value?: number;
  width?: string;
};

export const ProgressCircle: React.FunctionComponent<React.PropsWithChildren<PropsType>> = ({
  text,
  value,
  minValue,
  maxValue,
  width,
  height,
  children,
}) => {
  const getStyles = ({ theme }: { theme: DefaultTheme }) => ({
    path: {
      stroke: `${colors.green600}`,
    },
    trail: {
      stroke: `${colors.gray100}`,
    },
    text: {
      fill: `${colors.green600}`,
      fontFamily: `${theme.fonts.sans}`,
    },
  });

  return (
    <ProgressCircleContainer height={height} width={width}>
      <ThemeConsumer>
        {(theme: DefaultTheme) => (
          <CircularProgressbarWithChildren
            text={text}
            value={value}
            minValue={minValue}
            maxValue={maxValue}
            strokeWidth={14}
            styles={getStyles({ theme })}
          >
            {children}
          </CircularProgressbarWithChildren>
        )}
      </ThemeConsumer>
    </ProgressCircleContainer>
  );
};

ProgressCircle.defaultProps = {
  text: '',
  width: '32px',
  height: '32px',
};

const ProgressCircleContainer = styled.div<{ height?: string; width?: string }>`
  width: ${(props): string => props.width};
  height: ${(props): string => props.height};
`;

export default ProgressCircle;
