/* eslint react/require-default-props: 0 */
import React, { Component } from 'react';
import styled from 'styled-components';
import Joyride from 'react-joyride';
import Alignment from '../Alignment';
import Button from '../Button';
import Group from '../Group';
import IconButton from '../IconButton';
import { IconClose } from '../Icon';
import Label from '../Label';
import Link from '../Link';
import Paragraph from '../Paragraph';
import Spacer from '../Spacer';
import { colors } from '../../themes';

type PropsType = {
  /**
   * This callback function allows you to view the state of the Guide component
   * https://docs.react-joyride.com/callback
   */
  callback?: (data: Record<string, any>) => void;
  children?: JSX.Element | null;
  /**
   * An object that's passed in to the underlying react-floater component for performing
   * actions such as disabling the animation.
   *
   * https://docs.react-joyride.com/props
   *
   * https://github.com/gilbarbara/react-floater
   *
   * By default the animation has been disabled.
   * This settings can be extended by importing floaterProps from Guide component file.
   */
  floaterProps?: Record<string, any>;
  overlayType?: OverlayType;
  showOverlay?: boolean;
  showSkipButton?: boolean;
  steps?: Record<string, any>[];
  /**
   * Refer to
   * https://github.com/gilbarbara/react-joyride/blob/3e08384415a831b20ce21c8423b6c271ad419fbf/src/styles.js
   * for manual control over styling By default it's using our set styles, which can be imported
   * from this file if necessary in future. By default we use these object properties for setting
   * styles:
   * @param {object} styles.options
   * @param {object} styles.spotlight
   */
  styles?: Record<string, any>;
};

type OverlayType = 'default' | 'transparent';

/**
 * OverlayType lookup Object.
 * SpotlightColor defaults to 'grey' if not provided in the internal library.
 * @type {{default: {overlayColor: string},
 * transparent: {overlayColor: string, spotlightColor: string}}}
 */
export const overlayTypes = {
  default: {
    /**
     * Default overlay color from kountaTheme file.
     */
    overlayColor: 'rgba(74, 74, 74, 0.6)',
    /**
     * Using translucent from kountaTheme file.
     */
    spotlightColor: 'rgba(255,255,255,0.35)',
  },
  transparent: {
    overlayColor: 'transparent',
    spotlightColor: 'transparent',
  },
};

export const defaultFloaterProps = {
  disableAnimation: true,
};

export const defaultJoyRideStyles = {
  options: {
    arrowColor: '#fff',
    backgroundColor: '#fff',
    primaryColor: colors.blue500,
    textColor: colors.gray600,
    beaconSize: '24px',
    /**
     * Default overlay color from kountaTheme file.
     */
    overlayColor: 'rgba(74, 74, 74, 0.6)',
  },
  spotlight: {
    /**
     * Using translucent from kountaTheme file.
     */
    backgroundColor: 'rgba(255,255,255,0.35)',
  },
};

/**
 * A style getter for specified overlayType.
 *
 * @param overlayType
 * @return {{options: ((defaultJoyRideStyles.options&
 * {overlayColor: (string|string)})|
 * ({arrowColor, backgroundColor, beaconSize, primaryColor, textColor, overlayColor}&
 * {overlayColor: (string|string)})), spotlight: {backgroundColor: (string|string)}}}
 */
const getDefaultStyles = (overlayType: OverlayType): Record<string, any> => ({
  options: {
    ...defaultJoyRideStyles.options,
    overlayColor: overlayType
      ? overlayTypes[overlayType].overlayColor
      : overlayTypes.default.overlayColor,
  },

  spotlight: {
    backgroundColor: overlayType
      ? overlayTypes[overlayType].spotlightColor
      : overlayTypes.default.spotlightColor,
  },
});

export default class Guide extends Component<PropsType, any> {
  static defaultProps = {
    steps: [],
    children: null,
    showSkipButton: true,
    callback: () => {},
    showOverlay: false,
    floaterProps: defaultFloaterProps,
    overlayType: 'default',
  };

  constructor(props) {
    super(props);
    this.state = {
      steps: [],
    };
  }

  componentDidMount() {
    if (this.props.steps) {
      this.setState({ steps: this.props.steps });
    }
  }

  render(): JSX.Element {
    const { steps } = this.state;
    const {
      callback,
      children,
      floaterProps,
      overlayType,
      styles: stylesProp,
      showOverlay,
      showSkipButton,
    } = this.props;

    const styles = stylesProp || getDefaultStyles(overlayType);
    return (
      <div>
        <Joyride
          steps={steps}
          callback={callback}
          run
          continuous
          disableOverlay={!showOverlay}
          showSkipButton={showSkipButton}
          scrollToFirstStep
          spotlightPadding={0}
          tooltipComponent={ToolTip}
          styles={styles}
          floaterProps={floaterProps}
        />
        {children}
      </div>
    );
  }
}

const ToolTip = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  skipProps,
  primaryProps,
  tooltipProps,
}: Record<string, any>): JSX.Element => (
  <div {...tooltipProps} style={{ ...step.styles.options }}>
    <TooltipBody {...tooltipProps}>
      {step.title && <Label>{step.title}</Label>}
      <Paragraph>{step.content}</Paragraph>
      <PositionClose>
        <IconButton onClick={closeProps.onClick}>
          <IconClose width={16} height={16} />
        </IconButton>
      </PositionClose>
      <Spacer spacing="s16" />
      <Group horizontal full>
        {step.showSkipButton && <Link onClick={skipProps.onClick}>Skip</Link>}
        <Alignment horizontal="right">
          <Group horizontal align="right" spacing="8px">
            {index > 0 && (
              <Button slim secondary type="button" {...backProps}>
                {backProps.title}
              </Button>
            )}
            {continuous && (
              <Button slim primary type="button" {...primaryProps}>
                {primaryProps.title}
              </Button>
            )}
            {!continuous && (
              <Button slim primary type="button" {...closeProps}>
                {closeProps.title}
              </Button>
            )}
          </Group>
        </Alignment>
      </Group>
    </TooltipBody>
  </div>
);

const TooltipBody = styled.div`
  position: relative;
  background-color: white;
  padding: 24px 24px 16px;
  box-shadow: 1px 3px 14px -2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

const PositionClose = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
`;
