import React from 'react';
import styled from 'styled-components';

type PropsType = {
  templateColumns?: string;
  templateRows?: string;
};

export default class GridContainer extends React.Component<
  React.PropsWithChildren<PropsType>,
  any
> {
  static defaultProps = {
    templateColumns: 'repeat(auto-fill, minmax(128px, 1fr))',
    templateRows: '',
  };

  render(): JSX.Element {
    return (
      <Container
        templateColumns={this.props.templateColumns}
        templateRows={this.props.templateRows}
      >
        {this.props.children}
      </Container>
    );
  }
}

const Container = styled.div<{ templateColumns?: string; templateRows?: string }>`
  display: grid;
  grid-template-columns: ${(props: Record<string, any>): string => props.templateColumns};
  grid-template-rows: ${(props: Record<string, any>): string => props.templateRows};
  grid-gap: 16px;
`;
