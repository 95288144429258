import {
  validateEmail,
  validateName,
  validatePhoneNumber,
} from '../../../../validation/validators/stringValidator';

import { InvalidFieldsType, RequiredType, RequiredReturnType } from '../../types';

const validateForm = function (
  invalidState: InvalidFieldsType,
  requiredObject: RequiredType,
  key: string,
  value: string,
) {
  let { invalidName, invalidEmail, invalidPhoneNo } = invalidState;

  const notRequiredValues = getNotRequiredValues(requiredObject);

  switch (key) {
    case 'name':
      invalidName = !validateName(value, notRequiredValues.minNameVal).isValid;
      break;
    case 'email':
      invalidEmail = !validateEmail(value, notRequiredValues.minEmailVal).isValid;
      break;
    case 'phone':
      invalidPhoneNo = !validatePhoneNumber(
        value,
        value.substring(0, 3),
        notRequiredValues.plusPrefix,
        notRequiredValues.minPhoneVal,
      ).isValid;
      break;
    default:
      break;
  }
  this.setState({ invalidName, invalidEmail, invalidPhoneNo });
};

// Get Minimum value of not required items for validator.
export const getNotRequiredValues = (required: RequiredType): RequiredReturnType => {
  // eslint-disable-next-line one-var
  let plusPrefix = false,
    minPhoneVal = 0,
    minNameVal = 0,
    minEmailVal = 0;

  // If phone, name, and email are not required, minimumValues should be 0.
  // eslint-disable-next-line eqeqeq
  if (typeof required == 'object') {
    const { name, email, phone } = required;

    // @TODO Confirm and handle logic for if these properties are required!

    if (name) minNameVal = 1;
    if (email) minEmailVal = 1;
    if (phone) {
      plusPrefix = false;
      minPhoneVal = 1;
    }
  }
  return { plusPrefix, minNameVal, minEmailVal, minPhoneVal };
};

export default validateForm;
