// @ts-nocheck
import React from 'react';
import styled from 'styled-components';
import RadioButton, { RadioButtonItemType } from '../RadioButton/RadioButton';
import Segment from '../Segment';

import { Mobile, TabletAndDesktop } from '../../utils/responsive';
import DateContainer from '../DatePicker/DateContainer';
import { getSegmentItems, getSegmentValue, DateRangePresetType } from './dateRangeUtils';

type PresetRendererType = {
  onPresetSelect: (e: { id: string } | RadioButtonItemType) => void;
  presets: DateRangePresetType;
  selectedPreset: string;
};

export const PresetRenderer = React.memo(
  ({ presets, selectedPreset = '', onPresetSelect }: PresetRendererType): JSX.Element => {
    const presetOptions = Object.entries(presets).map(([key, { name, value }]) => {
      if (!name || !value || !key) return null;
      return (
        <RadioButton
          key={key}
          inputValue={key}
          label={name}
          checked={key === selectedPreset}
          onChange={onPresetSelect}
        />
      );
    });

    const mobileAndTabletStyle = {
      display: 'grid',
      gridRowGap: '16px',
      gridColumnGap: '16px',
      gridTemplateColumns: '1fr 1fr',
    };

    return (
      <>
        <Mobile>
          <div style={mobileAndTabletStyle}>{presetOptions}</div>
        </Mobile>
        <TabletAndDesktop>
          <Segment
            options={getSegmentItems(presets)}
            onChange={onPresetSelect}
            value={getSegmentValue(presets, selectedPreset).id}
            fullWidth
          />
        </TabletAndDesktop>
      </>
    );
  },
);

export const StyledDash = styled.span`
  margin: 0 8px;
`;

export const DateInputContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const PresetRangeContainer = styled.div`
  position: relative;
  width: ${({ rangeInputWidth }: { rangeInputWidth: string }): string => rangeInputWidth};
`;

export const StyledDatePicker = styled<any>(DateContainer)`
  .DayPicker {
    max-width: -webkit-fill-available;
  }

  @media screen and (max-width: 767px) {
    .DayPicker-wrapper {
      padding-bottom: 128px;
    }
  }
  .DayPicker-Months {
    display: grid;
    width: 100%;
    grid-template-columns: ${({ columns }): string =>
      columns > 1 ? `repeat(${columns}, 280px)` : '1fr'};
    grid-gap: 16px;
  }
  .DayPicker-Month {
    margin: unset;
    margin-top: 1em;
  }
  .DayPicker-Day {
    padding: unset;
    height: 42px;
    width: 42px;
    border-radius: ${({ singleSelect }): string => (singleSelect ? '4px' : '0 !important')};
  }
  .DayPicker-Day--start {
    border-top-left-radius: ${({ singleSelect }): string =>
      !singleSelect ? '42px !important' : ''};
    border-bottom-left-radius: ${({ singleSelect }): string =>
      !singleSelect ? '42px !important' : ''};
  }
  .DayPicker-Day--end {
    border-top-right-radius: ${({ singleSelect }): string =>
      !singleSelect ? '42px !important' : ''};
    border-bottom-right-radius: ${({ singleSelect }): string =>
      !singleSelect ? '42px !important' : ''};
  }
`;

// TODO Move mobile component to it's own file
