import React, { PureComponent } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import AdvancedSelect from '../AdvancedSelect';
import Button from '../Button';
import DatePickerInput from '../DatePickerInput';
import Group from '../Group';
import Popup from '../Popup';
import { MobileAndTablet } from '../../utils/responsive';
import { createDayRangeNumeric } from '../DateRangeInput/dateRangeUtils';
import { DateRangeType as RangeType } from '../../types/internal/DateRangeType';

const DATE_FORMAT = 'DD MMM YYYY';

export type PresetType = 'PAST_30' | 'PAST_7' | 'PAST_1' | 'TODAY';

export type OptionType = RangeType | PresetType;

type AdvancedOptionType = {
  dateRange: () => RangeType;
  label: string;
  value: PresetType | 'DATE_CUSTOM' | 'DATE_RANGE_SELECT';
};

export const PRESET_OPTIONS: {
  [K in PresetType]: {
    dateRange: () => RangeType;
    label: string;
    value: PresetType;
  };
} = {
  PAST_30: {
    value: 'PAST_30',
    label: 'Past month',
    dateRange: (): RangeType => createDayRangeNumeric(30),
  },
  PAST_7: {
    value: 'PAST_7',
    label: 'Past 7 days',
    dateRange: (): RangeType => createDayRangeNumeric(7),
  },
  PAST_1: {
    value: 'PAST_1',
    label: 'Yesterday',
    dateRange: (): RangeType => createDayRangeNumeric(1),
  },
  TODAY: {
    value: 'TODAY',
    label: 'Today',
    dateRange: (): RangeType => createDayRangeNumeric(0),
  },
};

type PropsType = {
  onRangeSelected: (selectedValue: OptionType) => void;
  selectedValue: OptionType;
};

type StateType = {
  customRange: RangeType;
  options: AdvancedOptionType[];
  selectOption: AdvancedOptionType;
};

const StyledDatePickerHolder = styled.div`
  width: 100%;
`;

export const DateRangeContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SingleRangeDatePickerInput = styled(DatePickerInput)`
  width: 120px;
  max-width: 120px;
`;

export const formatDate = (unixDate: number): string => moment.unix(unixDate).format(DATE_FORMAT);

export const formatDateRange = (range: RangeType): string =>
  `${formatDate(range.fromDate)} - ${formatDate(range.toDate)}`;

/**
 * **Deprecated** Alternative choice would be to use DateRangeInput.
 * @deprecated
 */
export default class DateRangeSelect extends PureComponent<PropsType, StateType> {
  static defaultProps = {};

  constructor(props: PropsType) {
    super(props);

    const customOption: AdvancedOptionType =
      typeof props.selectedValue === 'string'
        ? {
            value: 'DATE_RANGE_SELECT',
            label: 'Select date range',
            dateRange: (): RangeType => {
              throw new Error('No range avaliable');
            },
          }
        : {
            value: 'DATE_CUSTOM',
            label: formatDateRange(props.selectedValue),
            dateRange: (): RangeType => this.state.customRange,
          };

    this.state = {
      options: [
        PRESET_OPTIONS.PAST_30,
        PRESET_OPTIONS.PAST_7,
        PRESET_OPTIONS.PAST_1,
        PRESET_OPTIONS.TODAY,
        customOption,
      ],
      selectOption:
        typeof props.selectedValue === 'string'
          ? PRESET_OPTIONS[props.selectedValue]
          : customOption,
      customRange:
        typeof props.selectedValue === 'string'
          ? PRESET_OPTIONS[props.selectedValue].dateRange()
          : props.selectedValue,
    };
  }

  filterClick = () => {
    const selectOption: AdvancedOptionType = {
      value: 'DATE_CUSTOM',
      label: formatDateRange(this.state.customRange),
      dateRange: (): RangeType => this.state.customRange,
    };

    this.setState({ selectOption }, () => {
      const options = this.state.options.filter(
        (x: AdvancedOptionType): boolean => x !== selectOption,
      );

      this.setState({ options });
      if (this.props.onRangeSelected) {
        this.props.onRangeSelected(this.state.customRange);
      }
    });
  };

  advanceOptionChanged = (selectOption: AdvancedOptionType) => {
    this.setState({ selectOption }, () => {
      if (
        this.props.onRangeSelected &&
        this.state.selectOption.value !== 'DATE_RANGE_SELECT' &&
        this.state.selectOption.value !== 'DATE_CUSTOM'
      ) {
        this.props.onRangeSelected(this.state.selectOption.value);
      }
    });
  };

  setFromDate = (date: Date) => {
    this.setState({
      customRange: {
        fromDate: moment(date).startOf('day').unix(),
        toDate: this.state.customRange.toDate,
      },
    });
  };

  setToDate = (date: Date) => {
    this.setState({
      customRange: {
        toDate: moment(date).endOf('day').unix(),
        fromDate: this.state.customRange.fromDate,
      },
    });
  };

  renderConfirmButton = (): JSX.Element => (
    <Button className="dateSection confirmFilter" secondary onClick={this.filterClick} />
  );

  render = (): JSX.Element => {
    const { fromDate, toDate } = this.state.customRange;
    return (
      <StyledDatePickerHolder>
        {this.state.selectOption.value !== 'DATE_RANGE_SELECT' ? (
          <AdvancedSelect
            options={this.state.options}
            onChange={this.advanceOptionChanged}
            defaultValue={this.state.selectOption}
          />
        ) : (
          <ResponsiveRangeSelector
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={this.setFromDate}
            setToDate={this.setToDate}
            confirmButton={this.renderConfirmButton}
          />
        )}
      </StyledDatePickerHolder>
    );
  };
}

type RangeSelectorType = {
  confirmButton: Function;
  fromDate: number;
  setFromDate: (date: Date) => void;
  setToDate: (date: Date) => void;
  toDate: number;
};

type RangeRendererType = RangeSelectorType & {
  mobile: boolean;
};

export const RangeRenderer = ({
  mobile,
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  confirmButton,
}: RangeRendererType): JSX.Element => (
  <DateRangeContainer>
    <Group horizontal={!mobile} spacing="8px">
      <SingleRangeDatePickerInput
        // @ts-ignore
        date={moment.unix(fromDate).format(DATE_FORMAT)}
        placeholder="From"
        onDateChange={setFromDate}
      />
      {!mobile && <span className="rangeSeparator">—</span>}
      <SingleRangeDatePickerInput
        // @ts-ignore
        date={moment.unix(toDate).format(DATE_FORMAT)}
        placeholder="To"
        onDateChange={setToDate}
      />
      {confirmButton()}
    </Group>
  </DateRangeContainer>
);

const WithPopup = ({ desktop, children }: Record<string, any>): JSX.Element =>
  desktop ? <Popup>{children}</Popup> : children;

const ResponsiveRangeSelector = (props: RangeSelectorType): JSX.Element => (
  <MobileAndTablet>
    {(mobile: boolean): JSX.Element => (
      <WithPopup desktop={!mobile}>
        <RangeRenderer {...props} mobile={mobile} />
      </WithPopup>
    )}
  </MobileAndTablet>
);
