import React, { Component } from 'react';
import { IconPropsType } from './types';
import { colors } from '../../themes';

/**
 * Icons are referenced as named components
 */
export default class Icon extends Component<IconPropsType, any> {
  static defaultProps = {
    label: '',
    svgMarkup: '',
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    color: colors.gray700,
  };

  render() {
    const markup = this.props.svgMarkup;

    if (!markup) {
      return null;
    }
    const SVG_REGEX = /(<svg[^>]*>|<\/svg>)/g;
    const FILL_REGEX = /fill="[^"]*"/g;
    const STROKE_REGEX = /stroke="[^"]*"/g;

    const body = markup.toString().replace(SVG_REGEX, '');

    let finalMarkup;
    if (body.match(STROKE_REGEX)) {
      finalMarkup = body.replace(STROKE_REGEX, `stroke=${this.props.color}`);
    } else {
      finalMarkup = body.replace(FILL_REGEX, `fill=${this.props.color}`);
    }
    return (
      <svg
        className="icon"
        xmlns="http://www.w3.org/2000/svg"
        aria-label={this.props.label}
        width={this.props.width}
        height={this.props.height}
        viewBox={this.props.viewBox}
        dangerouslySetInnerHTML={{ __html: finalMarkup }}
      />
    );
  }
}
