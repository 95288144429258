import React, { Component } from 'react';
import styled from 'styled-components';
import Paragraph from '../Paragraph';

const Content = styled.div`
  margin-left: 8px;
`;

const CheckMark = styled.div`
  /* Create a custom radio button */
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: ${({ theme }): string => theme.radio.backgroundColor};
  border: ${({ theme }): string => theme.radio.border};
  border-radius: 50%;

  /* Create the indicator (the dot/circle - hidden when not checked) */
  &:after {
    content: '';
    position: absolute;
    display: none;
  }
  /* Style the indicator (dot/circle) */
  &:after {
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${({ theme }): string => theme.radio.selectedBackgroundColor};
  }
`;

const Input = styled.input`
  display: none;
`;

const Radio = styled.label`
  display: inline-block;
  position: relative;
  padding-left: 24px;
  cursor: pointer;

  /* On mouse-over, add a grey background colour */
  &:hover input ${CheckMark} {
    background-color: ${({ theme }): string => theme.form.hoverBackgroundColor};
  }

  /* When the radio button is checked, add the selected colour */
  ${Input}:checked ~ ${CheckMark} {
    border: ${({ theme }): string => theme.radio.selectedBorder};

    /* Show the indicator (dot/circle) when checked */
    &:after {
      display: block;
    }
  }

  input[disabled] {
    cursor: not-allowed;
  }

  input[disabled] ~ ${Content} {
    cursor: not-allowed;
    opacity: 0.5;
  }

  input[disabled] ~ ${CheckMark} {
    cursor: not-allowed;
    background-color: ${({ theme }): string => theme.radio.inactiveBackgroundColor};
  }
`;

export type RadioInputItemType = {
  checked: boolean;
  inactive?: boolean;
  inputValue: string;
  label?: string;
};

type PropsType = RadioInputItemType & {
  inputName: string;
  onChange: (change: RadioInputItemType) => void;
};

type StateType = {
  checked: boolean;
};

export default class RadioInput extends Component<PropsType, StateType> {
  static defaultProps = {
    checked: false,
    inactive: false,
    label: null,
    onChange: () => {},
  };

  constructor(props: PropsType) {
    super(props);
    this.state = { checked: props.checked };
  }

  UNSAFE_componentWillReceiveProps(nextProps: PropsType) {
    this.setState({ checked: nextProps.checked });
  }

  handleChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange({
      inputValue: changeEvent.target.value,
      checked: changeEvent.target.checked,
    });
  };

  render(): JSX.Element {
    const content = this.props.children ? (
      this.props.children
    ) : (
      <Paragraph>{this.props.label}</Paragraph>
    );

    return (
      <Radio>
        <Input
          value={this.props.inputValue}
          type="radio"
          name={this.props.inputName}
          data-chaminputid="radioInput"
          onChange={this.handleChange}
          checked={this.state.checked}
          disabled={this.props.inactive}
        />
        <CheckMark />
        <Content>{content}</Content>
      </Radio>
    );
  }
}
