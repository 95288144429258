import React, { FC, memo, useState, useEffect } from 'react';

import styled from 'styled-components';

import Box from '../Box';
import Checkbox from '../Checkbox';
import { SpacingTypes } from '../../theme';
import { DataPropsType } from '../../utils/dataAttributes';

type PropsType = DataPropsType & {
  autoWidth?: boolean;
  checked?: boolean;
  element?: React.ReactNode | null;
  forceChecked?: boolean;
  htmlFor?: string;
  id?: string;
  inactive?: boolean;
  indeterminate?: boolean;
  label?: string;
  minimal?: boolean;
  onChange?: (checked: boolean, id: string | null) => void;
  padding?: SpacingTypes;
  truncate?: boolean;
};

const SelectableButton: FC<React.PropsWithChildren<PropsType>> = ({
  autoWidth = false,
  id = '',
  inactive = false,
  indeterminate = false,
  checked,
  forceChecked,
  minimal = false,
  element = null,
  onChange = () => {},
  ...restProps
}) => {
  const [isChecked, setIsChecked] = useState(checked || false);
  const finalCheckState = forceChecked !== undefined ? Boolean(forceChecked) : isChecked;

  useEffect(() => {
    setIsChecked(forceChecked || checked || false);
  }, [checked, forceChecked]);

  function toggleCheck() {
    if (inactive) {
      return;
    }

    setIsChecked(!finalCheckState);

    if (onChange) {
      // Order of arguments here is strange with the id second
      // but that's for backwards compatibility
      onChange(!finalCheckState, id);
    }
  }

  function handleBoxClick(event: React.SyntheticEvent) {
    event.preventDefault();
    toggleCheck();
  }

  return (
    <Box
      selected={finalCheckState || indeterminate}
      spacing={restProps.padding || (minimal ? 's12' : 's24')}
      onClick={handleBoxClick}
      disabled={inactive}
    >
      <Content autoWidth={autoWidth} minimal={minimal}>
        <Checkbox
          {...restProps}
          inactive={inactive}
          hideCheckbox={minimal}
          onChange={toggleCheck}
          checked={finalCheckState}
          padding="24px"
        />
        {element}
      </Content>
    </Box>
  );
};

export default memo(SelectableButton);

const Content = styled.div<{ autoWidth: boolean; minimal?: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: ${({ theme }) => theme.colors.text};

  @supports (display: grid) {
    display: grid;
    grid-template-columns: minmax(${({ autoWidth }) => (autoWidth ? '0' : '72px')}, 1fr) auto;
  }
`;
