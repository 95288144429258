import React, {
  FunctionComponent,
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { debounce } from 'underscore';
import { IconArrowLeft, IconArrowRight } from '../Icon';
import IconButton from '../IconButton';
import { clamp, isEllipsisMarker, paginate } from './paginationUtils';
import { DataPropsType, getDataAttributes } from '../../utils/dataAttributes';
import { borderFocused } from '../../mixins';

export type PaginationPropsType = DataPropsType & {
  className?: string;
  /** The page number to display as selected. This is zero-indexed, so 0 will display as page 1.
   * This component *only* supports operating as a controlled component, so this has to be updated by the consuming component. */
  currentPage: number;
  /** The maximum number of items (pages or ellipsis) to show at once. */
  maxPageDisplay?: number;
  onChange: (page: number) => void;
  /** The total number of pages. */
  pageCount: number;
};

const Pagination: FunctionComponent<PaginationPropsType> = ({
  className,
  data,
  currentPage,
  pageCount,
  maxPageDisplay,
  onChange,
}: PaginationPropsType) => {
  const [screenPageLimit, updateScreenPageLimit] = useState(maxPageDisplay);
  const wrapRef: MutableRefObject<HTMLUListElement> = useRef();
  const theme = useContext(ThemeContext);

  const minPage = 0;
  const maxPage = pageCount - 1;
  /* Because screenPageLimit is only recalculated on resize, maxPageDisplay could have
   * changed in the meantime. Therefore we always need to clamp */
  const pageLimit = clamp(screenPageLimit, 0, maxPageDisplay);
  const pages = paginate(currentPage, minPage, maxPage, pageLimit);
  const arrowSize = 24;

  // If the page count is 1, the arrows will never need to be shown, so we can save some space
  const displayArrows = pageCount > 1;
  const hideLeftArrow = currentPage === minPage;
  const hideRightArrow = currentPage === maxPage;

  const onClickLeft = () => onChange(clamp(currentPage - 1, minPage, maxPage));
  const onClickRight = () => onChange(clamp(currentPage + 1, minPage, maxPage));

  const onResize = debounce(() => {
    if (!wrapRef?.current?.clientWidth) {
      return;
    }

    const list = wrapRef.current;
    const itemSize = ITEM_WIDTH + ITEM_MARGIN;
    const arrowWidth = arrowSize * 2;
    const availableWidth = list.clientWidth - arrowWidth - ITEM_MARGIN;
    const newPageLimit = Math.floor(availableWidth / itemSize);
    updateScreenPageLimit(newPageLimit);
  }, 250);

  useEffect(() => {
    onResize(); // Once on initial page load
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <ListContainer {...getDataAttributes(data)} className={className} ref={wrapRef}>
      {displayArrows && (
        <IconButtonWrap hidden={hideLeftArrow}>
          <IconButton type="button" onClick={onClickLeft}>
            <IconArrowLeft width={arrowSize} height={arrowSize} color={theme.pagination.color} />
          </IconButton>
        </IconButtonWrap>
      )}
      {pages.map((page: number) => {
        if (isEllipsisMarker(page)) {
          return (
            <Item key={page}>
              <ItemLink>...</ItemLink>
            </Item>
          );
        }

        const active = page === currentPage;
        return (
          <Item key={page}>
            <PageLink
              type="button"
              active={active}
              onClick={() => onChange(page)}
              data-active={active ? '' : undefined}
            >
              {page + 1 /* Page is zero-indexed */}
            </PageLink>
          </Item>
        );
      })}
      {displayArrows && (
        <IconButtonWrap hidden={hideRightArrow}>
          <IconButton type="button" onClick={onClickRight}>
            <IconArrowRight width={arrowSize} height={arrowSize} color={theme.pagination.color} />
          </IconButton>
        </IconButtonWrap>
      )}
    </ListContainer>
  );
};

Pagination.defaultProps = {
  maxPageDisplay: 10,
};

export default Pagination;

const ITEM_WIDTH = 32;
const ITEM_MARGIN = 4;
const ListContainer = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  list-style: none;
`;
const IconButtonWrap = styled.div<{ hidden: boolean }>`
  visibility: ${({ hidden }) => hidden && 'hidden'};
  display: inline-flex;
`;
const Item = styled.li`
  display: block;
  margin-left: ${ITEM_MARGIN}px;
`;
const itemLinkCss = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: ${ITEM_WIDTH}px;
  width: ${ITEM_WIDTH}px;
  line-height: normal;
  user-select: none;
`;
const ItemLink = styled.span`
  ${itemLinkCss};
`;
const PageLink = styled.button<{ active: boolean }>`
  ${itemLinkCss};
  cursor: pointer;
  appearance: none;
  border: none;
  border-radius: 4px;
  color: ${({ theme }) => theme.pagination.color};

  &:hover {
    background-color: ${({ active, theme }) => !active && theme.button.hoverBackgroundColor};
  }
  &:focus,
  &:focus-within {
    ${borderFocused};
  }

  color: ${({ active, theme }) => active && theme.pagination.selectedColor};
  background-color: ${({ active, theme }) =>
    active ? theme.pagination.selectedBackground : 'transparent'};
`;
