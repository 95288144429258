import React from 'react';
import styled from 'styled-components';
import { SpacingTypes } from '../../theme';
import { themeGet } from '../../utils/themeGet';

type PropsType = {
  className?: string;
  element?: JSX.Element | null;
  elementOnLeft?: boolean;
  footer?: JSX.Element | null;
  hasBorder?: boolean;
  hasBorderRadius?: boolean;
  height?: string;
  hidePadding?: boolean;
  onClick?: () => void;
  paddingSize?: SpacingTypes;
  width?: string;
};

/**
 * Cards differ from Boxes because they are clickable.
 * In the future we will think about adding actions to a card.
 */
const Card: React.FunctionComponent<React.PropsWithChildren<PropsType>> = ({
  width,
  height,
  hasBorder,
  hidePadding,
  paddingSize,
  element,
  elementOnLeft,
  footer,
  children,
  onClick,
  className,
  hasBorderRadius,
}) => {
  return (
    <CardStyle
      {...{ width, height, hasBorder, hasBorderRadius }}
      onClick={onClick}
      className={className}
    >
      <CardLayout {...{ elementOnLeft, height }} className={elementOnLeft ? 'elementOnLeft' : ''}>
        {element}
        <CardContentStyle {...{ hidePadding, paddingSize, element, elementOnLeft }}>
          {children}
        </CardContentStyle>
        {footer && <div className="footer">{footer}</div>}
      </CardLayout>
    </CardStyle>
  );
};

Card.defaultProps = {
  hasBorder: false,
  height: 'max-content',
  width: '100%',
  hidePadding: false,
  paddingSize: 'medium',
  elementOnLeft: false,
  footer: null,
  hasBorderRadius: true,
  onClick: () => {},
};

const CardStyle = styled.div<{
  hasBorder?: boolean;
  hasBorderRadius: boolean;
  height?: string;
  width?: string;
}>`
  background-color: ${({ theme }): string => theme.card.backgroundColor};
  height: ${({ height }): string => height};
  width: ${({ width }): string => width};
  box-shadow: ${({ theme }): string => theme.card.shadow};
  border: ${({ theme, hasBorder }): string => hasBorder && theme.card.border};
  border-radius: ${({ theme, hasBorderRadius }): string =>
    hasBorderRadius ? theme.card.radius : '0'};
`;

const CardLayout = styled.div<{ elementOnLeft?: boolean; height?: string }>`
  display: flex;
  height: ${({ height }): string => height};
  flex-direction: ${({ elementOnLeft }): string => (elementOnLeft ? 'row' : 'column')};
  clip-path: inset(0 0 0 0 round 4px);

  &.elementOnLeft {
    > * {
      &:nth-child(1) {
        width: calc(100% / 3 * 1);
      }
      &:nth-child(2) {
        width: calc(100% / 3 * 2);
      }
    }
    @supports (display: grid) {
      > * {
        &:nth-child(1) {
          width: 100%;
        }
        &:nth-child(2) {
          width: 100%;
        }
      }
      display: grid;
      grid-template-columns: 1fr 2fr;
    }
  }

  .footer {
    > * {
      border-radius: 0;
    }
  }
`;

const CardContentStyle = styled.div<{
  element?: JSX.Element | null;
  elementOnLeft?: boolean;
  hidePadding?: boolean;
  paddingSize?: string;
}>`
  flex-grow: 1;
  padding: ${({ hidePadding, paddingSize }): any =>
    hidePadding ? '0px' : themeGet(`space.${paddingSize}`)};
  margin-top: ${({ element, elementOnLeft }): string =>
    element && !elementOnLeft ? '-4px' : 'unset'};
`;

export default Card;
