import React, { Component } from 'react';
import styled from 'styled-components';
import { debounce } from 'underscore';
import { IconClear, IconSearch } from '../Icon';
import Link from '../Link';
import Spacer from '../Spacer';
import Input from '../Input';

type PropsType = {
  autoFocus?: boolean;
  cancelLabel?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  onBlur?: () => void;
  onCancel?: () => void;
  onChange?: (value: string) => void;
  onClear?: () => void;
  onEnterKeyPress?: (e?: string) => void;
  /**
   * A Debounced Search callback
   * @returns {string} value at the end of a search.
   * */
  onSearchChanged?: (value: string) => void;
  placeholder?: string;
  position?: string;
  /**
   * The number of characters required to be entered before onChange is called. Default is 0
   */
  searchLength?: number;
  searchText?: string;
  showBorder?: boolean;
};

type StateType = {
  value: string;
};

export default class FullSearch extends Component<PropsType, StateType> {
  static defaultProps = {
    placeholder: 'Search',
    cancelLabel: 'Cancel',
    searchLength: 0,
    searchText: '',
    showBorder: false,
    position: 'inherit',
    autoFocus: true,
    onClear: () => {},
    onChange: () => {},
    onBlur: () => {},
    onCancel: () => {},
  };

  constructor(props) {
    super(props);
    this.state = { value: '' };
  }

  static getDerivedStateFromProps(props: PropsType, state: StateType): Record<string, any> | null {
    if (state.value !== props.searchText) {
      return {
        value: props.searchText,
      };
    }
    return null;
  }

  componentDidMount() {
    if (this.props.autoFocus && this.getInputRef().current) {
      this.getInputRef().current.focus();
    }
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ value: this.props.searchText });
  }

  componentDidUpdate(prevProps: Readonly<PropsType>): void {
    // Update debounced `onSearchChanged` handler when `onSearchChanged` prop is changed.
    if (this.props.onSearchChanged !== prevProps.onSearchChanged) {
      this.onSearchChanged = this.props.onSearchChanged
        ? debounce(this.props.onSearchChanged)
        : undefined;
    }
  }

  inputRef = React.createRef<HTMLInputElement>();

  getInputRef(): React.RefObject<HTMLInputElement> {
    return this.props.inputRef || this.inputRef;
  }

  handleChangeEvent: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    // In React SyntheticEvents are pooled by default and for debounce to work they have to be
    // retained.
    // https://reactjs.org/docs/events.html#event-pooling
    e.persist();
    const text = e.target.value;
    const characterCount = this.props.searchLength;
    if (text.length >= characterCount) {
      this.props.onChange(text);
      if (this.onSearchChanged) {
        this.onSearchChanged(text);
      }
    }
    this.updateValue(text);
  };

  handleKeyDownEvent: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.keyCode === 13 && this.props.onEnterKeyPress) {
      const { value } = e.target as HTMLInputElement;
      this.props.onEnterKeyPress(value);
    }
  };

  handleClearEvent = () => {
    if (this.state.value !== '') {
      if (this.getInputRef().current) {
        this.getInputRef().current.value = '';
      }
      this.updateValue('');
      this.props.onClear();
      if (this.props.autoFocus && this.getInputRef().current) {
        this.getInputRef().current.focus();
      }
    }
  };

  // NOTE: This is updated within `componentDidUpdate` if the `onSearchChanged` prop is changed.
  onSearchChanged = this.props.onSearchChanged && debounce(this.props.onSearchChanged, 250);

  updateValue = (newValue: string) => {
    this.setState({ value: newValue });
  };

  getClearClass(): string {
    const text = this.state.value;
    return typeof text !== 'undefined' && text.length > 0 ? 'clearIcon' : 'hide';
  }

  render(): JSX.Element {
    const clearClassName = this.getClearClass();
    return (
      <FullSearchContainer position={this.props.position}>
        <SearchComponent role="search" showBorder={this.props.showBorder}>
          <div className="searchIcon">
            <IconSearch />
          </div>
          <div
            role="button"
            tabIndex={-1}
            className={clearClassName}
            onClick={this.handleClearEvent}
          >
            <IconClear />
          </div>
          <Input
            placeholder={this.props.placeholder}
            type="text"
            ref={this.getInputRef()}
            data-chaminputid="fullSearchInput"
            // @ts-ignore
            onKeyUp={this.handleChangeEvent}
            onBlur={this.props.onBlur}
            value={this.state.value}
            onChange={this.handleChangeEvent}
            onKeyDown={this.handleKeyDownEvent}
          />
        </SearchComponent>
        <Spacer />
        <Link onClick={this.props.onCancel}>{this.props.cancelLabel}</Link>
      </FullSearchContainer>
    );
  }
}

const FullSearchContainer = styled.div<{ position?: string }>`
  display: flex;
  padding-right: 16px;
  width: 100%;
  position: ${(props: Record<string, any>): string => props.position};
`;

export const SearchComponent = styled.div<{ showBorder?: boolean }>`
  &&&& {
    position: relative;
    margin: 0;
    cursor: pointer;
    width: 100%;

    .searchIcon {
      position: absolute;
      left: 8px;
      top: 12px;
      display: block;
    }

    .hide {
      display: none;
    }
    .clearIcon {
      position: absolute;
      right: 12px;
      top: 14px;

      &:focus {
        outline: none;
      }
    }
  }
`;
