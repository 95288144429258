import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import NumberInput, { PropsType as NumberInputPropsType } from '../NumberInput/NumberInput';
import { colors } from '../../themes';
import { DataPropsType } from '../../utils/dataAttributes';

export type PropsType = NumberInputPropsType &
  DataPropsType & {
    allowNegative?: boolean;
    error?: boolean | null;
    errorMessage?: string;
    errorMessagesAllowed?: boolean;
    highlightBorder?: boolean;
    highlightText?: boolean;
    id?: string;
    inactive?: boolean;
    initialValue?: string;
    inputRef?: React.RefObject<HTMLInputElement>;
    maxWidth?: string;
    name?: string;
    placeholder?: string;
    showBorder?: boolean;
    slim?: boolean;
    value?: string | number;
  };

const QuantityInput: React.FunctionComponent<React.PropsWithChildren<PropsType>> = ({
  slim,
  highlightText,
  highlightBorder,
  showBorder,
  inactive,
  ...restProps
}) => {
  const classes = classnames('textInputContainer', inactive ? 'inactive' : '');
  return (
    <StyledQuantity
      {...{
        slim,
        highlightText,
        highlightBorder,
        showBorder,
        disabled: restProps.disabled,
      }}
    >
      <NumberInput {...restProps} slim={slim} containerClassName={classes} />
    </StyledQuantity>
  );
};

QuantityInput.defaultProps = {
  id: 'Quantity',
  inactive: false,
  initialValue: '',
  value: '',
  allowNegative: true,
  name: 'quantityInput',
  onChange: () => {},
  placeholder: '0',
  errorMessagesAllowed: false,
  error: undefined,
  maxWidth: '56px',
  align: 'center',
  slim: false,
  showBorder: true,
  highlightText: true,
  highlightBorder: true,
};

const StyledQuantity = styled.div<PropsType>`
  .textInputContainer {
    border: ${({ disabled, highlightBorder, theme }): string =>
      !disabled && highlightBorder ? `${theme.border.focusedBorder} !important` : ''};
    border-width: ${(props): string => (!props.showBorder ? '0 !important' : '')};

    input {
      background-color: ${({ theme }): string => `${theme.form.backgroundColor} !important`};
      color: ${({ theme }): string => `${theme.link.primary} !important`};
      font-weight: ${(props): string => (props.highlightText ? 'bold !important' : '')};

      &:disabled {
        background-color: ${({ theme }): string =>
          `${theme.form.inactiveBackgroundColor} !important`};
        color: ${({ theme }): string => `${theme.form.inactiveColor} !important`};
      }
    }

    &.inactive {
      input {
        color: ${({ highlightText }): string =>
          highlightText ? `${colors.gray600} !important` : ''};
        background-color: ${colors.gray00};
      }
    }

    &.error {
      border: ${({ theme }): string => `${theme.border.errorBorder} !important`};
      border-width: ${(props): string => (!props.showBorder ? '0 !important' : '')};
      input {
        color: ${({ theme }): string => `${theme.text.error} !important`};
      }
    }
  }
`;

export default QuantityInput;
