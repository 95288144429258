export const setupClientRects = () => {
  window.HTMLElement.prototype.getBoundingClientRect = (): any => {
    const self = (this as any) ?? null;
    return {
      width: parseFloat(self?.style?.width ?? 0),
      height: parseFloat(self?.style?.height ?? 0),
      top: parseFloat(self?.style?.marginTop ?? 0),
      left: parseFloat(self?.style?.marginLeft ?? 0),
      bottom: 0,
      x: 0,
      y: 0,
    };
  };

  window.HTMLElement.prototype.getClientRects = (): any => {
    const self = (this as any) ?? null;
    return [
      {
        width: parseFloat(self?.style?.width ?? 0),
        height: parseFloat(self?.style?.height ?? 0),
        top: parseFloat(self?.style?.marginTop ?? 0),
        left: parseFloat(self?.style?.marginLeft ?? 0),
        bottom: 0,
        x: 0,
        y: 0,
      },
    ];
  };
};
