import { isFunction } from 'underscore';
import Big, { BigSource } from '@kounta/deprecated-big.js';
import { Currency } from './currency';

type CurrencyOptionsType = {
  currency?: string;
  sign?: string;
};

// Defines the compound type that is acceptable as a numerical value for Big.
type NumericalType = Decimal | number | string;

// @ts-ignore TS does not recognise Big as a class
export class Decimal extends Big {
  // Constructor type definition is required as TS fails to recognise the parent constructor
  // We also need to add this class as a valid constructor value
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(value: BigSource | Decimal) {
    super(value);
  }

  static sumOf(set: Decimal[], prop: string): Decimal {
    let i;
    let j;
    let original;
    let sum;
    const len = set.length;
    sum = new Decimal(0);
    for (j = 0; j < len; j++) {
      i = set[j];
      original = i;
      if (prop != null) {
        i = i[prop];
      }
      if (isFunction(i)) {
        i = i.call(original);
      }
      sum = sum.plus(i != null ? i : 0);
    }
    return sum;
  }

  toInt(): number {
    return Math.floor(this.toFloat());
  }

  toCurrency(options: CurrencyOptionsType = {}): string {
    const currency: Currency =
      typeof options.currency === 'string' ? Currency.get(options.currency) : this.getCurrency();

    if (options.sign === undefined) {
      options.sign = Currency.SIGN_LOCAL;
    }

    return this.format(this, currency, options.sign);
  }

  getCurrency(ccy = 'AUD'): Currency {
    return Currency.get(ccy);
  }

  roundByCurrency(): Decimal {
    return this.round(this.getCurrency().precision, 1);
  }

  trimByCurrency(): Decimal {
    return this.round(this.getCurrency().precision, 0);
  }

  toFloat(): number {
    return parseFloat(this.toString());
  }

  toJSON(): number {
    return this.toFloat();
  }

  toNumber(): number {
    return this.toFloat();
  }

  /**
   * Get the dollar amount in whole integer cents. $11.56 would return 1156.
   */
  cents(): number {
    return this.times(100).toInt();
  }

  // Proxy methods. Since we are extending Big and not modifying the prototype
  // we must be very careful that any method called on the Decimal type does not
  // end up calling the parent method which will instantiate a new Big. Normally
  // this would be find but we have some custom methods on the Decimal class so
  // it needs to remain that type when chaining.
  abs(): Decimal {
    return new Decimal(super.abs());
  }

  add(y: NumericalType): Decimal {
    return new Decimal(super.add(y));
  }

  cmp(amount: NumericalType): number {
    return super.cmp(amount);
  }

  div(y: NumericalType): Decimal {
    const result = new Decimal(super.div(y));

    // KO-9495, KO-9591 - In iOS safari ~ 10.3.1 we've seen some cases
    // if we divide two numbers the result is rounded down
    // e.g. 8 / 1.1 = 7, instead of 7.2727
    // So we can check if we get the incorrect result by multiple the result
    // by the divisor and after round comparing it the dividend
    if (!result.times(y).round(4).eq(this.round(4))) {
      // TODO: remove comments around log if we want this in POS
      // log({
      //   type: 'incorrect_decimal_div_calc',
      //   message: `${this.toString()} / ${y.toString()} != ${result.toString()}`
      // });
      // if we do hit the issue then we do the calculation with
      // floating points in the knowledge it will be correctly rounded
      // later on when it needs to be
      return new Decimal(this.toFloat() / new Decimal(y).toFloat());
    }

    return result;
  }

  eq(amount: NumericalType): boolean {
    return super.eq(amount);
  }

  format(decimalAmount: Decimal, currency: Currency, sign: string = Currency.SIGN_LOCAL): string {
    const thousandsReplace = `$1${currency.thousands}$2$3`;
    const thousandsPattern = /(\d)(\d{3})(,|\.|$)/;
    let amount: any = decimalAmount.roundByCurrency();
    const isNegative = amount.lt(0);
    const numberSign = isNegative ? '-' : '';
    amount = isNegative ? new Decimal(amount.times(-1)) : amount;

    amount = amount.toFixed(currency.precision);
    // @ts-ignore This property doesn't seem to be inherited from Big
    if (this.decimal !== '.') {
      amount = amount.replace('.', currency.decimal);
    }
    while (thousandsPattern.test(amount)) {
      amount = amount.replace(thousandsPattern, thousandsReplace);
    }
    const space = (currency.space && ' ') || '';

    switch (sign) {
      case Currency.SIGN_GLOBAL:
        sign = currency.global;
        break;
      case Currency.SIGN_PORTABLE:
        sign = currency.portable;
        break;
      case Currency.SIGN_LOCAL:
      default:
        sign = currency.sign;
        break;
    }

    if (currency.prepend) {
      return numberSign + sign + space + amount;
    }
    return numberSign + amount + space + sign;
  }

  gt(amount: NumericalType): boolean {
    return super.gt(amount);
  }

  gte(amount: NumericalType): boolean {
    return super.gte(amount);
  }

  lt(amount: NumericalType): boolean {
    return super.lt(amount);
  }

  lte(amount: NumericalType): boolean {
    return super.lte(amount);
  }

  minus(y: NumericalType): Decimal {
    return new Decimal(super.minus(y));
  }

  mod(y: NumericalType): Decimal {
    return new Decimal(super.mod(y));
  }

  mul(y: NumericalType): Decimal {
    return new Decimal(super.mul(y));
  }

  negate(): Decimal {
    return new Decimal(0).minus(this);
  }

  plus(y: NumericalType): Decimal {
    return new Decimal(super.plus(y));
  }

  pow(y: NumericalType): Decimal {
    return new Decimal(super.pow(y));
  }

  round(dp: number, rm?: number): Decimal {
    return new Decimal(super.round(dp, rm));
  }

  sqrt(): Decimal {
    return new Decimal(super.sqrt());
  }

  sub(y: NumericalType): Decimal {
    return new Decimal(super.sub(y));
  }

  times(y: NumericalType): Decimal {
    return new Decimal(super.times(y));
  }

  toFixed(x?: number): string {
    return super.toFixed(x);
  }

  toString(): string {
    return super.toString();
  }
}
