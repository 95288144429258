import React, { FC, memo } from 'react';
import styled from 'styled-components';
import DecimalInput, { PropsType as DecimalInputProps } from '../DecimalInput/DecimalInput';
import IconButton from '../IconButton';
import { IconPlus, IconMinus } from '../Icon';
import { Decimal } from '../../utils';
import { DataPropsType, getDataAttributes } from '../../utils/dataAttributes';

type PropsType = DecimalInputProps &
  DataPropsType & {
    decimalSeparator?: string;
    /**
     * Analytics attribute is applied to the decrement button, increment button, and input with the following respective postfixes:
     * ```[analytics]_decrement```, ```[analytics]_increment```, and ```[analytics]_input```
     */
    placeholder?: string;
    preventInvalidInput?: boolean;
    showBorder?: boolean;
    showHighlight?: boolean;
    slim?: boolean;
    step?: number;
  };

/**
 * Allows the user to increment or decrement a value.
 */
const DecimalCounter: FC<React.PropsWithChildren<PropsType>> = ({
  allowNegative = true,
  data,
  disabled,
  placeholder = '0',
  value = '',
  step = '1',
  maxWidth = '144px',
  decimalSeparator,
  onChange,
  ...restProps
}) => {
  function handleDecrement() {
    const zero = new Decimal('0');
    const oldValue = new Decimal(value || '0');
    let newValue = oldValue.minus(step);
    if (!allowNegative && newValue.lt(zero)) {
      newValue = zero;
    }
    handleChange(newValue.toString());
  }

  function handleIncrement() {
    const oldValue = new Decimal(value || '0');
    const newValue = oldValue.add(step).toString();
    handleChange(newValue);
  }

  function handleChange(newValue: string) {
    if (onChange) {
      onChange(newValue);
    }
  }

  const decrement = (
    <PrefixWrapper>
      <IconButton
        onClick={handleDecrement}
        showOutline={false}
        data={getDataAttributes(data, 'decrement', '')}
        disabled={disabled}
      >
        <IconMinus width={16} height={16} />
      </IconButton>
    </PrefixWrapper>
  );
  const increment = (
    <SuffixWrapper>
      <IconButton
        onClick={handleIncrement}
        showOutline={false}
        data={getDataAttributes(data, 'increment', '')}
        disabled={disabled}
      >
        <IconPlus width={16} height={16} />
      </IconButton>
    </SuffixWrapper>
  );
  return (
    <DecimalInput
      {...restProps}
      variant="short"
      disabled={disabled}
      allowNegative={allowNegative}
      value={value}
      decimalSeparator={decimalSeparator}
      maxWidth={maxWidth}
      prefix={decrement}
      suffix={increment}
      placeholder={placeholder}
      onChange={handleChange}
      data={getDataAttributes(data, 'input', '')}
    />
  );
};

export default memo(DecimalCounter);

const PrefixWrapper = styled.div`
  margin-left: -4px;
`;

const SuffixWrapper = styled.div`
  margin-right: -4px;
`;
