import React from 'react';
import styled from 'styled-components';
import { Heading2 } from '../Heading';
import Label from '../Label';
import Paragraph from '../Paragraph/Paragraph';

const Container = styled.div<{ contextWidth?: string }>`
  .formSectionHeader {
    margin-bottom: 24px;
  }
  .formSectionTitle {
    margin-bottom: 8px;
  }

  @media screen and (min-width: 768px) {
    display: flex;
    .formSectionHeader {
      width: 30%;
    }
    .formSectionContent {
      width: 100%;
    }

    @supports (display: grid) {
      display: grid;
      grid-template-columns: ${(props: PropsType): string => props.contextWidth} 1fr;
      grid-column-gap: 32px;
      .formSectionHeader,
      .formSectionContent {
        width: 100%;
      }
    }
  }
`;

type PropsType = {
  content?: React.ReactNode | null;
  /**
   * This value can be expressed in pixels (100px), percentage, or fr units
   */
  contextWidth?: string;
  label?: string;
  title?: string;
};

const FormSection: React.FunctionComponent<React.PropsWithChildren<PropsType>> = (props) => {
  const getContent = () => {
    if (props.content) {
      if (typeof props.content === 'string') {
        return <Paragraph>{props.content}</Paragraph>;
      }
      return props.content;
    }
    return null;
  };

  return (
    <Container contextWidth={props.contextWidth}>
      <div className="formSectionHeader">
        <div className="formSectionTitle">
          <Heading2>{props.title}</Heading2>
          {props.label && <Label>{props.label}</Label>}
        </div>
        <div className="formSectionSubheading">{getContent()}</div>
      </div>
      <div className="formSectionContent">{props.children}</div>
    </Container>
  );
};

FormSection.defaultProps = {
  title: '',
  label: '',
  contextWidth: '30%',
  content: null,
};

export default FormSection;
